/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {select, call} from 'redux-saga/effects';
import {Selector} from 'containers';
import apiSaga from 'api/apiSaga';
import {lookupProtocol, reverseLookupProtocol} from 'containers/Service/ServiceUtils';
import {getOrgId} from 'containers/User/UserState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {getNoLabelHref, sortOptions} from 'containers/Selector/SelectorUtils';

export const getQuery = (query, keyword, values) => ({
  query,
  ...(values.get('labelsAndLabelGroups')?.length
    ? {
        selected_scope: JSON.stringify(
          values
            .get('labelsAndLabelGroups')
            .filter(({href}) => !href.includes('exists') && !href.includes('all'))
            .map(({href}) => ({[href.includes('label_groups') ? 'label_group' : 'label']: {href}})),
        ),
      }
    : {}),
});

export const populateFacetCategory = ({id, name, facetName, resourceType, noPartial = false, query = {}, params}) => ({
  id,
  name,
  resources: {
    [id]: {
      dataProvider: `${resourceType}.facets`,
      apiArgs: {query: {facet: facetName ?? id, getQuery, ...query}, ...(params && {params})},
      allowPartial: !noPartial,
    },
  },
});

export const populateProtocolFacetCategory = ({id, name, resourceType, query = {}, params}) => {
  return {
    id,
    name,
    resources: {
      [id]: {
        *dataProvider(apiOptions) {
          const {data} = yield call(apiSaga, `${resourceType}.facets`, apiOptions);
          const matches = data?.matches.map(proto => ({
            id: String(proto),
            value: lookupProtocol(proto),
          }));

          return matches;
        },
        apiArgs: {
          query: {
            facet: id,
            getQuery: query => {
              const protocol = reverseLookupProtocol(query);

              return {query: protocol === -1 ? '' : String(protocol)};
            },
            ...query,
          },
          ...(params && {params}),
        },
        allowPartial: false,
      },
    },
  };
};

export const populateAutoCompleteCategory = ({id, name, resourceType, noPartial = false, params}) => ({
  id,
  name,
  resources: {
    [id]: {
      dataProvider: `${resourceType}.autocomplete`,
      apiArgs: {...(params && {params})},
      allowPartial: !noPartial,
    },
  },
});

/**
 *
 * @param {{
 *  resourceType?: string;
 *  hasLabelGroups?: boolean;
 *  hasAll?: boolean;
 *  hasNoLabels?: boolean;
 *  allowCreateOptions?: boolean;
 *  allowMultipleSelection?: boolean;
 *  labelTypesNameObj: Record<string, string>;
 *  labelTypeInitialRegExp: RegExp,
 *  query?: Record;
 *  params?: Record;
 * }} options
 * @returns labelsAndLabelGroups categories
 */
export const populateLabelsCategories = ({
  resourceType,
  hasLabelGroups = false,
  hasAll = false,
  hasNoLabels = false,
  allowCreateOptions = false,
  allowMultipleSelection = false,
  labelTypeInitialRegExp,
  labelTypesNameObj,
  query = {},
  params,
  tooltipProps,
  onSelect,
}) => [
  Selector.categoryPresets.labelsAndLabelGroups({
    hasTypeList: true,
    resourceType,
    hasLabelGroups,
    allowCreateOptions,
    allowMultipleSelection,
    labelTypeInitialRegExp,
    labelTypesNameObj,
    query,
    params,
    tooltipProps,
    onSelect,
  }),
  ...(hasNoLabels
    ? [
        {
          id: 'noLabel',
          name: intl('Common.NoLabel'),
          resources: {
            noLabel: {
              selectIntoResource: 'labelsAndLabelGroups',
              *statics({query, values}) {
                const orgId = yield select(getOrgId);
                const labelTypesNameObj = yield select(getDisplayNames);

                // TODO: use resource.conflict to implement this
                const selectedNoLabels =
                  values.get('labelsAndLabelGroups')?.filter(({href}) => href.includes('exists')) ?? [];

                const noLabels = Object.entries(labelTypesNameObj)
                  .map(([key, name]) => ({
                    href: getNoLabelHref(orgId, key),
                    value: intl('Common.NoLabels', {name}),
                    key,
                  }))
                  .filter(({href}) => !selectedNoLabels.some(selected => selected.href === href));

                return sortOptions(noLabels, query);
              },
            },
          },
        },
      ]
    : []),
  ...(hasAll
    ? [
        {divider: true},
        {
          id: 'allLabel',
          name: intl('Common.All'),
          displayResourceAsCategory: true,
          resources: {
            allLabel: {
              noEmptyBanner: true,
              statics: [intl('Common.All')],
              optionProps: {
                isPill: true,
                pillProps: {icon: 'scope', noContextualMenu: true},
              },
              selectedProps: {
                hideResourceName: true,
                pillPropsValue: {icon: 'scope', noContextualMenu: true},
              },
              onSelect: (evt, {values}) => {
                if (values.has('labelsAndLabelGroups')) {
                  values.delete('labelsAndLabelGroups');
                }
              },
            },
          },
        },
      ]
    : []),
];

export const provisionStatusCategory = {
  id: 'update_type',
  name: intl('Provision.Status'),
  resources: {
    update_type: {
      statics: [
        {value: 'create', text: intl('Provision.PendingAddition')},
        {value: 'delete', text: intl('Provision.PendingDeletion')},
        {value: 'update', text: intl('Provision.PendingModification')},
      ],
      optionProps: {
        idPath: 'value',
        textPath: 'text',
      },
    },
  },
};
