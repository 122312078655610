/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import {clamp} from 'lodash';
import type {Result, ResultStatus} from 'containers/IlluminationMap/MapTypes';
import type {PageBlock, PaginationData} from './MapPaginationTypes';
import {isDownloading, isDownloadProcessing} from 'containers/IlluminationMap/Utils/MapStatusUtils';
import {
  MAX_NUMBER_CONNS_EXPLORER_MAX_DOWNLOAD_RESULTS_DEFAULT,
  MAX_NUMBER_CONNS_EXPLORER_MAX_RESULTS_DEFAULT,
} from 'containers/IlluminationMap/Filter/ControlButtonGroup/ResultsSettingsModal/MapFilterResultsSettingsModal';

export const PAGINATION_BLOCKS_MIDDLE_COUNT = 3;

// Returns an estimated maximum number of matching items that the API will search for in the DB
export const resultExceedsDbMaxResultsLimit = ({
  result,
  maxDownloadResults,
}: {
  result?: Result;
  maxDownloadResults: number;
}): boolean => Boolean(result && maxDownloadResults * (maxDownloadResults > 10_000 ? 1.1 : 2) <= result.matchesCount);

export const getIntegerArray = (n: number, start = 1): number[] => Array.from({length: n}, (_, i) => i + start);

export const getPaginationDetails = ({
  result,
  offset = 0,
  maxResults = MAX_NUMBER_CONNS_EXPLORER_MAX_RESULTS_DEFAULT,
  maxDownloadResults = MAX_NUMBER_CONNS_EXPLORER_MAX_DOWNLOAD_RESULTS_DEFAULT,
}: {
  result: Result;
  offset: number;
  maxResults?: number;
  maxDownloadResults?: number;
}): PaginationData => {
  const {flowsCount = 0, matchesCount = 0} = result ?? {};
  const maxOffset = Math.max(flowsCount - 1, 0);
  const nPages = Math.ceil(flowsCount / maxResults);
  const currentPage = clamp(Math.ceil((offset + maxResults) / maxResults), 0, nPages);
  const pageOffsets = Object.fromEntries(
    Array.from({length: nPages}).map((_, i) => [
      i + 1,
      {
        offsetStart: i * maxResults,
        offsetEnd: Math.min(Math.max((i + 1) * maxResults - 1, 0), maxOffset),
      },
    ]),
  );
  const exceededMaxResultsLimit = resultExceedsDbMaxResultsLimit({result, maxDownloadResults});

  return {
    queryId: result?.queryId,
    offset,
    pageSize: maxResults,
    nPages,
    currentPage,
    pageOffsets,
    flowsCount,
    matchesCount,
    exceededMaxResultsLimit,
  };
};

export const getPaginationProgress = ({
  resultStatus,
  currentPage,
}: {
  resultStatus?: ResultStatus;
  currentPage?: number;
}): {isBusy: boolean; loadingPage?: number} => {
  const downloading = isDownloading(resultStatus);
  const processingAfterDownload = isDownloadProcessing(resultStatus);
  const loadingPage =
    (downloading || processingAfterDownload) &&
    resultStatus?.reason === 'pagination' &&
    resultStatus?.params?.pageNumber === currentPage
      ? currentPage
      : undefined;

  return {
    isBusy: downloading || processingAfterDownload,
    loadingPage,
  };
};

/**
 * Returns an array of page number boxes
 *
 * @param nPages (number, default=0) # of pages
 * @param currentPage: (number, default=0) - current page
 * @param exceededMaxResultsLimit?: (boolean, default=false) when true, a box containing
 *  the "Exceeded Max Results" indicator (asterisk) will be inserted after the last page
 *  number box, before the "Next Page" box.
 */
export const getPageBlocks = ({
  nPages = 0,
  currentPage = 0,
  exceededMaxResultsLimit = false,
}: {
  nPages: number;
  currentPage: number;
  exceededMaxResultsLimit?: boolean;
}): PageBlock[] => {
  nPages ||= 0;
  currentPage ||= 0;

  const blocks: PageBlock[] = [];
  const previousPageNumber = Math.max(currentPage - 1, Math.min(nPages, 1));
  const nextPageNumber = Math.min(currentPage + 1, nPages);

  // add previous page button
  blocks.push({type: 'previous', number: previousPageNumber});

  if (nPages <= 5) {
    /*
     If nPages <= 5, we display all page blocks as shown below.
       nPages = 3; currentPage = 2;
       [<][1][2][3][>]

        nPages = 5; currentPage = 2;
       [<][1][2][3][4][5][>]
     */
    getIntegerArray(nPages).forEach(number => {
      blocks.push({type: 'number', number});
    });
  } else {
    /*
    If nPages > 7, we display them in one of the following formats (depending on offset and nPages)
      nPages = 7; currentPage = 3
      [<][1][2][3][4][.][8][>]

      nPages = 7; currentPage = 4
      [<][1][.][3][4][5][.][8][>]

      nPages = 7; currentPage = 6
      [<][1][.][5][6][7][8][>]
     */

    // first page
    blocks.push({type: 'number', number: 1});

    // leading ellipsis
    if (currentPage > PAGINATION_BLOCKS_MIDDLE_COUNT) {
      blocks.push({type: 'ellipsis'});
    }

    // The "middle" section is comprised of 1 - 3 nodes with pageNumbers from currentPage - 1 to currentPage + 3

    const middleStartMin = Math.min(PAGINATION_BLOCKS_MIDDLE_COUNT - 1 || 0, nPages);
    const middleStart = clamp(
      currentPage - 1,
      middleStartMin,
      Math.max(middleStartMin, nPages - PAGINATION_BLOCKS_MIDDLE_COUNT),
    );

    Array.from({length: PAGINATION_BLOCKS_MIDDLE_COUNT}).forEach((_, i: number) => {
      const number = middleStart + i;

      if (number > 1 && number < nPages) {
        blocks.push({type: 'number', number});
      }
    });

    // "trailing" ellipsis
    if (nPages - currentPage > PAGINATION_BLOCKS_MIDDLE_COUNT - 1) {
      blocks.push({type: 'ellipsis'});
    }

    // "last page" block (currentPage === nPages)
    if (nPages > PAGINATION_BLOCKS_MIDDLE_COUNT) {
      blocks.push({type: 'number', number: nPages});
    }
  }

  if (exceededMaxResultsLimit) {
    blocks.push({type: 'asterisk'});
  }

  // add next page button
  blocks.push({type: 'next', number: nextPageNumber});

  return blocks;
};
