/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';

export default {
  graph: combineReducers({
    openCombos(state = {}, action) {
      switch (action.type) {
        case 'MAP_OPEN_COMBOS_SAVE':
          return action.data;
        case 'MAP_SET_GRAPH_STATE':
          return action.data?.openCombos || state;
        default:
          return state;
      }
    },
    positions(state = {}, action) {
      switch (action.type) {
        case 'MAP_GRAPH_CHART_CHANGED':
          return action.data;
        default:
          return state;
      }
    },
  }),
  serverRoleLabelRecommendations(state = [], action) {
    switch (action.type) {
      case 'SET_SERVER_LABEL_RECOMMENDATIONS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getOpenCombos = state => state.map?.graph?.openCombos;
export const getPositions = state => state.map?.graph?.positions ?? null;
export const getServerRoleLabelRecommendations = state => state.serverRoleLabelRecommendations;

// This should be replaced by an API eventually
export const getLabelTypes = createSelector([], () => [
  {display_name: intl('Common.Location'), key: 'loc'},
  {display_name: intl('Common.Environment'), key: 'env'},
  {display_name: intl('Common.Application'), key: 'app'},
  {display_name: intl('Common.Role'), key: 'role'},
]);

export const getLabelTypeNames = createSelector([getLabelSetting], labelTypes =>
  labelTypes.reduce((result, {key, display_name}) => ({...result, [key]: display_name}), {}),
);

// Converts server roles to href -> server role mapping e.g. {"/orgs/1/labels/xyz" => "AD-Domain-Services"}
export const getIconLabelMappingFromServerRoles = createSelector([getServerRoleLabelRecommendations], serverRoles =>
  serverRoles.reduce((result, serverRole) => {
    serverRole.label_recommendations?.forEach(labelRecommendation => {
      if (labelRecommendation?.href && !result.get(labelRecommendation.href)) {
        result.set(labelRecommendation.href, serverRole.server_role);
      }
    });

    return result;
  }, new Map()),
);
