/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import type {AnyAction} from 'redux';
import type {DefaultRootState} from 'react-redux';
import type {HrefId} from 'utils/href';

declare module 'react-redux' {
  interface DefaultRootState {
    // TODO: should augment user state
    readonly xpressDashboard: {
      readonly serversCount: number;
      readonly protectedServersCount: number;
      readonly unlabeledServersCount: number;
      readonly unprotectedServersCount: number;
      readonly endpointsCount: number;
      readonly protectedEndpointsCount: number;
      readonly unlabeledEndpointsCount: number;
      readonly unprotectedEndpointsCount: number;
      readonly queryId: HrefId;
      readonly inProgressQueryId?: HrefId;
      readonly trafficUpdatedAt: string;
    };
  }
}

export default {
  xpressDashboard(state = {}, action: AnyAction): DefaultRootState {
    switch (action.type) {
      case 'SET_XPRESS_DASHBOARD_DATA':
        return {...state, ...action.data};
      default:
        return state as DefaultRootState;
    }
  },
};

const getXpressDashboard = (state: DefaultRootState) => state.xpressDashboard;

export const getXpressDashboardState = createSelector(getXpressDashboard, dashboardState => dashboardState);
