/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getExplorerRows} from 'containers/IlluminationMap/List/MapListState';
import {providerConsumerOrder} from 'containers/PolicySettings/PolicySettingsState';
import {getLabelTypes} from 'containers/IlluminationMap/Graph/MapGraphState';
import {getRouteParams} from 'containers/App/AppState';
import {getGridSettings} from './RuleImpactConfig';

export default {
  ruleToInspect(state = null, action) {
    switch (action.type) {
      case 'SET_RULE_TO_INSPECT':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRuleToInspect = state => state.ruleToInspect;

const gridSettings = createSelector(
  [providerConsumerOrder, getLabelTypes, getRouteParams],
  (order, labelTypes, {policy}) => {
    return getGridSettings(order, labelTypes, policy || 'reported');
  },
);

export const getRuleImpactExplorerGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getExplorerRows,
  });
