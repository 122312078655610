/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Children, cloneElement, type ComponentPropsWithoutRef, isValidElement} from 'react';
import {tidUtils} from 'utils';
import type {ReactStrictNode} from 'utils/types';
import styles from './ToolBar.css';

const defaultTid = 'comp-toolbar';

export type ToolbarProps = {
  // FIXME: remove this line if eslint(lines-around-comment is fixed on TS interface)
  /** Value for justify-content property */
  justify?: string;
  noWrap?: boolean;
  hasObjectSelector?: boolean;
  tid?: string;
  children?: ReactStrictNode;
} & ThemeProps &
  ComponentPropsWithoutRef<'div'>;

export default function Toolbar(props: ToolbarProps): JSX.Element {
  const {children, justify = 'space-between', noWrap, tid, theme, ...elementProps} = mixThemeWithProps(styles, props);

  elementProps.className = theme.toolBar;
  elementProps.style = {justifyContent: justify, ...(noWrap ? {flexWrap: 'nowrap'} : undefined)};
  elementProps['data-tid'] = tidUtils.getTid(defaultTid, tid);

  return (
    <div {...elementProps}>
      {Children.map(children, child => (isValidElement<ThemeProps>(child) ? cloneElement(child, {theme}) : child))}
    </div>
  );
}
