/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import {Pill} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

export const memberOfFilterMap = createSelector([], () => ({
  labelsAndLabelGroups: intl('Common.Name'),
}));

export const memberOfGridSettings = createSelector([], () => ({
  id: 'memberOflist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showCapacity: true,
  showPagination: true,
  columns: {
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
      format: ({row, value}) => (
        <Pill.Label group position="before" type={row.type}>
          {value}
        </Pill.Label>
      ),
    },
  },
  templates: [
    [{columns: ['name'], size: 'minmax(200px, auto)'}],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['name'], size: 'minmax(200px, auto)'}];
        }

        return [{columns: ['name'], size: 'minmax(200px, auto)'}];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['name'], size: 'minmax(200px, auto)'}];
        }

        return [{columns: ['name'], size: 'minmax(200px, auto)'}];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['name'], size: 'minmax(100px, auto)'}];
        }

        return [{columns: ['name'], size: 'minmax(100px, auto)'}];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['name'], size: 'minmax(100px, auto)'}];
        }

        return [{columns: ['name'], size: 'minmax(100px, auto)'}];
      },
    },
  ],
}));
