/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Link, Pill, StatusIcon} from 'components';
import {createSelector} from 'reselect';
import type {Rule} from 'illumio';
import {formatStatus, getRuleOptionsPills} from 'containers/Ruleset/Item/RulesetItemUtils';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {getRuleServices} from 'containers/Ruleset/Item/Configs/RulesetItemConfig';
import {hrefUtils} from 'utils';

export type RuleTypes = 'allow' | 'deny' | 'override';

interface RuleInspectorRow {
  row: {
    data: {
      rule: Rule;
      oldRule?: Rule;
      rule_set?: {href: string; name: string};
    };
    type: RuleTypes;
  };
}

export const rulesGridSettings = createSelector([], () => ({
  id: 'inspectRule',
  showManager: false,
  columns: {
    state: {
      header: intl('Common.Status'),
      format: formatStatus,
      sortable: false,
    },
    providingServices: getRuleServices('providers'),
    consumingServices: getRuleServices('consumers'),
    arrow: {},
    consumers: {
      header: intl('Common.Consumers'),
      format: ({
        row: {
          data: {rule, oldRule},
        },
      }: RuleInspectorRow) => <Pill.Endpoint type="consumers" showUserGroups value={rule} oldValue={oldRule} />,
      sortable: false,
    },
    providers: {
      header: intl('Common.Providers'),
      format: ({
        row: {
          data: {rule, oldRule},
        },
      }: RuleInspectorRow) => <Pill.Endpoint type="providers" value={rule} oldValue={oldRule} />,
      sortable: false,
    },
    ruleOptions: {
      header: intl('Rulesets.Rules.RuleOptions'),
      format: ({
        row: {
          data: {rule},
        },
      }: RuleInspectorRow) => getRuleOptionsPills(rule).map(({pill: PillOption}) => PillOption),
      sortable: false,
    },
    action: {
      format: ({row}: RuleInspectorRow) => {
        if (!row.type) {
          return null;
        }

        const status = row.type === 'allow' ? 'check' : 'deny';
        const label = row.type === 'allow' ? intl('Common.Allow') : intl('Common.Deny');

        return <StatusIcon status={status} label={label} noTextColor />;
      },
      sortable: false,
    },
    ruleSet: {
      linky: true,
      header: intl('Common.Ruleset'),
      disabled: false,
      sortable: false,
      format: ({
        row: {
          data: {rule_set},
        },
      }: RuleInspectorRow) =>
        rule_set ? (
          <Link to="rulesets.item" params={{id: hrefUtils.getId(rule_set?.href), pversion: 'draft', tab: null}}>
            {rule_set?.name}
          </Link>
        ) : null,
    },
  },
  templates: [
    {
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const isReverseProviderConsumer = this.isReverseProviderConsumer;

        const consumers = [
          {columns: ['consumers'], size: 'minmax(150px, 1fr)'},
          {columns: ['consumingServices'], size: 'minmax(150px, 1fr)'},
        ];

        const providers = [
          {columns: ['providers'], size: 'minmax(150px, 1fr)'},
          {columns: ['providingServices'], size: 'minmax(150px, 1fr)'},
          {columns: ['ruleOptions'], size: 'max-content'},
        ];

        return [
          {columns: ['state'], size: 'minmax(120px, max-content)'},
          ...(isReverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(isReverseProviderConsumer ? providers : consumers),
          {columns: ['action'], size: 'max-content'},
          {columns: ['ruleSet'], size: 'minmax(150px, 0.6fr)'},
        ];
      },
      isReverseProviderConsumer: false,
    },
    {
      maxWidth: 1440,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const isReverseProviderConsumer = this.isReverseProviderConsumer;
        const consumers = [{columns: ['consumers', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
        const providers = [{columns: ['providers', 'providingServices', 'ruleOptions'], size: 'minmax(150px, 1fr)'}];

        return [
          {columns: ['state'], size: 'minmax(120px, max-content)'},
          ...(isReverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(isReverseProviderConsumer ? providers : consumers),
          {columns: ['action'], size: 'max-content'},
          {columns: ['ruleSet'], size: 'minmax(100px, 0.6fr)'},
        ];
      },
      isReverseProviderConsumer: false,
    },
    {
      maxWidth: 1152,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
        };
      },
      template() {
        const isReverseProviderConsumer = this.isReverseProviderConsumer;
        const consumers = ['consumers', 'consumingServices'];
        const providers = ['providers', 'providingServices', 'ruleOptions'];

        return [
          {columns: ['state'], size: 'minmax(120px, max-content)'},
          {
            columns: [
              ...(isReverseProviderConsumer ? consumers : providers),
              'arrow',
              ...(isReverseProviderConsumer ? providers : consumers),
            ],
            size: 'minmax(170px, 1fr)',
          },
          {columns: ['action'], size: 'max-content'},
          {columns: ['ruleSet'], size: 'minmax(100px, 0.6fr)'},
        ];
      },
      isReverseProviderConsumer: false,
    },
    {
      maxWidth: 760,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
        };
      },
      template() {
        const isReverseProviderConsumer = this.isReverseProviderConsumer;
        const consumers = ['consumers', 'consumingServices'];
        const providers = ['providers', 'providingServices', 'ruleOptions'];

        return [
          {
            columns: [
              'state',
              ...(isReverseProviderConsumer ? consumers : providers),
              'arrow',
              ...(isReverseProviderConsumer ? providers : consumers),
            ],
            size: 'minmax(200px, 1fr)',
          },
          {columns: ['action'], size: 'max-content'},
          {columns: ['ruleSet'], size: 'minmax(50px, 0.6fr)'},
        ];
      },
      isReverseProviderConsumer: false,
    },
    {
      maxWidth: 540,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
        };
      },
      template() {
        const isReverseProviderConsumer = this.isReverseProviderConsumer;
        const consumers = ['consumers', 'consumingServices'];
        const providers = ['providers', 'providingServices', 'ruleOptions'];

        return [
          {
            columns: [
              'state',
              ...(isReverseProviderConsumer ? consumers : providers),
              'arrow',
              ...(isReverseProviderConsumer ? providers : consumers),
            ],
            size: 'minmax(120px, 1fr)',
          },
          {columns: ['action'], size: 'max-content'},
          {columns: ['ruleSet'], size: 'minmax(30px, 0.6fr)'},
        ];
      },
      isReverseProviderConsumer: false,
    },
  ],
}));
