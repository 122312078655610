/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import intl from 'intl';
import {Button, StatusIcon} from 'components';
import _ from 'lodash';
import Pill from 'components/Pill/Pill';
import {getLabelSelectorOptionsMap} from 'containers/Label/LabelSettings/LabelSettingState';

export const gridSettings = createSelector([getLabelSelectorOptionsMap], typeMap => ({
  id: 'profilelabellist',
  sort: 'type',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: false,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    type: {
      header: intl('Common.Type'),
      value: 'key',
      format: ({value}) => typeMap.get(value),
    },
    multilabels: {
      header: intl('ContainerClusters.UseContainerAnnotations'),
      value: ({row}) => row.data.multi,
      format: ({row, value}) => {
        return value.map(label => (
          <Pill.Label type={row.data.key} href={label.href}>
            {label.value}
          </Pill.Label>
        ));
      },
    },
    singlelabel: {
      header: intl('ContainerClusters.AssignLabel'),
      value: ({row}) => row.data.single,
      format: ({row, value}) => {
        return (
          value && (
            <Pill.Label type={row.data.key} href={value.href}>
              {value.value}
            </Pill.Label>
          )
        );
      },
    },
    nolabel: {
      header: intl('ContainerClusters.NoLabelAllowedHeader'),
      value: 'nolabel',
      format: ({value}) =>
        value && (
          <div>
            <StatusIcon key="deny" status="deny" position="before" />
            {intl('ContainerClusters.NoLabelAllowedHeader')}
          </div>
        ),
    },
    edit: {
      sortable: false,
      value: 'is_editable',
      format: ({component, row}) => {
        return (
          <Button
            color="standard"
            noFill
            tid="edit"
            icon="edit"
            onClick={component && _.partial(component.handleEditSingleRow, row)}
          />
        );
      },
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['type'], size: 'minmax(50px, auto)'},
      {columns: ['multilabels'], size: 'minmax(100px, auto)'},
      {columns: ['singlelabel'], size: 'minmax(100px, auto)'},
      {columns: ['nolabel'], size: 'minmax(100px, auto)'},
      {columns: ['edit'], size: 'minmax(50px, auto)'},
    ],
    {
      maxWidth: 1024,
      template() {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type'], size: 'minmax(50px, auto)'},
          {columns: ['multilabels'], size: 'minmax(100px, auto)'},
          {columns: ['singlelabel'], size: 'minmax(100px, auto)'},
          {
            columns: ['nolabel'],
            size: 'minmax(100px, auto)',
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type'], size: 'minmax(50px, auto)'},
          {columns: ['multilabels'], size: 'minmax(100px, auto)'},
          {columns: ['singlelabel'], size: 'minmax(100px, auto)'},
          {
            columns: ['nolabel'],
            size: 'minmax(100px, auto)',
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 480,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['singlelabel', 'type'], size: 'minmax(100px, auto)'},
          {columns: ['multilabels'], size: 'minmax(100px, auto)'},
          {
            columns: ['nolabel'],
            size: 'minmax(100px, auto)',
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
  ],
}));
