/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import styles from './Selectors.css';
import * as PropTypes from 'prop-types';
import {PureComponent, createRef} from 'react';
import {Modal, Pill, Tooltip} from 'components';
import {pillIconMap} from './SelectorUtils';

export default class Option extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isCategory: PropTypes.bool,
    isCustom: PropTypes.bool,
    checkbox: PropTypes.bool,
    className: PropTypes.any,
    selected: PropTypes.bool,
    categoryKey: PropTypes.string,
    object: PropTypes.object,
    renderOption: PropTypes.func,
    saveRef: PropTypes.func,
    onHover: PropTypes.func,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    value: PropTypes.any,
  };

  static defaultProps = {
    isCategory: false,
    checkbox: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };

    this.saveRef = this.saveRef.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.optionRef = createRef();
  }

  saveRef(element) {
    this.option = element;
    this.props.saveRef(this);
  }

  handleHover() {
    this.props.onHover(this);

    if (!this.state.showTooltip && this.optionRef.current?.scrollHeight > this.optionRef.current?.clientHeight) {
      this.setState({
        showTooltip: true,
      });
    }
  }

  handleSelect() {
    const {value, categoryKey, onSelect} = this.props;

    onSelect(value, categoryKey);
  }

  handleUnselect(evt) {
    const {value, object, onUnselect} = this.props;

    onUnselect(evt, value, object);
  }

  handleKeyDown(handleOpen, event) {
    if (event.key === 'Enter') {
      // we need to prevent Selector's key down from occurring when we want to open page invoker modal
      event.stopPropagation();
      handleOpen();
    }
  }

  render() {
    const {
      children,
      disabled,
      selected,
      object,
      desc,
      value,
      isCustom,
      isCategory,
      checkbox,
      className,
      pageInvokerRef,
    } = this.props;
    const isLabelObject = object && (object.type === 'labels' || object.type === 'label_groups') && value.key;
    const optionStyles = cx(className, {
      [styles.option]: !isCategory,
      [styles.category]: isCategory,
      [styles.custom]: isCustom,
      [styles.disabled]: disabled,
      [styles.checkbox]: checkbox,
      [styles.labelOption]: isLabelObject,
    });

    const option = isLabelObject ? (
      <Pill.Label group={Boolean(value.name)} type={pillIconMap()[value.key || value.categoryKey] || value.key}>
        {children}
      </Pill.Label>
    ) : (
      children
    );

    if (checkbox) {
      const checkboxListItem = (
        <li
          key={value.href || value.key || value}
          tabIndex={-1}
          className={optionStyles}
          ref={this.saveRef}
          onClick={selected ? this.handleUnselect : this.handleSelect}
          onMouseEnter={this.handleHover}
        >
          <input
            type="checkbox"
            id={children}
            value={children}
            checked={selected}
            onChange={selected ? this.handleUnselect : this.handleSelect}
          />
          <span data-tid="comp-select-results-item-value">{option}</span>
        </li>
      );

      if (value.tooltip) {
        return <Tooltip {...value.tooltip}>{() => checkboxListItem}</Tooltip>;
      }

      return checkboxListItem;
    }

    let listItem;
    const listItemContent = (
      <>
        <div ref={this.optionRef} data-tid="comp-select-results-item-value" className={styles.text}>
          {this.state.showTooltip && <Tooltip reference={this.optionRef} content={option} right fast />}
          {option}
        </div>
        {desc && (
          <div className={styles.secondary} data-tid="comp-select-results-item-description">
            {desc}
          </div>
        )}
      </>
    );

    // page invoker can wrap a category or an option within a category
    if (this.props.pageInvokerProps || value.pageInvokerProps) {
      const pageInvokerProps = this.props.pageInvokerProps ?? value.pageInvokerProps;

      listItem = (
        <Modal.PageInvoker edit pageInvokerRef={pageInvokerRef} onSave={this.props.onSave} {...pageInvokerProps}>
          {({handleOpen}) => (
            <li
              key={value.href || value.key || value}
              tabIndex={-1}
              ref={this.saveRef}
              className={optionStyles}
              onClick={handleOpen}
              onKeyDown={_.partial(this.handleKeyDown, handleOpen)}
              data-tid="comp-select-results-item"
              onMouseMove={this.handleHover}
            >
              {listItemContent}
            </li>
          )}
        </Modal.PageInvoker>
      );
    } else {
      listItem = (
        <li
          key={value.href || value.key || value}
          tabIndex={-1}
          ref={this.saveRef}
          className={optionStyles}
          onClick={this.handleSelect}
          data-tid="comp-select-results-item"
          onMouseMove={this.handleHover}
        >
          {listItemContent}
        </li>
      );
    }

    if (value.tooltip) {
      return <Tooltip {...value.tooltip}>{() => listItem}</Tooltip>;
    }

    return listItem;
  }
}
