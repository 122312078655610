/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {EndpointType, LinkData, MapDisplay} from 'containers/IlluminationMap/MapTypes';
import {isManagedEndpoint, type MapGroupingSettings} from 'containers/IlluminationMap/MapTypes';
import {getId} from 'utils/href';

export const defaultGroupingOrder = ['appGroup', 'loc', 'env'];
export const defaultMaxItems = 20;
export const defaultAutoGrouping = {enabled: true, maxItems: defaultMaxItems, groupingOrder: defaultGroupingOrder};
export const defaultMapFilters = {
  consumerInclude: new Map(),
  providerInclude: new Map(),
  servicesInclude: new Map(),
  consumerExclude: new Map(),
  providerExclude: new Map(),
  servicesExclude: new Map(),
  consumerOrProviderInclude: new Map(),
  consumerOrProviderExclude: new Map(),
};

export const showGroupingSelector = (display: MapDisplay, allowGrouping: boolean): boolean => {
  if (display === 'mesh' && allowGrouping) {
    return true;
  }

  if (display === 'map' && allowGrouping) {
    return true;
  }

  return false;
};

export const calculateLabelCounts = (links: LinkData[]): Record<string, Set<string>> =>
  links.reduce(
    (result, link) => {
      const endpoints: EndpointType[] = ['source', 'target'];

      endpoints.forEach(endpointType => {
        const endpoint = link[endpointType];

        if (isManagedEndpoint(endpoint)) {
          endpoint.details.labels.forEach(label => {
            result[label.key] ||= new Set();

            result[label.key].add(label.href);
          });

          result.appGroup.add(endpoint.details.appGroupId);
          result.labelSets.add(endpoint.details.labels.map(label => getId(label.href)).join(','));
        }
      });

      return result;
    },
    {appGroup: new Set(), labelSets: new Set()} as Record<string, Set<string>>,
  );

export const calculateAutoGrouping = (
  labelCounts: Record<string, Set<string>>,
  groupingSettings: MapGroupingSettings,
): string[] => {
  const {groupingOrder, maxItems} = groupingSettings;
  const allGrouping = ['labelSets', ...(groupingOrder || [])];

  return [
    ...allGrouping.reduce((result: Set<string>, grouping: string, index: number): Set<string> => {
      if (labelCounts[grouping]?.size > maxItems) {
        if (allGrouping[index + 1]) {
          result.add(allGrouping[index + 1]);
        }
      }

      return result;
    }, new Set()),
  ];
};
