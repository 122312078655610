/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createCachedSelector} from 're-reselect';
import {getLabelTypeList} from 'containers/Label/LabelSettings/LabelSettingState';
import {clickableColumn} from 'components/Grid/GridUtils';
import {Modal, Pill} from 'components';
import {edge} from 'api/apiUtils';
import _ from 'lodash';
import styles from 'components/ExpandableList/ExpandableList.css';

const handleToggle = (_, setParentState, expanded) => {
  setParentState({expanded: !expanded});
};

const columnParent = (props, initialCount, showInModalFrom) => {
  const {content, state, setState, row} = props;
  const {expanded} = state;
  let modal;

  if (expanded && row.data.labelsMap?.size >= showInModalFrom) {
    modal = (
      <Modal.Alert
        key="modal"
        title={intl('Common.Labels')}
        gap="gapXSmall"
        onClose={_.partial(handleToggle, _, setState, expanded)}
      >
        {Array.from(row.data.labelsMap, ([key, {href, value, name}]) => (
          <Pill.Label key={key} href={href} group={href.includes('label_groups')} type={edge ? null : key}>
            {value || name}
          </Pill.Label>
        ))}
      </Modal.Alert>
    );
  }

  return (
    <>
      {/*the labels in the column should remain unexpanded*/}
      {content}
      {modal}
    </>
  );
};

const clickableLabelConfig = (key, display_name, labelTypeList, initialCount, showInModalFrom) =>
  clickableColumn({
    header: display_name,
    noPadding: true,
    value: 'labelsMap',
    // showLess column should not be in "customizable columns" dropdown
    sortable: key !== 'showLess',
    format: formatProps => {
      const {value: labelsMap, clickableRef, parentState, setParentState, row, column} = formatProps;

      if (!labelsMap.size) {
        return null;
      }

      const label = labelsMap?.get(key);
      const {expanded} = parentState;

      // don't show anything if:
      // - no label found (no label of current label type) and not mock column 'showLess'
      if (!label && key !== 'showLess') {
        return null;
      }

      let keepCountCols = true;
      let hiddenCols = 0;

      // visible and existing columns
      let renderableColumns = 0;

      for (const [labelKey] of labelsMap) {
        if (row.cells.get(`${column.parentId}-${labelKey}`)?.column.hidden) {
          hiddenCols++;
        } else if (keepCountCols) {
          renderableColumns++;
        }

        if (labelKey === key) {
          if (!expanded) {
            break;
          }

          keepCountCols = false;
        }
      }

      const handleKeyUp = event => {
        if (event.key === ' ' || event.key === 'Enter') {
          // Emulate click on Space and Enter
          setParentState({expanded: true});
        }
      };

      if (
        key !== 'showLess' &&
        (renderableColumns <= initialCount ||
          (renderableColumns > initialCount && expanded && labelsMap.size < showInModalFrom))
      ) {
        return (
          <Pill.Label
            href={label.href}
            group={label.href.includes('label_groups')}
            type={edge ? null : key}
            ref={clickableRef}
          >
            {label.value || label.name}
          </Pill.Label>
        );
      }

      // show link if at the end of list and there are more labels to see
      if (key === 'showLess' && labelsMap.size - initialCount > 0) {
        const renderedCols = renderableColumns > initialCount ? initialCount : renderableColumns;
        const numMore = labelsMap.size - renderedCols;

        return (
          <>
            {expanded && hiddenCols ? <i>{intl('Common.ColumnsHidden', {count: hiddenCols})}</i> : null}{' '}
            <div
              key="more"
              onClick={_.partial(handleToggle, _, setParentState, expanded)}
              onKeyUp={_.partial(handleKeyUp)}
              className={styles.more}
              tabIndex={0}
              ref={clickableRef}
              data-tid="more"
            >
              {expanded && initialCount < showInModalFrom
                ? intl('Common.ShowLess')
                : intl('Common.CountMore', {count: numMore})}
            </div>
          </>
        );
      }
    },
    sort: ({value: labelsMap}) => {
      const label = labelsMap?.get(key);

      return label?.value || label?.name;
    },
  });

export const allSubColConfig = {
  noPadding: true,
  header: intl('Common.All'),
  value: ({row}) => row.data.labelsMap.size === 0,
  format: ({value}) => value && <Pill.Label all />,
};

export const getLabelsColumn = createCachedSelector(
  [
    getLabelTypeList,
    (state, props) => Boolean(props?.hasAll),
    (state, props) => props?.customAll,
    (state, props) => props?.initialCount || 8,
    (state, props) => props?.showInModalFrom || 25,
  ],
  (labelTypeList, hasAll, customAll, initialCount, showInModalFrom) =>
    edge
      ? clickableLabelConfig('role', intl('Common.Group'))
      : {
          header: intl('Common.Labels'),
          horizontal: true,
          columns: [...labelTypeList, {key: 'showLess', display_name: 'showLess'}].reduce(
            (result, {key, display_name}) => ({
              ...result,
              [key]: clickableLabelConfig(key, display_name, labelTypeList, initialCount, showInModalFrom),
            }),
            {
              ...(hasAll && {all: customAll || allSubColConfig}),
            },
          ),

          format: props => columnParent(props, initialCount, showInModalFrom),
          templates: [...(hasAll ? ['all'] : []), ...labelTypeList.map(({key}) => key), 'showLess'],
        },
)((state, props) => (props?.hasAll ? 'hasAll' : 'noAll'));
