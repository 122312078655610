/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {useState, useContext, useCallback, type ReactElement} from 'react';
import Tippy, {type TippyProps} from '@tippyjs/react/headless';
import type {ComputedPlacement, Modifier} from '@popperjs/core';
import type {Plugin, Instance} from 'tippy.js';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import cx from 'classnames';
import {tidUtils} from 'utils';
import {isMotionReduced} from 'utils/dom';
import {getPlacement, placementMap, hideOnScroll} from './TooltipUtils';
import styles from './Tooltip.css';
import Singleton, {SingletonContext} from './TooltipSingleton';
import type {MutuallyExclusive, ReactStrictNode} from 'utils/types';
import type {CamelCase} from 'type-fest';

export type TooltipPlacement = CamelCase<ComputedPlacement>;
export type {ComputedPlacement};

type TooltipLocalPropsWithoutPlacement = {
  // content is not directly passed to Tippy but have the same type as the Tippy prop's content
  content?: TippyProps['content'];

  /**
   * only pass render-able children to tooltip
   */
  children?: ReactStrictNode | (() => ReactElement);
  tid?: string;

  noSingleton?: boolean;

  // props that control what major styles are applied
  /**
   * arrow or no arrow? your choice.
   */
  arrow?: boolean;

  // TODO: should use a literal type for mutually exclusiveness
  /**
   * used if you want the tooltip to use the light theme
   */
  light?: boolean;

  /**
   * used if you want the tooltip to use the dark theme
   */
  dark?: boolean;

  // props for the positioning engine
  /**
   * the positioning engine computes the position of the tooltip based on the element this prop is applied to
   */
  block?: boolean;

  // Although TippyProps['maxWidth'] exist, maxWidth is not passed to Tippy
  // but used by Tooltip internally only. So we type it independently from TippyProps['maxWidth'].
  /**
   * @type [string] you can, and probably should use CSS variables here - e.g. 'calc(var(--100px * 2))'
   * @type [number] but, you can pass a number as well - this will converted to hard px value e.g. 250 -> '250px'
   */
  maxWidth?: string | number;

  /**
   * tells the positioning engine how to place the tooltip if the preferred placement isn't possible
   * **YOU MUST** set the first element of the array to the general position of the preferred placement
   * e.g. {bottomRight: true, flipBehavior: ['bottom', 'topRight', 'topEnd']}, see components page for examples and caveats
   */
  flipBehavior?: TooltipPlacement[];

  /**
   * array of plugins to pass to Tippy
   */
  plugins?: Plugin[];

  /**
   * hide on scroll plugin for uncontrolled tooltip - spread in to plugins array
   */
  disableHideOnScroll?: boolean;

  /**
   * duration of the css animation in milliseconds: [show, hide] - if length is 1, show and hide take the same value
   */
  duration?: number[];

  source?: TippyProps['singleton'];

  popperOptions?: Modifier<unknown, Record<string, unknown>>[];
} & MutuallyExclusive<{
  //
  /**
   * show the tooltip instantly
   */
  instant?: boolean;

  /**
   * show the tooltip fast, with just 100ms delay
   */
  fast?: boolean;
}>;

type TooltipLocalProps = TooltipLocalPropsWithoutPlacement &
  MutuallyExclusive<{
    // props for tooltip placement - these are mutually exclusive
    [K in TooltipPlacement]?: boolean;
  }>;

// Omit some TippyProps because they are handled internally by Tooltip and shouldn't be exposed to consumers
type AllowedTippyProps = Omit<TippyProps, 'maxWidth' | 'placement' | 'singleton' | 'popperOptions' | 'children'>;

/** This is a Discriminated Union, please use `UnionPick` or `UnionOmit` if you want to pick or omit some props */
export type TooltipProps = TooltipLocalProps & AllowedTippyProps & ThemeProps;

/** This is a Discriminated Union, please use `UnionPick` or `UnionOmit` if you want to pick or omit some props */
export type TooltipPropsWithoutPlacement = TooltipLocalPropsWithoutPlacement & AllowedTippyProps & ThemeProps;

Tooltip.Singleton = Singleton;
Tooltip.InteractiveDataAttribute = 'data-interactive';

export default function Tooltip(props: TooltipProps): JSX.Element {
  const {
    children,
    tid,
    light = false,
    dark = true,
    block = false,
    theme,
    content,
    instant = false,
    fast = false,
    // desctructure flattens props and removes union information
    // but it's fine here because we dont need to know that the
    // placement shorthands are mutually exclusive inside Tooltip
    top,
    topStart,
    topEnd,
    right,
    rightStart,
    rightEnd,
    bottom,
    bottomStart,
    bottomEnd,
    left,
    leftStart,
    leftEnd,
    flipBehavior,
    animation,
    maxWidth = 'calc(var(--70px) * 5)',
    arrow = true,
    duration = [275, 250],
    noSingleton = false,
    source,
    trigger = 'mouseenter',
    popperOptions = [],
    onMount,
    onHide,
    disableHideOnScroll = false,
    plugins = [],
    ...restProps
  } = mixThemeWithProps(styles, props);

  // restProps type is not TippyProps because of the excluded prop trigger
  // so we need another variable with type TippyProps
  const tippyProps: TippyProps = restProps;

  tippyProps.appendTo ??= document.body;
  tippyProps.delay ??= [500, 250];
  tippyProps.ignoreAttributes ??= true;
  tippyProps.moveTransition ??= 'transform 0.2s ease-out';

  const context = useContext(SingletonContext);

  // if the visible prop is present, the tooltip is controlled and we can't use trigger prop
  // https://github.com/atomiks/tippyjs-react#visible-boolean-controlled-mode
  if (tippyProps.visible === undefined) {
    tippyProps.trigger = trigger;
  }

  tippyProps.plugins = !disableHideOnScroll ? [...plugins, hideOnScroll] : plugins;

  const [isVisible, setVisibility] = useState<boolean | null>(null);

  // onTrigger, onMount and onHide are tippy.js lifecycle methods
  tippyProps.onMount = useCallback(
    instance => {
      setVisibility(true);

      onMount?.(instance);
    },
    [onMount],
  );

  tippyProps.onHide = useCallback(
    (instance: Instance) => {
      const {popper, unmount} = instance;

      const unmountInstance = () => {
        // this condition prevents an issue with the transition
        // when the user triggers a mouseleave event the transition starts to occur
        // but if they trigger a mouseenter event, prior to the transition finishing....
        // we'll unmount a visible tooltip!
        if ((popper.firstElementChild as HTMLElement | null)?.dataset.state === 'hidden') {
          unmount();
        }

        // need to control when we remove the transitionend listener because it fires multiple times
        popper.firstElementChild?.removeEventListener('transitionend', unmountInstance);
      };

      popper.firstElementChild?.addEventListener('transitionend', unmountInstance);
      setVisibility(false);

      onHide?.(instance);
    },
    [onHide],
  );

  // must call getPlacement with `props`, because shorthand placement props are destructured out of `tippyProps`
  const placement = getPlacement(props);
  const defaultPlacement = 'top';

  // in regards to conditionals related to context?.target && source: https://github.com/atomiks/tippyjs-react#headless-singleton
  if (!noSingleton && context?.target && !source) {
    // this is the target inside of the singleton - overrides certain properties of source when triggered
    return (
      <Tippy
        content={content}
        singleton={context.target}
        reference={tippyProps.reference}
        placement={placement || getPlacement(context.props) || defaultPlacement}
      >
        {typeof children === 'function' ? (
          children()
        ) : children !== undefined && children !== null && children !== false ? (
          <div className={theme[block ? 'block' : 'inline']}>{children}</div>
        ) : undefined}
      </Tippy>
    );
  }

  // render tooltip instantly (this is different than duration which is duration of css animation)
  if (instant || tippyProps.trigger === 'click') {
    tippyProps.delay = [0, null];
  } else if (fast) {
    tippyProps.delay = [100, 250];
  }

  const transitionDuration =
    isVisible === null || isMotionReduced() ? 0 : duration[isVisible ? 0 : duration.length - 1];

  // tippy content can dynamically change when inside of a singleton, but use content for standard tooltips
  tippyProps.render = (attrs, tippyContent) => (
    <div
      {...attrs}
      style={{maxWidth, transitionDuration: `${transitionDuration}ms`}}
      data-state={isVisible ? 'visible' : 'hidden'}
      tabIndex={-1}
      data-interactive={tippyProps.interactive}
      className={cx(theme.tooltip, theme[light ? 'light' : dark ? 'dark' : ''], {
        [theme.top]: attrs['data-placement']?.startsWith('top'),
        [theme.right]: attrs['data-placement']?.startsWith('right'),
        [theme.bottom]: attrs['data-placement']?.startsWith('bottom'),
        [theme.left]: attrs['data-placement']?.startsWith('left'),
      })}
    >
      <div className={theme.content} data-tid={tidUtils.getTid('tooltip', tid)}>
        {tippyContent || content}
      </div>
      {arrow && <div data-popper-arrow="" className={theme.arrow} />}
    </div>
  );

  const hasCustomFlipModifier = popperOptions.some(modifier => modifier.name === 'flip');

  tippyProps.animation ??= true;
  tippyProps.popperOptions = {
    modifiers: [
      ...(!hasCustomFlipModifier
        ? [
            {
              name: 'flip',
              enabled: true, // this should always be true, otherwise you get overflow
              options: {
                fallbackPlacements: flipBehavior?.map(shorthand => placementMap.get(shorthand)),
              },
            },
          ]
        : []),
      ...popperOptions,
    ],
  };

  // this is the source inside the singleton
  if (!noSingleton && source) {
    tippyProps.singleton = source;

    return <Tippy {...tippyProps} />;
  }

  tippyProps.placement = placement || defaultPlacement;

  // standard tooltip
  return (
    <Tippy {...tippyProps}>
      {typeof children === 'function' ? (
        children()
      ) : children !== undefined && children !== null && children !== false ? (
        <div className={theme[block ? 'block' : 'inline']}>{children}</div>
      ) : undefined}
    </Tippy>
  );
}
