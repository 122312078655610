/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {createElement, useCallback} from 'react';
import {Checkbox, IconSort, Button, MenuItem} from 'components';

const defaultAsteriskTid = 'elem-asterisk';

export default function GridCellHead(props) {
  const {
    cell,
    theme,
    breakpoint,
    onClick,
    grid: {columns, sortObject},
  } = props;
  const {columnId: sortColumnId, factor: sortFactor} = sortObject;
  const {id, tid, header, headerDesc} = cell;

  const handleSort = useCallback(
    ({id, sorted, defaultOrder, isDate} = {}, evt) => {
      let sortFactor = sorted;

      if (sortFactor === null) {
        if (defaultOrder) {
          sortFactor = defaultOrder === 'asc' ? undefined : 1; //Determines Next sort order onClick of this cell
        } else if (isDate) {
          sortFactor = 1;
        }
      }

      onClick(evt, {sort: `${sortFactor === 1 ? '-' : ''}${id}`});
    },
    [onClick],
  );

  if (id === 'checkboxes') {
    return (
      <Checkbox
        key={id}
        checked={props.selectedAll}
        disabled={!props.selectableAll}
        hidden={!props.selectableAll}
        onChange={props.onSelectAll}
        theme={theme}
        themePrefix="checkboxCell-"
        data-tid={tid}
        inputProps={{'data-tid': 'elem-input'}}
      />
    );
  }

  const elementArgs = [];

  const cellIsSortable = cell.sortable ?? true;

  const cellSortFactor =
    cell.sorted || cell.templates?.some(id => sortColumnId === `${cell.id}-${id}`) ? sortFactor : undefined;

  // To apply style to specific column headers, rather than all columns, use {column}-customCellHeader
  const classes = cx(theme.cell, theme[id], theme[`${id}-customCellHeader`], props.column.extraCellClasses?.[id], {
    [theme.cellSortSelection]: cellIsSortable && cell.templates?.length,
    [theme.cellSorted]: Boolean(cellSortFactor),
    [theme.cellSortable]: cellIsSortable && !cell.templates?.length,
  });

  elementArgs.push('div', {
    'key': id,
    'data-tid': tid,
    'className': classes,
    ...(cellIsSortable && !cell.templates?.length && {onClick: _.partial(handleSort, cell)}),
  });

  if (cellIsSortable && !cell.templates?.length) {
    elementArgs.push(<IconSort sorted={cellSortFactor} theme={theme} themePrefix="cellHeadSort-" />);
  }

  const showAsterisk =
    typeof cell.showAsterisk === 'function' ? cell.showAsterisk({cell, breakpoint}) : cell.showAsterisk;

  if (showAsterisk) {
    elementArgs.push(
      <span className={theme.asterisk} data-tid={defaultAsteriskTid}>
        *
      </span>,
    );
  }

  if (cellIsSortable && cell.templates?.length) {
    //Render sort selection menu
    const sortSelectionMenu = cell.templates.reduce((result, subColumnId) => {
      const id = `${cell.id}-${subColumnId}`;
      const subColumn = columns.get(id);

      if (subColumn.sortable === false || subColumn.hidden) {
        // Only provide sortable options in sort selection dropdown
        return result;
      }

      result.push(
        <MenuItem
          key={id}
          // TODO: Change to `sort-${id} after Core 22.4.0, so QA can fix automation
          tid={`sort-${subColumn.header}`}
          text={
            <Checkbox
              notChangeable
              label={subColumn.header}
              checked={id === sortColumnId}
              iconOn={<IconSort invisible={id !== sortColumnId} sorted={subColumn.sorted} />}
            />
          }
          onSelect={_.partial(handleSort, subColumn)}
        />,
      );

      return result;
    }, []);

    elementArgs.push(
      <Button.Menu
        noStyle
        noAnimateInAndOut
        menuNoDropdownIcon
        menu={sortSelectionMenu}
        menuProps={{triggerOnHover: true, triggerOnHoverOpenDebounce: 100}}
        menuAlign="left"
        theme={theme}
        themePrefix="sortSelectionButton-"
      >
        <IconSort sorted={cellSortFactor} theme={theme} themePrefix="cellHeadSort-" />
        {cell.header}
        {cellSortFactor && sortColumnId ? (
          <div className={theme.headerDesc}>{intl('Common.SortedBy', {column: columns.get(sortColumnId)?.header})}</div>
        ) : null}
        {headerDesc ? <div className={theme.headerDesc}>{headerDesc}</div> : null}
      </Button.Menu>,
    );
  } else {
    elementArgs.push(typeof header === 'function' ? header.call(props.component, {cell, breakpoint}) : header);

    if (headerDesc) {
      elementArgs.push(<div className={theme.headerDesc}>{headerDesc}</div>);
    }
  }

  return createElement(...elementArgs);
}
