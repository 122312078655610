/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {isUserScoped} from 'containers/User/UserState';

export default {
  appGroup: combineReducers({
    appGroups(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.nodes;
        default:
          return state;
      }
    },
    appGroupLabels(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.labels;
        default:
          return state;
      }
    },
    appGroupRuleCoverage(state = [], action) {
      switch (action.type) {
        case 'APPGROUP_RULE_COVERAGE_GET_LIST':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getAppGroups = state => state.appGroup.appGroups;
export const getAppGroupLabels = state => state.appGroup.appGroupLabels;
export const getAppGroupRuleCoverage = state => state.appGroup.appGroupRuleCoverage;
export const isAppGroupsEnabled = createSelector([getAppGroups, isUserScoped], (appGroups, isUserScoped) => {
  return !isUserScoped || Object.keys(appGroups).length;
});

export const getAppGroupsWithRuleCoverage = createSelector([getAppGroupRuleCoverage], appGroups => {
  return appGroups
    .map(appGroup => {
      const name = appGroup.labels
        .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
        .map(label => label.value)
        .join(' | ');
      const {intra_group, inter_group, ip_list, num_workloads} = appGroup;
      const totalServices =
        (intra_group?.num_services || 0) + (inter_group?.num_services || 0) + (ip_list?.num_services || 0);
      const totalRules = (intra_group?.num_rules || 0) + (inter_group?.num_rules || 0) + (ip_list?.num_rules || 0);
      const coverage = totalServices ? totalRules / totalServices : 0;
      const members = num_workloads;

      return {name, coverage, members, href: appGroup.href, totalServices, totalRules};
    })
    .sort((a, b) => (a.members < b.members ? 1 : a.members > b.members ? -1 : 0));
});
