/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import * as qs from 'qs';
import _ from 'lodash';
import PubSub from 'pubsub';
import {RedirectError} from 'errors';
import {actions} from 'redux-router5';
import {call, spawn} from 'redux-saga/effects';
import * as progressBar from 'progressBar';
import transitionPath from 'router5-transition-path';
import {isSmoothScrollSupported} from 'utils/dom';
import {defaultTimeout, defaultWarningTimeout} from 'api/api';

export default class Prefetcher {
  constructor({store, routesList, routesMap, router}) {
    this.store = store;
    this.routesList = routesList;
    this.routesMap = routesMap;
    this.router = router;
    this.progressBar = progressBar;

    this.fetchedOnce = false;
    this.fetchCancelledByNavigationAlertAction = false;

    this.fetchRoutesSaga = this.fetchRoutesSaga.bind(this);
    this.routeMiddleware = this.routeMiddleware.bind(this);
    this.saveScroll = this.saveScroll.bind(this);
    this.unsavedWarning = this.unsavedWarning.bind(this);

    // Save reference to the #root element for the faster lookups
    this.$root = document.querySelector('#root');

    // cache selection object - we can use getters to check if text is selected, and deselect on navigation
    this.textSelectionObj = window.getSelection();

    // Save scroll upon unloading the page, for instance in cases of page reload (Cmd+R) or restoring the closed page (Cmd+Shift+T)
    window.addEventListener('unload', () => this.saveScroll());
    // Alert user leaving unsaved changes in Form
    window.addEventListener('beforeunload', evt => this.unsavedWarning(evt));
    // Save scroll position after scrolling (by user interaction or by window.scrollTo) is done
    window.addEventListener(
      'scroll',
      _.debounce(() => this.saveScroll(), 100),
      {passive: true},
    );

    // Form is changed
    PubSub.subscribe(
      'FORM.DIRTY',
      formProps => {
        this.formIsDirty = formProps.dirty;
        this.resetForm = formProps.resetForm;
        this.formId = formProps.id;
      },
      {getLast: true},
    );
  }

  clear() {
    this.fetchingTask = null;
    this.finishedFetches = null;
    this.redirecting = false;
    this.redirectingChildren = false;
    this.stateBeforeFetching = null;
    this.canceledPopState = false;
    this.initialFetchError = null;
  }

  async routeMiddleware(toState, fromState) {
    const route = this.routesMap.get(toState.name);

    if (this.canceledPopState) {
      // If we cancel transition which was caused by history or manual url change, router would navigate to the root
      // To workaround that throw string one more time to cancel for sure (#CANCEL_WORKAROUND)
      // https://github.com/router5/router5/issues/249
      // https://github.com/router5/router5/issues/195
      history.replaceState(null, document.title, '#' + fromState.path);

      this.canceledPopState = false;

      throw 'Broken transition canceled'; // eslint-disable-line no-throw-literal
    }

    // Temporary we have to correctly decode params in toState using decodeURIComponent (#ENCODE_WORKAROUND)
    // ToState is officially mutable.
    // Until this is fixed https://github.com/router5/router5/issues/427
    for (const [name, value] of Object.entries(toState.params)) {
      if (typeof value === 'string') {
        toState.params[name] = decodeURIComponent(value);
      }
    }

    if (this.formIsDirty && (toState.meta?.source === 'popstate' || !toState.meta.options?.noUnsavedPendingWarning)) {
      // Cancel confirmation is mounted when noUnsavedPendingWarning is false, noUnsavedPendingWarning is false by default
      // However, skip noUnsavedPendingWarning flag if it's history url change
      // we may still want to notify users that there are unsaved changes in the current route
      const answer = await new Promise(resolve => PubSub.publish('UNSAVED.WARNING', {resolve}));

      if (answer === 'cancel') {
        if (window.location?.href?.endsWith(toState.path) || window.location?.href?.endsWith(`${toState.path}/`)) {
          // If location equals to target state, means url has been changed from the outside (manually or by browser history buttons)
          // In that case replace history state to rollback to previous url manually on next middleware call (#CANCEL_WORKAROUND)
          this.canceledPopState = true;
        }

        throw 'Transition canceled by user'; // eslint-disable-line no-throw-literal
      } else {
        this.formIsDirty = false;

        if (typeof this.resetForm === 'function') {
          this.resetForm();
        }
      }
    }

    // Also have to rebuild path with decoded params (#ENCODE_WORKAROUND)
    toState.path = this.router.buildPath(toState.name, toState.params);

    if (route.redirectTo) {
      if (__DEV__ && !this.routesMap.has(route.redirectTo)) {
        throw new Error(`Route '${route.redirectTo}' doesn't exist, check 'forwardTo' on '${toState.name}' route`);
      }

      const redirect = {
        name: route.redirectTo,
        params: {
          // First apply default params on target routes, then take from current state and then assign from redirectParams
          ...this.routesMap.get(route.redirectTo).autoParams,
          ...toState.params,
          ...route.redirectParams,
        },
      };

      throw {redirect}; // eslint-disable-line no-throw-literal
    }

    const currentRouteState = this.router.getState();

    // If user focus url bar and press enter, do nothing, but don't throw error
    if (currentRouteState && currentRouteState.path === toState.path) {
      const message = `Transition canceled due to routing to same path as current: ${toState.path}`;

      // If content is rendered and user navigates to target page
      // and while redirect is in progress, user navigates to the same target then cancel current redirect and start again
      if (window.contentRendered) {
        this.fetchCancel(true);
        this.fetchFinish({progress: 'rewind'});
      }

      console.info(message);

      if (toState.meta?.source === 'popstate' || !window.contentRendered) {
        // Don't throw if it's history/manual url change, just exit https://github.com/router5/router5/issues/249
        // Or, if target is same as source, and it's the initial load, then just exit
        return;
      }

      throw message;
    }

    const {toActivate, toDeactivate, intersection} = transitionPath(toState, fromState);
    const intersectionRouteNames = getRouteListFromRouteName(intersection);

    // If just a query parameter of parent router has been change, router 5 returns it and all its children in toActivate and toDeactivate,
    // So to get routes that really appeared or changed, compare toActivate and toDeactivate manually
    // All should be activated and fetched if it's a redirect
    const toActivateReally = this.redirecting
      ? toActivate
      : toActivate.reduce(
          (result, name) => {
            if (
              !toDeactivate.includes(name) ||
              result.urlParamChanged ||
              Object.entries(toState.meta.params[name]).some(([param, type]) => {
                const changed = toState.params[param] !== fromState.params[param];

                if (changed && type === 'url') {
                  // If url (not query) param changes all nested routes should be activated
                  result.urlParamChanged = true;
                }

                return changed;
              })
            ) {
              result.toActivateReally.push(name);
            }

            return result;
          },
          {toActivateReally: [], urlParamChanged: false},
        ).toActivateReally;

    const {href} = window.location;
    const {name, params} = toState;
    const query = href.includes('?') ? qs.parse(href.substr(href.lastIndexOf('?') + 1)) : {};

    this.previous = {
      toState: this.toState,
      fromState: this.fromState,
      toActivate: this.toActivate,
      toActivateReally: this.toActivateReally,
      toDeactivate: this.toDeactivate,
      intersection: this.intersection,
    };

    this.toState = toState;
    this.fromState = fromState;
    this.toActivate = toActivate;
    this.toActivateReally = toActivateReally;
    this.toDeactivate = toDeactivate;
    this.intersection = intersection;
    this.options = {name, params, query, router: this.router, prefetcher: this};

    // Check if one of the intersection routes handles children prefetch on children transition manually
    const parentRouteHandlesTransition = intersectionRouteNames.some(routeName => {
      const {prefetchChildrenByComponent} = this.routesMap.get(routeName);

      return ['child-transition', 'always'].includes(prefetchChildrenByComponent);
    });

    if (parentRouteHandlesTransition) {
      this.managePageScroll();

      // If one of the parent routes always handles routes transition of its children by itself, just exit - change route immediately
      return;
    }

    // Fetch containers on demand via dynamic imports.
    try {
      await this.getContainersToImport(name);
    } catch (error) {
      if (!this.fromState && this.toState) {
        this.initialFetchError = error;

        return;
      }

      PubSub.publish('NAVIGATION.ALERT', {error});

      return;
    }

    const {routesToPrefetch, continueProgressBarOnChildrenRender} = this.getRoutesToActivate(name);

    if (!routesToPrefetch.length) {
      this.managePageScroll();

      if (this.redirecting) {
        // If we're redirecting to route without fetches, just finish batch and progressBar
        this.fetchFinish({progress: continueProgressBarOnChildrenRender ? false : 'end', batchEnd: true});
      } else if (this.fetchingTask) {
        // If user clicked on different link without fetches while current fetch is still happening, cancel it and finish batch
        this.fetchCancel(true);
        this.fetchFinish({progress: continueProgressBarOnChildrenRender ? false : 'end'});
      }

      if (!continueProgressBarOnChildrenRender) {
        this.pageReadyPublish(toState);
      }

      return;
    }

    if (this.redirecting) {
      // If we're redirecting within this middleware fetch, do not start batching and continue progressBar
      this.finishedFetches = null;
      this.redirecting = false;
      this.store.batchStart();
      this.progressBar.start({rewind: false});
    } else {
      if (this.fetchingTask) {
        // If there is a fetching task, means user clicked on different link with fetches while current fetch is still happening
        // In that case cancel current fetch, reset store but continue batching
        this.fetchCancel();
      } else {
        // If user navigates from idle state, start batching
        this.store.batchStart();
      }

      // Save current state on batching start, so we can reset it if navigation cancelled/redirected
      this.stateBeforeFetching = this.store.getState();

      if (this.fetchedOnce) {
        // If it's not the very first middlewere call (in this case progressBar has already been started by entry.js)
        // and it is not redirect, then start progressBar
        // or restart it if user clicked on different link while current fetch is still happening
        this.progressBar.start({rewind: true});

        // Notify navigation popup to close
        PubSub.publish('NAVIGATION.ALERT', null);
      }
    }

    this.fetchDispatch(routesToPrefetch);

    // Spawn fetch task
    const fetchingTask = this.fetch(routesToPrefetch);

    // Listen to escape key to cancel transition
    let fetchCancelledByEsc = false;
    const handleEsc = evt => {
      if (evt.keyCode === 27) {
        evt.stopPropagation();

        if (this.fetchedOnce) {
          fetchCancelledByEsc = true;

          // Notify navigation popup to close
          PubSub.publish('NAVIGATION.ALERT', null);
          this.fetchCancel(true);
        }
      }
    };

    window.addEventListener('keyup', handleEsc);

    try {
      // Waiting for task completion
      await fetchingTask.toPromise();
    } catch (error) {
      if (error instanceof RedirectError) {
        const redirect = {name: error.to ? `app.${error.to}` : name, params: error.params};

        // If it's redirect error check that redirect target is not the same that we navigating from
        // (if we throw redirect to tha same as from, than fromState will be null on next middleware)
        if (!fromState || redirect.name !== fromState.name || !_.isEqual(redirect.params, fromState.params)) {
          if (__DEV__) {
            console.info(`Redirecting to ${decodeURIComponent(this.router.buildUrl(redirect.name, redirect.params))}`);
          }

          if (error.details.proceedFetching) {
            // If fetching should be continued (data, fetched before RedirectError was thrown, should remain in store),
            // set redirecting flag so on next middleware run, which will be called by redirect, avoid starting new batch or progressBar
            this.redirecting = true;
          } else {
            // Redirect without continuing means throw away currently fetched data and start over on next middleware run
            // Essentially that means the same as user click on different link while current transition is still fetching
            // In that case we're just redirecting and next middleware run will handle it the same way as user click
          }

          // Router5 looks at redirect property of plain object to make redirect on transition interruption
          throw {redirect}; // eslint-disable-line no-throw-literal
        } else {
          // Notify navigation popup to close if the target is same as source
          PubSub.publish('NAVIGATION.ALERT', null);
        }
      } else {
        // Notify navigation popup to render error message
        PubSub.publish('NAVIGATION.ALERT', {error});
      }

      if (window.rendered) {
        // If it is not redirect error, reset state, rewind progressBar and throw error object to cancel transition
        this.fetchCancel(true);
        this.fetchFinish({progress: 'rewind'});
        Object.assign(this, this.previous);

        if (href.endsWith(toState.path)) {
          // If location equals to target state, means url has been changed from the outside (manually or by browser history buttons)
          // In that case replace history state to rollback to previous url manually on next middleware call (#CANCEL_WORKAROUND)
          this.canceledPopState = true;
        }

        console.groupCollapsed('Transition canceled by fetcher');
        console.info(error);
        console.groupEnd();

        throw 'Transition canceled'; // eslint-disable-line no-throw-literal
      } else {
        // If it's initial fetch (AppSaga) and it is failed, don't throw an exception,
        // but rather stop it here to be able to render App.js with the navigaion error message
        this.fetchFinish({progress: 'end', batchEnd: true});
        this.pageReadyPublish(toState, false);
        this.initialFetchError = error;

        return;
      }
    } finally {
      window.removeEventListener('keyup', handleEsc);
    }

    if (fetchingTask.isCancelled()) {
      // If there was no error caught, but fetchingTask is cancelled
      // means user clicked on other link while current fetch was running - do nothing in that case, just exit.
      if (fetchCancelledByEsc || this.fetchCancelledByNavigationAlertAction) {
        this.fetchCancelledByNavigationAlertAction = false;
        // But if fetch was cancelled by Esc key, then cancel fetch and throw error object to cancel transition
        this.fetchFinish({progress: 'rewind'});
        Object.assign(this, this.previous);

        console.info('Transition canceled by user');

        // #CANCEL_WORKAROUND
        if (href.endsWith(toState.path)) {
          this.canceledPopState = true;
        }

        throw 'Transition canceled'; // eslint-disable-line no-throw-literal
      }

      return;
    }

    // If fetch is done normally, restore page scroll (if it's a history navigation) or smoothly scroll to the top
    this.managePageScroll();

    // If fetch is done normally, finish batch and progressBar if child PrefetchRouteChildren doesn't handle it
    this.fetchFinish({progress: continueProgressBarOnChildrenRender ? false : 'end', batchEnd: true});

    if (!continueProgressBarOnChildrenRender) {
      this.pageReadyPublish(toState);
    }
  }

  fetchDispatch(routes) {
    // Before dispatching data fetch start
    // Notify navigation popup with current timestamp and timeout to set timers for timeout warning and error messages
    PubSub.publish('NAVIGATION.ALERT', {
      errorTimeout: routes.component?.timeout || defaultTimeout,
      warningTimeout: routes.component?.warningTimeout || defaultWarningTimeout,
      timestamp: Date.now(),
    });
    this.store.dispatch({
      type: 'FETCHING_DATA_START',
      routes: routes.map(route => {
        const {component, parents, children, childrenToFetch, ...pureRoute} = route;

        pureRoute.parents = parents.map(({component, parents, children, childrenToFetch, ...pureParent}) => pureParent);
        pureRoute.children = children.map(({component, parents, children, childrenToFetch, ...pureChild}) => pureChild);
        pureRoute.childrenToFetch = childrenToFetch.map(
          ({component, parents, children, childrenToFetch, ...pureChild}) => pureChild,
        );

        return pureRoute;
      }),
    });
  }

  fetch(routes) {
    this.fetchingTask = this.store.runSaga(this.fetchRoutesSaga, routes);

    return this.fetchingTask;
  }

  // Store will be notified by @@router5/TRANSITION_* after this middlware finishes
  fetchFinish({progress, batchEnd, batchNotify = false} = {}) {
    this.clear();

    this.fetchedOnce ||= true;

    if (batchEnd) {
      this.store.dispatch({type: 'FETCHING_DATA_END'});
      this.store.batchEnd(batchNotify);
    }

    if (progress) {
      this.progressBar[progress]();
    }
  }

  fetchCancel(batchEnd, cancelledByNavigationAlertAction = false) {
    if (this.fetchingTask) {
      if (this.fetchingTask.isRunning()) {
        this.fetchingTask.cancel();
      }

      if (this.stateBeforeFetching) {
        this.store.reset(this.stateBeforeFetching);
        this.stateBeforeFetching = null;
      }

      if (batchEnd) {
        this.store.batchEnd();
      }

      if (cancelledByNavigationAlertAction) {
        this.fetchCancelledByNavigationAlertAction = true;
      }

      this.clear();
    }
  }

  *fetchRoutesSaga(routes) {
    const finishedFetches = new Set();

    for (const route of routes) {
      try {
        const {prefetch} = route.component;
        const params = {...this.options, route};

        prefetch.refetch = this.refetch.bind(this, prefetch, params);
        prefetch.refetchAsync = (...args) => {
          this.store.runSaga(prefetch.refetch, ...args);
        };

        yield call(prefetch, params);

        finishedFetches.add(route.name);
      } catch (error) {
        if (error instanceof RedirectError && error.details.proceedFetching && error.details.thisFetchIsDone) {
          finishedFetches.add(route.name);
        }

        this.finishedFetches = finishedFetches;
        throw error;
      }
    }

    return finishedFetches;
  }

  // Method attached to all prefetch sagas,
  // that calls that saga with the same parameters and refetch flag,
  // and that transforms RedirectError to navigate action (because it's not transition phase, so we have to navigate manually)
  *refetch(prefetch, params, ...args) {
    try {
      this.fetchingTask = yield spawn(prefetch, params, true, ...args);

      yield this.fetchingTask.toPromise();
    } catch (error) {
      if (error instanceof RedirectError) {
        const to = error.to ? `app.${error.to}` : params.route.name;
        const navigate = [to, error.params, {reload: false, replace: true}];

        this.store.dispatch(actions.navigateTo(...navigate));

        return navigate;
      }

      this.fetchingTask = null;
      throw error;
    }

    this.fetchingTask = null;
  }

  managePageScroll() {
    if (this.pageHeightResetTimeout) {
      clearTimeout(this.pageHeightResetTimeout);
    }

    this.scrollRestored = null;
    this.rootHeightResetTimout = null;

    if (history.state?.path === this.toState?.path && history.state?.scrollPos) {
      // Instantly restore scroll position from history object:
      // 1. On move backward/forward in history from other domain: fromState null, toState source is 'undefined'
      // 2. On move backward/forward in history, toState source is 'popstate'
      // 3. On page refresh: toState source is 'undefined', history state object contains the page scroll position
      const {top, left, height} = history.state.scrollPos;
      const scrollTo = {top, left, behavior: 'auto'};

      // Restore document height on moving forward/backward in history to prevent page from jumping to the top first
      this.$root.style.height = Math.max(height, this.$root.scrollHeight) + 'px';

      // Since there is no smooth scrolling, immediately scroll
      window.scrollTo(...(isSmoothScrollSupported ? [scrollTo] : [scrollTo.left, scrollTo.top]));
      this.scrollRestored = true;

      // Right after rendering reset #root to automatic content height once scrolling is complete and content is rendered
      this.rootHeightResetTimout = 0;
    } else if (!this.fromState) {
      // Save scrollPos on initial page loading (with empty history) with initial values (0, 0)
      // So if we immediately navigate to another page, scroll there and press history back we would jump to the top
      this.saveScroll();
    } else if (this.toState.meta.options?.scrollTop) {
      // Scroll to the top of the page on navigation when scrollTop flag is set
      // Scroll with smooth behavior when scrollTop flag value is 'smooth'
      const scrollTo = {left: 0, top: 0, ...(this.toState.meta.options.scrollTop === 'smooth' && {behavior: 'smooth'})};

      // Save scroll position on regular navigation right before url and history.state are changed
      const {left, top} = this.saveScroll();

      if (left || top) {
        // If current page is scrolled, either window.scrollX or window.scrollY is non zero, then
        // Fix current height on navigation to prevent page with size-watcher from jumping to the top because there might be a render lag.
        // But not more than 2000px to prevent animation taking too much time
        this.$root.style.height = Math.min(2000, this.$root.scrollHeight) + 'px';

        // Need to delay scroll animation to wait for fixed height on $root to be applied,
        // otherwise animation duration will be computed by browser based on current $root height which might be more than 2000px
        setTimeout(() => {
          window.scrollTo(...(isSmoothScrollSupported ? [scrollTo] : [scrollTo.left, scrollTo.top]));
        });
      }

      // Reset #root to automatic content height once scrolling animation is complete and content is rendered
      this.rootHeightResetTimout =
        this.toState.meta.options?.scrollTop === 'smooth' && isSmoothScrollSupported ? 1000 : 0;
    }
  }

  saveScroll(left = window.scrollX, top = window.scrollY, height = document.body.scrollHeight) {
    history.replaceState(
      {
        ...history.state,
        scrollPos: {left, top, height},
      },
      document.title,
      `#${history.state?.path ?? '/'}`,
    );

    return {left, top, height};
  }

  unsavedWarning(evt) {
    if (this.formIsDirty) {
      // Close the cancel confirmation modal
      PubSub.publish('UNSAVED.WARNING', null);
      // Cancel the event
      evt.preventDefault();
      // Chrome requires returnValue to be set
      evt.returnValue = '';
    }
  }

  pageReadyPublish(toState, closeNavAlert = true) {
    // some unique edge cases related to text selection persisting after navigation - deselect if text selection still persists
    if (this.textSelectionObj.toString()) {
      this.textSelectionObj.empty();
    }

    if (closeNavAlert) {
      // Notify navigation popup to close
      PubSub.publish('NAVIGATION.ALERT', null);
    }

    if (this.pageReadyPublishTimeout) {
      clearTimeout(this.pageReadyPublishTimeout);
    }

    // Publish event to notify QA about page readiness
    // In case of using ResizeObserver polyfill increase waiting time,
    // because instead of using microtask it uses combination of microtasks, timeouts and animation frames
    this.pageReadyPublishTimeout = setTimeout(
      () => {
        PubSub.publish('PAGE.READY', toState.path);
        this.pageReadyPublishTimeout = null;
      },
      window.__RO_POLYFILL__ ? 300 : 0,
    );

    if (typeof this.rootHeightResetTimout === 'number') {
      this.pageHeightResetTimeout = setTimeout(() => {
        this.$root.style.height = 'auto';
        this.rootHeightResetTimout = null;
        this.scrollRestored = null;

        if (window.scrollX === 0 && window.scrollY === 0) {
          // Save zero scroll in history for pages that are not scrolled to prevent initial load scroll animation
          // when we move forward/backward in history
          this.saveScroll(0, 0);
        }
      }, this.rootHeightResetTimout);
    }
  }

  // Looks up routes list and loads and assigns dynamically imported containers to the routes component property.
  // Once loading a container, any linked reducers will get added to the reducer tree as well.
  async getContainersToImport(routeName, afterRouteName) {
    const routesNames = getRouteListFromRouteName(routeName, afterRouteName);

    for (const routeName of routesNames) {
      const route = this.routesMap.get(routeName);

      if (route.load) {
        const module = await route.load();
        // Component can be either a named or default export in the container module
        const loadedComponent = module[route.container] ?? module.default;

        route.component = loadedComponent;

        if (route.component.reducers) {
          const reducers = Array.isArray(route.component.reducers)
            ? route.component.reducers
            : [route.component.reducers];

          for (const reducer of reducers) {
            for (const [key, value] of Object.entries(reducer)) {
              this.store.injectReducer(key, value);
            }
          }
        }
      }
    }
  }

  getRoutesToActivate(routeName, afterRouteName) {
    const routesToActivate = [];

    const routesNamesToFetch = [];
    const routesNamesToActivate =
      afterRouteName && this.toActivate.includes(afterRouteName)
        ? this.toActivate
            .slice(this.toActivate.indexOf(afterRouteName) + 1)
            .filter(name => this.toActivateReally.includes(name))
        : [...this.toActivateReally];

    const routesNames = getRouteListFromRouteName(routeName, afterRouteName);
    const routes = routesNames.reduce((result, routeName) => {
      const route = {...this.routesMap.get(routeName), children: [], childrenToRender: [], childrenToFetch: []};

      result.forEach(prevRoute => prevRoute.children.push(route));
      result.push(route);

      if (routesNamesToActivate.includes(routeName)) {
        routesToActivate.push(route);

        if (route.component && route.component.prefetch) {
          routesNamesToFetch.push(routeName);
        }
      }

      return result;
    }, []);

    const routesToRender = [];
    const routesNamesToRender = [];

    for (const route of routes) {
      if (route.component) {
        for (const prevRoute of routes) {
          if (prevRoute === route) {
            break;
          }

          prevRoute.childrenToRender.push(route);
        }

        routesToRender.push(route);
        routesNamesToRender.push(route.name);

        if (route.prefetchChildrenByComponent) {
          break;
        }
      }
    }

    const routesToPrefetch = [];
    const routesNamesToPrefetch = [];
    let continueProgressBarOnChildrenRender = false;

    for (const route of routes) {
      if (!route.component) {
        continue;
      }

      const routeInActivateIndex = routesNamesToActivate.indexOf(route.name);

      if (routeInActivateIndex > -1) {
        if (route.component.prefetch && (!this.finishedFetches || !this.finishedFetches.has(route.name))) {
          for (const prevRoute of routes) {
            if (prevRoute === route) {
              break;
            }

            prevRoute.childrenToFetch.push(route);
          }

          routesToPrefetch.push(route);
          routesNamesToPrefetch.push(route.name);
        }

        // Stop iteration if activating route is the last one or it should handle children manually by own component,
        if (
          ['entry', 'always'].includes(route.prefetchChildrenByComponent) ||
          routeInActivateIndex === routesNamesToActivate.length - 1
        ) {
          // Check if progressBar should remain to be handled by the component's PrefetchRouteChildren
          continueProgressBarOnChildrenRender =
            // If component handles children fetch
            ['entry', 'always'].includes(route.progressBar) &&
            // If this transition is not between just its parameter, but real entry or child transition
            !this.toDeactivate.includes(route.name) &&
            // If component is not last
            routeInActivateIndex < routesNamesToActivate.length - 1 &&
            // If children components to activate also have fetch methods
            routesNamesToActivate.slice(routeInActivateIndex + 1).some(name => routesNamesToFetch.includes(name));

          break;
        }
      } else if (
        ['child-transition', 'always'].includes(route.prefetchChildrenByComponent) ||
        (route.prefetchChildrenByComponent === 'entry' && this.redirectingChildren)
      ) {
        continueProgressBarOnChildrenRender =
          ['child-transition', 'always'].includes(route.progressBar) ||
          (route.progressBar === 'entry' && this.redirectingChildren);
        break;
      }
    }

    return {
      routes,
      routesNames,
      routesToActivate,
      routesNamesToActivate,
      routesToRender,
      routesNamesToRender,
      routesToPrefetch,
      routesNamesToPrefetch,
      continueProgressBarOnChildrenRender,
    };
  }
}

// Returns array or routes from routeName
// ('app.one.two') => ['app', 'app.one', 'app.one.two']
// ('app.one.two', 'app') => ['app.one', 'app.one.two']
export function getRouteListFromRouteName(routeName, startRouteName = '') {
  const result = [];
  let leftName = routeName;

  if (routeName.startsWith(startRouteName)) {
    while (leftName !== startRouteName) {
      result.unshift(leftName);

      leftName = leftName.substring(0, leftName.lastIndexOf('.'));
    }
  }

  return result;
}
