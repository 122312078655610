/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {getEndpointTypeName, getLabelObject} from '../../Utils/MapTrafficQueryResponseUtils';
import {getEmptyLabelText, truncateLabel, isDeletedId, DELETED_COMBO_ID} from './MapGraphUtils';
import {getInnerComboCount, appGroupOrders, labelTruncationSize} from './MapGraphStyleUtils';
import {getLabelIds, getNodeId} from './MapGraphManagedEndpointUtils';
import type {Label} from 'illumio';
import type {EndpointDataType, EndpointType, ViewType} from '../../MapTypes';
import type {
  Items,
  ComboId,
  LabelType,
  GraphCombo,
  GraphCombos,
  ComboParents,
  ComboMapping,
  ComboLabelId,
  ComboLabelIds,
  RegraphCombine,
} from '../MapGraphTypes';

const MAP_VISIBLE_LABELS_COUNT = 3;

export const getComboId = (combo: Record<string, string>, combine: RegraphCombine): ComboId => {
  if (combo.nodes === 'deleted') {
    return DELETED_COMBO_ID;
  }

  let endType = '';

  const combinationIndex = combine.properties.findIndex(grouping => combo[grouping]);
  const comboLabelNames = Object.keys(combo).reduce((result: Set<string>, level: string): Set<string> => {
    (combo[level] || '')
      .split(',')
      .map((name: string): string => {
        if (name.includes('_source')) {
          endType = '_source';

          return name.replace(/(_source$)/gi, '');
        }

        if (name.includes('_target')) {
          endType = '_target';

          return name.replace(/(_target$)/gi, '');
        }

        return name;
      })
      .forEach(name => result.add(name));

    return result;
  }, new Set());

  const labelIds: string[] = [...comboLabelNames].sort((valueA, valueB) => {
    const a = valueA.split(':')[0];
    const b = valueB.split(':')[0];

    return a < b ? -1 : b < a ? 1 : 0;
  });

  return `_${combine.properties[combinationIndex]}_${labelIds.join(',')}${endType}`;
};

export const getOpenId = (comboId: ComboId): ComboId => `_open_${comboId}`;
export const getClosedId = (id: ComboId): ComboId => (id || '').replace(/(^_open_)/gi, '') as ComboId;

export const getComboLabelsFromId = (comboId: ComboId, labelsObject: Record<string, Label>): string[] => {
  const labelKeys = comboId.split(',').map(label => label.split(':')[0]);

  return labelKeys.map(key => labelsObject[key]?.value).filter(Boolean);
};

export const getComboGroupTypeFromId = (comboId: ComboId): string => {
  const match = comboId.match(/^(_([^_]+)_)(.*)$/);

  return match?.[2] || '';
};

export const isComboOpen = (
  combo: ComboLabelId,
  id: ComboId,
  openCombos: Record<string, boolean>,
  combine: RegraphCombine,
): boolean => {
  const outerMostGrouping = combine.properties[combine.level - 1];

  if (combo === undefined) {
    return false;
  }

  if (isDeletedId(id)) {
    return false;
  }

  if (outerMostGrouping === 'nodes' && !openCombos[id]) {
    return false;
  }

  return openCombos.hasOwnProperty(id) ? Boolean(openCombos[id]) : id.includes(`_${outerMostGrouping}_`);
};

export const isStrippedChildCombo = (
  childMatch = '',
  parentMatch = '',
  child: ComboId,
  parent: ComboId,
  combos: ComboMapping,
): boolean => {
  return Boolean(
    (parentMatch && parentMatch.split(',').every(id => childMatch.includes(id))) ||
      combos.endpoints[child || '']?.includes(parent),
  );
};

export const getStrippedComboId = (comboId: ComboId): string => {
  const comboIdRegex = /^(_([^_]+)_)(.*)$/;

  return (comboId.match(comboIdRegex)?.[3] || '').replace('_source', '').replace('_target', '');
};

export const isChildCombo = (child: ComboId, parent: ComboId, combos: ComboMapping): boolean => {
  const childMatch = getStrippedComboId(child);
  const parentMatch = getStrippedComboId(parent);

  return isStrippedChildCombo(childMatch, parentMatch, child, parent, combos);
};

export const getComboLabelIdsFromId = (comboId: ComboId): string => {
  const match = comboId.match(/^(_([^_]+)_)(.*)$/);

  return match?.[3] || '';
};

export const getComboTextForCombo = (combo: GraphCombo, labelTypes: LabelType[]): string => {
  return combo.labels
    .map(label => truncateLabel(label.value || getEmptyLabelText(label.key, labelTypes), labelTruncationSize))
    .join('\n');
};

export const findIdLabelsContainsFocusedLabels = (focusedLabels: string, labels: string[]): boolean => {
  const focusLabelsArray = focusedLabels.split(',');

  return focusLabelsArray.every(label => labels.includes(label));
};

export const getCombo = (
  labels: Label[],
  level: string,
  endType: EndpointType,
  viewType: ViewType,
  focusedComboId: ComboId,
): {id: ComboId; labels: Label[]} => {
  const focusedLabels = focusedComboId && (focusedComboId.split('_').pop() as string);

  labels = _.uniqBy(labels, 'key').sort((a, b) => (a.key < b.key ? -1 : b.key < a.key ? 1 : 0));

  const idLabels = labels.map(label => `${label.key}:${label.id}`);
  const idLabelsContainsFocusedLabels = focusedLabels && findIdLabelsContainsFocusedLabels(focusedLabels, idLabels);
  const id = `_${level}_${idLabels.join(',')}` as ComboId;

  let viewBasedId = '' as ComboId;

  if (viewType === 'bidirectional') {
    viewBasedId = id;
  } else if (viewType === 'directional') {
    viewBasedId = `${id}_${endType}`;
  } else if (viewType === 'focused') {
    if (focusedComboId === id || (idLabelsContainsFocusedLabels && level === 'nodes')) {
      viewBasedId = id;
    } else {
      viewBasedId = `${id}_${endType}`;
    }
  }

  return {
    labels,
    id: viewBasedId,
  };
};

export const getComboCounts = (combo: GraphCombo): {count: number; type: string}[] => {
  const endpoints = combo.endpoints;
  const endpointTypes: EndpointDataType[] = Object.keys(endpoints) as EndpointDataType[];

  if (isDeletedId(combo.id)) {
    return [{type: intl('Common.DeletedWorkloads', {count: combo.endpointCount}), count: combo.endpointCount}];
  }

  return endpointTypes
    .map(endpointType => ({
      count: Object.keys(endpoints[endpointType] || {}).length,
      type: endpointType !== '' ? getEndpointTypeName[endpointType] : '',
    }))
    .filter(type => type.count);
};

export const getEmptyLabel = (key: string, labelTypes: LabelType[]): {key: string; id: string; name: string} => ({
  key,
  id: 'discovered',
  name: labelTypes.find(type => type.key === key)?.display_name || '',
});

export const getComboLabelsForLevel = (
  level: string,
  appGroupId: string,
  labelObject: Record<string, Label>,
  labelTypes: LabelType[],
): Label[] => {
  if (level === 'appGroup') {
    // Find all the label ids in the appGroupId
    const ids = appGroupId ? appGroupId.split(',') : [];

    return ids.map(keyId => {
      const [key] = keyId.split(':');

      // Add either the complete label or a 'discovered' dummy label for the missing label
      return labelObject[key] || getEmptyLabel(key, labelTypes);
    });
  }

  if (level === 'nodes') {
    // Get all the labels for the 'nodes' level
    return labelTypes.map(type => labelObject[type.key] || getEmptyLabel(type.key, labelTypes));
  }

  // For any other 'label' level get just the single label of the level
  return [labelObject[level] || getEmptyLabel(level, labelTypes)];
};

export const updateParentLabelsForLevel = (
  level: string,
  parentLabelsObject: Record<string, Label[]>,
  labels: Label[],
): Label[] => {
  const parentTypes = Object.keys(parentLabelsObject);
  const levelLabels = [...(parentLabelsObject[level] || [])];

  if (parentTypes.length > 0) {
    // Add to the specific parent level
    return parentTypes.reduce((result, parentLevel) => {
      result = [...(parentLabelsObject[parentLevel] || []), ...labels];

      return result;
    }, levelLabels);
  }

  return labels;
};

export const getComboName = (
  comboLabels: Label[],
  parentLabels: Label[],
  comboId: ComboId,
  endpointCount: number,
  truncate: boolean,
): string => {
  if (isDeletedId(comboId)) {
    return intl('Common.DeletedWorkloads', {count: endpointCount});
  }

  let labelNames = comboLabels
    .filter(label => label && label.value && !parentLabels.some(parent => label.id === parent.id))
    .map(label => {
      return truncate ? truncateLabel(label.value, labelTruncationSize) : label.value;
    });

  const totalNames = labelNames.length;

  if (totalNames > 0) {
    if (totalNames > MAP_VISIBLE_LABELS_COUNT + 1) {
      labelNames = labelNames.slice(0, MAP_VISIBLE_LABELS_COUNT);
      labelNames.push(intl('Explorer.MoreItems', {numLists: totalNames - MAP_VISIBLE_LABELS_COUNT}));
    }

    return labelNames.join('\n');
  }

  return '';
};

export const calculateSuperAppGroupsCombo = (
  combos: Record<ComboId, GraphCombo>,
  grouping: string,
  focusedAppGroup: ComboId,
  connectedComboId: ComboId,
): GraphCombos => {
  const comboKeys: ComboId[] = Object.keys(combos) as ComboId[];
  const superAppGroupSource = '_superAppGroups_source';
  const superAppGroupTarget = '_superAppGroups_target';

  const superAppGroups = comboKeys.reduce((result, comboId): GraphCombos => {
    if (comboId.includes(grouping) && comboId !== focusedAppGroup && !comboId.includes('discovered')) {
      // removing appgroups which has discovered label
      const combo = combos[comboId];
      const superAppGroupType = comboId.includes('source') ? superAppGroupSource : superAppGroupTarget;
      const name =
        superAppGroupType === superAppGroupSource ? intl('Map.ConsumingAppGroups') : intl('Map.ProvidingAppGroups');

      result[superAppGroupType] ||= {
        endpoints: {},
        endpointCount: 0,
        innerComboCount: 0,
        labels: [],
        comboLabels: [],
        parentLabels: [],
        allLabels: [],
        parents: {},
        appGroup: '',
        endType: superAppGroupType === superAppGroupSource ? 'source' : 'target',
        type: 'combo',
        id: superAppGroupType,
        // Add hierarchy and sequence to Providing and Consuming App Group
        data: {
          ...appGroupOrders.consumerOrProviderGroup,
          sequence:
            superAppGroupType === superAppGroupSource
              ? appGroupOrders.consumerGroup.sequence
              : appGroupOrders.providerGroup.sequence,
        },
        appGroupCount: 0,
        appGroups: {},
        name,
        fullName: name,
      };

      result[superAppGroupType].appGroupCount = (result[superAppGroupType].appGroupCount || 0) + 1;

      const appGroups = result[superAppGroupType].appGroups;

      if (appGroups) {
        appGroups[comboId] = combo;
      }
    }

    return result;
  }, {} as GraphCombos);

  if (connectedComboId) {
    const superAppGroupType = connectedComboId.includes('target') ? 'target' : 'source';

    if (superAppGroupType === 'target' && superAppGroups[superAppGroupTarget]) {
      superAppGroups[superAppGroupTarget].appGroupCount = (superAppGroups[superAppGroupTarget].appGroupCount || 0) - 1;
    } else if (superAppGroups[superAppGroupSource]) {
      superAppGroups[superAppGroupSource].appGroupCount = (superAppGroups[superAppGroupSource].appGroupCount || 0) - 1;
    }
  }

  if (!superAppGroups[superAppGroupSource]?.appGroupCount) {
    delete superAppGroups[superAppGroupSource];
  }

  if (!superAppGroups[superAppGroupTarget]?.appGroupCount) {
    delete superAppGroups[superAppGroupTarget];
  }

  return superAppGroups;
};

export const calculateEndpointsForFocusedView = (
  endpoints: Record<string, ComboId[]>,
  focusedCombo: ComboId,
  connectedCombo: ComboId,
): Record<string, ComboId[]> => {
  const focusedEndpoints = Object.keys(endpoints).reduce((result, endpointKey: string) => {
    if (!endpoints[endpointKey].includes(focusedCombo) && !endpoints[endpointKey].includes(connectedCombo)) {
      if (endpointKey.includes('source') && !endpoints[endpointKey].includes('_superAppGroups_source')) {
        endpoints[endpointKey].push('_superAppGroups_source');
      } else if (endpointKey.includes('target') && !endpoints[endpointKey].includes('_superAppGroups_target')) {
        endpoints[endpointKey].push('_superAppGroups_target');
      }
    }

    result = endpoints;

    return result;
  }, {} as Record<string, ComboId[]>);

  return focusedEndpoints;
};

export const calculateCombos = (
  graphItems: Items,
  grouping: string[],
  labelTypes: LabelType[],
  viewType: ViewType,
  focusedComboId: ComboId,
  connectedComboId: ComboId,
): ComboMapping => {
  // Get visible closed combos, and remove endpoints inside closed combos
  const {managedEndpoints = {}} = graphItems;
  const levels = ['nodes', ...grouping];
  // Start with all the managed endpoints
  const comboMapping = Object.keys(managedEndpoints).reduce(
    (result: ComboMapping, key: string): ComboMapping => {
      const endpoint = managedEndpoints[key];
      const endType = endpoint.data.endType as EndpointType;
      const endpointType = endpoint.managedType;
      const endpointLabelsObject = endpoint.labelObject || {};
      const endpointAppGroupId = endpoint.appGroupId || '';

      // For each level of the graph find the combo for the endpoint
      levels.forEach((level, index) => {
        // A record of the labels specific to each level of parent
        const parentLabelsObject: Record<string, Label[]> = {};
        // A list of parent labels across all levels keyed by the type
        const allParentLabels: Record<string, Label> = {};
        let comboLabels: Label[] = [];

        // Within each level we have to walk all the higher levels to add them to this combo
        for (let comboIndex = levels.length - 1; comboIndex >= index; comboIndex--) {
          const comboLevel = levels[comboIndex];

          const labels = getComboLabelsForLevel(comboLevel, endpointAppGroupId, endpointLabelsObject, labelTypes);

          if (key !== 'deleted') {
            // If we are in a higher level than the combo we are adding this to the parents
            if (index < comboIndex) {
              // Add these labels to 'allParentLabels'
              labels.forEach(label => {
                allParentLabels[label.key] = label;
              });

              // Update the parent Labels Object, which contains a list of labels for each parent level
              parentLabelsObject[comboLevel] = updateParentLabelsForLevel(comboLevel, parentLabelsObject, labels);
            } else {
              // If it's not a parent label, then it's a combo label itself
              comboLabels = labels.filter(label => !allParentLabels[label.key]);
            }
          }
        }

        const parentLabels = Object.values(allParentLabels);
        const combo = getCombo([...parentLabels, ...comboLabels], level, endType, viewType, focusedComboId);

        if (key === 'deleted') {
          combo.id = DELETED_COMBO_ID;
          combo.labels = [];
        }

        result.combos[combo.id] ||= {
          type: 'combo',
          ...combo,
          comboLabels,
          parentLabels,
          allLabels: combo.labels,
          endpointCount: 0,
          innerComboCount: 0,
          endpoints: {},
          appGroup: endpoint.data.appGroup as string,
          parents: Object.keys(parentLabelsObject).reduce((result, parentLevel): ComboParents => {
            result[parentLevel] = getCombo(
              parentLabelsObject[parentLevel],
              parentLevel,
              endType,
              viewType,
              focusedComboId,
            );

            return result;
          }, {} as ComboParents),
          name: '',
          fullName: '',
          data: {},
          endType,
        };

        // xpress product prefers non-truncated combos, with smaller text size
        const truncateName = !__ANTMAN__;

        result.combos[combo.id].endpointCount += 1;
        result.combos[combo.id].name =
          getComboName(comboLabels, parentLabels, combo.id, result.combos[combo.id].endpointCount, truncateName) ||
          getEmptyLabelText(level, labelTypes, parentLabels);

        result.combos[combo.id].fullName =
          getComboName(comboLabels, parentLabels, combo.id, result.combos[combo.id].endpointCount, false) ||
          getEmptyLabelText(level, labelTypes, parentLabels);

        const endpointKey = key || 'deleted';

        // Add this endpoint to the categorized list of endpoints
        // Where the categories are 'workloads', 'virtual services', etc.
        result.combos[combo.id].endpoints[endpointType] ||= {};

        result.combos[combo.id].endpoints[endpointType]![endpointKey] = {
          ...endpoint,
        };

        // Add the combo id to the list of combos for this endpoint
        result.endpoints[endpointKey] ||= [];

        result.endpoints[endpointKey].push(combo.id);
      });

      return result;
    },
    {combos: {}, endpoints: {}},
  );

  if (viewType === 'focused') {
    const combos = comboMapping.combos;
    const endpoints = comboMapping.endpoints;
    const focusedEndpoints = calculateEndpointsForFocusedView(endpoints, focusedComboId, connectedComboId);
    const superAppGroups = calculateSuperAppGroupsCombo(combos, '_appGroup_', focusedComboId, connectedComboId);

    comboMapping.combos = {...combos, ...superAppGroups};
    comboMapping.endpoints = focusedEndpoints;
  }

  return comboMapping;
};

export const calculateComboIds = (combos: GraphCombos): ComboLabelIds => {
  return Object.keys(combos).reduce((result: ComboLabelIds, comboId): ComboLabelIds => {
    const groupType = getComboGroupTypeFromId(comboId as ComboId);
    const groupLabels = getComboLabelIdsFromId(comboId as ComboId);
    const comboLabelId: ComboLabelId = {
      [groupType]: groupLabels,
    };

    result[comboId as ComboId] = comboLabelId;

    return result;
  }, {} as ComboLabelIds);
};

export const getComboData = (
  combo: GraphCombo,
  level: string,
  labelObject: Record<string, Label>,
  groupingProperties: string[],
  groupingIndex: number,
  labelTypes: LabelType[],
): Record<string, string> => {
  let data = getLabelIds(
    labelObject,
    labelTypes.filter(type => groupingProperties.indexOf(type.key) >= groupingIndex),
  );

  const appGroupIndex = groupingProperties.indexOf('appGroup');

  if (appGroupIndex >= groupingIndex) {
    data.appGroup = combo.appGroup;
  }

  if (level === 'nodes') {
    data.nodes = getNodeId(labelObject, labelTypes);
  }

  if (isDeletedId(combo.id)) {
    data = {nodes: 'deleted'};
  }

  return data;
};

export const calculateClosedCombosForFocusedView = (
  comboMapping: ComboMapping,
  comboIds: ComboLabelIds,
  openCombos: Record<string, boolean>,
  grouping: string[],
  focusedAppGroupId: ComboId,
  connectedAppGroupId: ComboId,
): Record<string, GraphCombo> => {
  const combos: Record<ComboId, GraphCombo> = comboMapping.combos;
  const comboKeys: ComboId[] = Object.keys(combos) as ComboId[];
  const groupingProperties = ['nodes', ...grouping];
  const combine = {
    properties: groupingProperties,
    level: grouping.length + 1,
  };

  const closedCombos = comboKeys.reduce((result: Record<string, GraphCombo>, comboId: ComboId) => {
    if (
      comboId.includes('_nodes_') &&
      !isComboOpen(comboIds[comboId], comboId, openCombos, combine) &&
      (combos[comboId].parents.appGroup?.id === focusedAppGroupId ||
        combos[comboId].parents.appGroup?.id === connectedAppGroupId)
    ) {
      const data = {appGroup: combos[comboId].appGroup, nodes: comboId.replace('_nodes_', '')};

      combos[comboId].data = data;
      result[comboId] = combos[comboId];
    }

    if (comboId.includes('_superAppGroups_')) {
      result[comboId] = combos[comboId];
    }

    return result;
  }, {} as Record<string, GraphCombo>);

  return closedCombos;
};

export const calculateClosedCombos = (
  comboMapping: ComboMapping,
  comboIds: ComboLabelIds,
  openCombos: Record<string, boolean>,
  grouping: string[],
  labelTypes: LabelType[],
): Record<string, GraphCombo> => {
  const groupingProperties = ['nodes', ...grouping];
  const combine = {
    properties: groupingProperties,
    level: grouping.length + 1,
  };

  const combos: Record<ComboId, GraphCombo> = comboMapping.combos;
  const comboKeys: ComboId[] = Object.keys(combos) as ComboId[];

  // Walk through the combos
  return comboKeys.reduce((result: GraphCombos, comboId): GraphCombos => {
    const combo = combos[comboId];

    // Find the uppermost closed combo parent
    const topClosedComboLevel = [...groupingProperties].reverse().find(level => {
      const nextComboId: ComboId = combo.parents[level]?.id || comboId;

      return !isComboOpen(comboIds[nextComboId], nextComboId, openCombos, combine);
    });

    if (topClosedComboLevel) {
      const topClosedComboId = combo.parents[topClosedComboLevel]?.id || comboId;
      const topClosedCombo = combos[topClosedComboId];
      const groupingIndex = groupingProperties.indexOf(topClosedComboLevel);

      // If we haven't already calculated this combo
      if (!result[topClosedComboId]) {
        const labelObject = getLabelObject(topClosedCombo.allLabels);
        const innerComboCount = getInnerComboCount(Object.keys(comboIds), topClosedComboId, combine);
        const data = getComboData(
          topClosedCombo,
          topClosedComboLevel,
          labelObject,
          groupingProperties,
          groupingIndex,
          labelTypes,
        );

        result[topClosedComboId] = {
          ...topClosedCombo,
          innerComboCount,
          data,
        };
      }
    }

    return result;
  }, {} as GraphCombos);
};
