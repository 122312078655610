/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
/**
 * Routes configuration.
 * Do not import this file in your components to get routes list or map to avoid circular dependencies,
 * instead take them from AppContext
 * In component: this.context.router.routesList
 */

import _ from 'lodash';
import type {MergeWithCustomizer} from 'lodash';
import {edge} from 'api/apiUtils';
import type {ComponentType} from 'react';
import type {Route as Router5Route} from 'router5';

export interface RawRoute<Dependencies = Record<string, unknown>> extends Router5Route {
  prefetchChildrenByComponent?: string;
  progressBar?: string;
  forwardParams?: Record<string, unknown>;
  container?: string;
  children?: RawRoute<Dependencies>[];
}

export interface Route extends RawRoute {
  parent?: Route;
  parents: Route[];
  nameStrip?: string;
  component?: ComponentType;
  load?: () => Promise<ComponentType>;
  redirectTo?: string;
  redirectToStrip?: string;
  redirectParams?: Record<string, unknown>;
  autoParams?: Record<string, unknown>;
}

// Router5 default route http://router5.github.io/docs/router-options.html#default-route
export const defaultRoute = 'app';

// Flatten list of routes. [{name: 'app.user', path: '...'}, {name: 'app.user.list', path: '...'}]
export const routesList: Route[] = [];

// Map of flatten routes {'app.user': {name: 'app.user', path: '...'}, ...}
export const routesMap = new Map<string, Route>();

// Routes configuration tree
export const routesTree = {
  name: 'app',
  path: '?:showVersion',
  container: 'App',
  prefetchChildrenByComponent: 'entry',
  progressBar: 'entry',
  forwardTo: 'landing',
  children: [
    {
      name: 'rbac',
      path: '/rbac',
      container: 'RBAC', load: () => import(/* webpackChunkName: 'RBAC' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/RBAC'),
      forwardTo: '>roles',
      children: [
        {
          name: 'accesswizard',
          path: '/accesswizard/?:type?:prevRoute?:scopeId?:roles?:authSecPrincipals',
          container: 'AccessWizard', load: () => import(/* webpackChunkName: 'AccessWizard' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessWizard/AccessWizard'),
        },
        {
          name: 'serviceAccounts',
          path: '/serviceaccounts',
          container: 'ServiceAccountContainer', load: () => import(/* webpackChunkName: 'ServiceAccountContainer' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/ServiceAccountContainer'),
          forwardTo: '>list',
          children: [
            {name: 'list', path: '/?:svcacctslist', container: 'ServiceAccountList', load: () => import(/* webpackChunkName: 'ServiceAccountList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/List/ServiceAccountList')},
            {name: 'create', path: '/create', container: 'ServiceAccountEdit', load: () => import(/* webpackChunkName: 'ServiceAccountEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/Item/Edit/ServiceAccountEdit')},
            {
              name: 'item',
              path: '/:id',
              forwardTo: '>view',
              container: 'ServiceAccountItem', load: () => import(/* webpackChunkName: 'ServiceAccountItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/Item/ServiceAccountItem'),
              children: [
                {name: 'edit', path: '/edit', container: 'ServiceAccountEdit', load: () => import(/* webpackChunkName: 'ServiceAccountEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/Item/Edit/ServiceAccountEdit')},
                {name: 'view', path: '/?:apikeys', container: 'ServiceAccountView', load: () => import(/* webpackChunkName: 'ServiceAccountView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/ServiceAccount/Item/View/ServiceAccountView')},
              ],
            },
          ],
        },
        {
          name: 'roles',
          path: '/roles',
          forwardTo: '>global',
          children: [
            {
              name: 'global',
              path: '/global',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:globalrolelist', container: 'GlobalRoleList', load: () => import(/* webpackChunkName: 'GlobalRoleList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/Roles/Global/List/GlobalRoleList')},
                {name: 'detail', path: '/:id?:globalroledetail', container: 'GlobalRoleDetail', load: () => import(/* webpackChunkName: 'GlobalRoleDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/Roles/Global/Detail/GlobalRoleDetail')},
              ],
            },
            {
              name: 'scope',
              path: '/scope',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:scopelist', container: 'ScopeList', load: () => import(/* webpackChunkName: 'ScopeList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/Roles/Scope/List/ScopeList')},
                {name: 'detail', path: '/:id?:scopedroledetail', container: 'ScopeDetail', load: () => import(/* webpackChunkName: 'ScopeDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/Roles/Scope/Detail/ScopeDetail')},
              ],
            },
          ],
        },
        {
          name: 'users',
          path: '/users',
          forwardTo: '>local',
          children: [
            {
              name: 'local',
              path: '/local',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:localuserlist', container: 'LocalUserList', load: () => import(/* webpackChunkName: 'LocalUserList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/LocalUsers/List/LocalUserList')},
                {name: 'detail', path: '/:id?:localuserdetail', container: 'LocalUserDetail', load: () => import(/* webpackChunkName: 'LocalUserDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/LocalUsers/Detail/LocalUserDetail')},
              ],
            },
            {
              name: 'external',
              path: '/external',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:externaluserlist', container: 'ExternalUserList', load: () => import(/* webpackChunkName: 'ExternalUserList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/ExternalUsers/List/ExternalUserList')},
                {name: 'detail', path: '/:id?:externaluserdetail', container: 'ExternalUserDetail', load: () => import(/* webpackChunkName: 'ExternalUserDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/ExternalUsers/Detail/ExternalUserDetail')},
                {
                  name: 'groups',
                  path: '/groups',
                  forwardTo: '>list',
                  children: [
                    {name: 'list', path: '/?:externalgrouplist', container: 'ExternalGroupList', load: () => import(/* webpackChunkName: 'ExternalGroupList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/ExternalGroups/List/ExternalGroupList')},
                    {name: 'detail', path: '/:id?:externalgroupdetail', container: 'ExternalGroupDetail', load: () => import(/* webpackChunkName: 'ExternalGroupDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/ExternalGroups/Detail/ExternalGroupDetail')},
                  ],
                },
              ],
            },
            {
              name: 'activity',
              path: '/activity',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:useractivitylist', container: 'UserActivityList', load: () => import(/* webpackChunkName: 'UserActivityList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/UserActivity/List/UserActivityList')},
                {name: 'detail', path: '/:id?:useractivitydetail?:tab', container: 'UserActivityDetail', load: () => import(/* webpackChunkName: 'UserActivityDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/UsersAndGroups/UserActivity/Detail/UserActivityDetail')},
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'reporting',
      path: '/reporting',
      container: 'Reporting', load: () => import(/* webpackChunkName: 'Reporting' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Reporting/Reporting'),
      forwardTo: '>downloads',
      children: [
        {
          name: 'downloads',
          path: '/downloads',
          forwardTo: '>list',
          children: [{name: 'list', path: '/?:downloadslist', container: 'DownloadsList', load: () => import(/* webpackChunkName: 'DownloadsList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Reporting/Downloads/List/DownloadsList')}],
        },
        {
          name: 'recurrence',
          path: '/recurrence',
          forwardTo: '>list',
          children: [{name: 'list', path: '/?:recurrencelist', container: 'RecurrenceList', load: () => import(/* webpackChunkName: 'RecurrenceList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Reporting/Recurrence/List/RecurrenceList')}],
        },
      ],
    },

    {name: 'helppopup', path: '/helppopup/?:page?:customHelpPopUpKey'},

    {name: 'myprofile', path: '/myprofile', container: 'MyProfile', load: () => import(/* webpackChunkName: 'MyProfile' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/User/Profile/MyProfile')},

    {
      name: 'myroles',
      path: '/myroles',
      forwardTo: '>list',
      children: [{name: 'list', path: '/?:myroleslist', container: 'MyRolesList', load: () => import(/* webpackChunkName: 'MyRolesList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/User/Roles/MyRolesList')}],
    },

    {name: 'apikeys', path: '/apikeys', children: [{name: 'list', path: '/?:apikeylist', container: 'APIKeysList', load: () => import(/* webpackChunkName: 'APIKeysList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/APIKeys/List/APIKeysList')}]},

    {name: 'user', path: '/users', children: [{name: 'add', path: '/add', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')}]},

    {
      name: 'events',
      path: '/events',
      forwardTo: '>list',
      forwardParams: {section: 'landing'},
      children: [
        {name: 'list', path: '/:section?:showQuickFilter?:eventlist', container: 'EventsList', load: () => import(/* webpackChunkName: 'EventsList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Events/List/EventsList')},
        {name: 'detail', path: '/list/:id?:system', container: 'EventsDetail', load: () => import(/* webpackChunkName: 'EventsDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Events/Detail/EventsDetail')},
        {
          name: 'config',
          path: '/config',
          forwardTo: '>detail',
          forwardParams: {mode: 'view'},
          children: [
            {
              name: 'detail',
              path: '/:mode?:supercluster?:nonsupercluster',
              container: 'EventsConfig', load: () => import(/* webpackChunkName: 'EventsConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Events/Config/EventsConfig'),
              defaultParams: {mode: 'view'},
            },
            {
              name: 'destinations',
              path: '/destinations',
              children: [
                {name: 'add', path: '/add', container: 'EventsConfigDestinationAdd', load: () => import(/* webpackChunkName: 'EventsConfigDestinationAdd' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Events/Config/Destination/EventsConfigDestinationAdd')},
                {name: 'edit', path: '/:id/edit', container: 'EventsConfigDestinationEdit', load: () => import(/* webpackChunkName: 'EventsConfigDestinationEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Events/Config/Destination/EventsConfigDestinationEdit')},
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'apiKeySettings',
      path: '/apikeysettings',
      forwardTo: '>view',
      children: [
        {name: 'view', path: '/', container: 'APIKeySettingsView', load: () => import(/* webpackChunkName: 'APIKeySettingsView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/APIKeySettings/Item/View/APIKeySettingsView')},
        {name: 'edit', path: '/edit', container: 'APIKeySettingsEdit', load: () => import(/* webpackChunkName: 'APIKeySettingsEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/APIKeySettings/Item/Edit/APIKeySettingsEdit')},
      ],
    },

    {
      name: 'offlineTimers',
      path: '/offlinetimers',
      forwardTo: '>detail',
      children: [
        {name: 'detail', path: '/', container: 'OfflineTimers', load: () => import(/* webpackChunkName: 'OfflineTimers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/OfflineTimers/OfflineTimers')},
        {name: 'edit', path: '/edit', container: 'OfflineTimersEdit', load: () => import(/* webpackChunkName: 'OfflineTimersEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/OfflineTimers/OfflineTimersEdit')},
      ],
    },

    {
      name: 'securitysettings',
      path: '/securitysettings',
      forwardTo: '>section',
      forwardParams: {section: 'general'},
      children: [
        {
          name: 'section',
          path:
            '/:section?:mode' +
            '?:securitysettings-staticscopes' +
            '?:securitysettings-firewallcoexistence' +
            '?:securitysettings-blockaction' +
            '?:securitysettings-loopbackinterfaces' +
            '?:securitysettings-containers' +
            '?:securitysettings-ipforwarding',
          container: 'SecuritySettings', load: () => import(/* webpackChunkName: 'SecuritySettings' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecuritySettings/SecuritySettings'),
          defaultParams: {section: 'general'},
        },
      ],
    },

    {
      name: 'corporatepublicips',
      path: '/corporatepublicips',
      container: 'CorporatePublicIPsItem', load: () => import(/* webpackChunkName: 'CorporatePublicIPsItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/CorporatePublicIPs/Item/CorporatePublicIPsItem'),
      forwardTo: '>item',
      children: [
        {name: 'edit', path: '/edit', container: 'CorporatePublicIPsEdit', load: () => import(/* webpackChunkName: 'CorporatePublicIPsEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/CorporatePublicIPs/Item/Edit/CorporatePublicIPsEdit')},
        {name: 'item', path: '/', container: 'CorporatePublicIPsView', load: () => import(/* webpackChunkName: 'CorporatePublicIPsView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/CorporatePublicIPs/Item/View/CorporatePublicIPsView')},
      ],
    },

    {
      name: 'essentialservicerules',
      path: '/essentialservicerules/?:pversion',
      container: 'EssentialServiceRules', load: () => import(/* webpackChunkName: 'EssentialServiceRules' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EssentialServiceRules/EssentialServiceRules'),
      defaultParams: {pversion: 'draft'},
    },

    {
      name: 'venoperations',
      path: '/venoperations',
      container: 'VenOperationsEnabled', load: () => import(/* webpackChunkName: 'VenOperationsEnabled' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VenOperations/VenOperationsEnabled'),
      forwardTo: '>detail',
      children: [
        {name: 'detail', path: '/', container: 'VenOperations', load: () => import(/* webpackChunkName: 'VenOperations' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VenOperations/VenOperations')},
        {name: 'edit', path: '/edit', container: 'VenOperationsEdit', load: () => import(/* webpackChunkName: 'VenOperationsEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VenOperations/VenOperationsEdit')},
      ],
    },

    {
      name: 'policySettings',
      path: '/policysettings',
      forwardTo: '>detail',
      children: [
        {name: 'detail', path: '/', container: 'PolicySettings', load: () => import(/* webpackChunkName: 'PolicySettings' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PolicySettings/PolicySettings')},
        {name: 'edit', path: '/edit', container: 'PolicySettingsEdit', load: () => import(/* webpackChunkName: 'PolicySettingsEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PolicySettings/PolicySettingsEdit')},
      ],
    },

    {name: 'pending', path: '/pending?:pendinglist', container: 'PendingList', load: () => import(/* webpackChunkName: 'PendingList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Pending/List/PendingList')},
    {name: 'provision', path: '/provision?:selected?:dependency', container: 'Provision', load: () => import(/* webpackChunkName: 'Provision' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Provision/Provision')},
    {name: 'provisioning', path: '/provisioning', container: 'Provisioning', load: () => import(/* webpackChunkName: 'Provisioning' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Provisioning')},

    {
      name: 'segmentationTemplates',
      path: '/segmentationtemplates',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'detail', path: '/:id', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
      ],
    },

    {
      name: 'rulesets',
      path: '/rulesets',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:rulesetlist?:maplist', container: 'RulesetList', load: () => import(/* webpackChunkName: 'RulesetList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ruleset/List/RulesetList')},
        {name: 'create', path: '/create', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'clone', path: '/clone/:rule_set_id/:pversion', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {
          name: 'item',
          path: '/:id/:pversion?:maplist',
          defaultParams: {pversion: 'draft'},
          container: 'RulesetItem', load: () => import(/* webpackChunkName: 'RulesetItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ruleset/Item/RulesetItem'),
        },
      ],
    },
    {
      name: 'boundaries',
      path: '/boundaries',
      container: 'EnforcementBoundariesMain', load: () => import(/* webpackChunkName: 'EnforcementBoundariesMain' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/EnforcementBoundariesMain'),
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:boundarieseruleslist', container: 'EnforcementBoundariesList', load: () => import(/* webpackChunkName: 'EnforcementBoundariesList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/List/EnforcementBoundariesList')},
        {name: 'create', path: '/create', container: 'EnforcementBoundariesEdit', load: () => import(/* webpackChunkName: 'EnforcementBoundariesEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/Item/Edit/EnforcementBoundariesEdit')},
        {
          name: 'item',
          path: '/enforcement',
          forwardTo: '>view',
          container: 'EnforcementBoundariesItem', load: () => import(/* webpackChunkName: 'EnforcementBoundariesItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/Item/EnforcementBoundariesItem'),
          children: [
            {
              name: 'view',
              path: '/:id/:pversion',
              defaultParams: {pversion: 'draft'},
              container: 'EnforcementBoundariesView', load: () => import(/* webpackChunkName: 'EnforcementBoundariesView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/Item/Summary/EnforcementBoundariesView'),
            },
            {
              name: 'edit',
              path: '/:id/:pversion/edit',
              defaultParams: {pversion: 'draft'},
              container: 'EnforcementBoundariesEdit', load: () => import(/* webpackChunkName: 'EnforcementBoundariesEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/EnforcementBoundaries/Item/Edit/EnforcementBoundariesEdit'),
            },
            {
              name: 'explorer',
              path: '/:id/:pversion/explorer',
              defaultParams: {pversion: 'draft'},
              container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
            },
          ],
        },
        {
          name: 'ruleSearch',
          path: '/rulesearch/:id/:pversion/',
          defaultParams: {pversion: 'draft'},
          container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
        },
        {
          name: 'proposedRules',
          path: '/proposedrules/:id/:pversion/',
          defaultParams: {pversion: 'draft'},
          container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
        },
      ],
    },
    {name: 'ruleSearch', path: '/rulesearch/:pversion', defaultParams: {pversion: 'draft'}, container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
    {name: 'venLibrary', path: '/venlibrary?:venlibrary', container: 'VenLibrary', load: () => import(/* webpackChunkName: 'VenLibrary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Library/VenLibrary')},

    {
      name: 'workloads',
      path: '/workloads',
      forwardTo: '>list',
      container: 'WorkloadManagement', load: () => import(/* webpackChunkName: 'WorkloadManagement' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/WorkloadManagement/WorkloadManagement'),
      children: [
        {name: 'list', path: '/?:workloadlist', container: 'WorkloadList', load: () => import(/* webpackChunkName: 'WorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/List/WorkloadList')},
        {name: 'create', path: '/create', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Edit/WorkloadEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'WorkloadItem', load: () => import(/* webpackChunkName: 'WorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/WorkloadItem'),
          children: [
            {name: 'view', path: '/', container: 'WorkloadSummary', load: () => import(/* webpackChunkName: 'WorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Summary/WorkloadSummary')},
            {name: 'edit', path: '/edit', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Edit/WorkloadEdit')},
            {name: 'virtualServices', path: '/virtualServices?:workloadvs', container: 'WorkloadVirtualServices', load: () => import(/* webpackChunkName: 'WorkloadVirtualServices' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/VirtualServices/WorkloadVirtualServices')},
            {name: 'services', path: '/services?:workloadprocesses', container: 'WorkloadProcesses', load: () => import(/* webpackChunkName: 'WorkloadProcesses' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Processes/WorkloadProcesses')},
            {name: 'blockedTraffic', path: '/blockedtraffic', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'rules', path: '/rules?:inbound?:outbound?:forward?:customip?:admin', container: 'WorkloadRules', load: () => import(/* webpackChunkName: 'WorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Rules/WorkloadRules')},
            {name: 'report', path: '/report?:compatibilityReport', container: 'WorkloadCompatibilityReport', load: () => import(/* webpackChunkName: 'WorkloadCompatibilityReport' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/Report/WorkloadCompatibilityReport')},
            {name: 'vulnerabilities', path: '/vulnerabilities?:workloadvulnerability', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {
              name: 'boundaries',
              path: '/boundaries?:inboundenforcement?:outboundenforcement',
              container: 'WorkloadEnforcementBoundaries', load: () => import(/* webpackChunkName: 'WorkloadEnforcementBoundaries' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Workload/Item/EnforcementBoundaries/WorkloadEnforcementBoundaries'),
            },
          ],
        },
        {
          name: 'containers',
          path: '/containers',
          forwardTo: '>list',
          children: [
            {name: 'list', path: '/?:scope?:cclusterworkloadlist', container: 'ContainerWorkloadList', load: () => import(/* webpackChunkName: 'ContainerWorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerWorkload/List/ContainerWorkloadList')},
            {
              name: 'item',
              path: '/:id',
              container: 'ContainerWorkloadItem', load: () => import(/* webpackChunkName: 'ContainerWorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerWorkload/Item/ContainerWorkloadItem'),
              forwardTo: '>view',
              children: [
                {name: 'view', path: '/', container: 'ContainerWorkloadSummary', load: () => import(/* webpackChunkName: 'ContainerWorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerWorkload/Item/Summary/ContainerWorkloadSummary')},
                {name: 'containers', path: '/containers', container: 'ContainerWorkloadContainers', load: () => import(/* webpackChunkName: 'ContainerWorkloadContainers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerWorkload/Item/Containers/ContainerWorkloadContainers')},
                {name: 'rules', path: '/rules?:inbound?:outbound?:customip', container: 'ContainerWorkloadRules', load: () => import(/* webpackChunkName: 'ContainerWorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerWorkload/Item/Rules/ContainerWorkloadRules')},
              ],
            },
          ],
        },
        {
          name: 'vens',
          path: '/vens',
          container: 'Ven', load: () => import(/* webpackChunkName: 'Ven' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Ven'),
          forwardTo: '>list',
          children: [
            {name: 'list', path: '/?:venslist', container: 'VenList', load: () => import(/* webpackChunkName: 'VenList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/List/VenList')},
            {
              name: 'item',
              path: '/:id',
              forwardTo: '>view',
              container: 'VenItem', load: () => import(/* webpackChunkName: 'VenItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Item/VenItem'),
              children: [
                {name: 'view', path: '/?:supportreportlist', container: 'VenSummary', load: () => import(/* webpackChunkName: 'VenSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Item/Summary/VenSummary')},
                {name: 'edit', path: '/edit', container: 'VenEdit', load: () => import(/* webpackChunkName: 'VenEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Item/Edit/VenEdit')},
                {
                  name: 'containerWorkloads',
                  path: '/containerworkloads?:cclusterworkloadlist',
                  container: 'VenContainerWorkloads', load: () => import(/* webpackChunkName: 'VenContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Ven/Item/ContainerWorkloads/VenContainerWorkloads'),
                },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'services',
      path: '/services',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:servicelist', container: 'ServiceList', load: () => import(/* webpackChunkName: 'ServiceList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Service/List/ServiceList')},
        {name: 'create', path: '/create', container: 'ServiceEdit', load: () => import(/* webpackChunkName: 'ServiceEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Service/Item/Edit/ServiceEdit')},
        {
          name: 'item',
          path: '/:id/:pversion',
          forwardTo: '>view',
          defaultParams: {pversion: 'draft'},
          container: 'ServiceItem', load: () => import(/* webpackChunkName: 'ServiceItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Service/Item/ServiceItem'),
          children: [
            {name: 'edit', path: '/edit', container: 'ServiceEdit', load: () => import(/* webpackChunkName: 'ServiceEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Service/Item/Edit/ServiceEdit')},
            {name: 'view', path: '/', container: 'ServiceView', load: () => import(/* webpackChunkName: 'ServiceView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Service/Item/View/ServiceView')},
          ],
        },
      ],
    },

    {
      name: 'supportBundles',
      path: '/supportbundles',
      container: 'SupportBundles', load: () => import(/* webpackChunkName: 'SupportBundles' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SupportBundles/SupportBundles'),
      forwardTo: '>pce',
      children: [
        {
          name: 'pce',
          path: '/pce',
          forwardTo: '>list',
          children: [{name: 'list', path: '/?:pcerptlist', container: 'PCESupportBundlesList', load: () => import(/* webpackChunkName: 'PCESupportBundlesList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SupportBundles/PCESupportBundles/List/PCESupportBundlesList')}],
        },
        {
          name: 'ven',
          path: '/ven',
          forwardTo: '>list',
          children: [{name: 'list', path: '/?:supportreportlist', container: 'SupportReportsList', load: () => import(/* webpackChunkName: 'SupportReportsList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SupportBundles/VENSupportReports/List/SupportReportsList')}],
        },
      ],
    },

    {
      name: 'virtualServices',
      path: '/virtualservices',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:scope?:virtualservicelist', container: 'VirtualServiceList', load: () => import(/* webpackChunkName: 'VirtualServiceList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/List/VirtualServiceList')},
        {name: 'create', path: '/create?:virtualserviceaddress', container: 'VirtualServiceConfig', load: () => import(/* webpackChunkName: 'VirtualServiceConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Config/VirtualServiceConfig')},
        {
          name: 'item',
          path: '/:id/:pversion',
          forwardTo: '>view',
          defaultParams: {pversion: 'draft'},
          container: 'VirtualServiceItem', load: () => import(/* webpackChunkName: 'VirtualServiceItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Item/VirtualServiceItem'),
          children: [
            {name: 'view', path: '/', container: 'VirtualServiceSummary', load: () => import(/* webpackChunkName: 'VirtualServiceSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Item/Summary/VirtualServiceSummary')},
            {name: 'edit', path: '/edit?:virtualserviceaddress', container: 'VirtualServiceConfig', load: () => import(/* webpackChunkName: 'VirtualServiceConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Config/VirtualServiceConfig')},
            {
              name: 'workloads',
              path: '/workloads',
              forwardTo: '>list',
              children: [
                {name: 'list', path: '/?:vswllist', container: 'VirtualServiceWorkloads', load: () => import(/* webpackChunkName: 'VirtualServiceWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Item/Workloads/VirtualServiceWorkloads')},
                {name: 'bind', path: '/bind', container: 'VirtualServiceAddWorkloads', load: () => import(/* webpackChunkName: 'VirtualServiceAddWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Item/Workloads/VirtualServiceAddWorkloads')},
              ],
            },
            {name: 'containerWorkloads', path: '/containers?:vsclist', container: 'VirtualServiceContainers', load: () => import(/* webpackChunkName: 'VirtualServiceContainers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualService/Item/Container/VirtualServiceContainers')},
          ],
        },
      ],
    },

    {
      name: 'labels',
      path: '/labels',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:labellist', container: 'LabelList', load: () => import(/* webpackChunkName: 'LabelList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/List/LabelList')},
        {name: 'create', path: '/create', container: 'LabelEdit', load: () => import(/* webpackChunkName: 'LabelEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/Edit/LabelEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'LabelsItem', load: () => import(/* webpackChunkName: 'LabelsItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/LabelsItem'),
          children: [
            {name: 'view', path: '/', container: 'LabelView', load: () => import(/* webpackChunkName: 'LabelView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/View/LabelView')},
            {name: 'edit', path: '/edit', container: 'LabelEdit', load: () => import(/* webpackChunkName: 'LabelEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/Edit/LabelEdit')},
          ],
        },
      ],
    },

    {
      name: 'labelGroups',
      path: '/labelgroups',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:labelgrouplist', container: 'LabelGroupList', load: () => import(/* webpackChunkName: 'LabelGroupList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/List/LabelGroupList')},
        {name: 'create', path: '/create', container: 'LabelGroupEdit', load: () => import(/* webpackChunkName: 'LabelGroupEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Edit/LabelGroupEdit')},
        {
          name: 'item',
          path: '/:id/:pversion',
          forwardTo: '>members',
          defaultParams: {pversion: 'draft'},
          container: 'LabelGroupItem', load: () => import(/* webpackChunkName: 'LabelGroupItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Item/LabelGroupItem'),
          children: [
            {
              name: 'summary',
              path: '/summary',
              forwardTo: '>view',
              children: [
                {name: 'view', path: '/', container: 'LabelGroupSummary', load: () => import(/* webpackChunkName: 'LabelGroupSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Item/Summary/LabelGroupSummary')},
                {name: 'edit', path: '/edit', container: 'LabelGroupEdit', load: () => import(/* webpackChunkName: 'LabelGroupEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Edit/LabelGroupEdit')},
              ],
            },
            {
              name: 'members',
              path: '/members',
              forwardTo: '>view',
              children: [{name: 'view', path: '/?:memberlist', container: 'LabelGroupMembers', load: () => import(/* webpackChunkName: 'LabelGroupMembers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Item/Members/LabelGroupMembers')}],
            },
            {name: 'memberOf', path: '/memberOf?:memberOflist', container: 'LabelGroupMemberOf', load: () => import(/* webpackChunkName: 'LabelGroupMemberOf' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LabelGroup/Item/MemberOf/LabelGroupMemberOf')},
          ],
        },
      ],
    },

    {
      name: 'labelsettings',
      path: '/labelsettings',
      forwardTo: '>view',
      children: [
        {name: 'view', path: '/?:labelsettingslist', container: 'LabelSettings', load: () => import(/* webpackChunkName: 'LabelSettings' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/LabelSettings/LabelSettings')},
        {name: 'edit', path: '/edit', container: 'LabelSettingsEdit', load: () => import(/* webpackChunkName: 'LabelSettingsEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Label/LabelSettings/LabelSettingsEdit')},
      ],
    },

    {
      name: 'pairingProfiles',
      path: '/pairingprofiles',
      container: 'PairingProfile', load: () => import(/* webpackChunkName: 'PairingProfile' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/PairingProfile'),
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:pairingprofilelist', container: 'PairingProfileList', load: () => import(/* webpackChunkName: 'PairingProfileList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/List/PairingProfileList')},
        {name: 'create', path: '/create', container: 'PairingProfileEdit', load: () => import(/* webpackChunkName: 'PairingProfileEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/Item/Edit/PairingProfileEdit')},
        {name: 'pair', path: '/pair/?:id', container: 'PairingProfilePair', load: () => import(/* webpackChunkName: 'PairingProfilePair' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/Item/Pair/PairingProfilePair')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'PairingProfilesItem', load: () => import(/* webpackChunkName: 'PairingProfilesItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/Item/PairingProfilesItem'),
          children: [
            {name: 'view', path: '/', container: 'PairingProfileView', load: () => import(/* webpackChunkName: 'PairingProfileView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/Item/View/PairingProfileView')},
            {name: 'edit', path: '/edit', container: 'PairingProfileEdit', load: () => import(/* webpackChunkName: 'PairingProfileEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/PairingProfile/Item/Edit/PairingProfileEdit')},
          ],
        },
      ],
    },

    {
      name: 'secureGateways',
      path: '/secureconnectgateways',
      container: 'SecureGateway', load: () => import(/* webpackChunkName: 'SecureGateway' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/SecureGateway'),
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:securegatewaylist', container: 'SecureGatewayList', load: () => import(/* webpackChunkName: 'SecureGatewayList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/List/SecureGatewayList')},
        {name: 'create', path: '/create', defaultParams: {mode: 'create'}, container: 'SecureGatewayEdit', load: () => import(/* webpackChunkName: 'SecureGatewayEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/Item/Edit/SecureGatewayEdit')},
        {
          name: 'item',
          path: '/:id?:mode',
          forwardTo: '>view',
          container: 'SecureGatewayItem', load: () => import(/* webpackChunkName: 'SecureGatewayItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/Item/SecureGatewayItem'),
          children: [
            {
              name: 'edit',
              path: '/edit?:sgrules?:pversion',
              defaultParams: {mode: 'edit', pversion: 'draft'},
              container: 'SecureGatewayEdit', load: () => import(/* webpackChunkName: 'SecureGatewayEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/Item/Edit/SecureGatewayEdit'),
            },
            {
              name: 'view',
              path: '/:pversion?:sgrules',
              defaultParams: {pversion: 'draft'},
              container: 'SecureGatewayView', load: () => import(/* webpackChunkName: 'SecureGatewayView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/SecureGateway/Item/View/SecureGatewayView'),
            },
          ],
        },
      ],
    },

    {
      name: 'accessRestrictions',
      path: '/accessrestrictions',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:accessrestrictionslist', container: 'AccessRestrictionList', load: () => import(/* webpackChunkName: 'AccessRestrictionList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessRestriction/List/AccessRestrictionList')},
        {name: 'create', path: '/create', container: 'AccessRestrictionEdit', load: () => import(/* webpackChunkName: 'AccessRestrictionEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessRestriction/Item/Edit/AccessRestrictionEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'AccessRestrictionItem', load: () => import(/* webpackChunkName: 'AccessRestrictionItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessRestriction/Item/AccessRestrictionItem'),
          children: [
            {name: 'edit', path: '/edit', container: 'AccessRestrictionEdit', load: () => import(/* webpackChunkName: 'AccessRestrictionEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessRestriction/Item/Edit/AccessRestrictionEdit')},
            {name: 'view', path: '/', container: 'AccessRestrictionDetail', load: () => import(/* webpackChunkName: 'AccessRestrictionDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/RBAC/AccessRestriction/Item/Detail/AccessRestrictionDetail')},
          ],
        },
      ],
    },

    {
      name: 'trustedproxy',
      path: '/trustedproxy',
      forwardTo: '>list',
      children: [{name: 'list', path: '/?:trustedproxylist', container: 'TrustedProxyList', load: () => import(/* webpackChunkName: 'TrustedProxyList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/TrustedProxy/List/TrustedProxyList')}],
    },

    {
      name: 'flowCollectionFilters',
      path: '/flowcollection',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:flowcollectionfilterslist', container: 'FlowCollectionFilters', load: () => import(/* webpackChunkName: 'FlowCollectionFilters' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/FlowCollectionFilters/List/FlowCollectionFiltersList')},
        {name: 'create', path: '/create', container: 'FlowCollectionFiltersEdit', load: () => import(/* webpackChunkName: 'FlowCollectionFiltersEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/FlowCollectionFilters/Edit/FlowCollectionFiltersEdit')},
        {name: 'edit', path: '/:id/edit', container: 'FlowCollectionFiltersEdit', load: () => import(/* webpackChunkName: 'FlowCollectionFiltersEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/FlowCollectionFilters/Edit/FlowCollectionFiltersEdit')},
      ],
    },

    {
      name: 'iplists',
      path: '/iplists',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:iplistlist', container: 'IPListList', load: () => import(/* webpackChunkName: 'IPListList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IPList/List/IPListList')},
        {name: 'create', path: '/create', container: 'IPListEdit', load: () => import(/* webpackChunkName: 'IPListEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IPList/Item/Edit/IPListEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'IPListItem', load: () => import(/* webpackChunkName: 'IPListItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IPList/Item/IPListItem'),
          children: [
            {name: 'edit', path: '/:pversion/edit', defaultParams: {pversion: 'draft'}, container: 'IPListEdit', load: () => import(/* webpackChunkName: 'IPListEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IPList/Item/Edit/IPListEdit')},
            {name: 'view', path: '/:pversion', container: 'IPListView', load: () => import(/* webpackChunkName: 'IPListView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IPList/Item/View/IPListView')},
          ],
        },
      ],
    },

    {
      name: 'authenticationSettings',
      path: '/authenticationsettings',
      container: 'AuthenticationSettingsContainer', load: () => import(/* webpackChunkName: 'AuthenticationSettingsContainer' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/AuthenticationSettingsContainer'),
      forwardTo: '>main',
      children: [
        {name: 'main', path: '/', container: 'AuthenticationSettings', load: () => import(/* webpackChunkName: 'AuthenticationSettings' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/AuthenticationSettings')},
        {
          name: 'passwordPolicy',
          path: '/passwordpolicy',
          forwardTo: '>view',
          children: [
            {name: 'view', path: '/', container: 'PasswordPolicy', load: () => import(/* webpackChunkName: 'PasswordPolicy' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/PasswordPolicy/PasswordPolicy')},
            {name: 'edit', path: '/edit', container: 'PasswordPolicyEdit', load: () => import(/* webpackChunkName: 'PasswordPolicyEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/PasswordPolicy/PasswordPolicyEdit')},
          ],
        },
        {
          name: 'sso',
          path: '/sso',
          forwardTo: '>list',
          children: [
            {
              name: 'list',
              path: '/list',
              forwardTo: '>samlConfigs',
              children: [
                {name: 'samlConfigs', path: '/?:samlConfigs', container: 'AuthenticationSettingsSAMLList', load: () => import(/* webpackChunkName: 'AuthenticationSettingsSAMLList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/SAML/List/AuthenticationSettingsSAMLList')},
                {
                  name: 'item',
                  path: '/:id',
                  forwardTo: '>view',
                  container: 'AuthenticationSettingsSAMLItem', load: () => import(/* webpackChunkName: 'AuthenticationSettingsSAMLItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/SAML/AuthenticationSettingsSAMLItem'),
                  children: [
                    {name: 'view', path: '/', container: 'AuthenticationSettingsSAMLView', load: () => import(/* webpackChunkName: 'AuthenticationSettingsSAMLView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/SAML/View/AuthenticationSettingsSAMLView')},
                    {name: 'edit', path: '/edit', container: 'AuthenticationSettingsSAMLEdit', load: () => import(/* webpackChunkName: 'AuthenticationSettingsSAMLEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/SAML/Edit/AuthenticationSettingsSAMLEdit')},
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'ldap',
          forwardTo: '>list',
          path: '/ldap',
          children: [
            {
              name: 'list',
              path: '/list',
              forwardTo: '>ldapConfigs',
              children: [
                {name: 'ldapConfigs', path: '/?:ldapConfigs', container: 'AuthenticationSettingsLDAPList', load: () => import(/* webpackChunkName: 'AuthenticationSettingsLDAPList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/LDAP/List/AuthenticationSettingsLDAPList')},
                {
                  name: 'item',
                  path: '/:id',
                  forwardTo: '>view',
                  container: 'AuthenticationSettingsLDAPItem', load: () => import(/* webpackChunkName: 'AuthenticationSettingsLDAPItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/LDAP/AuthenticationSettingsLDAPItem'),
                  children: [
                    {name: 'view', path: '/', container: 'AuthenticationSettingsLDAPView', load: () => import(/* webpackChunkName: 'AuthenticationSettingsLDAPView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/LDAP/View/AuthenticationSettingsLDAPView')},
                    {name: 'edit', path: '/edit', container: 'AuthenticationSettingsLDAPEdit', load: () => import(/* webpackChunkName: 'AuthenticationSettingsLDAPEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/LDAP/Edit/AuthenticationSettingsLDAPEdit')},
                  ],
                },
                {name: 'create', path: '/create?:pce', container: 'AuthenticationSettingsLDAPEdit', load: () => import(/* webpackChunkName: 'AuthenticationSettingsLDAPEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AuthenticationSettings/LDAP/Edit/AuthenticationSettingsLDAPEdit')},
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'userGroups',
      path: '/usergroups',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:usergrouplist', container: 'UserGroupList', load: () => import(/* webpackChunkName: 'UserGroupList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/UserGroup/List/UserGroupList')},
        {name: 'create', path: '/create', container: 'UserGroupEdit', load: () => import(/* webpackChunkName: 'UserGroupEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/UserGroup/Edit/UserGroupEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'UserGroupItem', load: () => import(/* webpackChunkName: 'UserGroupItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/UserGroup/UserGroupItem'),
          children: [
            {name: 'view', path: '/', container: 'UserGroupView', load: () => import(/* webpackChunkName: 'UserGroupView' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/UserGroup/View/UserGroupView')},
            {name: 'edit', path: '/edit', container: 'UserGroupEdit', load: () => import(/* webpackChunkName: 'UserGroupEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/UserGroup/Edit/UserGroupEdit')},
          ],
        },
      ],
    },

    {
      name: 'group',
      path: '/groups/:id',
      children: [
        {name: 'rules', path: '/rules', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'workloads', path: '/workloads', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'pairingProfiles', path: '/pairingprofiles', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
      ],
    },
    {name: 'appGroupsList', path: '/appgroupslist', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},

    {
      name: 'appGroups',
      path: '/appgroups',
      container: 'AppGroups', load: () => import(/* webpackChunkName: 'AppGroups' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/AppGroups/AppGroups'),
      children: [
        {
          name: 'detail',
          path: '/:id',
          children: [
            {name: 'members', path: '/members', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'workloads', path: '/workloads', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'virtualServices', path: '/virtualservices', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'virtualServers', path: '/virtualservers', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'containerWorkloads', path: '/containerworkloads', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'rules', path: '/rules', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'pairingProfiles', path: '/pairingprofiles', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'vulnerabilities', path: '/vulnerabilities', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'explorer', path: '/explorer', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {
              name: 'illumination',
              path: '/illuminationplus?:query?:go?:offset?:policy?:policyFilter?:display?:connection?:tab?:maplist?:mapworkloads?:maplinks?:grouping?:consuming?:providing?:rulesTab?:ruleMode?:mappanelunmanagedsummary',
              container: 'Illumination', load: () => import(/* webpackChunkName: 'Illumination' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IlluminationMap/Map'),
            },
            {
              name: 'policyGenerator',
              path: '/policygenerator',
              container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
              children: [
                {
                  name: 'intraScope',
                  path: '/intrascope',
                  children: [
                    {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                  ],
                },

                {
                  name: 'extraScope',
                  path: '/extrascope',
                  children: [
                    {name: 'choose', path: '/choose', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                  ],
                },

                {
                  name: 'ipList',
                  path: '/iplist',
                  children: [
                    {name: 'choose', path: '/choose', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                    {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'versions',
      path: '/versions',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:versionlist', container: 'ProvisionVersionList', load: () => import(/* webpackChunkName: 'ProvisionVersionList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Version/List/VersionList')},
        {
          name: 'item',
          path: '/:pversion',
          children: [
            {name: 'detail', path: '/?:versiondetail', container: 'ProvisionVersionDetail', load: () => import(/* webpackChunkName: 'ProvisionVersionDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Version/Detail/VersionDetail')},
            {name: 'restore', path: '/restore?:versionrestore', container: 'ProvisionVersionRestore', load: () => import(/* webpackChunkName: 'ProvisionVersionRestore' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Provisioning/Version/Restore/VersionRestore')},
          ],
        },
      ],
    },

    {
      name: 'health',
      path: '/health',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:healthList', container: 'HealthList', load: () => import(/* webpackChunkName: 'HealthList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Health/List/HealthList')},
        {name: 'detail', path: '/:fqdn?:group', container: 'HealthDetail', load: () => import(/* webpackChunkName: 'HealthDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Health/Detail/HealthDetail')},
      ],
    },

    {
      name: 'loadBalancers',
      path: '/loadbalancers',
      container: 'LoadBalancer', load: () => import(/* webpackChunkName: 'LoadBalancer' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/LoadBalancer'),
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:loadbalancerslist', container: 'LoadBalancerList', load: () => import(/* webpackChunkName: 'LoadBalancerList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/List/LoadBalancerList')},
        {name: 'create', path: '/create', container: 'LoadBalancerEdit', load: () => import(/* webpackChunkName: 'LoadBalancerEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/Item/Edit/LoadBalancerEdit')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>view',
          container: 'LoadBalancerItem', load: () => import(/* webpackChunkName: 'LoadBalancerItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/Item/LoadBalancerItem'),
          children: [
            {name: 'view', path: '/', container: 'LoadBalancerSummary', load: () => import(/* webpackChunkName: 'LoadBalancerSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/Item/Summary/LoadBalancerSummary')},
            {name: 'edit', path: '/edit', container: 'LoadBalancerEdit', load: () => import(/* webpackChunkName: 'LoadBalancerEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/Item/Edit/LoadBalancerEdit')},
            {name: 'servers', path: '/servers?:virtualserverlist', container: 'LoadBalancerServers', load: () => import(/* webpackChunkName: 'LoadBalancerServers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/LoadBalancer/Item/Servers/LoadBalancerServers')},
          ],
        },
      ],
    },

    {
      name: 'containerClusters',
      path: '/containerclusters',
      container: 'ContainerCluster', load: () => import(/* webpackChunkName: 'ContainerCluster' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/ContainerCluster'),
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:cclusterlist', container: 'ContainerClusterList', load: () => import(/* webpackChunkName: 'ContainerClusterList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/List/ContainerClusterList')},
        {name: 'create', path: '/create', container: 'ContainerClusterConfig', load: () => import(/* webpackChunkName: 'ContainerClusterConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Config/ContainerClusterConfig')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>summary',
          container: 'ContainerClusterDetail', load: () => import(/* webpackChunkName: 'ContainerClusterDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/ContainerClusterDetail'),
          children: [
            {name: 'summary', path: '/summary', container: 'ContainerClusterSummary', load: () => import(/* webpackChunkName: 'ContainerClusterSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Summary/ContainerClusterSummary')},
            {
              name: 'profiles',
              path: '/profiles',
              forwardTo: '>list',
              children: [
                {
                  name: 'list',
                  path: '/?:cclusterprofilelist',
                  container: 'ContainerClusterContainerWorkloadProfileList', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/List/ContainerClusterContainerWorkloadProfileList'),
                },
                {
                  name: 'create',
                  path: '/create?:profilelabellist',
                  container: 'ContainerClusterContainerWorkloadProfileConfig', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/Config/ContainerClusterContainerWorkloadProfileConfig'),
                },
                {
                  name: 'item',
                  path: '/:profileId?:profilelabellist',
                  container: 'ContainerClusterContainerWorkloadProfileDetail', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/Detail/ContainerClusterContainerWorkloadProfileDetail'),
                },
                {
                  name: 'edit',
                  path: '/:profileId/edit?:profilelabellist',
                  container: 'ContainerClusterContainerWorkloadProfileConfig', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/Config/ContainerClusterContainerWorkloadProfileConfig'),
                },
                {
                  name: 'default',
                  path: '/default?:profilelabellist',
                  container: 'ContainerClusterContainerWorkloadProfileDetail', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/Detail/ContainerClusterContainerWorkloadProfileDetail'),
                },
                {
                  name: 'defaultedit',
                  path: '/defaultedit?:profilelabellist',
                  container: 'ContainerClusterContainerWorkloadProfileConfig', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Profile/Config/ContainerClusterContainerWorkloadProfileConfig'),
                },
              ],
            },
            {name: 'workloads', path: '/workloads?:workloadlist', container: 'ContainerClusterWorkloads', load: () => import(/* webpackChunkName: 'ContainerClusterWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Workload/ContainerClusterWorkloads')},
            {name: 'vens', path: '/vens?:venslist', container: 'ContainerClusterVens', load: () => import(/* webpackChunkName: 'ContainerClusterVens' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/Ven/ContainerClusterVens')},
            {
              name: 'containerWorkloads',
              path: '/containerworkloads?:cclusterworkloadlist',
              container: 'ContainerClusterContainerWorkloads', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/ContainerWorkload/ContainerClusterContainerWorkloads'),
            },
            {
              name: 'serviceBackends',
              path: '/serviceBackends?:cclusterservicebackendlist',
              container: 'ContainerClusterServiceBackends', load: () => import(/* webpackChunkName: 'ContainerClusterServiceBackends' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Detail/ServiceBackend/ContainerClusterServiceBackends'),
            },
            {name: 'edit', path: '/edit', container: 'ContainerClusterConfig', load: () => import(/* webpackChunkName: 'ContainerClusterConfig' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ContainerCluster/Config/ContainerClusterConfig')},
          ],
        },
      ],
    },

    {
      name: 'networks',
      path: '/networks',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:networklist', container: 'NetworkList', load: () => import(/* webpackChunkName: 'NetworkList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Network/List/NetworkList')},
        {name: 'create', path: '/create', container: 'NetworkEdit', load: () => import(/* webpackChunkName: 'NetworkEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Network/Edit/NetworkEdit')},
        {
          name: 'item',
          path: '/:network_id',
          forwardTo: '>summary',
          children: [
            {name: 'summary', path: '/', container: 'NetworkSummary', load: () => import(/* webpackChunkName: 'NetworkSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Network/Item/NetworkSummary')},
            {name: 'edit', path: '/edit', container: 'NetworkEdit', load: () => import(/* webpackChunkName: 'NetworkEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Network/Edit/NetworkEdit')},
          ],
        },
      ],
    },

    {
      name: 'switches',
      path: '/switches',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:switchlist', container: 'SwitchList', load: () => import(/* webpackChunkName: 'SwitchList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/List/SwitchList')},
        {name: 'create', path: '/create', container: 'SwitchCreate', load: () => import(/* webpackChunkName: 'SwitchCreate' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Create/SwitchCreate')},
        {
          name: 'item',
          path: '/:id',
          forwardTo: '>summary',
          container: 'SwitchDetail', load: () => import(/* webpackChunkName: 'SwitchDetail' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Detail/SwitchDetail'),
          children: [
            {name: 'summary', path: '/', container: 'SwitchSummary', load: () => import(/* webpackChunkName: 'SwitchSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Detail/Summary/SwitchSummary')},
            {name: 'edit', path: '/edit', container: 'SwitchEdit', load: () => import(/* webpackChunkName: 'SwitchEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Edit/SwitchEdit')},
            {
              name: 'interfaces',
              path: '/interfaces',
              forwardTo: '>item',
              children: [
                {name: 'item', path: '/', container: 'SwitchInterfaces', load: () => import(/* webpackChunkName: 'SwitchInterfaces' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Detail/Interface/SwitchInterfaces')},
                {name: 'edit', path: '/edit', container: 'SwitchInterfacesEdit', load: () => import(/* webpackChunkName: 'SwitchInterfacesEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Switch/Detail/Interface/SwitchInterfacesEdit')},
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'virtualServers',
      path: '/virtualservers',
      forwardTo: '>list',
      children: [
        {name: 'list', path: '/?:virtualserverlist', container: 'VirtualServerList', load: () => import(/* webpackChunkName: 'VirtualServerList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/List/VirtualServerList')},
        {
          name: 'item',
          path: '/:id/:pversion',
          forwardTo: '>summary',
          defaultParams: {pversion: 'draft'},
          container: 'VirtualServerItem', load: () => import(/* webpackChunkName: 'VirtualServerItem' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/VirtualServerItem'),
          children: [
            {
              name: 'summary',
              path: '/summary',
              forwardTo: '>view',
              children: [
                {name: 'view', path: '/', container: 'VirtualServerSummary', load: () => import(/* webpackChunkName: 'VirtualServerSummary' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/Summary/VirtualServerSummary')},
                {name: 'edit', path: '/edit', container: 'VirtualServerSummaryEdit', load: () => import(/* webpackChunkName: 'VirtualServerSummaryEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/Summary/VirtualServerSummaryEdit')},
              ],
            },
            {
              name: 'members',
              path: '/members',
              forwardTo: '>view',
              children: [
                {name: 'view', path: '/?:workloadlist', container: 'VirtualServerMembers', load: () => import(/* webpackChunkName: 'VirtualServerMembers' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/Members/VirtualServerMembers')},
                {name: 'edit', path: '/edit', container: 'VirtualServerMembersEdit', load: () => import(/* webpackChunkName: 'VirtualServerMembersEdit' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/Members/VirtualServerMembersEdit')},
              ],
            },
            {name: 'rules', path: '/rules?:inbound?:outbound', container: 'VirtualServerRules', load: () => import(/* webpackChunkName: 'VirtualServerRules' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VirtualServer/Item/Rules/VirtualServerRules')},
          ],
        },
      ],
    },

    {name: 'blockedTraffic', path: '/blockedtraffic', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
    {name: 'trafficevents', path: '/trafficevents', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
    {name: 'connectivity', path: '/connectivity', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
    {name: 'apiexplorer', path: '/api', container: 'ApiExplorer', load: () => import(/* webpackChunkName: 'ApiExplorer' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/ApiExplorer/ApiExplorer')},
    {
      name: 'illumination',
      path: '/illuminationplus?:query?:go?:landing?:offset?:policy?:policyFilter?:display?:connection?:tab?:maplist?:mapworkloads?:maplinks?:grouping?:rulesTab?:ruleMode?:mappanelunmanagedsummary',
      container: 'Illumination', load: () => import(/* webpackChunkName: 'Illumination' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/IlluminationMap/Map'),
    },
    {
      name: 'map',
      path: '/map',
      container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
      children: [
        {name: 'level', path: '/:type/:id', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'prevlevel', path: '/:prevtype/:previd/:type/:id', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
      ],
    },

    {
      name: 'appMap',
      path: '/appgroup',
      container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
      children: [
        {name: 'level', path: '/:type/:id', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
        {name: 'prevlevel', path: '/:prevtype/:previd/:type/:id', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
      ],
    },

    {
      name: 'supportreport',
      path: '/supportreport',
      children: [{name: 'list', path: '/?:supportreportlist', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')}],
    },

    {
      name: 'policygenerator',
      path: '/policygenerator',
      container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
      children: [
        {
          name: 'intraScope',
          path: '/intrascope',
          children: [
            {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
          ],
        },

        {
          name: 'extraScope',
          path: '/extrascope',
          children: [
            {name: 'choose', path: '/choose', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
          ],
        },

        {
          name: 'ipList',
          path: '/iplist',
          children: [
            {name: 'choose', path: '/choose', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'configure', path: '/configure', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
          ],
        },

        {
          name: 'ringFence',
          path: '/segmentation',
          children: [
            {name: 'choose', path: '/choose', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'preview', path: '/preview', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
            {name: 'success', path: '/success', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
          ],
        },
      ],
    },

    {
      name: 'reports',
      path: '/exportreports',
      forwardTo: '>list',
      children: [{name: 'list', path: '/?:reportlist', container: 'ReportsList', load: () => import(/* webpackChunkName: 'ReportsList' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Reports/List/ReportsList')}],
    },

    {name: 'explorer', path: '/explorer/?:go', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
    {name: 'trafficParameters', path: '/trafficparameters', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},

    {
      name: 'componentsold',
      path: '/componentsold',
      container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld'),
      children: [{name: 'forms', path: '/forms', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')}],
    },
    {
      name: 'components',
      path: '/components',
      container: 'ComponentsTabs', load: () => import(/* webpackChunkName: 'ComponentsTabs' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/ComponentsTabs'),
      forwardTo: 'components.list',
      children: [
        {name: 'list', path: '/list?:section?:tab?:secondaryGrid', container: 'Components', load: () => import(/* webpackChunkName: 'Components' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/Components')},
        {name: 'forms', path: '/forms?:section', container: 'Forms', load: () => import(/* webpackChunkName: 'Forms' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/Forms/ComponentsForms')},
        {name: 'diff', path: '/diff?:section', container: 'ComponentsDiff', load: () => import(/* webpackChunkName: 'ComponentsDiff' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/Diff/ComponentsDiff')},
        {name: 'modals', path: '/modals?:section', container: 'ComponentsModals', load: () => import(/* webpackChunkName: 'ComponentsModals' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/Modals/Modals')},
        {name: 'selector', path: '/selector?:section', container: 'ComponentsSelector', load: () => import(/* webpackChunkName: 'ComponentsSelector' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/Selector/ComponentsSelector')},
      ],
    },

    {name: 'versionmismatch', path: '/versionmismatch', container: 'VersionMismatch', load: () => import(/* webpackChunkName: 'VersionMismatch' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/VersionMismatch/VersionMismatch')},

    {
      name: 'testloader1',
      path: '/testloader1',
      container: 'TestLoader1', load: () => import(/* webpackChunkName: 'TestLoader1' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader1'),
      children: [
        {
          name: 'testloader2',
          path: '/testloader2',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3', path: '/testloader3', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
        {
          name: 'testloader2alt',
          path: '/testloader2alt',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3alt', path: '/testloader3alt', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
      ],
    },

    {
      name: 'testloader1h',
      path: '/testloader1h',
      container: 'TestLoader1Handle', load: () => import(/* webpackChunkName: 'TestLoader1Handle' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader1Handle'),
      prefetchChildrenByComponent: 'entry',
      progressBar: null,
      children: [
        {
          name: 'testloader2',
          path: '/testloader2',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3', path: '/testloader3', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
        {
          name: 'testloader2alt',
          path: '/testloader2alt',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3alt', path: '/testloader3alt', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
      ],
    },

    {
      name: 'testloader1ht',
      path: '/testloader1ht',
      container: 'TestLoader1Handle', load: () => import(/* webpackChunkName: 'TestLoader1Handle' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader1Handle'),
      prefetchChildrenByComponent: 'child-transition',
      progressBar: null,
      children: [
        {
          name: 'testloader2',
          path: '/testloader2',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3', path: '/testloader3', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
        {
          name: 'testloader2alt',
          path: '/testloader2alt',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3alt', path: '/testloader3alt', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
      ],
    },

    {
      name: 'testloader1ha',
      path: '/testloader1ha',
      container: 'TestLoader1Handle', load: () => import(/* webpackChunkName: 'TestLoader1Handle' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader1Handle'),
      prefetchChildrenByComponent: 'always',
      progressBar: null,
      children: [
        {
          name: 'testloader2',
          path: '/testloader2',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [
            {name: 'testloader3', path: '/testloader3', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')},
            {name: 'testloader3throw', path: '/testloader3throw', container: 'TestLoader3throw', load: () => import(/* webpackChunkName: 'TestLoader3throw' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3throw')},
          ],
        },
        {
          name: 'testloader2alt',
          path: '/testloader2alt',
          container: 'TestLoader2', load: () => import(/* webpackChunkName: 'TestLoader2' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader2'),
          children: [{name: 'testloader3alt', path: '/testloader3alt', container: 'TestLoader3', load: () => import(/* webpackChunkName: 'TestLoader3' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/Components/TestLoader/TestLoader3')}],
        },
      ],
    },
    {name: 'resourceNotFound', path: '/resourcenotfound', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/param-ci_dev/thor/app/containers/JumpToOld')},
  ],
} as RawRoute;

// Use to merge container routes such as a target containers and shared common containers.
export const routesMerger: MergeWithCustomizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.values(_.mergeWith(_.keyBy(objValue, 'name'), _.keyBy(srcValue, 'name'), routesMerger));
  }
};

// Flatten routes tree and put into routesList and routesMap
export const processRoutes = (
  routes: RawRoute[],
  parent: Route,
  containers: Record<string, ComponentType>,
): {routesList: typeof routesList; routesMap: typeof routesMap; defaultRoute: typeof defaultRoute} => {
  // TODO: Double check if this is needed
  if (edge) {
    routes = routes?.filter(route => !edge || route.name !== 'group');
  }

  for (const {children, forwardTo, forwardParams, defaultParams, ...rest} of routes) {
    const route = rest as Route;

    if (parent) {
      route.parent = parent;
      route.parents = parent.parents.concat(parent);
      route.nameStrip = `${parent.nameStrip ? `${parent.nameStrip}.` : ''}${route.name}`;
      route.name = `${parent.name}.${route.name}`;
    } else {
      route.parents = [];
    }

    if (!route.component && typeof route.container !== 'undefined') {
      route.component = containers[route.container];
    }

    if (__DEV__ && route.container && !(route.component || route.load)) {
      throw new Error(`Router can't find '${route.container}' container`);
    }

    // Rename forwardTo and defaultParams to handle them manually in Prefetcher
    if (forwardTo) {
      if (forwardTo.startsWith('>')) {
        route.redirectTo = `${route.name}.${forwardTo.substr(1)}`;
        route.redirectToStrip = `${route.nameStrip}.${forwardTo.substr(1)}`;
      } else {
        route.redirectTo = `app.${forwardTo}`;
        route.redirectToStrip = forwardTo;
      }

      if (forwardParams) {
        route.redirectParams = forwardParams;
      }
    }

    if (defaultParams) {
      route.autoParams = parent ? {...parent.autoParams, ...defaultParams} : defaultParams;
    }

    routesList.push(route);
    routesMap.set(route.name, route);

    if (children) {
      processRoutes(children, route, containers);
    }
  }

  return {
    routesList,
    routesMap,
    defaultRoute,
  };
};
