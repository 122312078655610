/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Badge, Pill, StatusIcon} from 'components';
import {UserName} from 'containers';
import {formatUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './LabelGroupList.css';
import {createSelector} from 'reselect';
import {isIPForwardingEnabled} from 'containers/App/AppState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {populateFacetCategory, provisionStatusCategory} from 'containers/Selector/GridFilter/GridFilterUtils';

const resourceType = 'label_groups';

export const categories = createSelector([getDisplayNames], labelTypesNameObj => [
  populateFacetCategory({
    id: 'name',
    name: intl('Common.Name'),
    resourceType,
    params: {pversion: 'draft'},
  }),
  populateFacetCategory({
    id: 'description',
    name: intl('Common.Description'),
    resourceType,
    params: {pversion: 'draft'},
  }),
  provisionStatusCategory,
  {
    id: 'key',
    name: intl('Common.Type'),
    resources: {
      key: {
        statics: Object.entries(labelTypesNameObj).map(([id, value]) => ({value: id, text: value})),
        optionProps: {
          textPath: 'text',
          idPath: 'value',
        },
      },
    },
  },
]);

export const formatUsageCell = (value, text) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <StatusIcon position="before" status="inuse" />
      {text}
    </>
  );
};

export const formatHeader = areas => (
  <>
    <div className={styles.usageHeader}>{intl('Common.InUseBy')}</div>
    {areas}
  </>
);

export const gridSettings = createSelector([isIPForwardingEnabled], isIPForwardingEnabled => ({
  id: 'labelgrouplist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => formatUtils.getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={formatUtils.getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    type: {
      header: intl('Common.Type'),
      value: 'display_name',
      format: ({row, value}) => (
        <Pill.Label noAffix noFill group position="before" type={row.data.key}>
          {value}
        </Pill.Label>
      ),
    },
    rulesets: {
      header: intl('Common.Rulesets'),
      value: ({row}) => row.data.usage && (row.data.usage.ruleset || row.data.usage.rule),
      format: ({value}) => formatUsageCell(value, intl('Common.Rulesets')),
    },
    labelGroups: {
      header: intl('Labels.Groups'),
      value: ({row}) => row.data.usage.label_group,
      format: ({value}) => formatUsageCell(value, intl('Labels.Groups')),
    },
    staticPolicy: {
      header: intl('Common.StaticPolicy'),
      value: ({row}) => row.data.usage.static_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Common.StaticPolicy')),
      disabled: __ANTMAN__,
    },
    ipForwarding: {
      header: intl('Settings.IPForwarding.Title'),
      value: ({row}) => row.data.usage.ip_forwarding_enabled_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.IPForwarding.Title')),
      disabled: __ANTMAN__ || !isIPForwardingEnabled,
    },
    loopbackInterfaces: {
      header: intl('Settings.LoopbackInterfaces.Title'),
      value: ({row}) => row.data.usage.loopback_interfaces_in_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.LoopbackInterfaces.Title')),
      disabled: __ANTMAN__,
    },
    containerSettings: {
      header: intl('Settings.Containers.ContainerSettings'),
      value: ({row}) => row.data.usage.containers_inherit_host_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.Containers.ContainerSettings')),
      disabled: __ANTMAN__,
    },
    blockaction: {
      header: intl('Common.BlockAction'),
      value: ({row}) => row.data.usage.blocked_connection_reject_scopes,
      format: ({value}) => formatUsageCell(value, intl('Common.BlockAction')),
      disabled: __ANTMAN__,
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by?.username,
      format: ({row, clickableRef}) =>
        row.data.updated_by && <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    enforcementBoundary: {
      header: intl('Workloads.EnforcementBoundaries'),
      value: ({row}) => row.data.usage.enforcement_boundary,
      format: ({value}) => formatUsageCell(value, intl('Workloads.EnforcementBoundaries')),
    },
    rbac: {
      header: intl('Common.RBAC'),
      value: ({row}) => row.data.usage.permission,
      format: ({value}) => formatUsageCell(value, intl('Common.RBAC')),
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(200px, auto)'},
      {columns: ['type'], size: 'minmax(100px,auto)'},
      {
        columns: ['rulesets', 'labelGroups', 'enforcementBoundary', 'ipForwarding'],
        size: 'minmax(110px, auto)',
        header: formatHeader,
      },
      {
        columns: ['staticPolicy', 'rbac', 'loopbackInterfaces', 'containerSettings', 'blockaction'],
        size: 'minmax(110px, auto)',
        header: formatHeader,
      },
      {columns: ['updatedAt'], size: 'minmax(230px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(270px, auto)'},
    ],
    {
      maxWidth: 1440,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['type', 'name'], size: 'minmax(200px, auto)'},
            {
              columns: ['rulesets', 'labelGroups', 'enforcementBoundary', 'ipForwarding'],
              size: 'minmax(110px, auto)',
              header: formatHeader,
            },
            {
              columns: ['staticPolicy', 'rbac', 'loopbackInterfaces', 'containerSettings', 'blockaction'],
              size: 'minmax(110px, auto)',
              header: formatHeader,
            },
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(260px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['type', 'name'], size: 'minmax(200px, auto)'},
          {
            columns: ['rulesets', 'labelGroups', 'enforcementBoundary', 'ipForwarding'],
            size: 'minmax(110px, auto)',
            header: formatHeader,
          },
          {
            columns: ['staticPolicy', 'rbac', 'loopbackInterfaces', 'containerSettings', 'blockaction'],
            size: 'minmax(110px, auto)',
            header: formatHeader,
          },
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(260px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['type', 'name'], size: 'minmax(200px, auto)'},
            {
              columns: [
                'rulesets',
                'labelGroups',
                'enforcementBoundary',
                'ipForwarding',
                'staticPolicy',
                'rbac',
                'loopbackInterfaces',
                'containerSettings',
                'blockaction',
              ],
              size: 'minmax(110px, auto)',
              header: formatHeader,
            },
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['type', 'name'], size: 'minmax(200px, auto)'},
          {
            columns: [
              'rulesets',
              'labelGroups',
              'enforcementBoundary',
              'ipForwarding',
              'staticPolicy',
              'loopbackInterfaces',
              'rbac',
              'containerSettings',
              'blockaction',
            ],
            size: 'minmax(110px, auto)',
            header: formatHeader,
          },
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type', 'name', 'status'], size: 'minmax(100px, auto)'},
            {
              columns: [
                'rulesets',
                'labelGroups',
                'enforcementBoundary',
                'ipForwarding',
                'staticPolicy',
                'loopbackInterfaces',
                'rbac',
                'containerSettings',
                'blockaction',
              ],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(180px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type', 'name', 'status'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'rulesets',
              'labelGroups',
              'enforcementBoundary',
              'ipForwarding',
              'staticPolicy',
              'loopbackInterfaces',
              'rbac',
              'containerSettings',
              'blockaction',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(180px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type', 'name', 'updatedAt', 'updatedBy', 'status'], size: 'minmax(100px, auto)'},
            {
              columns: [
                'rulesets',
                'labelGroups',
                'enforcementBoundary',
                'ipForwarding',
                'staticPolicy',
                'rbac',
                'loopbackInterfaces',
                'containerSettings',
                'blockaction',
              ],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type', 'name', 'updatedAt', 'updatedBy', 'status'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'rulesets',
              'labelGroups',
              'enforcementBoundary',
              'ipForwarding',
              'staticPolicy',
              'rbac',
              'loopbackInterfaces',
              'containerSettings',
              'blockaction',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
  ],
}));
