/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import _ from 'lodash';
import {hrefUtils} from 'utils';
import scopeReducers from './Roles/Scope/ScopeState';
import {getAuthSecPrincipalsHrefMap} from './AuthSecPrincipalState';
import {roleStrings, getScopeId, getLabelObjects, getLabelObject, getScopeIdString} from 'containers/RBAC/RBACUtils';
import {getOrgId} from 'containers/User/UserState';

/*
 *  Permissions are the main objects for RBAC. They consist of:
 *  href,
 *  scope: [
 *    {href: <labelHref>}, (up to three of these label href objects, empty scope = all-all-all)
 *    ...
 *  ],
 *  role: {
 *    href: <roleHref>
 *  },
 *  auth_security_principal: {
 *    href: <authSecPrincipalHref>
 *  },
 */
export default {
  permissions: combineReducers({
    ...scopeReducers,
    all(state = [], action) {
      switch (action.type) {
        case 'PERMISSION_GET_ALL':
          return action.data.permissions;
        default:
          return state;
      }
    },
    allGetting(state = false, action) {
      switch (action.type) {
        case 'PERMISSION_GETTING_ALL':
          return true;
        case 'PERMISSION_GET_ALL':
          return false;
        default:
          return state;
      }
    },
  }),
};

/*
 *  Returns the normal get collection response in raw api format
 */
export const getPermissions = state => state.permissions.all;
export const getPermissionsGetting = state => state.permissions.allGetting;

/*
 *  *** USE THIS FORMAT BY DEFAULT ***
 *  The default api response format for permissions is a little cumbersome since all the hrefs are wrapped in an object.
 *  This selector flattens the response structure and also adds some common formats like role name or scopeString.
 *  This should be the default permission strucutre used when dealing w/ permissions on RBAC or User pages.
 */
export const getPermissionsExpanded = createSelector(
  [getPermissions, getAuthSecPrincipalsHrefMap, getOrgId],
  (permissions, authSecPrincipalsMap, orgId) =>
    permissions
      .filter(permission => {
        // In MSSP managed orgs, the permission collection api result also contains permission for MSSP admins
        // but we don't want to show them
        const authSecPrincipalHref = permission?.auth_security_principal?.href;
        const authSecPrincipalOrgId = hrefUtils.getOrgIdFromHref(authSecPrincipalHref);

        return authSecPrincipalOrgId === orgId;
      })
      .map(permission => {
        // Use getLabelObjects to normalize the format of the scope.
        const scopeHrefs = getLabelObjects(permission.scope)
          .map(scopeLabel => scopeLabel.href)
          .sort();
        const scope = permission.scope;
        const scopeId = _.sortBy(getScopeId(scope), ['key']);
        const authSecPrincipalHref = _.get(permission, 'auth_security_principal.href');
        const roleHref = _.get(permission, 'role.href');
        const role = hrefUtils.getId(roleHref);
        const roleString = roleStrings()[role];
        const authSecPrincipal = authSecPrincipalsMap[authSecPrincipalHref];

        return {
          href: permission.href,
          scope,
          scopeId,
          scopeHrefs,
          authSecPrincipal,
          authSecPrincipalHref,
          roleString,
          roleHref,
          role,
        };
      }),
);

/*
 *  This selector returns an object which maps scope id to permissionsExpanded results
 */
export const getExpandedPermissionsScopeIdMap = createSelector(getPermissionsExpanded, permissions => {
  const result = permissions.reduce((res, permission) => {
    res[getScopeIdString(permission.scopeId)] = permission;

    return res;
  }, {});

  return result;
});

/*
 *  This selector returns an object which maps permissionHrefs to getPermissionsExpanded results
 */
export const getExpandedPermissionsMap = createSelector(getPermissionsExpanded, permissions =>
  permissions.reduce((res, permission) => {
    res[permission.href] = permission;

    return res;
  }, {}),
);

export const getDefaultReadOnlyPermission = createSelector(getPermissionsExpanded, permissions =>
  permissions.find(permission => permission.authSecPrincipal.name === null),
);

//  ------  PERMISSION FILTER SELECTORS  ------

/*
 * Returns an object w/
 * key: JSON.stringify of scopeHrefs
 * value: set of permissionHrefs for permissions which have the same scope as the key
 */
export const getScopeHrefsToPermissionHrefsMap = createSelector(getPermissionsExpanded, permissions =>
  permissions.reduce((res, permission) => {
    if (res.hasOwnProperty(permission.scopeId)) {
      res[permission.scopeId].add(permission.href);
    } else {
      res[permission.scopeId] = new Set([permission.href]);
    }

    return res;
  }, {}),
);

/*
 * Retrieve the User's specific permission labels from the scope
 */
export const getPermissionsScopeLabels = createSelector(getPermissionsExpanded, permissions =>
  permissions.reduce((res, permission) => {
    const data = permission.scope.reduce((label, item) => {
      const labelObject = getLabelObject(item);

      label[labelObject.href] = labelObject;

      return label;
    }, {});

    Object.assign(res, data);

    return res;
  }, {}),
);

/*
 * Returns an object w/
 * key: authSecPrincipalHref
 * value: set of permissionHrefs for permissions which have the same authSecPrincipal as the key
 */
export const getAuthSecPrincipalHrefToPermissionHrefsMap = createSelector(getPermissionsExpanded, permissions =>
  permissions.reduce((res, permission) => {
    if (res.hasOwnProperty(permission.authSecPrincipalHref)) {
      res[permission.authSecPrincipalHref].add(permission.href);
    } else {
      res[permission.authSecPrincipalHref] = new Set([permission.href]);
    }

    return res;
  }, {}),
);
