/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {init} from '../app';
import routes from './routes';
import {reducers} from '../reducer';
import * as commonContainers from '../containers';
import * as containers from './containers';
import TemplatesWizardReducers from 'antman/containers/TemplatesWizard/TemplatesWizardState';
import ExplorerReducer from 'containers/IlluminationMap/MapState';
import RuleImpactReducers from 'antman/containers/TemplatesWizard/RuleImpact/RuleImpactState';
import RuleInspectorReducers from 'antman/containers/RuleInspector/RuleInspectorState';
import EndpointReducers from 'antman/containers/Endpoints/EndpointsState';
import XpressDashboardReducers from 'antman/containers/Dashboard/XpressDashboardState';
import ServerLabelingReducers from 'antman/containers/Servers/ServerLabeling/ServerLabelingState';
import ServersReducers from 'antman/containers/Servers/ServersState';

// Update global reducers with main Antman reducers
const antmanReducers = {
  ...TemplatesWizardReducers,
  ...RuleImpactReducers,
  ...ExplorerReducer,
  ...RuleInspectorReducers,
  ...XpressDashboardReducers,
  ...ServerLabelingReducers,
  ...EndpointReducers,
  ...ServersReducers,
};

init({routes, containers: {...commonContainers, ...containers}, reducers: {...reducers, ...antmanReducers}});
