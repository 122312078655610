/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {hrefUtils} from 'utils';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {all, call, select, put, retry} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getLabelGroups, getLabelGroupsCount, getGridSettings, getFilterMap} from './LabelGroupListState';
import gridSaga from 'components/Grid/GridSaga';
import {isEdge} from 'containers/App/AppState';
import {RedirectError, RequestError} from 'errors';
import {getId} from 'utils/href';

function* fetchLabelGroups({filter, pversion = 'draft', force = false} = {}) {
  const query = {max_results: 500, usage: true, includeDeleted: false, representation: 'simple'};

  if (filter) {
    Object.entries(filter).forEach(([name, [value]]) => {
      query[name] = value.value ?? value;
    });
  }

  return yield call(apiSaga, 'label_groups.get_collection', {
    query,
    params: {pversion},
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getLabelGroups)) || count !== (yield select(getLabelGroupsCount))) {
        yield put({type: 'LABEL_GROUPS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchLabelGroupList(route, refetch = false) {
  const edgeEnabled = yield select(isEdge);

  if (edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchLabelGroups, {filter: filterParams.isEmpty ? undefined : filterParams.valid, force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list.length;
    },
  });
}

export function* removeLabelGroup({href, pversion = 'draft'}) {
  yield call(apiSaga, 'label_group.delete', {
    params: {pversion, label_group_id: hrefUtils.getId(href)},
    hrefs: [href],
    *onDone() {
      yield put({type: 'LABEL_GROUPS_REMOVE', data: href});
      apiCachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

export function* fetchLabelGroupsMatches({query, params, retries = 2}) {
  let errorMessage;

  try {
    // Call to get the matching query name
    const matches = yield retry(retries, 0, apiSaga, 'label_groups.autocomplete', {params, query});

    return matches;
  } catch (error) {
    errorMessage = error;
  }

  // Throw error message when request fails
  throw new RequestError({
    message: errorMessage,
  });
}

export function* fetchAllLabelGroupLabels({href, pversion = 'draft'} = {}) {
  yield call(apiSaga, 'label_group.all_labels', {
    params: {label_group_id: getId(href), pversion},
    cache: false,
    *onDone({data}) {
      yield put({type: 'SET_ALL_LABEL_GROUP_LABELS', data: {href, allLabels: data}});
    },
  });
}
