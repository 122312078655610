/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {all, call, select, put, retry, spawn} from 'redux-saga/effects';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {RedirectError, RequestError} from 'errors';
import {isEdge} from 'containers/App/AppState';
import {getRulesetDetail, getRulesetVersions, getPCEUpgradeRuleset} from './RulesetItemState';
import {fetchSelectiveServices} from 'containers/Service/List/ServiceListSaga';
import {fetchPendingObjectList} from 'containers/Provisioning/Pending/List/PendingListSaga';
import {hrefUtils} from 'utils';
import {isUserWithReducedScope} from 'containers/User/UserState';

export function* fetchPCEUpgradeRuleset({force = false, pversion = 'draft'} = {}) {
  const userIsWithReducedScope = yield select(isUserWithReducedScope);
  const edgeEnabled = yield select(isEdge);

  // Users with limited scoped read should not access this data, but no need to redirect
  if (__MSP__ || edgeEnabled || userIsWithReducedScope) {
    return;
  }

  const query = {
    external_data_set: 'com.illumio.ilo_defaults',
    external_data_reference: 'ilo_non_domain_policies',
    name: intl('Common.PCEUpgradeRulesetName'),
  };

  try {
    const rulesets = yield call(apiSaga, 'rule_sets.get_collection', {
      query,
      params: {pversion},
      cache: !force,
      *onDone({data: list}) {
        const pceUpgradeRuleset = list[0] ?? {};

        if (force || pceUpgradeRuleset !== (yield select(getPCEUpgradeRuleset))) {
          yield put({type: 'RULESET_GET_PCE_UPGRADE_INSTANCE', data: pceUpgradeRuleset});
        }
      },
    });

    if (!rulesets) {
      throw new Error('Unable to fetch rulesets');
    }

    return rulesets;
  } catch (error) {
    console.warn(error.message);
  }
}

export function* fetchGroupIdAndRedirect(params) {
  const rulesetItem = yield call(fetchRulesetInstance, params);

  if (rulesetItem.hasOwnProperty('external_data_reference')) {
    throw new RedirectError({
      to: 'groups.view',
      params: {
        group: hrefUtils.getId(rulesetItem.external_data_reference),
        tab: 'inboundpolicy',
        pversion: params.pversion,
      },
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
}

export function* fetchRulesetItem({params}, refetch = false, noRedirect = false) {
  const edgeEnabled = yield select(isEdge);
  const {id, pversion, tab} = params;

  // In Edge Redirect to the corresponding group
  if (edgeEnabled) {
    return yield call(fetchGroupIdAndRedirect, {id, pversion, force: refetch, representation: 'rule_set_scopes'});
  }

  // antman doesn't use tabs
  const validTab = __ANTMAN__ || ['intrascope', 'extrascope', 'iptables'].includes(tab);
  const isOldVersion = Number.isInteger(Number(pversion)) && pversion > 0;
  const validPversion = pversion === 'active' || pversion === 'draft' || isOldVersion;

  if (!validPversion || !validTab) {
    throw new RedirectError({params: {id, pversion: 'draft', tab: 'intrascope'}, proceedFetching: true});
  }

  const [{data: draft}, {data: active}, {data: oldPversionObj}, {data: oldPrevPversionObj}] = yield all([
    fetchRulesetInstance({id, pversion: 'draft', force: refetch}),
    fetchRulesetInstance({id, pversion: 'active', force: refetch}),
    ...(isOldVersion ? [fetchRulesetInstance({id, pversion, force: refetch})] : [{}]),
    ...(isOldVersion && pversion > 1 ? [fetchRulesetInstance({id, pversion: pversion - 1, force: refetch})] : [{}]),
  ]);

  // If both draft and active are not valid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;

  if (!validItem || (isOldVersion && !oldPversionObj && !oldPrevPversionObj)) {
    throw new RedirectError({to: 'rulesets.list', proceedFetching: true, thisFetchIsDone: true});
  }

  if (pversion === 'active' && !draft.update_type) {
    throw new RedirectError({
      params: {id, pversion: 'draft', tab: 'intrascope'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  const data = {draft, active, oldPversionObj, oldPrevPversionObj};

  if (refetch || data !== (yield select(getRulesetDetail))) {
    yield put({type: 'RULESET_GET_ITEM', data});
  }

  const {
    created_by: createdBy,
    updated_by: updatedBy,
    scopes,
  } = (yield select(getRulesetVersions)).versions?.pversionObj ?? {};

  const shouldRedirectToIntrascopeTab = !noRedirect && !__ANTMAN__ && scopes?.[0]?.length === 0 && tab === 'extrascope';

  if (shouldRedirectToIntrascopeTab) {
    throw new RedirectError({
      params: {id, pversion, tab: 'intrascope'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  yield call(fetchSelectiveUsers, [createdBy, updatedBy]);

  return data;
}

export function* fetchRulesetInstance(params) {
  const {id, force = false, pversion = 'draft', representation = 'rule_set_services_labels_and_names'} = params;

  return yield call(apiSaga, 'rule_sets.get_instance', {
    params: {pversion, rule_set_id: id},
    query: {representation},
    ignoreCodes: [404],
    cache: !force,
  });
}

export function* updateRuleset({params, data}) {
  yield call(apiSaga, 'rule_set.update', {
    params,
    data,
    *onDone() {
      // Invalidate list
      apiCachedResponses.removeByMethodName('rule_sets.get_collection');
      // Invalidate labels list to update the "in use by" column
      apiCachedResponses.removeByMethodName('labels.get_collection');
      apiCachedResponses.removeByMethodName('label_groups.get_collection');
    },
  });
}

export function* createRuleset({params, data}) {
  return yield call(apiSaga, 'rule_sets.create', {
    params,
    data,
    *onDone() {
      yield spawn(fetchPendingObjectList, {force: true});
      // Invalidate instance
      apiCachedResponses.removeByMethodName('rule_sets.get_instance');
      // Invalidate list
      apiCachedResponses.removeByMethodName('rule_sets.get_collection');
      // Invalidate labels list to update the "in use by" column
      apiCachedResponses.removeByMethodName('labels.get_collection');
      apiCachedResponses.removeByMethodName('label_groups.get_collection');
    },
  });
}

export function* removeRuleset({id, pversion = 'draft'}) {
  return yield call(apiSaga, 'rule_set.delete', {
    params: {rule_set_id: id, pversion},
    *onDone() {
      // Invalidate list
      apiCachedResponses.removeByMethodName('rule_sets.get_collection');
      // refetch instance
      yield call(fetchRulesetItem.refetch);
      // Invalidate labels list to update the "in use by" column
      apiCachedResponses.removeByMethodName('labels.get_collection');
      apiCachedResponses.removeByMethodName('label_groups.get_collection');
    },
  });
}

export function* removeRules({id, pversion = 'draft', data}) {
  return yield call(apiSaga, 'sec_rules.delete', {
    params: {rule_set_id: id, pversion},
    data,
  });
}

export function* fetchConsumerPolicyServices({query}) {
  const {data} = yield call(apiSaga, 'services.autocomplete', {
    query: {...query, max_results: 25},
    params: {pversion: 'draft'},
  });

  return yield call(fetchSelectiveServices, data.matches, {filterEgress: true});
}

export function* fetchPolicyServices({query}) {
  const {data} = yield call(apiSaga, 'services.autocomplete', {
    query: {...query, max_results: 25},
    params: {pversion: 'draft'},
  });

  return yield call(fetchSelectiveServices, data.matches);
}

export function* createRule({pversion = 'draft', rule_set_id, data, isIpTableRule}) {
  yield call(apiSaga, isIpTableRule ? 'ip_tables_rules.create' : 'sec_rules.create', {
    params: {
      pversion,
      rule_set_id,
    },
    data,
  });
}

// Deny rules sagas for CoreX
export function* createDenyRule({pversion = 'draft', rule_set_id, data}) {
  yield call(apiSaga, 'deny_rules.create', {
    params: {
      pversion,
      rule_set_id,
    },
    data,
  });
}

export function* updateRule({href: ruleHref, rule_set_id, data, isIpTableRule}) {
  yield call(apiSaga, isIpTableRule ? 'ip_tables_rule.update' : 'sec_rule.update', {
    params: {
      pversion: 'draft',
      rule_set_id,
      [isIpTableRule ? 'ip_tables_rule_id' : 'sec_rule_id']: hrefUtils.getId(ruleHref),
    },
    data,
  });
}

// Deny rules sagas for CoreX
export function* updateDenyRule({href: ruleHref, rule_set_id, data}) {
  yield call(apiSaga, 'deny_rule.update', {
    params: {
      pversion: 'draft',
      rule_set_id,
      deny_rule_id: hrefUtils.getId(ruleHref),
    },
    data,
  });
}

export function* updateRules({pversion = 'draft', rule_set_id, data}) {
  return yield call(apiSaga, 'rule_set.update', {
    params: {
      rule_set_id,
      pversion,
    },
    data,
  });
}

export function* fetchRulesetFacet({query, params, retries = 2}) {
  let errorMessage;

  try {
    // Call facets to get the matching query name
    const facets = yield retry(retries, 0, apiSaga, 'rule_sets.facets', {params, query});

    return facets;
  } catch (error) {
    errorMessage = error;
  }

  // Throw error message when request fails
  throw new RequestError({
    message: errorMessage,
  });
}
