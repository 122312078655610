/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {useEffect, useReducer} from 'react';
import type {ReactStrictNode} from 'utils/types';

export interface RerendererProps {
  /**
   * Rerender component "at least" every specified milliseconds (Default is 5000)
   * It might be rendered more often by parent and on each render timeout will restart (debounce)
   */
  every?: number;
  children: (() => ReactStrictNode) | ReactStrictNode;
}

export default function Rerenderer({every = 5000, children}: RerendererProps): JSX.Element {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const timeout = setTimeout(forceUpdate, every);

    return () => clearTimeout(timeout);
  });

  // In the current React type definition, function components can only have `JSX.Element` or `null` as
  // the return type, but React > v16 supports returning string, number and boolean.
  // The root cause of this issues stem from https://github.com/microsoft/TypeScript/issues/21699
  //
  // The current workaround is to double assert the return type
  // TODO: change this once the mentioned issue is fixed.
  return (typeof children === 'function' ? children() : children) as JSX.Element;
}
