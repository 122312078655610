/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

//  For View Option in Filter Bar Edit Mode: "Providers AND Consumers" and "Providers OR Consumers"
export const ViewOptions = [
  {
    id: 'consumerOrProvider',
    OrViewId: true,
    title: intl('IlluminationMap.ConsumerAndOrProvider', {or: true, not: false}),
  },
  {
    id: 'consumerAndProvider',
    OrViewId: false,
    title: intl('IlluminationMap.ConsumerAndOrProvider', {or: false, not: false}),
  },
];

// For showing/hiding filter fields ("Exclusion Filters" and "Reported Policy Decision")
export const FilterVisibilityOptions = [
  {id: 'showExclusionFilters', title: intl('IlluminationMap.ShowExclusionFilters')},
  {id: 'showReportedPolicyDecisionFilter', title: intl('IlluminationMap.ShowReportedPolicyDecisionFilter')},
];
