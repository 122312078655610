/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {createElement, cloneElement, Fragment, type ReactElement} from 'react';
import Pill, {type PillProps} from './Pill';
import {getTid} from 'utils/tid';
import stylesUtils from 'utils.css';
import type {TruthFull} from 'utils/types';
import {ExpandableLabelList, ExpandableList, type ExpandableListProps} from 'components/index';
import _ from 'lodash';

const defaultTid = 'comp-pilldiff';
const sanitizeInput = <T,>(inputArr: T[] = []) => inputArr.filter(Boolean) as TruthFull<T>[];

type Value = {key: string; pill: ReactElement<PillProps, typeof Pill>};
type ModifiedValue = Value & {[key in 'deleted' | 'created']?: boolean} & {tid: string};

export type PillDiffProps = {
  tid?: string;
  noDiff?: boolean;
  labelsOnly?: boolean;
  notExpandable?: boolean;

  expandableProps?: Omit<ExpandableListProps<unknown>, 'value' | 'children'> | null;
  value?: (Value | undefined | null)[];

  oldValue?: (Value | undefined | null)[];

  // By default diff is shown in one line. Set to true to show in separate lines
  vertical?: boolean;
  // set true if no need wrap in gap
  noWrap?: boolean;
} & PillProps;

export default function PillDiff(props: PillDiffProps): JSX.Element | null {
  const {
    noDiff = false,
    vertical = false,
    noWrap = false,
    tid,
    value: inputValue,
    labelsOnly = false,
    notExpandable = false,
    expandableProps,
    oldValue: inputOldValue,
    ...pillProps
  } = props;

  const value = sanitizeInput(inputValue);
  const oldValue = sanitizeInput(inputOldValue);

  if (value.length === 0 && oldValue.length === 0) {
    return null;
  }

  const className = cx(stylesUtils.gapXSmall, {
    [stylesUtils.gapHorizontalWrap]: !vertical,
    [stylesUtils.gapAlignStart]: vertical,
  });

  if (noDiff) {
    const unchangedTid = getTid(`${defaultTid}-unchanged`, tid);

    if (notExpandable) {
      return createElement(
        noWrap ? Fragment : 'div',
        noWrap ? {'data-tid': unchangedTid} : {className, 'data-tid': unchangedTid},
        ...value.map(({key, pill}) => cloneElement(pill, {key, tid: 'unchanged', ...pillProps})),
      );
    }

    const expandableListChildren = ({key, pill}: Value) => cloneElement(pill, {key, tid: 'unchanged', ...pillProps});

    if (labelsOnly) {
      return (
        <ExpandableLabelList {...expandableProps} values={value} typeAccessor="pill.props.type">
          {expandableListChildren}
        </ExpandableLabelList>
      );
    }

    // override initialCount as this is used in detail pages
    return (
      <ExpandableList initialCount={20} {...expandableProps} values={value}>
        {expandableListChildren}
      </ExpandableList>
    );
  }

  const pillsMap = value.reduce((map, item) => map.set(item.key, item), new Map<string, Value>());

  const {unchanged, removed} = oldValue.reduce(
    (acc, pill) => {
      const valuePill = pillsMap.get(pill.key);

      if (valuePill) {
        pillsMap.delete(pill.key);
        acc.unchanged.push({...valuePill, tid: 'unchanged'});
      } else {
        acc.removed.push({...pill, tid: 'removed', deleted: true});
      }

      return acc;
    },
    {unchanged: [], removed: []} as {unchanged: ModifiedValue[]; removed: ModifiedValue[]},
  );

  const added: ModifiedValue[] = Array.from(pillsMap.values()).map(value => ({...value, tid: 'added', created: true}));

  const content = () => {
    if (notExpandable) {
      return (
        <>
          {added.length > 0 &&
            added.map(({key, pill, tid, created}) => cloneElement(pill, {key, created, tid, ...pillProps}))}
          {unchanged.length > 0 && unchanged.map(({key, pill, tid}) => cloneElement(pill, {key, tid, ...pillProps}))}
          {removed.length > 0 &&
            removed.map(({key, pill, tid, deleted}) => cloneElement(pill, {key, deleted, tid, ...pillProps}))}
        </>
      );
    }

    // maintain added/unchanged/removed order
    const values: ModifiedValue[][] = [added, unchanged, removed];
    const valuesFlat: ModifiedValue[] = [...added, ...unchanged, ...removed];

    const expandableListChildren = ({key, pill, tid, deleted, created}: ModifiedValue) =>
      cloneElement(pill, {
        key,
        tid,
        created,
        deleted,
        ...pillProps,
      });

    if (labelsOnly) {
      return (
        <ExpandableLabelList {...expandableProps} values={values} typeAccessor="pill.props.type">
          {expandableListChildren}
        </ExpandableLabelList>
      );
    }

    // override initialCount as this is used in detail pages
    return (
      <ExpandableList initialCount={20} {...expandableProps} values={valuesFlat}>
        {expandableListChildren}
      </ExpandableList>
    );
  };

  if (noWrap) {
    return content();
  }

  return (
    <div className={className} data-tid={getTid(defaultTid, tid)}>
      {content()}
    </div>
  );
}
