/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {populateFacetCategory, populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';

export const getCategories = createSelector(
  [getDisplayNames, getTypeInitialRegExp],
  (labelTypesNameObj, labelTypeInitialRegExp) => [
    populateFacetCategory({
      id: 'auth_security_principal_name',
      name: intl('RBAC.Principals'),
      resourceType: 'org_permissions',
      query: {
        facet: 'auth_security_principal_name',
        scoped_roles_only: true,
      },
      noPartial: true,
    }),
    {
      id: 'roles',
      name: intl('Common.Roles'),
      resources: {
        roles: {
          statics: [
            {
              id: 'ruleset_manager',
              value: intl('Role.RulesetManager'),
            },
            {
              id: 'limited_ruleset_manager',
              value: intl('Role.LimitedRulesetManager'),
            },
            {
              id: 'ruleset_provisioner',
              value: intl('Role.RulesetProvisioner'),
            },
            {
              id: 'ruleset_viewer',
              value: intl('Role.RulesetViewer'),
            },
            {
              id: 'workload_manager',
              value: intl('Role.WorkloadManager'),
            },
          ],
        },
      },
    },
    ...populateLabelsCategories({
      resourceType: 'user_scopes',
      includeSelectedResources: ['labelsAndLabelGroups'],
      hasLabelGroups: true,
      hasAll: true,
      hasNoLabels: true,
      labelTypesNameObj,
      labelTypeInitialRegExp,
      params: {pversion: 'active'},
    }),
  ],
);

export const gridSettings = createSelector(
  [state => getLabelsColumn(state, {hasAll: true, initialCount: 20})],
  labelsColumn => ({
    id: 'scopelist',
    capacities: [25, 50, 100, 250, 500],
    capacity: 50,
    maxPage: Number.MAX_SAFE_INTEGER,
    sort: 'scope-all', // Make 'All' scope show first by default
    showColumns: true,
    showCapacity: true,
    showPagination: true,
    columns: {
      checkboxes: {},
      scope: {...labelsColumn, header: intl('Common.Scopes')},
      roles: {
        header: intl('Common.Roles'),
        value: 'num_roles',
      },
      principals: {
        header: intl('RBAC.Principals'),
        value: 'num_auth_security_principals',
      },
    },
    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['scope'], size: 'minmax(135px, auto)'},
        {columns: ['roles'], size: 'minmax(135px, auto)'},
        {columns: ['principals'], size: 'minmax(135px, auto)'},
      ],
      {
        maxWidth: 640,
        template: [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['scope'], size: 'minmax(135px, auto)'},
          {columns: ['roles'], size: 'minmax(70px, auto)'},
          {columns: ['principals'], size: 'minmax(70px, auto)'},
        ],
      },
    ],
  }),
);
