/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {HeaderProps} from 'containers';
import {AttributeList, Badge, Button, Modal, ToolBar, ToolGroup, TypedMessages, Form, Link, Tooltip} from 'components';
import {object, string} from 'yup';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import LabelReducers from '../LabelState';
import {getLabelEdit} from './LabelEditState';
import {fetchLabelMatches} from '../List/LabelListSaga';
import {fetchLabelItem, updateLabel} from '../LabelSaga';
import {createLabels, verifyUserPermissions} from './LabelEditSaga';
import {reactUtils, hrefUtils} from 'utils';
import {setExistingName, isExcludeName, getDuplicateObject} from 'containers/FormComponents/duplicateValidation';
import {getLabelSelectorOptions} from '../LabelSettings/LabelSettingState';
import {formatDataReference, isEntityEdited} from 'utils/dataValidation';

// Get formik's mandatory initialValues props for form setup
const getInitialValues = (label = {}, typeOptions = []) => ({
  // A required input value use empty string
  name: label.value || '',
  // required input, must be one of 'role', 'app', 'env', 'loc'
  type: typeOptions.find(option => option.value === label.key) || null,
});

// Initial State
const getInitialState = (props, typeOptions) => ({
  initialValues: getInitialValues(props.label, typeOptions),
  isEdit: props.routeName === 'app.labels.item.edit',
  cancel: false,
  saving: false,
  error: null,
});

// when container is controlled we will pass the data via containerProps, as opposed to connecting to the store
const makeMapState = (state, props) =>
  props.controlled ? {labelSelectorOptions: getLabelSelectorOptions(state)} : getLabelEdit(state);

@connect(makeMapState, null, null, {forwardRef: true})
export default class LabelEdit extends Component {
  static prefetch = verifyUserPermissions;
  static contextType = AppContext;
  static reducers = LabelReducers;

  static propTypes = {
    buttonAlign: PropTypes.oneOf(['top', 'bottom']),
    excludeKeys: PropTypes.array,
    onDone: PropTypes.func,
    onCancel: PropTypes.func,
    excludeNames: PropTypes.array, // checking for exclude names in isExcludeName fn
    excludeNameMessage: PropTypes.string, // error message in case user hits one of the excluded names
  };

  constructor(props) {
    super(props);

    // Use to determine if a label name already exist setting this doesn't require re-render
    this.existingNameError = false;

    // Yup validation
    this.schemas = object({
      name: string()
        .max(255, intl('Common.NameIsTooLong'))
        .test(
          'is-duplicate',
          () => {
            if (this.duplicateObject) {
              return intl(
                'Common.NameExist',
                {
                  value: (
                    <Link to="labels.item" params={{id: hrefUtils.getId(this.duplicateObject.href)}}>
                      {this.duplicateObject.value}
                    </Link>
                  ),
                },
                {jsx: true},
              );
            }

            return this.props.excludeNameMessage ?? intl('Common.NameIsNotAllowed');
          },
          () =>
            // During an onBlur (navigating away), need to preserve the error message when there is a name clash
            !this.existingNameError,
        )
        .required(intl('Common.AddValidName')),
      type: object().nullable().required(intl('Labels.Create.Placeholder.LabelType')),
    });

    this.handleSubmit = _.noop;
    this.renderForm = this.renderForm.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderEditAlert = this.renderEditAlert.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.validateAndUpdateName = _.debounce(this.validateAndUpdateName.bind(this), 500);

    this.typeOptions = props.labelSelectorOptions.filter(type => !props.excludeKeys?.includes(type.value));
    this.state = getInitialState(props, this.typeOptions);
    this.duplicateObject = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.initialValues.name !== nextProps.label.value && prevState.initialValues.key !== nextProps.label.key) {
      return getInitialState(
        nextProps,
        nextProps.labelSelectorOptions.filter(type => !nextProps.excludeKeys?.includes(type.value)),
      );
    }

    return null;
  }

  componentDidMount() {
    const {label, controlled} = this.props;

    if (controlled && !_.isEmpty(label)) {
      // We need to call formik setValue to set field values with prop data so that form dirty is set true
      // Passing it as initial value in <Form/> will not set dirty flag
      const initialValues = getInitialValues(label, this.typeOptions);

      this.formik.setValues(initialValues);

      this.validateAndUpdateName();
    }
  }

  // Parse the proper payload to send to the API when creating label.
  // This method is only called when no errors and all the required values are met for formik
  async handleOnSave(evt) {
    const {values, setSubmitting} = this.formik;
    const {isEdit} = this.state;
    const {label, onDone} = this.props;
    const {fetcher, navigate} = this.context;
    const payload = {
      value: values.name.trim(),
    };

    // Only pass in key properties as a payload during create mode per API
    if (values.type && !isEdit) {
      payload.key = values.type.value;
    }

    try {
      let id;

      await reactUtils.setStateAsync({saving: true}, this);
      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      let modifiedLabel;

      if (isEdit) {
        id = this.props.currentRouteParams?.id;

        if (label.external_data_set) {
          payload.external_data_set = label.external_data_set;
        }

        if (label.external_data_reference) {
          payload.external_data_reference = formatDataReference(label.external_data_reference);
        }

        await fetcher.spawn(updateLabel, id, payload);
      } else {
        const {data} = await fetcher.spawn(createLabels, payload);

        modifiedLabel = data;

        id = hrefUtils.getId(data.href);
      }

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));

      if (typeof onDone === 'function') {
        onDone(evt, modifiedLabel);
      } else {
        await fetcher.fork(fetchLabelItem, {params: {id}}, true);
        // Navigate to a view page
        navigate({to: 'labels.item', params: {id}});
      }
    } catch (error) {
      this.setState({error, saving: false});
      // Call formik method to set isSubmitting to false
      setSubmitting(false);
    }
  }

  // Handle the input field
  async handleNameChange(evt) {
    const {setFieldValue, validateForm} = this.formik;
    const value = evt.target.value;

    // Update the Form.Input name value since self component is controlling
    setFieldValue('name', value);

    // Reset the existingNameError here to prevent seeing the deleted character delay
    if (this.existingNameError) {
      this.existingNameError = false;
      // Call to validateForm formik's schema
      validateForm();
    }

    // Don't need to call debounce when value is empty
    if (value.trim()) {
      // Note: Invoke debounce here to delay after calling setFieldValue for formik's values to update properly
      this.validateAndUpdateName();
    }
  }

  handleCancel(evt) {
    const {
      props: {onCancel, currentRouteParams: {id} = {}},
      state: {isEdit},
      context: {navigate},
    } = this;

    if (typeof onCancel === 'function') {
      return onCancel();
    }

    navigate({
      evt,
      ...(isEdit ? {to: 'labels.item', params: {id}} : {to: 'labels.list'}),
    });
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Validating name if it's already existing
  async validateAndUpdateName() {
    const {
      context: {fetcher},
      props: {excludeNames},
      formik: {values},
    } = this;

    this.duplicateObject = null;

    if (isExcludeName(excludeNames, this.formik.values.name, this.state.initialValues.name)) {
      this.existingNameError = true;
      setExistingName(this.formik);

      return;
    }

    // Note: Important to trim value to pass to facet API
    const value = values.name.trim();

    // Don't need to make request when the original label name match current in addition make only
    // request when there is a value.
    if (value && this.formik.initialValues.name !== value) {
      if (this.fetchMatches) {
        // Cancel task if it is still running
        fetcher.cancel(this.fetchMatches);
      }

      this.fetchMatches = fetcher.fork(fetchLabelMatches, {
        query: {facet: 'key', query: value, max_results: 1},
      });

      try {
        const {data} = await this.fetchMatches;

        // returns duplicated object of Input request with a link (href) to render in errorMessage of Input
        this.duplicateObject = getDuplicateObject(data?.matches, this.formik.values.name, 'value');

        if (this.duplicateObject) {
          this.existingNameError = true;
          setExistingName(this.formik);

          return;
        }
      } catch (error) {
        await reactUtils.setStateAsync({facet: {error}}, this);
      }
    }
  }

  // Render alert message when edit or create fails
  renderEditAlert() {
    const {error, isEdit} = this.state;
    const token = _.get(error, 'data[0].token');
    const title = isEdit ? intl('Labels.Errors.Edit') : intl('Labels.Errors.Create');
    const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {isValid} = options;

    this.formik = options;

    const {saving, onSaveDone, error, isEdit, initialValues} = this.state;
    const {
      label,
      currentRouteParams: {id} = {},
      buttonAlign = 'top',
      typeIsDisabled,
      saveButtonProps,
      cancelButtonProps,
      controlled,
      isXpressOnboardingObject,
    } = this.props;

    const buttons = (
      <ToolBar>
        <ToolGroup>
          <Button
            icon="save"
            tid="save"
            disabled={isValid === false}
            text={intl('Common.Save')}
            progressCompleteWithCheckmark
            progress={saving}
            progressError={Boolean(error)}
            onClick={this.handleOnSave}
            onProgressDone={onSaveDone}
            {...saveButtonProps}
          />
          <Button
            color="standard"
            icon="cancel"
            tid="cancel"
            text={intl('Common.Cancel')}
            onClick={this.handleCancel}
            {...cancelButtonProps}
          />
        </ToolGroup>
      </ToolBar>
    );

    // Cannot remove or edit critical objects created in Xpress Onboarding wizard
    const nameInput = (
      <Form.Input
        tid="name"
        onChange={this.handleNameChange}
        placeholder={intl('Labels.Create.Placeholder.LabelName')}
        name="name"
        disabled={isXpressOnboardingObject}
      />
    );

    const attributes = [
      buttonAlign === 'top' ? {divider: true} : null,
      {title: intl('Common.General')},
      {
        tid: 'name',
        key: <Form.Label name="name" title={intl('Common.Name')} />,
        value: isXpressOnboardingObject ? (
          <Tooltip content={intl('Antman.Common.WarningEdit', {item: intl('Common.Label')})} instant>
            {nameInput}
          </Tooltip>
        ) : (
          nameInput
        ),
      },
      {
        tid: 'type',
        key: <Form.Label name="type" title={intl('Common.Type')} />,
        value: (
          <Form.Selector
            name="type"
            disabled={isEdit || typeIsDisabled}
            placeholder={intl('Labels.Create.Placeholder.LabelType')}
            options={this.typeOptions}
          />
        ),
      },
      buttonAlign === 'bottom' ? {value: buttons} : null,
    ];

    if (label && label.external_data_set) {
      attributes.push({
        tid: 'external_data_set',
        key: <Form.Label name="external_data_set" title={intl('Common.ExternalSet')} />,
        value: label.external_data_set,
      });
    }

    if (label && label.external_data_reference) {
      attributes.push({
        tid: 'external_data_reference',
        key: <Form.Label name="external_data_reference" title={intl('Common.ExternalReference')} />,
        valueGap: 'gapMedium gapHorizontal gapAlignStart',
        value: isEntityEdited(label.external_data_reference) ? (
          <>
            <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
              {intl('Common.Edited')}
            </Badge>
            <span>{label.external_data_reference}</span>
          </>
        ) : (
          label.external_data_reference
        ),
      });
    }

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={intl('Common.Labels')}
            subtitle={initialValues.name}
            label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
            up={isEdit ? {to: 'labels.item', params: {id}} : 'labels.list'}
          />
        )}
        {buttonAlign === 'top' && buttons}
        <AttributeList valuesGap="gapLarge">{attributes}</AttributeList>
        {error && this.renderEditAlert()}
      </>
    );
  }

  render() {
    const {
      props: {controlled, formProps},
      state: {initialValues},
    } = this;

    return (
      <Form
        enableReinitialize
        schemas={this.schemas}
        initialValues={controlled ? getInitialValues() : initialValues}
        onSubmit={this.handleSubmit}
        {...formProps}
      >
        {this.renderForm}
      </Form>
    );
  }
}
