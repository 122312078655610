/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getAllUsersMap, isSuperclusterMember} from 'containers/User/UserState';
import {getGridSelector, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './PendingListConfig';
import {
  formatCountsForTally,
  getProvisionMenuCounts,
  getProvisionCountsTotal,
  getTypeAndRoute,
  calculatedItemTypeStaticValues,
  aggregateOutboundPolicy,
  outboundOrgPolicyObjType,
} from 'containers/Provisioning/ProvisioningUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isKubernetesSupported, isEdge} from 'containers/App/AppState';
import {getId} from 'utils/href';

export default {
  // List of objects waiting to be provisioned by user
  pending(state = {}, {type, data}) {
    switch (type) {
      case 'PROVISION_GET_PENDING':
        return data ?? state;
      default:
        return state;
    }
  },
  pendingCount(state = 0, {type, count}) {
    switch (type) {
      case 'PROVISION_GET_PENDING':
        return count ?? state;
      default:
        return state;
    }
  },
  pendingOutboundAllowRuleset(state = null, action) {
    switch (action.type) {
      case 'OUTBOUND_POLICY_ALLOWRULESET':
        return action.data;
      default:
        return state;
    }
  },
};

export const getPending = state => state.provisioning.pending;
export const getPendingCount = state => state.provisioning.pendingCount;
export const getOutboundAllowRulesetId = state => getId(state.provisioning.pendingOutboundAllowRuleset?.href);
export const getOutboundAllowRulesetHref = state => state.provisioning.pendingOutboundAllowRuleset?.href;

export const getCounts = createSelector(
  [getPending, getOutboundAllowRulesetId, isEdge],
  (items, outboundAllowRulesetId, edgeEnabled) => {
    if (edgeEnabled) {
      items = _.pick(items, ['rule_sets', 'ip_lists', 'services', 'essential_service_rules', 'enforcement_boundaries']);
    }

    return getProvisionMenuCounts(items, outboundAllowRulesetId);
  },
);

export const getTotalCount = createSelector([getCounts, isEdge], (counts, edgeEnabled) => {
  if (edgeEnabled) {
    counts = _.pick(counts, ['rule_sets', 'ip_lists', 'services', 'essential_service_rules', outboundOrgPolicyObjType]);
  } else if (__ANTMAN__) {
    counts = _.omit(counts, ['virtual_servers', ...(isKubernetesSupported ? [] : ['virtual_services'])]);
  }

  return getProvisionCountsTotal(counts);
});

export const getGridSettings = createSelector(
  [isSuperclusterMember, gridSettings],
  (superclusterMember, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = superclusterMember;

    return {...gridSettings, columns};
  },
);

const getFilters = state =>
  getUrlFilterParam(state, {settings: getGridSettings, filterMap: getSelectorSettings().filterMap});

export const getOutboundPolicyRow = createSelector(
  [getPending, getAllUsersMap, getOutboundAllowRulesetHref],
  (pending, usersMap, outboundAllowRulesetHref) =>
    aggregateOutboundPolicy({
      ruleSets: pending.rule_sets,
      outboundAllowRulesetHref,
      enforcementBoundaries: pending.enforcement_boundaries,
      usersMap,
    }),
);

export const getSecPolicyRows = createSelector(
  [getPending, getAllUsersMap, isEdge, getOutboundAllowRulesetId, getOutboundPolicyRow],
  (pending, usersMap, isEdge, outboundAllowRulesetId, outboundPolicyRow) => {
    let secPolicyRows = [];

    if (isEdge) {
      if (outboundPolicyRow) {
        secPolicyRows.push(outboundPolicyRow);
      }

      pending = _.pick(pending, ['rule_sets', 'ip_lists', 'services', 'enforcement_boundaries']);
    }

    const pendingEntries = Object.entries(pending).reduce((result, [objType, items]) => {
      if (Array.isArray(items)) {
        for (const item of items) {
          if (objType === 'rule_sets' && getId(item.href) === outboundAllowRulesetId) {
            continue;
          }

          result.push({
            key: item.href,
            selectable: item.caps?.includes('provision'),
            data: {
              ...item,
              type: objType,
              ...getTypeAndRoute(objType),
              updated_by: fillUserInfo(usersMap, item.updated_by),
            },
          });
        }
      } else if (objType === 'firewall_settings') {
        result.push({
          key: items.href,
          selectable: items.caps?.includes('provision'),
          data: {
            ...items,
            type: objType,
            ...getTypeAndRoute(objType),
            updated_by: fillUserInfo(usersMap, items.updated_by),
          },
        });
      }

      return result;
    }, []);

    secPolicyRows = secPolicyRows.concat(pendingEntries);

    return secPolicyRows;
  },
);

export const getPendingProvisioningRows = createSelector(
  [getSecPolicyRows, getFilters, getSelectorSettings, isEdge],
  (rows, {valid: filter}, selectorSettingsObject, isEdge) => {
    let result = [];

    const filterEntry = Object.entries(filter).map(([categoryKey, [value]]) => ({
      categoryKey,
      value,
      categoryName: selectorSettingsObject.filterMap[categoryKey],
    }));

    if (filterEntry.length && filterEntry[0].categoryKey === 'type') {
      // currently we only support the type filter
      result = rows.filter(row => row.data.caps && row.data.typeLabel === filterEntry[0].value);
    } else if (isEdge) {
      // check for typeLabel to be sure that we don't have any rows with undefined in Edge
      // (for example enforcement boundary rows which shouldn't exist in grid).
      result = rows.filter(row => row.data.typeLabel && !row.key.includes('enforcement_boundaries') && row.data.caps);
    } else if (__ANTMAN__) {
      result = rows.filter(
        row =>
          (row.data.type !== 'virtual_servers' && row.data.caps) ||
          (isKubernetesSupported && row.data.type === 'virtual_services'),
      );
    } else {
      result = rows.filter(row => row.data.caps);
    }

    return result;
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getPendingProvisioningRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getPendingProvisioningPage = createSelector(
  [getGrid, getCounts, isEdge, getSelectorSettings],
  (grid, counts, edgeEnabled, selectorSettingsObject) => {
    if (edgeEnabled) {
      counts = _.pick(counts, [
        'rule_sets',
        'ip_lists',
        'services',
        'essential_service_rules',
        outboundOrgPolicyObjType,
      ]);
    } else if (__ANTMAN__) {
      counts = _.omit(counts, ['virtual_servers', ...(isKubernetesSupported ? [] : ['virtual_services'])]);
    }

    const tallyItems = formatCountsForTally(counts);
    const total = getProvisionCountsTotal(counts);

    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      statics: calculatedItemTypeStaticValues(counts),
    };

    return {grid, tallyItems, total, selector, edgeEnabled};
  },
);
