/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

export type ResultQueryStatusActions =
  | 'EXPLORER_TRAFFIC_QUERY_INIT' // about to create a new async query
  | 'EXPLORER_TRAFFIC_QUERY_SUCCESS' // traffic query created successfully
  | 'EXPLORER_TRAFFIC_QUERY_ERROR' // error while creating traffic query
  | 'EXPLORER_TRAFFIC_QUERY_CLEANUP'; // deletes the state for a given uuid

export type ResultDownloadStatusActions =
  | 'EXPLORER_TRAFFIC_DOWNLOAD_INIT' // polling/download is about to start; initializes uuid's state;
  | 'EXPLORER_TRAFFIC_DOWNLOAD_LOADING' // polling/download has started
  | 'EXPLORER_TRAFFIC_DOWNLOAD_PROCESSING' // processing has begun after download
  | 'EXPLORER_TRAFFIC_DOWNLOAD_READY' // processing finished and graph has rendered
  | 'EXPLORER_TRAFFIC_DOWNLOAD_ERROR' // polling or download threw an error
  | 'EXPLORER_TRAFFIC_DOWNLOAD_CLEANUP'; // deletes the state for a given uuid

export type ResultStatusActions = ResultQueryStatusActions | ResultDownloadStatusActions;

export const RESULT_ACTIONS_STATUS = {
  EXPLORER_TRAFFIC_QUERY_INIT: 'query-init',
  EXPLORER_TRAFFIC_QUERY_SUCCESS: 'query-success',
  EXPLORER_TRAFFIC_QUERY_ERROR: 'query-error',
  EXPLORER_TRAFFIC_QUERY_CLEANUP: 'query-cleanup',

  EXPLORER_TRAFFIC_DOWNLOAD_INIT: 'download-init',
  EXPLORER_TRAFFIC_DOWNLOAD_LOADING: 'download-loading',
  EXPLORER_TRAFFIC_DOWNLOAD_PROCESSING: 'download-processing',
  EXPLORER_TRAFFIC_DOWNLOAD_READY: 'download-ready',
  EXPLORER_TRAFFIC_DOWNLOAD_ERROR: 'download-error',
  EXPLORER_TRAFFIC_DOWNLOAD_CLEANUP: 'download-cleanup',

  MAP_TIME_RANGE_CHANGE: 'processing',
  MAP_GRAPH_CHART_UPDATE_COMPLETE: 'ready',
};
