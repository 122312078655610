/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {RedirectError} from 'errors';
import {call, put, select, spawn} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {sagasUtils} from 'utils';
import {getClusters, getClustersMap, getInRollingUpgrade} from './HealthState';
import {isHealthMetricsEnabled} from './Detail/HealthDetailState';
import {isHealthEnabled} from 'containers/App/AppState';

let pollingTask = null;

export function* fetchHealthDetailPage({params, force = false} = {}) {
  const healthIsEnabled = yield select(isHealthEnabled); // Check to be sure user is not in SaaS

  if (!healthIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(fetchHealth, {force});

  const clustersMap = yield select(getClustersMap);

  // Prevent rendering invalid fqdns
  if (params?.fqdn !== 'local' && !clustersMap[params?.fqdn]) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  const healthMetricsEnabled = yield select(isHealthMetricsEnabled);

  if (!params?.group && healthMetricsEnabled) {
    throw new RedirectError({
      to: 'health.detail',
      params: {fqdn: params.fqdn, group: 'Node'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }
}

function parseDescriptions(descriptions) {
  const result = [];

  if (descriptions.length) {
    const descriptionsObject = descriptions[0];
    const {groups} = descriptionsObject;

    groups.forEach(group => {
      if (group.components) {
        group.components.forEach(component => {
          if (component.contents) {
            component.contents.forEach(metric => {
              if (component.section && metric.metric && metric.description) {
                result.push({title: `${component.section} - ${metric.metric}`, subtitle: metric.description});
              }
            });
          }
        });
      }
    });
  }

  return result;
}

export function* fetchHealthMetricDescriptions({name, force = false}) {
  yield call(apiSaga, 'health.get', {
    query: {reference: null},
    strictNullHandling: true,
    cache: !force,
    *afterFetch({data: descriptions}) {
      yield put({
        type: 'LOAD_CUSTOM_HELP_MENU_ITEMS',
        payload: {
          [name]: parseDescriptions(descriptions),
        },
      });
    },
  });
}

export function* fetchHealth({force = false} = {}) {
  if (yield select(isHealthEnabled)) {
    let needToRestartPolling = false;

    yield call(apiSaga, 'health.get', {
      cache: !force,
      *beforeFetch() {
        if (pollingTask && pollingTask.isRunning()) {
          // If there is a running polling, stop it to restart later after current fetch
          yield call(stopFetchHealthPolling);
          needToRestartPolling = true;
        }
      },
      *afterFetch({data: clusters}) {
        if (clusters !== (yield select(getClusters))) {
          yield put({
            type: 'HEALTH_GET_CLUSTERS',
            payload: {clusters, wasInRollingUpgrade: yield select(getInRollingUpgrade)},
          });
        }

        if (needToRestartPolling) {
          yield call(startFetchHealthPolling);
        }
      },
    });
  }
}

export function* startFetchHealthPolling({instantStart = false} = {}) {
  if ((yield select(isHealthEnabled)) && (!pollingTask || !pollingTask.isRunning())) {
    pollingTask = yield spawn(sagasUtils.startPolling, {
      instantStart,
      interval: 15_000,
      *saga() {
        yield call(apiSaga, 'health.get', {
          cache: false,
          priority: 0,
          *afterFetch({data: clusters}) {
            if (clusters !== (yield select(getClusters))) {
              yield put({
                type: 'HEALTH_GET_CLUSTERS',
                payload: {clusters, wasInRollingUpgrade: yield select(getInRollingUpgrade)},
              });
            }
          },
        });
      },
    });
  }
}

export function* stopFetchHealthPolling() {
  if (pollingTask && pollingTask.isRunning()) {
    pollingTask.cancel();
    pollingTask = null;
  }
}
