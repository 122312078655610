/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {createSelector} from 'reselect';
import {StatusIcon} from 'components';
import {getWorkloadStatus} from 'containers/Workload/WorkloadUtils';

export const endpointEnforcementListGridSettings = createSelector([], () => ({
  id: 'endpointEnforcementList',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Common.Status'),
      value: ({row}) => getWorkloadStatus(row.data),
      format: ({value}) =>
        value && (
          <>
            <StatusIcon
              status={value}
              title={`${intl('Workloads.VENConnectivity')}: ${getWorkloadStatusIntl(value)}`}
            />
            &nbsp;
            {getWorkloadStatusIntl(value)}
          </>
        ),
      sortable: false,
    },
    hostname: {
      header: intl('Common.Hostname'),
      value: ({row}) => row.data.hostname,
      sortable: false,
    },
    enforcementMode: {
      header: intl('Antman.Servers.Enforcement.Mode'),
      value: ({row}) => row.data.enforcementMode,
      format: ({value}) => (value === 'visibility_only' ? intl('Common.VisibilityOnly') : value),
      sortable: false,
    },
  },
  templates: [
    {
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'minmax(calc(1.2 * var(--100px)), max-content)'},
          {columns: ['hostname'], size: 'minmax(calc(2 * var(--100px)), max-content)'},
          {columns: ['enforcementMode'], size: 'minmax(calc(1.5 * var(--100px)), max-content)'},
        ];
      },
    },
  ],
}));
