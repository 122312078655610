/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSettings, getExplorerLabelsColumn} from './MapListConfig';
import {
  getSubLinks,
  getAggregateLinksWithRules,
  getFilteredLinks,
  getMapRouteParams,
  getPolicyVersion,
} from '../MapState';
import {getIsCSFrame} from 'containers/App/AppState';
import {providerConsumerOrder} from 'containers/PolicySettings/PolicySettingsState';
import {getGridSelector, getGridSortedRows} from 'components/Grid/GridSelectors';
import {getGridData} from './MapListUtils';
import {getColorBlindMode} from 'containers/User/Settings/SettingsState';

export const getIndividualExplorerRows = createSelector(
  [getFilteredLinks, getPolicyVersion, getIsCSFrame],
  (filteredLinks, policy, isCSFrame) => {
    return getGridData(filteredLinks, true, policy, isCSFrame);
  },
);

export const getAggregatedExplorerRows = createSelector(
  [getAggregateLinksWithRules, getPolicyVersion, getIsCSFrame],
  (links, policy, isCSFrame) => {
    return getGridData(links, false, policy, isCSFrame);
  },
);

export const getExplorerSubRows = createSelector(
  [getSubLinks, getPolicyVersion, getIsCSFrame],
  (links, policy, isCSFrame) => {
    return getGridData(links, true, policy, isCSFrame);
  },
);

// pass direction to getExplorerLabelsColumn
const gridSettings = createSelector(
  [
    providerConsumerOrder,
    getMapRouteParams,
    getPolicyVersion,
    getColorBlindMode,
    getExplorerLabelsColumn('source'),
    getExplorerLabelsColumn('target'),
    getIsCSFrame,
  ],
  (
    order,
    {connection = 'aggregated', display = 'table'},
    policyVersion,
    colorDeficiency,
    sourceLabelsColumn,
    targetLabelsColumn,
    isCSFrame,
  ) => {
    return getGridSettings({
      providerConsumerOrder: order,
      policyVersion,
      connection,
      summary: display === 'map',
      colorDeficiency,
      sourceLabelsColumn,
      targetLabelsColumn,
      isCSFrame,
    });
  },
);

export const getExplorerRows = createSelector(
  [getIndividualExplorerRows, getAggregatedExplorerRows, getMapRouteParams, getIsCSFrame],
  (individualRows, aggregatedRows, {connection, tab}, isCSFrame) => {
    const rows = connection === 'individual' ? individualRows : aggregatedRows;

    if (isCSFrame && tab === 'ruleTraffic') {
      return rows.filter(row => row.data.policy?.reported?.decision !== 'unknown');
    }

    return rows;
  },
);

export const getExplorerRowsCount = createSelector(getExplorerRows, rows => ({
  matched: rows.length,
  total: rows.length,
}));

export const getExplorerGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getExplorerRows,
  });

export const getExplorerSubGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getExplorerSubRows,
  });

export const getListSortedRows = state =>
  getGridSortedRows(state, {
    settings: gridSettings,
    rows: getIndividualExplorerRows,
  });
