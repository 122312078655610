/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError from './Application';
import type {ApplicationErrorOptions} from './Application';
import {processParams} from 'components/Link/LinkUtils';

type UnknownRecord = Record<string, unknown>;

interface RedirectErrorOptions extends ApplicationErrorOptions {
  to?: string;
  params?: UnknownRecord;
  query?: UnknownRecord;
  proceedFetching?: boolean;
  thisFetchIsDone?: boolean;
}

/**
 * Request fetch error
 */
export default class RedirectError extends ApplicationError {
  to: string;
  params: UnknownRecord;
  query: UnknownRecord;

  constructor(data: RedirectErrorOptions | string = {}) {
    if (typeof data === 'string') {
      data = {code: data};
    }

    const {to = '', params = {}, query = {}, ...details} = data;

    _.defaults(details, {
      code: 'REDIRECT',
      statusCode: 302,
    });

    super(details);
    this.name = 'RedirectError';

    this.to = to;
    this.params = processParams(params);
    this.query = query;
  }

  toString(): string {
    const messages = [`Redirect to "${this.to}"`];
    const extra: string[] = [];

    if (!_.isEmpty(this.params)) {
      extra.push(`params ${JSON.stringify(this.params)}`);
    }

    if (!_.isEmpty(this.query)) {
      extra.push(`query ${JSON.stringify(this.query)}`);
    }

    const extraMessage = extra.join(' and ');

    if (extraMessage) {
      messages.push(extraMessage);
    }

    return messages.join(' with ');
  }
}
