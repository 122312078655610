/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getRouteParams, getRouteName, isEdge, getIsCSFrame} from 'containers/App/AppState';
import {getPolicyVersions, fillPolicyObjUserInfo} from 'containers/Provisioning/ProvisioningUtils';
import {getAllUsersMap, doesUserHaveGlobalObjectPermissions} from 'containers/User/UserState';
import {isAPIAvailable} from 'api/apiUtils';
import {isSystemUser} from 'containers/IPList/IPListUtils';
import {dataFormatUtils, ipUtils} from 'utils';
import {excludeRemovedItems} from './Edit/IPListEditUtils';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'IP_LISTS_GET_DETAIL':
        return action.data;
      default:
        return state;
    }
  },

  any(state = {}, action) {
    switch (action.type) {
      case 'IP_LIST_ANY':
        return action.data;
      default:
        return state;
    }
  },
};

export const getIPInstance = state => state.iplist.instance;
export const getAnyIPList = state => state.iplist.any;

export const getIPVersions = createSelector([getIPInstance, getRouteParams], (instance, {pversion}) =>
  getPolicyVersions(instance, pversion),
);

export const getIPEdit = createSelector(
  [
    createSelector([getRouteName, getIPInstance], (routeName, ip) => (routeName === 'app.iplists.create' ? {} : ip)),
    getRouteName,
  ],
  (ipInstance, routeName) => {
    // Important to initialize active and draft to null when active or draft is empty
    // when calling dataFormatUtils.ipListsDiff()
    let ip = {
      active: _.isEmpty(ipInstance.active) ? null : ipInstance.active,
      draft: _.isEmpty(ipInstance.draft) ? null : ipInstance.draft,
    };

    const isEdit = routeName === 'app.iplists.item.edit';
    const ipNames = [];
    const fqdnNames = [];

    // Parsing active and draft only valid when editing
    if (ip.draft?.name && isEdit) {
      const diff = dataFormatUtils.ipListsDiff(ip.draft, ip.active);
      let combinedFqdnsAndIpAddresses = excludeRemovedItems(diff.ip_ranges) || [];

      if (diff.fqdns && diff.fqdns.length) {
        combinedFqdnsAndIpAddresses = combinedFqdnsAndIpAddresses.concat(excludeRemovedItems(diff.fqdns)) || [];
      }

      const info =
        combinedFqdnsAndIpAddresses.map(range => {
          const originalText = ipUtils.stringifyAddressObject(range);

          if (range.fqdn) {
            // Save only fqdn names
            fqdnNames.push(originalText);
          } else {
            // Save only ip names
            // Used to display the proper ip name
            // e.g. range = { range.from_ip: 10.1.1.1, range.to.ip: 10.10.10.10 } will convert to  '10.1.1.1 - 10.10.10.10'
            ipNames.push(originalText);
          }

          if (!range.fromIp && !range.fqdn) {
            const transformed = ipUtils.parseAddressLine(originalText);

            transformed.removed = range.removed;
            transformed.type = range.type;

            return transformed;
          }

          return range;
        }) || [];

      // Save a copy of ip_ranges and fqdn to an original property that is used for logical parsing
      const ipFQDN = info.reduce(
        (newData, data) => {
          const info = data;

          // Clone to prevent circular reference
          // info.original is used in ip::areIpRangesEqual()
          // to set the type e.g. new, old, updated
          info.original ||= _.cloneDeep(data);

          if (data.fqdn) {
            newData.fqdn.push(info);
          } else {
            newData.ip_ranges.push(info);
          }

          return newData;
        },
        {ip_ranges: [], fqdn: []},
      );

      ip = Object.assign(ip, {
        name: diff.name,
        nameType: diff.nameType,
        description: diff.description,
        descriptionType: diff.descriptionType,
        ...ipFQDN,
        ipNames,
        fqdnNames,
        length: combinedFqdnsAndIpAddresses.length,
      });
    }

    return {
      ip,
      isEdit,
    };
  },
);

export const getIPItem = createSelector(
  [getIPVersions, getRouteParams, getAllUsersMap, doesUserHaveGlobalObjectPermissions, getIPEdit, isEdge, getIsCSFrame],
  (
    {versions, isOldVersion, pversionObjIsDeleted},
    params,
    usersMap,
    userHasGlobalObjectPermission,
    ipEdit,
    edgeEnabled,
    isCSFrame,
  ) => {
    const id = params.id;

    const policyVersion = isOldVersion ? Number(params.pversion) : params.pversion;
    // check provision option and not a system generated user
    const hasWritePermission = userHasGlobalObjectPermission;

    versions = fillPolicyObjUserInfo(versions, usersMap);

    // versions.pversionObj is most updated version between active and draft
    const systemUser = isSystemUser(userHasGlobalObjectPermission, versions.pversionObj);

    // versions.prevPversionObj can be undefined when IP policy is deleted
    const diff = dataFormatUtils.ipListsDiff(versions.pversionObj, versions.prevPversionObj);
    const fqdns = diff?.fqdns ?? [];

    let ipInstance = {};

    // Use the versions.draft property because the API 'ip_list.update' will use {pversion: 'draft'}
    // thus need to check against the 'draft' property
    // Note: When draft has update_type === 'delete' then version is being deleted
    const editButtonIsDisabled = versions?.draft?.update_type === 'delete' || !isAPIAvailable('ip_list.update');

    // Use the versions.draft property because the API 'ip_list.delete' will use {pversion: 'draft'} when deleting thus need to check
    // against the 'draft' property
    const removeButtonIsDisabled = versions?.draft?.update_type === 'delete' || !isAPIAvailable('ip_list.delete');

    // diff is the difference between the active which is provisioned vs draft which is the version that hasn't been provisioned
    // ip_ranges and fqdns are used to show the difference between the new, old ip when viewing in draft mode
    if (diff) {
      ipInstance = {
        ip_ranges: diff.ip_ranges,
        fqdns,
      };
    }

    // Ip greater than 1000 can't be edited because editor will be unresponsive
    const isIpMaxed = ipInstance?.ip_ranges?.length > 1000;

    return {
      versions,
      policyVersion,
      isOldVersion,
      id,
      params,
      hasWritePermission,
      pversionObjIsDeleted,
      systemUser,
      isIpMaxed,
      removeButtonIsDisabled,
      editButtonIsDisabled,
      ipInstance,
      ...ipEdit,
      edgeEnabled,
      noExperimental: true, // don't allow experimental by default
      isCSFrame,
      ...(isCSFrame && {noFQDN: true}),
    };
  },
);
