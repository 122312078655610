/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Fragment, PureComponent} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {GraphBar} from 'components';
import styles from './LabelGraphBar.css';

const colorPalettes = {
  lightgreenAndGray: ['#46ab5c', '#c7d7df'],
  orangeAndGray: ['#ff9100', '#c7d7df'],
  redAndGray: ['#e83f33', '#c7d7df'],
  redAndLightgreen: ['#e83f33', '#46ab5c'], // Security Rules
  grayAndLightgreen: ['#c7d7df', '#46ab5c'], // VENs by Connectivity
  grayAndNavy: ['#c7d7df', '#0068ad'], // VENs by Policy State
};

/**
 * Extension component of GraphBar that provides a simple comparison graph for two values
 * with support for nodes as titles and labels for context.
 */
export default class LabelGraphBar extends PureComponent {
  static propTypes = {
    /**
     * Values to compare e.g. [{value: 120, title: <Link />, label: 'VENs'}, {...}]
     *
     * Takes a values object with the following properties:
     *
     * value - Numerical value to display
     * title - Any node - can be used as a link for more context
     * label - Labels for the values
     */
    values: PropTypes.array,
    colorPalette: PropTypes.oneOf(Object.keys(colorPalettes)), // Color style that maps to value indexes
    legend: PropTypes.bool,
    theme: PropTypes.object,
  };

  static defaultProps = {
    values: [],
  };

  render() {
    const {tid = 'label-graph-bar', values, colorPalette, legend, theme} = mixThemeWithProps(styles, this.props);

    // Get base total
    const total = values.reduce((result, {value}) => value + result, 0);
    // Derive appropriate graph widths (percentages) from total for each value (max. 2)
    const graphOrder = values
      .map(({value, tooltip, color}, index) => ({
        tooltip,
        color: color ?? colorPalettes[colorPalette][index],
        exact: true,
        percentage: Math.max(Math.round((value / total) * 100), 2),
      }))
      .filter(graph => graph.percentage > 0);

    return (
      <div data-tid={tid}>
        {legend ? null : (
          <div className={theme.nodeWrapper}>
            {
              // For any non-positive value disable the title node and show empty state styling for the label below
              values.map(({title}, index) => (
                <Fragment key={index}>{title}</Fragment>
              ))
            }
          </div>
        )}
        <GraphBar theme={theme} size="medium" noAnimation values={graphOrder} />
        <div className={legend ? styles.legendWrapper : theme.nodeWrapper}>
          {values.map(({value, label, color, title}, index) => (
            <span key={index} className={cx(theme.title, {[theme.disabled]: value <= 0})}>
              {legend ? (
                <>
                  <span
                    className={styles.legendColor}
                    style={{backgroundColor: color || colorPalette[colorPalette][index]}}
                  />
                  {title}
                </>
              ) : null}
              <span className={styles.legend}>{label}</span>
            </span>
          ))}
        </div>
      </div>
    );
  }
}
