/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {getAggregatedDraftPolicy} from '../../MapPolicyUtils';
import {getManagedEndpoint} from './MapGraphManagedEndpointUtils';
import {getUnmanagedEndpoint} from './MapGraphUnmanagedEndpointUtils';
import {getLink} from './MapGraphLinkUtils';
import type {Label} from 'illumio';
import type {EndpointType, LinkData, ViewType, EndType, UnmanagedEndpointType, ManagedDetails} from '../../MapTypes';
import {isManagedEndpoint, type MapGroupingSettings} from '../../MapTypes';
import type {
  Items,
  ComboId,
  LabelType,
  ComboItems,
  GraphLink,
  ComboMapping,
  GraphCombo,
  GraphCombosAndManagedEndpoints,
  GraphSelection,
} from '../MapGraphTypes';
import {
  comboNodeBorderWidth,
  comboNodeDonutWidth,
  comboNodeSizeBase,
  getUnmanagedNodeSize,
  managedNodeBorderWidth,
  managedNodeSizeBase,
  nodeSizeBase,
  unmanagedNodeBorderWidth,
} from 'containers/IlluminationMap/Graph/Utils/MapGraphStyleUtils';
import {Chart} from 'regraph';
import {type RefObject} from 'react';
import {type IconName} from 'components';
import {getItem, setItem} from 'utils/webStorage';
import type {PolicyOrder} from 'containers/IlluminationMap/MapPolicyUtils';

export const DELETED_COMBO_ID = '_nodes_deleted';

export const getEmptyLabelText = (key: string, labelTypes: LabelType[], parentLabels: Label[] = []): string => {
  // If the label was empty but there was a corresponding parent, show it.
  // This might happen if they add 'appGroup' on top of 'application'
  const parentLabel = (parentLabels || []).find(label => label.key === key);

  if (parentLabel && parentLabel.id && parentLabel.id !== 'discovered') {
    return parentLabel.value;
  }

  return `${intl('Common.No')} ${
    labelTypes.find(label => label.key === key)?.display_name ||
    (key === 'appGroup' && intl('Common.AppGroup')) ||
    intl('Common.Label')
  }`;
};

export const truncateLabel = (label: string, desiredLabelLength: number): string => {
  label = (typeof label === 'string' ? label : '').trim();

  if (label.length > desiredLabelLength + 4) {
    return `${label.slice(0, desiredLabelLength / 2)}...${label.slice((-1 * desiredLabelLength) / 2, label.length)}`;
  }

  return label;
};

export const isDeletedId = (id: ComboId): boolean => id === DELETED_COMBO_ID;

export const getViewBasedEndKey = (viewType: ViewType, href: string, end: EndType): string => {
  if (viewType === 'bidirectional') {
    return href;
  }

  if (viewType === 'directional') {
    return `${href}_${end}`;
  }

  if (viewType === 'focused') {
    return end === 'focused' ? href : `${href}_${end}`;
  }

  return '';
};

export const getViewBasedEnd = (
  viewType: ViewType,
  focusedAppGroup: string,
  endpoint: ManagedDetails,
  end: EndpointType,
): EndType => {
  if (viewType === 'directional') {
    return end;
  }

  if (viewType === 'focused' && focusedAppGroup !== endpoint.appGroupId) {
    return end;
  }

  return 'focused';
};

export const keepNoAppGroupEndpoints = (endpoint: ManagedDetails, viewType: ViewType): boolean => {
  if (viewType === 'focused' && endpoint.appGroup === 'No App Group') {
    return false;
  }

  return true;
};

// Data for the Graph
export const calculateGraphItems = (
  links: LinkData[],
  labelTypes: LabelType[],
  viewType: ViewType,
  focusedId: ComboId,
): Items => {
  const initialItems: Items = {managedEndpoints: {}, unmanagedEndpoints: {}, links: {}};
  const hrefs: {source: string; target: string} = {source: '', target: ''};
  const linkEnds: EndpointType[] = ['source', 'target'];
  const focusedAppGroup = focusedId?.replace('_appGroup_', '');

  return links.reduce((result, link) => {
    let removeLink = false;

    linkEnds.forEach((end: EndpointType) => {
      const linkEndpoint = link[end];

      if (isManagedEndpoint(linkEndpoint)) {
        const endpoint: ManagedDetails = linkEndpoint.details;
        const viewBasedEnd = getViewBasedEnd(viewType, focusedAppGroup, endpoint, end);
        const viewBasedManagedEndKey = getViewBasedEndKey(
          viewType,
          linkEndpoint.details.href || 'deleted',
          viewBasedEnd,
        );
        const viewBasedIp = viewType === 'directional' ? `${link[end].ip}_${end}` : link[end].ip;

        hrefs[end] = viewBasedManagedEndKey;

        if (keepNoAppGroupEndpoints(endpoint, viewType)) {
          if (result.managedEndpoints[viewBasedManagedEndKey]) {
            result.managedEndpoints[viewBasedManagedEndKey].ips.add(viewBasedIp);
          } else {
            result.managedEndpoints[viewBasedManagedEndKey] = getManagedEndpoint(
              endpoint,
              link[end].type,
              viewBasedIp,
              labelTypes,
              viewBasedEnd,
            );
          }
        } else {
          removeLink = true;
        }
      } else {
        const viewBasedUnmanagedKey = viewType === 'directional' ? `${link[end].type}_${end}` : link[end].type;

        hrefs[end] = viewBasedUnmanagedKey;

        result.unmanagedEndpoints[viewBasedUnmanagedKey] = getUnmanagedEndpoint(
          link[end].type as UnmanagedEndpointType,
          result.unmanagedEndpoints[hrefs[end]],
          link,
          end,
          viewType,
        );
      }
    });

    if (!removeLink) {
      const linkHref = Object.values(hrefs).join(';');

      result.links[linkHref] = getLink(result.links[linkHref], link, hrefs);
    }

    return result;
  }, initialItems);
};

export const calculateGraphItemRules = (items: Items, links: LinkData[]): Items => {
  const itemsWithRules = {...items};

  links.forEach(link => {
    const itemLink = itemsWithRules.links[link.graphKey];
    const itemLinkService = itemLink?.services[link.serviceKey];

    if (itemLink) {
      itemLink.policy.draft = getAggregatedDraftPolicy(itemLink.policy, link.policy);
    }

    if (itemLinkService) {
      itemLinkService.policy.draft = getAggregatedDraftPolicy(itemLinkService.policy, link.policy);
    }
  });

  return itemsWithRules;
};

export const getComboItems = (
  items: Items,
  combos: ComboMapping,
  closedCombos: Record<string, GraphCombo>,
  comboLinks: Record<string, GraphLink>,
): ComboItems => {
  return {
    managedEndpoints: Object.keys(items.managedEndpoints).reduce(
      (result: GraphCombosAndManagedEndpoints, endpointKey): GraphCombosAndManagedEndpoints => {
        // If none of the endpoint's combos are closed, then include the endpoint itself
        if (
          !combos.endpoints[endpointKey] ||
          !combos.endpoints[endpointKey].some(endpointCombo => closedCombos[endpointCombo])
        ) {
          result[endpointKey] = items.managedEndpoints[endpointKey];
        }

        return result;
      },
      {...closedCombos},
    ),
    unmanagedEndpoints: items.unmanagedEndpoints,
    links: comboLinks,
  };
};

/**
 * Returns the approximate radius of a given node (open combos currently not supported)
 * @param zoom
 * @param type
 */
export function getNodeRadius({zoom, type}: {zoom: number; type: string}): number {
  // @zoom=1;size=1, all nodes have a 27.5px radius.
  const baseNodeRadius = 27.5;
  let nodeRadius = 0;

  switch (type) {
    case 'unmanagedEndpoint':
      // @zoom=1;size=1, unmanagedEndpoint radius measures ~27.5px
      // nodeRadius~=27.5px, borderRadius=0px, donutRadius=0px, haloRadius=0px;
      nodeRadius = baseNodeRadius * getUnmanagedNodeSize(zoom) + unmanagedNodeBorderWidth;
      break;
    case 'managedEndpoint':
      // @zoom=1;size=1, managedEndpoint radius measures ~32.5px
      // nodeRadius~=27.5px, borderRadius~=5px, donutRadius=0px, haloRadius=0px;
      nodeRadius = baseNodeRadius * managedNodeSizeBase + managedNodeBorderWidth;
      break;
    case 'node':
      // @zoom=1;size=1, node radius measures ~37.5px
      // nodeRadius~=27.5px, borderRadius~=2.5px, donutRadius~=5px, haloRadius~=2.5px;
      nodeRadius = baseNodeRadius * nodeSizeBase + comboNodeBorderWidth + comboNodeDonutWidth;
      break;
    case 'combo':
      // @zoom=1;size=1, combo radius measures ~37.5px
      // nodeRadius~=27.5px, borderRadius~=2.5px, donutRadius~=5px, haloRadius~=2.5px;
      nodeRadius = baseNodeRadius * comboNodeSizeBase + comboNodeBorderWidth + comboNodeDonutWidth;
      break;
  }

  return nodeRadius * zoom;
}

/**
 * Returns the coordinates (relative to the chart) of the nodeId.
 * @param nodeId
 * @param positions
 * @param chartRef
 */
export function getNodeCoordinates({
  nodeId,
  positions,
  chartRef,
}: {
  nodeId: string;
  positions: Chart.Positions;
  chartRef: RefObject<Chart>;
}): Chart.Position | undefined {
  if (nodeId && positions && positions[nodeId] && chartRef?.current) {
    const {x: worldX, y: worldY} = positions[nodeId];

    return chartRef.current.viewCoordinates(worldX, worldY);
  }
}

/**
 * Returns the halfway point between the positions of nodeId1 and nodeId2
 * @param nodeId1
 * @param nodeId2
 * @param positions
 * @param chartRef
 */
export function getLinkCoordinates({
  nodeId1,
  nodeId2,
  positions,
  chartRef,
}: {
  nodeId1: string;
  nodeId2: string;
  positions: Chart.Positions;
  chartRef: RefObject<Chart>;
}): Chart.Position | undefined {
  if (!nodeId1 || !nodeId2) {
    return;
  }

  const coords1 = getNodeCoordinates({nodeId: nodeId1, positions, chartRef});
  const coords2 = getNodeCoordinates({nodeId: nodeId2, positions, chartRef});

  if (coords1 && coords2) {
    const {x: x1, y: y1} = coords1;
    const {x: x2, y: y2} = coords2;

    return {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2,
    };
  }
}

/**
 * Returns coordinates of an id (node or link) from the graph
 * @param nodeId1
 * @param nodeId2
 * @param positions
 * @param chartRef
 */
export function getCoordinates({
  id,
  positions,
  chartRef,
}: {
  id?: string;
  chartRef: RefObject<Chart>;
  positions: Chart.Positions;
}): Chart.Position | undefined {
  if (id) {
    const ids = id.split(';');

    return ids.length < 2
      ? getNodeCoordinates({nodeId: ids[0], positions, chartRef})
      : getLinkCoordinates({nodeId1: ids[0], nodeId2: ids[1], positions, chartRef});
  }
}

export const getLinkIconName = (
  number: number | undefined,
  policy: string,
  providerConsumerOrder: PolicyOrder,
): IconName => {
  const orderSequence = providerConsumerOrder === 'consumerFirst' ? 1 : 0;

  if (policy === 'blocked' || policy === 'potentiallyBlocked' || policy === 'allowed') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'map-link-left';
    }

    // Consumer -> Provider
    return 'map-link';
  }

  if (policy === 'allowedAcrossBoundary') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'across-enf-boundary-rtl';
    }

    // Consumer -> Provider
    return 'across-enf-boundary';
  }

  if (policy === 'blockedByBoundary' || policy === 'potentiallyBlockedByBoundary') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'enf-boundary-rtl';
    }

    // Consumer -> Provider
    return 'enf-boundary';
  }

  return 'map-link';
};

export const getSelectionForFocusedView = (
  clickedId: string,
  focusedComboId: string,
  openComboId: string,
): GraphSelection => {
  //In focused view, when you click on connected combo
  if (clickedId === openComboId) {
    return openComboId.includes('source')
      ? {comboLink: [`${openComboId};${focusedComboId}`]}
      : {comboLink: [`${focusedComboId};${openComboId}`]};
  }

  return {combo: [clickedId]};
};

export const setGraphState = ({
  queryId,
  key,
  value,
}: {
  queryId: string;
  key: string;
  value: MapGroupingSettings | GraphSelection | string[];
}): void => {
  type GraphState = {
    autoGrouping?: MapGroupingSettings;
    grouping?: string[];
    selection?: GraphSelection;
    openCombos?: Record<string, boolean>;
    timestamp: Date;
  };

  let graphState: Record<string, GraphState> = (getItem('mapGraphState') || {}) as Record<string, GraphState>;

  graphState = {
    ...graphState,
    [queryId]: {
      ...graphState[queryId],
      [key]: value,
      timestamp: new Date(),
    },
  };

  setItem('mapGraphState', graphState);
};

export const appGroupMapLayout = {
  name: 'sequential',
  orientation: 'right',
  curvedLinks: false,
  stretch: 1,
  level: 'hierarchy',
  orderBy: 'sequence',
  packing: 'aligned',
  stacking: {arrange: 'none'},
  tightness: 1,
} as Chart.LayoutOptions;

export const truncateString = (text: string, desiredLabelLength: number): string => {
  text = text.trim();

  if (text.length > desiredLabelLength) {
    const acceptedLength = Math.floor((desiredLabelLength - 3) / 2);

    return `${text.slice(0, acceptedLength)}...${text.slice(-acceptedLength, text.length)}`;
  }

  return text;
};

export const truncateOpenComboLabel = (label: string, viewType: ViewType, numNodes: number): string => {
  label = (typeof label === 'string' ? label : '').trim();

  const splitLabel = label.split('\n');
  const truncatedLabel = splitLabel.map(label =>
    truncateString(label, viewType === 'focused' ? 35 : _.clamp(10 * numNodes, 10, 25)),
  );

  return truncatedLabel.join('\n');
};
