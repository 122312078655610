/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchLoadBalancers, fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';
import {hrefUtils} from 'utils';
import {
  getGridSettings,
  getDiscoveredVirtualServers,
  getVirtualServersCount,
  getFilterMap,
} from './VirtualServerListState';
import gridSaga from 'components/Grid/GridSaga';
import {isEdge} from 'containers/App/AppState';
import {RedirectError} from 'errors';
import {getXXXLabelsQueryParam} from 'api/apiUtils';

export function* fetchAllVirtualServers({filter, force = false} = {}) {
  const query = {max_results: 1000};

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      const value = values[0];

      switch (name) {
        case 'virtual_server_mode':
          query.has_virtual_server = value.id !== null;

          if (value.id !== null) {
            query[name] = value.id;
          }

          break;
        case 'slbs':
        case 'slb':
          query.slb = value.href;
          break;
        case 'labelsAndLabelGroups':
          if (!filter.virtual_server_mode || filter.virtual_server_mode[0].id !== null) {
            query.has_virtual_server = true;
            Object.assign(query, getXXXLabelsQueryParam([values.map(({href}) => href)], 'virtual_server_labels'));
          }

          break;
        default:
          query[name] = typeof value === 'object' ? (value.isPartial ? value.value : value.id) : value;
      }
    }
  }

  return yield call(apiSaga, 'discovered_virtual_servers.get_collection', {
    query,
    cache: !force,
    *onDone({data}) {
      if (force || data !== (yield select(getDiscoveredVirtualServers))) {
        yield put({type: 'DISCOVERED_VIRTUAL_SERVERS', data});
      }

      const {count, list} = data.reduce(
        (result, {name, virtual_server}) => {
          if (virtual_server) {
            result.count.matches++;
            result.list.push({href: virtual_server.href, name});
          }

          return result;
        },
        {count: {matches: 0}, list: []},
      );

      if (force || count !== (yield select(getVirtualServersCount))) {
        yield put({type: 'VIRTUAL_SERVERS_GET_LIST', data: {count, list}});
      }

      return {count};
    },
  });
}

export function* updateEnforceState({hrefs, policyState, pversion = 'draft'}) {
  yield all(
    hrefs.map(href =>
      call(apiSaga, 'virtual_server.update', {
        params: {virtual_server_id: hrefUtils.getId(href), pversion},
        data: {mode: policyState},
      }),
    ),
  );
}

export function* removeVirtualServer({href, pversion = 'draft'}) {
  yield call(apiSaga, 'virtual_server.delete', {
    params: {pversion, virtual_server_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* fetchVirtualServerList(route, refetch = false, options = {}) {
  const edgeEnabled = yield select(isEdge);

  if (__ANTMAN__ || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap, // re-getFilterMap fixed "filterMap is undefined"
    *onSaga({filterParams}) {
      const {fetchSLBs = true, filter: customFilter} = options;

      // restore filter stripped away by GridSelectors (filter is not in urlParams or gridParams in slb page url)
      const filter = filterParams.isEmpty ? customFilter : {...filterParams.valid, ...customFilter};

      // need to fetch before fetchLoadBalancers, because it checks isLoadBalancerEnabled.
      yield call(fetchDeviceTypes, {force: refetch});

      const [{count}] = yield all([
        call(fetchAllVirtualServers, {filter, force: refetch}),
        fetchSLBs && call(fetchLoadBalancers, {force: refetch}),
      ]);

      return count.matches;
    },
  });
}
