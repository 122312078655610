/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {createSelector} from 'reselect';
import {getRouteParams} from 'containers/App/AppState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getExplorerRows} from 'containers/IlluminationMap/List/MapListState';
import {getGridSettings} from './ServerEnforcementViewConfig';
import {providerConsumerOrder} from 'containers/PolicySettings/PolicySettingsState';
import {getLabelTypes} from 'containers/IlluminationMap/Graph/MapGraphState';
import {getId} from 'utils/href';

export default {
  server(state = null, action) {
    switch (action.type) {
      case 'SET_SERVER_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

const getServerData = state => state.servers.server;

const gridSettings = createSelector(
  [providerConsumerOrder, getLabelTypes, getRouteParams],
  (order, labelTypes, {policy}) => {
    return getGridSettings(order, labelTypes, policy || 'reported');
  },
);

export const getServerExplorerGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getExplorerRows,
  });

export const getServerViewPage = createSelector([getServerData], data => {
  return produce(data, draft => {
    draft.id = getId(data.href);
  });
});
