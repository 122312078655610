/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useSelector, useDispatch} from 'react-redux';
import {useRouter} from 'react-router5';
import {useLayoutEffect, useCallback, useMemo} from 'react';
import {Icon, Link, StickyShadow, CounterBadge, Tooltip, TabPanel, MenuItem} from 'components';
import {MainMenu, HelpMenu, ProvisionMenu, UserMenu} from 'containers';
import {isUIToggleEnabled, getIsCSFrame} from 'containers/App/AppState';
import {getHeaderProps} from './HeaderState';
import {generalUtils} from 'utils';
import {pickUpwardsParams} from '../../router/routesUtils';
import styles from './Header.css';
import antmanStyles from 'antman/containers/styles.css';

const stickyHeaderTheme = {sticky: styles.header};
const healthLinkTheme = {link: styles.healthState};
const stickyHeaderNoContentTheme = {sticky: styles.headerNoContent};

export default function Header() {
  const headerProps = useSelector(getHeaderProps);
  const dispatch = useDispatch();
  const isCSFrame = useSelector(getIsCSFrame);

  const {
    icon,
    title,
    subtitle,
    label,
    up,
    userIsWithReducedScope,
    versionMismatch,
    healthEnabled,
    clusterCount,
    clusterStatus,
    customSearch,
    route: {
      meta: {params: metaParams},
      name,
      params,
    },
    isXpress = false,
  } = headerProps;
  const showHealthCount = clusterCount || (clusterStatus && clusterStatus !== 'Normal');
  const {routesMap} = useRouter();

  const handleClick = useCallback(
    (type, evt) => {
      evt.preventDefault(); // Prevents setting focus glow on click if focus is not set by keys yet
      dispatch({type, data: 'click'});
    },
    [dispatch],
  );
  const handleKeyDown = useCallback(
    (type, evt) => {
      if (evt.key === 'Enter' || evt.key === ' ') {
        handleClick(evt); // Pass event to prevent default action (pagedown on space, for instance)
      }
    },
    [handleClick],
  );

  // Set document title in Big-Endian order
  useLayoutEffect(() => {
    document.title = _.compact([label, subtitle, title, intl('Illumio.Name')]).join(' - ');
  }, [title, subtitle, label]);

  const upButton = useMemo(() => {
    const route = routesMap.get(name);
    const props = {
      'tabIndex': '0',
      'theme': styles,
      'themePrefix': 'up-',
      'data-tid': 'comp-button comp-button-detail-nav',
    };

    if (up === true) {
      // If true, simply create link to a parent route name
      const parent = route.parents?.at(-1);

      if (parent) {
        Object.assign(props, {
          to: parent.name,
          params: pickUpwardsParams(parent, params, metaParams),
          themePrefix: 'upShow-',
          tooltip: Icon.getTitle('navigateup'),
        });
      }
    } else if (typeof up === 'string') {
      if (up.startsWith('<')) {
        // If starts with `<`, then find closest parent that ends with that name
        const end = `.${up.substr(1)}`;
        const parent = _.findLast(route.parents, parent => parent.name.endsWith(end));

        if (parent) {
          Object.assign(props, {
            to: parent.name,
            params: pickUpwardsParams(parent, params, metaParams),
            themePrefix: 'upShow-',
            tooltip: Icon.getTitle('navigateup'),
          });
        }
      } else {
        // If it's other string, consider it as full route names
        Object.assign(props, {to: up, themePrefix: 'upShow-', tooltip: Icon.getTitle('navigateup')});
      }
    } else if (typeof up === 'object') {
      // If is an object, than it contains any Button props and should be spread over Button with the ability to override icon, tid, etc.
      Object.assign(props, {themePrefix: 'upShow-', tooltip: Icon.getTitle('navigateup')}, up);
    }

    if (!window.contentRenderedAt || Date.now() - window.contentRenderedAt < 500) {
      // If page is just loaded, show only opacity transition to fade in, along with like page fade in and header title fade in
      props.style = {transition: 'var(--fadeInOpacity)'};
    }

    return (
      <Link {...props}>
        <Icon name="navigateup" theme={styles} themePrefix="upIcon-" />
      </Link>
    );
  }, [up]); // eslint-disable-line react-hooks/exhaustive-deps
  // ^ Make it depends only on 'up' prop specifically, to get snapshot of route name/params at the time when 'up' is set.
  // When navigation happens, new page is rendered first with the new route name/params,
  // but up button should update only when the new page sets new HeaderProps

  let content;

  if (isXpress || isCSFrame) {
    // in case of new coreX UI, we only show header if UI toggle is enabled. Otherwise no header, for now.
    if (isUIToggleEnabled) {
      content = (
        <div className={antmanStyles.xpressNavigation}>
          <div className={antmanStyles.navigation}>
            <TabPanel primary key="tabs" theme={{primary: antmanStyles.primary}}>
              {[
                {
                  active: /protect|servers|endpoints/.test(name),
                  link: 'protect',
                  text: intl('Common.Protect'),
                  icon: 'down',
                  tid: 'xpress-home',
                  iconProps: {
                    theme: {
                      icon: antmanStyles.xpressHeaderIcon,
                    },
                    tooltip: (
                      <>
                        <MenuItem
                          text={intl('Antman.Endpoints.Add')}
                          link={{to: 'endpoints.pairing'}}
                          icon="user"
                          theme={{
                            text: antmanStyles.text,
                            item:
                              name === 'app.endpoints.pairing'
                                ? antmanStyles.itemContentWithIconActive
                                : antmanStyles.itemContentWithIcon,
                          }}
                          tid="add-endpoints"
                        />
                        <MenuItem
                          text={intl('Antman.Servers.Add')}
                          link={{to: 'servers.pairing', params: {fetchIdleServers: true}}}
                          icon="database"
                          theme={{
                            text: antmanStyles.text,
                            item:
                              name === 'app.servers.pairing'
                                ? antmanStyles.itemContentWithIconActive
                                : antmanStyles.itemContentWithIcon,
                          }}
                          tid="add-servers"
                        />
                      </>
                    ),
                    tooltipProps: {
                      instant: true,
                      light: true,
                      interactive: true,
                      theme: {
                        tooltip: antmanStyles.tooltip,
                        content: antmanStyles.content,
                      },
                    },
                  },
                },
                {
                  link: 'xpressdashboard',
                  text: intl('Common.Dashboard'),
                  active: /xpressdashboard/.test(name),
                  tid: 'dashboard',
                },
                {
                  link: {to: 'illumination', params: {display: 'map'}},
                  text: 'Illumination',
                  active: /illumination/.test(name),
                  tid: 'illumination',
                },
              ]}
            </TabPanel>
          </div>
          <div className={antmanStyles.xpressUIToggle} data-tid="ui-toggle">
            <Link
              to="landing"
              theme={styles}
              tooltip={intl('Antman.Toggle.ExitPreview')}
              tooltipProps={{instant: true}}
            >
              <Icon name="enabled" theme={{svg: styles.svgBlueFill}} />
            </Link>
          </div>
        </div>
      );
    }
  } else {
    content = (
      <>
        {versionMismatch === 0 && <MainMenu />}
        {upButton}
        <div className={styles.headline}>
          <div className={styles.headlineInner}>
            {icon ? (
              typeof icon === 'string' ? (
                <Icon position="before" name={icon} />
              ) : (
                <Icon position="before" {...icon} />
              )
            ) : null}
            {title ? (
              <div className={styles.title} data-tid="comp-navbar-label">
                {title}
              </div>
            ) : null}
            {label || subtitle ? (
              <div className={styles.sublabel}>
                {subtitle ? (
                  <span className={styles.subtitle} data-tid="comp-navbar-title">
                    {subtitle}
                  </span>
                ) : null}
                {label ? (
                  <span className={styles.label} data-tid="comp-navbar-edit">
                    {label}
                  </span>
                ) : null}
              </div>
            ) : null}
            {customSearch}
          </div>
        </div>
        {(__ANTMAN__ || __TARGET__ === 'core') && !userIsWithReducedScope && (
          <Link
            to="dashboard"
            tabIndex="0"
            theme={healthLinkTheme}
            data-tid="comp-navbar-dashboard"
            tooltip={intl('Common.Dashboard')}
          >
            <div data-tid="dashboard">
              <Icon name="dashboard" />
            </div>
          </Link>
        )}
        {!__MSP__ && healthEnabled && versionMismatch === 0 && (
          <Link to="health.list" tabIndex="0" theme={healthLinkTheme} data-tid="comp-navbar-health">
            <div data-tid="healthstate">
              <Icon name="pce-health" />
              {showHealthCount && (
                <CounterBadge
                  color={clusterStatus === 'Error' ? 'red' : 'orange'}
                  count={clusterCount || 1}
                  theme={styles}
                />
              )}
            </div>
          </Link>
        )}
        {!__MSP__ && !userIsWithReducedScope && versionMismatch === 0 && <ProvisionMenu />}
        <UserMenu />
        <Tooltip
          placement="bottom"
          content={intl('Common.OpenInstantSearch', {command: generalUtils.isMac() ? 'Cmd + K' : 'Ctrl + K'})}
        >
          <div
            className={styles.instantSearch}
            onMouseDown={_.partial(handleClick, 'TOGGLE_INSTANT_SEARCH')}
            onKeyDown={_.partial(handleKeyDown, 'TOGGLE_INSTANT_SEARCH')}
          >
            <Icon tid="is-header-search" name="search" />
          </div>
        </Tooltip>
        <div
          tabIndex="0"
          className={styles.helpPopup}
          onMouseDown={_.partial(handleClick, 'TOGGLE_HELP_MENU_MODE')}
          onKeyDown={_.partial(handleKeyDown, 'TOGGLE_HELP_MENU_MODE')}
        >
          <Icon name="help-menu" />
        </div>
        <HelpMenu />
        {isUIToggleEnabled && (
          <div className={styles.uiToggle}>
            <Link
              to="protect"
              params={{
                product: 'Xpress',
              }}
              data-tid="comp-toggle-xpress"
              theme={styles}
              tooltip={intl('Antman.Toggle.TryPreview')}
              tooltipProps={{instant: true}}
            >
              <Icon name="disabled" theme={{svg: styles.svgWhiteFill}} />
            </Link>
          </div>
        )}
      </>
    );
  }

  return content ? (
    <StickyShadow
      alwaysAnimate
      type="header"
      depth={4}
      theme={isXpress ? stickyHeaderNoContentTheme : stickyHeaderTheme}
    >
      {content}
    </StickyShadow>
  ) : null;
}
