/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './CoreServicesItemConfig';
import {createSelector} from 'reselect';
import {getId} from 'utils/href';
import {getLabelObject} from 'containers/IlluminationMap/Utils/MapTrafficQueryResponseUtils';
import {getCoreServiceTypes} from 'containers/CoreServices/List/CoreServicesListState';

export default {
  itemList(state = [], action) {
    switch (action.type) {
      case 'CORESERVICES_GET_ITEM_LIST':
        // Combine the skip with the recommended
        if (action.data.tab === 'skip') {
          return [...state, ...action.data.list];
        }

        return action.data.list;
      default:
        return state;
    }
  },

  itemListCount(state = {}, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_ITEM_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  itemListLastRunAt(state = null, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_ITEM_LIST':
        return action.data.lastRunAt;
      default:
        return state;
    }
  },

  undoing(state = [], action) {
    switch (action.type) {
      case 'CORESERVICES_UNDOING':
        return [...state, action.href];
      case 'CORESERVICES_REMOVE_UNDOING':
        return (state || []).filter(href => href !== action.href);
      default:
        return state;
    }
  },
};

export const getCoreServicesItemList = state => state.coreServices.itemList;
export const getCoreServicesItemListCount = state => state.coreServices.itemListCount;
export const getCoreServicesItemListLastRunAt = state => state.coreServices.itemListLastRunAt;
export const getUndoingAction = state => state.coreServices.undoing;

export const getCoreServiceItemRows = createSelector(
  [getCoreServicesItemList, getCoreServiceTypes, getCoreServicesItemListLastRunAt, getUndoingAction],
  (list, types, lastRunAt, undoingAction) =>
    list.map(detectedCoreService => ({
      ...detectedCoreService,
      coreservice: (types || []).find(type => type.href === detectedCoreService.core_service_type?.href)?.name,
      key: getId(detectedCoreService.href),
      labels: detectedCoreService.workload ? getLabelObject(detectedCoreService.workload.labels) : {},
      selectable: true,
      scope: ['role', 'app', 'env', 'loc'],
      lastRunAt,
      undoing: undoingAction.includes(detectedCoreService.href),
    })),
);

export const getCoreServicesItemListPage = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getCoreServiceItemRows,
  });
