/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Pill, StatusIcon} from 'components';
import styles from './LabelList.css';
import {createSelector} from 'reselect';
import {isIPForwardingEnabled} from 'containers/App/AppState';
import {populateFacetCategory} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getDisplayNames} from '../LabelSettings/LabelSettingState';

export const filterMap = createSelector([], () => ({value: intl('Common.Name'), key: intl('Common.Type')}));

export const categories = createSelector([getDisplayNames], labelTypesNameObj => [
  populateFacetCategory({
    id: 'value',
    name: intl('Common.Name'),
    resourceType: 'labels',
  }),
  {
    id: 'key',
    name: intl('Common.Type'),
    resources: {
      key: {
        statics: Object.entries(labelTypesNameObj).map(([id, value]) => ({value: id, text: value})),
        optionProps: {
          textPath: 'text',
          idPath: 'value',
        },
      },
    },
  },
]);

export const formatUsageCell = (value, text) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <StatusIcon position="before" status="inuse" />
      {text}
    </>
  );
};

export const formatHeader = areas => (
  <>
    <div className={styles.usageHeader}>{intl('Common.InUseBy')}</div>
    {areas}
  </>
);

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([isIPForwardingEnabled], isIPForwardingEnabled => ({
  id: 'labellist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => row.data.value,
    },
    type: {
      header: intl('Common.Type'),
      value: 'display_name',
      format: ({row, value}) => (
        <Pill.Label noAffix noFill type={row.data.key}>
          {value}
        </Pill.Label>
      ),
    },
    workloads: {
      header: intl('Common.Workloads'),
      value: ({row}) => row.data.usage.workload,
      format: ({value}) => formatUsageCell(value, intl('Common.Workloads')),
    },
    containerWorkloads: {
      header: intl('Common.ContainerWorkloads'),
      value: ({row}) => row.data.usage.container_workload,
      format: ({value}) => formatUsageCell(value, intl('Common.ContainerWorkloads')),
    },
    containerWorkloadProfiles: {
      header: intl('ContainerClusters.ContainerWorkloadProfiles'),
      value: ({row}) => row.data.usage.container_workload_profile,
      format: ({value}) => formatUsageCell(value, intl('ContainerClusters.ContainerWorkloadProfiles')),
    },
    enforcementBoundary: {
      header: intl('Workloads.EnforcementBoundaries'),
      value: ({row}) => row.data.usage.enforcement_boundary,
      format: ({value}) => formatUsageCell(value, intl('Workloads.EnforcementBoundaries')),
    },
    rulesets: {
      header: intl('Common.Rulesets'),
      value: ({row}) => row.data.usage.ruleset,
      format: ({value}) => formatUsageCell(value, intl('Common.Rulesets')),
    },
    pairingProfiles: {
      header: intl('PairingProfiles.Profiles'),
      value: ({row}) => row.data.usage.pairing_profile,
      format: ({value}) => formatUsageCell(value, intl('PairingProfiles.Profiles')),
    },
    virtualServices: {
      header: intl('Common.VirtualServices'),
      value: ({row}) => row.data.usage.virtual_service,
      format: ({value}) => formatUsageCell(value, intl('Common.VirtualServices')),
    },
    virtualServers: {
      header: intl('Common.VirtualServer'),
      value: ({row}) => row.data.usage.virtual_server,
      format: ({value}) => formatUsageCell(value, intl('Common.VirtualServer')),
    },
    loopbackInterfaces: {
      header: intl('Settings.LoopbackInterfaces.Title'),
      value: ({row}) => row.data.usage.loopback_interfaces_in_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.LoopbackInterfaces.Title')),
      disabled: __ANTMAN__,
    },
    containerSettings: {
      header: intl('Settings.Containers.ContainerSettings'),
      value: ({row}) => row.data.usage.containers_inherit_host_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.Containers.ContainerSettings')),
      disabled: __ANTMAN__,
    },
    blockaction: {
      header: intl('Common.BlockAction'),
      value: ({row}) => row.data.usage.blocked_connection_reject_scopes,
      format: ({value}) => formatUsageCell(value, intl('Common.BlockAction')),
      disabled: __ANTMAN__,
    },
    ipForwarding: {
      header: intl('Settings.IPForwarding.Title'),
      value: ({row}) => row.data.usage.ip_forwarding_enabled_scopes,
      format: ({value}) => formatUsageCell(value, intl('Settings.IPForwarding.Title')),
      disabled: __ANTMAN__ || !isIPForwardingEnabled,
    },
    labelGroups: {
      header: intl('Labels.Groups'),
      value: ({row}) => row.data.usage.label_group,
      format: ({value}) => formatUsageCell(value, intl('Labels.Groups')),
    },
    RBAC: {
      header: intl('Common.RBAC'),
      value: ({row}) => row.data.usage.permission,
      format: ({value}) => formatUsageCell(value, intl('Common.RBAC')),
    },
    staticPolicy: {
      header: intl('Common.StaticPolicy'),
      value: ({row}) => row.data.usage.static_policy_scopes,
      format: ({value}) => formatUsageCell(value, intl('Common.StaticPolicy')),
    },
    firewallCoexistence: {
      header: intl('Firewall.Coexistence.Title'),
      value: ({row}) => row.data.usage.firewall_coexistence_scope,
      format: ({value}) => formatUsageCell(value, intl('Firewall.Coexistence.Title')),
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(200px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {
        columns: ['workloads', 'containerWorkloads', 'containerWorkloadProfiles', 'enforcementBoundary'],
        size: 'minmax(100px, auto)',
        header: formatHeader,
      },
      {columns: ['rulesets', 'pairingProfiles', 'virtualServers'], size: 'minmax(100px, auto)', header: formatHeader},
      {
        columns: ['virtualServices', 'loopbackInterfaces', 'containerSettings', 'blockaction'],
        size: 'minmax(100px, auto)',
        header: formatHeader,
      },
      {columns: ['labelGroups', 'RBAC'], size: 'minmax(100px, auto)', header: formatHeader},
      {
        columns: ['staticPolicy', 'firewallCoexistence', 'ipForwarding'],
        size: 'minmax(150px, auto)',
        header: formatHeader,
      },
    ],
    {
      maxWidth: 1152,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(200px, auto)'},
          {
            columns: ['workloads', 'containerWorkloads', 'containerWorkloadProfiles', 'enforcementBoundary'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['rulesets', 'pairingProfiles', 'virtualServers'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['virtualServices', 'loopbackInterfaces', 'containerSettings', 'blockaction', 'labelGroups'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['RBAC', 'staticPolicy', 'firewallCoexistence', 'ipForwarding'],
            size: 'minmax(150px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
    {
      maxWidth: 960,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'workloads',
              'containerWorkloads',
              'containerWorkloadProfiles',
              'enforcementBoundary',
              'rulesets',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: [
              'pairingProfiles',
              'virtualServers',
              'virtualServices',
              'loopbackInterfaces',
              'containerSettings',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['blockaction', 'labelGroups', 'RBAC', 'staticPolicy', 'firewallCoexistence', 'ipForwarding'],
            size: 'minmax(150px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'workloads',
              'containerWorkloads',
              'containerWorkloadProfiles',
              'enforcementBoundary',
              'rulesets',
              'pairingProfiles',
              'virtualServices',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: [
              'virtualServers',
              'loopbackInterfaces',
              'containerSettings',
              'labelGroups',
              'RBAC',
              'staticPolicy',
              'firewallCoexistence',
              'blockaction',
              'ipForwarding',
            ],
            size: 'minmax(130px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
  ],
}));
