/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import * as UserSelectors from 'containers/User/UserState';
import {isPairingProfilesEnabled} from 'containers/PairingProfile/PairingProfileState';
import {isEventsEnabled} from 'containers/Events/EventsState';
import {isEventsConfigEnabled} from 'containers/Events/Config/EventsConfigState';
import {isSecuritySettingsEnabled} from 'containers/SecuritySettings/SecuritySettingsState';
import {isVenOperationsEnabled} from 'containers/VenOperations/VenOperationsState';
import {isAuthenticationSettingsEnabled} from 'containers/AuthenticationSettings/AuthenticationSettingsState';
import {isAccessRestrictionEnabled} from 'containers/RBAC/AccessRestriction/AccessRestrictionState';
import {isOfflineTimersEnabled} from 'containers/OfflineTimers/OfflineTimersState';
import {isVenLibraryEnabled} from 'containers/Ven/VenState';
import {isLoadBalancerEnabled} from 'containers/LoadBalancer/LoadBalancerState';
import {isContainerClustersEnabled} from 'containers/ContainerCluster/ContainerClusterState';
import {isSecureGatewayEnabled} from 'containers/SecureGateway/SecureGatewayState';
import {isNetworkEnabled} from 'containers/Network/NetworkState';
import {isSwitchEnabled} from 'containers/Switch/SwitchState';
import {isSupportReportsEnabled} from 'containers/SupportBundles/VENSupportReports/SupportReportsState';
import {isPCESupportBundlesEnabled} from 'containers/SupportBundles/PCESupportBundles/PCESupportBundlesState';
import {isFlowCollectionEnabled} from 'containers/FlowCollectionFilters/FlowCollectionFiltersState';
import {getVenLibraries} from 'containers/Ven/Library/VenLibraryState';
import {isPolicySettingsEnabled} from 'containers/PolicySettings/PolicySettingsState';
import {getCoreServiceSettings} from 'containers/CoreServices/Settings/CoreServicesSettingsState';
import {isAPIKeySettingsEnabled} from 'containers/APIKeySettings/APIKeySettingsState';
import {isMyManagedTenantsEnabled} from 'msp/containers/MyManagedTenants/MyManagedTenantsState';
import {isAppGroupsEnabled} from 'containers/AppGroups/AppGroupState';
import {
  getSupportUrl,
  isIlluminationApiEnabled,
  areVulnerabilitiesEnabled,
  isNetworkEnforcementNodeEnabled,
  isReportingEnabled,
  isCoreServicesEnabled,
  isLabelSettingsEnabled,
  isLabelSettingsPageEnabled,
  isHealthEnabled,
  isEdge,
  isCrowdstrike,
  isIlluminationClassicEnabled,
} from 'containers/App/AppState';
import {createStructuredSelector} from 'reselect';
import {getDefaultAppMap, getDefaultIllumination} from 'containers/User/Settings/SettingsState';

export const getMainMenu = createStructuredSelector({
  isUserOwner: UserSelectors.isUserOwner,
  userIsWithReducedScope: UserSelectors.isUserWithReducedScope,
  userIsScoped: UserSelectors.isUserScoped,
  explorerIsEnabled: UserSelectors.isExplorerEnabled,
  trafficIsEnabled: UserSelectors.isTrafficEnabled,
  isUserReadOnlyOrProvisioner: UserSelectors.isUserReadOnlyOrProvisioner,
  vulnerabilitiesAreEnabled: areVulnerabilitiesEnabled,
  isNetworkEnforcementNodeEnabled,
  isReportingEnabled,
  isCoreServicesEnabled,
  isLabelSettingsEnabled,
  isUserWorkloadManagerOnly: UserSelectors.isUserWorkloadManagerOnly,
  isLabelSettingsPageEnabled,
  coreServicesSettings: getCoreServiceSettings,
  illuminationMapIsEnabled: UserSelectors.isIlluminationMapEnabled,
  illuminationApiIsEnabled: isIlluminationApiEnabled,
  loadBalancerIsEnabled: isLoadBalancerEnabled,
  containerClustersIsEnabled: isContainerClustersEnabled,
  secureGatewayIsEnabled: isSecureGatewayEnabled,
  networkIsEnabled: isNetworkEnabled,
  switchEnabled: isSwitchEnabled,
  eventsIsEnabled: isEventsEnabled,
  segmentationIsTemplatesEnabled: UserSelectors.isSegmentationTemplatesEnabled,
  pairingProfilesIsEnabled: isPairingProfilesEnabled,
  eventsConfigIsEnabled: isEventsConfigEnabled,
  securitySettingsIsEnabled: isSecuritySettingsEnabled,
  venOperationsEnabled: isVenOperationsEnabled,
  authenticationSettingsIsEnabled: isAuthenticationSettingsEnabled,
  accessRestrictionIsEnabled: isAccessRestrictionEnabled,
  policySettingsIsEnabled: isPolicySettingsEnabled,
  offlineTimersIsEnabled: isOfflineTimersEnabled,
  venLibraryIsEnabled: isVenLibraryEnabled,
  flowCollectionIsEnabled: isFlowCollectionEnabled,
  supportReportsIsEnabled: isSupportReportsEnabled,
  pceSupportBundlesIsEnabled: isPCESupportBundlesEnabled,
  isAppGroupsEnabled,
  defaultIllumination: getDefaultIllumination,
  illuminationClassicEnabled: isIlluminationClassicEnabled,
  isEdge,
  isCrowdstrike,

  // When userIsHealthEnabled: true - user is on premise
  // userIsHealthEnabled: false or doesn't exist - user is Sass
  userIsHealthEnabled: isHealthEnabled,
  vens: getVenLibraries,
  supportUrl: getSupportUrl,
  apiKeySettingsIsEnabled: isAPIKeySettingsEnabled,
  enforcementBoundaryEnabled: UserSelectors.canUserViewEnforcementBoundaries,
  myManagedTenantsIsEnabled: isMyManagedTenantsEnabled,
  defaultAppMap: getDefaultAppMap,
});
