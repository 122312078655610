/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {RedirectError} from 'errors';
import {getLabelInstance} from './Edit/LabelEditState';
import {cachedResponses} from 'api/apiCache';
import {getRouteParams, isEdge} from 'containers/App/AppState';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {getLabelsHrefMap} from './List/LabelListState';
import {hrefUtils} from 'utils';
import {getParameterizedPath, schemaMethodsMap} from 'api/apiUtils';
import {getOrgId} from 'containers/User/UserState';

export function* fetchLabelItem({params}, refetch = false) {
  const edgeEnabled = yield select(isEdge);

  try {
    const label = yield call(fetchLabelInstance, {
      force: refetch,
      id: params.id,
      usage: !edgeEnabled,
      dispatch: !edgeEnabled,
    });

    // In Edge Redirect to the corresponding group
    if (edgeEnabled) {
      if (label.hasOwnProperty('external_data_reference')) {
        throw new RedirectError({
          to: 'groups.view',
          params: {
            group: hrefUtils.getId(label.external_data_reference),
            tab: 'inboundpolicy',
            pversion: params.pversion || 'draft',
          },
          proceedFetching: true,
          thisFetchIsDone: true,
        });
      }

      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }

    yield call(fetchSelectiveUsers, [label.created_by, label.updated_by], refetch);

    return label;
  } catch (error) {
    if (error instanceof RedirectError) {
      throw error;
    }

    throw new RedirectError({to: edgeEnabled ? 'landing' : 'labels', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* fetchLabelInstance({id, force = false, usage = true, dispatch = true}) {
  return yield call(apiSaga, 'labels.get_instance', {
    params: {label_id: id},
    query: {usage},
    cache: !force,
    *onDone({data}) {
      if ((force || data !== (yield select(getLabelInstance, data?.href))) && dispatch) {
        yield put({type: 'LABELS_GET_INSTANCE', data});
      }

      return data;
    },
  });
}

export function* updateLabel(id, payload) {
  yield call(apiSaga, 'label.update', {
    params: {label_id: id},
    data: payload,
    *onDone() {
      // Invalidate instance
      cachedResponses.removeByMethodName('labels.get_instance');
      // Invalidate list
      cachedResponses.removeByMethodName('labels.get_collection');
      cachedResponses.removeByMethodName('pairing_profiles.get_collection');
    },
  });
}

export function* deleteLabel() {
  const {id} = yield select(getRouteParams);

  yield call(apiSaga, 'label.delete', {
    params: {label_id: id},
    *onDone() {
      const href = getParameterizedPath({
        path: schemaMethodsMap.get('labels.get_instance').path,
        orgId: yield select(getOrgId),
        params: {label_id: id},
      });

      yield put({type: 'LABELS_REMOVE', data: href});
      // Invalidate cache for list
      cachedResponses.removeByMethodName('labels.get_collection');
      cachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

// Take users from store or make parallel calls to fetch needed users
export function* fetchSelectiveLabels(labelsOrHrefs = [], force = false) {
  const labels = yield select(getLabelsHrefMap);
  const resultlabelsMap = new Map();

  yield all(
    labelsOrHrefs.reduce((result, label) => {
      if (!label) {
        return result;
      }

      if (typeof label.href === 'string' && label.href) {
        if (!force && labels[label.href]) {
          // If label already exists in store, just return it
          resultlabelsMap.set(label.href, labels[label.href]);
        } else {
          // Otherwise fetch this label and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(fetchLabelInstance, {id: hrefUtils.getId(label.href), force});

                const labels = yield select(getLabelsHrefMap);

                resultlabelsMap.set(label.href, labels[label.href] ?? null);
              } catch {
                // A Label in the label's table may not exist even though they exist in object history, that is fine
                resultlabelsMap.set(label.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultlabelsMap;
}
