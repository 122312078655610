/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {StatusIcon, ButtonGroup, Button} from 'components';
import IPListEdit from 'containers/IPList/Item/Edit/IPListEdit';
import ServiceEdit from 'containers/Service/Item/Edit/ServiceEdit';
import {ComboSelect, SingleItemSelect} from 'containers/Selectors';
import {hrefUtils} from 'utils';
import styles from './OutboundPolicy.css';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {networkTypeMap, networkTypes} from 'containers/Network/NetworkUtils';

export const newRuleHref = 'NEW_RULE_HREF';
export const defaultRuleKey = 'DEFAULT_RULE_KEY';
export const allowRulesGridId = 'allowRules';
export const denyRulesGridId = 'denyRules';
export const defaultRulesGridId = 'defaultGrid';

// generate menu item for "Add Allow Rule" & "Add Deny Rule" Button
export const menuItemGenerator = addAllowRule => (
  <div className={styles.menuItemContent}>
    <p className={styles.menuItemText}>{addAllowRule ? intl('Rulesets.AllowRules') : intl('Rulesets.DenyRules')}</p>
    <p className={styles.menuItemSubtext}>
      {addAllowRule ? intl('Rulesets.AllowDescription') : intl('Rulesets.DenyDescription')}
    </p>
  </div>
);

// formats outbound rules 'type' grid column
export const formatType = type => (
  <StatusIcon
    noTextColor
    status={type === 'allow' ? 'check' : 'deny'}
    label={type === 'allow' ? intl('Common.Allow') : intl('Common.Deny')}
  />
);

const sourceObject = {
  groups: {type: 'groups'},
  security_principals: {type: 'security_principals'},
};

export const formatGroups = (row, component) => {
  const {type} = row.data;

  return (
    <ComboSelect
      tid="source"
      alwaysActive
      placeholder={intl('Groups.SelectOneOrMore')}
      objects={Object.values(sourceObject)}
      onSelectionChange={_.partial(component.handleSelectionChange, 'consumers')}
      facets={['name', 'description', 'ip_address', 'hostname', 'os_id']}
      categories={[
        {
          value: intl('Common.Group'),
          categoryKey: 'groups',
          object: sourceObject.groups,
          tooltipProps: {
            appearWhen: 'all_groups',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('Groups.SelectingGroupTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey !== 'all_groups'),
        },
        ...(type === 'allow'
          ? [
              {
                value: intl('Common.UserGroup'),
                categoryKey: 'security_principals',
                object: sourceObject.security_principals,
              },
            ]
          : []),
        {
          value: intl('Common.AllGroups'),
          categoryKey: 'all_groups',
          isOption: true,
          showFacetName: false,
          tooltipProps: {
            appearWhen: 'groups',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('Groups.SelectingAllGroupsTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey !== 'groups'),
        },
      ]}
      initialItems={component.state.rowInEditMode?.consumers ?? []}
    />
  );
};

const filterIPMatches = match => match?.name !== intl('IPLists.Any');
const ipRangeObject = {type: 'ip_lists', pversion: 'draft'};
// formats outbound rules 'ipRanges' grid column
export const formatIPRanges = (row, component) => {
  return (
    <ComboSelect
      tid="ip-range"
      noIcon
      alwaysActive
      placeholder={intl('IPLists.SelectOneOrMore')}
      activeCategoryKey="ip_lists"
      objects={[ipRangeObject]}
      onSelectionChange={_.partial(component.handleSelectionChange, 'providers')}
      initialItems={component.state.rowInEditMode?.providers ?? []}
      filterMatches={filterIPMatches}
      categories={[
        {
          value: intl('Common.IPRange'),
          categoryKey: 'ip_lists',
          object: ipRangeObject,
          tooltipProps: {
            appearWhen: 'all_ips',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('IPLists.SelectingIPListTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey !== 'all_ips'),
        },
        {
          value: intl('IPLists.Any'),
          categoryKey: 'all_ips',
          isOption: true,
          showFacetName: false,
          tooltipProps: {
            appearWhen: 'ip_lists',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('IPLists.SelectingAnyIPListTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey === 'all_ips'),
        },
        {
          value: intl('IPLists.AddNewRange'),
          categoryKey: 'new_ip',
          categoryName: intl('Common.IPRange'),
          pageInvokerProps: {
            container: IPListEdit,
            containerProps: {controlled: true, noFQDN: row.data.type === 'deny'},
            title: intl('IPLists.AddNewRange'),
            unsavedWarningData: getDiscardChangesModalProps('iprange'),
          },
          resource: 'ip_lists',
        },
      ]}
    />
  );
};

const serviceSelectorObject = {type: 'services', pversion: 'draft'};
const filterServiceMatches = match => match?.name !== intl('Common.AllServices');

// formats outbound rules 'service' grid column
export const formatServices = (row, component) => {
  return (
    <ComboSelect
      tid="service"
      alwaysActive
      placeholder={intl('Services.SelectOneOrMore')}
      activeCategoryKey="services"
      objects={[serviceSelectorObject]}
      onSelectionChange={_.partial(component.handleSelectionChange, 'ingress_services')}
      filterMatches={filterServiceMatches}
      statics={{suggestedServices: component.props.suggestedServices}}
      initialItems={component.state.rowInEditMode?.ingress_services ?? []}
      categories={[
        {
          value: intl('Common.Service'),
          object: serviceSelectorObject,
          categoryKey: 'services',
          tooltipProps: {
            appearWhen: 'all_services',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('Services.SelectingServicesTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey !== 'all_services'),
        },
        {
          value: intl('Common.AllServices'),
          categoryKey: 'all_services',
          isOption: true,
          showFacetName: false,
          tooltipProps: {
            appearWhen: 'services',
            right: true,
            instant: true,
            trigger: 'mouseenter focus',
            maxWidth: 'calc(4 * var(--100px))',
            content: intl('Services.SelectingAllServicesTooltip'),
          },
          filterItems: (currentItems, newItems) => newItems.filter(({categoryKey}) => categoryKey === 'all_services'),
        },
        {
          value: intl('Services.AddNew'),
          categoryKey: 'newServices',
          categoryName: 'Services',
          pageInvokerProps: {
            container: ServiceEdit,
            containerProps: {controlled: true, hideOperatingSystems: true},
            title: intl('Services.AddNew'),
            unsavedWarningData: getDiscardChangesModalProps('service'),
          },
          resource: 'services',
        },
        {
          value: intl('Services.AddSuggested'),
          categoryKey: 'suggestedServices',
          categoryName: 'Services',
          resource: 'services',
        },
      ]}
    />
  );
};

// format edit/save/cancel buttons in grid
export const formatButton = (row, component) => {
  if (row.key === defaultRuleKey || row.data.update_type === 'delete' || component.props.policyVersion !== 'draft') {
    return;
  }

  const {type} = row.data;

  if (row.isInEditMode) {
    const handleClick =
      row.key === newRuleHref
        ? _.partial(component.handleSaveCreate, type)
        : _.partial(component.handleSaveUpdate, type);

    let tooltip;

    // consumers can't only have user groups, needs a group
    const onlyContainsUserGroups = component.state.rowInEditMode.consumers?.every(
      ({categoryKey}) => categoryKey === 'security_principals',
    );

    // deleted user groups must be cleared to save
    const containsDeletedUserGroups = component.state.rowInEditMode.consumers?.some(
      ({categoryKey, deleted}) => deleted && categoryKey === 'security_principals',
    );

    // consumers can't only have user groups, needs a group
    if (onlyContainsUserGroups) {
      tooltip = intl('Edge.Group.MustContainGroup');
    }

    // deleted user groups must be cleared to save
    if (containsDeletedUserGroups) {
      tooltip = tooltip
        ? `${tooltip} ${intl('Edge.Group.MustClearDeletedUserGroups')}`
        : intl('Edge.Group.MustClearDeletedUserGroups');
    }

    return (
      <ButtonGroup color="standard" size="small" noFill>
        <Button
          tid="save"
          icon="save"
          onClick={handleClick}
          disabled={component.props.ruleEditor.isSaveDisabled}
          tooltip={tooltip}
        />
        <Button tid="cancel" icon="cancel" onClick={component.handleRuleEditorCancel} />
      </ButtonGroup>
    );
  }

  return (
    <Button
      tid="edit"
      icon="edit"
      color="standard"
      size="small"
      noFill
      onClick={_.partial(component.handleEditRule, row, type)}
    />
  );
};

export const formatNetworkProfile = (row, component) => {
  return (
    <SingleItemSelect
      tid="network-profile"
      placeholder={intl('Policy.SelectNetworkProfile')}
      categories={[{value: intl('Policy.SelectNetworkProfile'), categoryKey: 'networkType'}]}
      statics={{
        networkType: networkTypes,
      }}
      onSelectionChange={_.partial(component.handleSelectionChange, 'network_type')}
      initialItems={component.state.rowInEditMode?.network_type}
    />
  );
};

// formats edge groups & userGroups so it can be passed to <Selector/> as initialItems prop
// consuming_security_principals needs a default arg, since it doesn't exist in deny rules
const formatConsumers = (consumers = [], consuming_security_principals = []) => [
  ...consumers.map(consumer => {
    const {href, value: name, actors} = consumer?.label ?? consumer;

    // {actors: 'ams'} means all groups
    return actors
      ? {
          categoryKey: 'all_groups',
          categoryName: intl('Common.AllGroups'),
          value: intl('Common.AllGroups'),
        }
      : {
          name,
          label: {href},
          categoryKey: 'groups',
          categoryName: intl('Common.Group'),
        };
  }),
  ...consuming_security_principals.map(userGroup => ({
    ...userGroup,
    categoryKey: 'security_principals',
    categoryName: intl('Common.UserGroups'),
  })),
];

// formats IPs so it can be passed to <Selector/> as initialItems prop
const formatProviders = (providers = []) =>
  providers.map(({ip_list: {href, name}}) => ({
    href,
    name,
    categoryKey: name === intl('IPLists.Any') ? 'all_ips' : 'ip_lists',
    categoryName: name === intl('IPLists.Any') ? null : intl('Common.IPRange'),
  }));

// formats services so it can be passed to <Selector/> as initialItems prop
const formatIngressServices = (services = []) =>
  services.map(({href, name}) => ({
    href,
    name,
    categoryKey: name === intl('Common.AllServices') ? 'all_services' : 'services',
    categoryName: name === intl('Common.AllServices') ? null : intl('Common.Services'),
  }));

// formats data in to a selector ready format, which we will pass as 'initialItems' prop
const formatNetworkType = type => [{value: networkTypeMap.get(type), categoryKey: 'networkType'}];

// formats data in to a selector ready format, which we will pass as 'initialItems' prop
export const formatInitialItems = ({
  consumers,
  consuming_security_principals,
  providers,
  ingress_services,
  network_type,
}) => ({
  consumers: formatConsumers(consumers, consuming_security_principals),
  providers: formatProviders(providers),
  ingress_services: formatIngressServices(ingress_services),
  network_type: formatNetworkType(network_type),
});

// iterate over initial items, and return an object of sets containing hrefs of the initial items
// constant time lookup, so we can quickly compute if the rule has changed and whether we should enable/disable save button
export const getInitialItemsHrefs = (consumers, userGroups = [], providers, services, networkType) => ({
  consumerHrefs: consumers.reduce(
    (accum, {label}) => (label?.href ? accum.add(label.href) : accum.add('all_groups')),
    new Set(),
  ),
  userGroupHrefs: userGroups.reduce((accum, {href}) => accum.add(href), new Set()),
  providerHrefs: providers.reduce((accum, {ip_list: {href}}) => accum.add(href), new Set()),
  serviceHrefs: services.reduce((accum, {href}) => accum.add(href), new Set()),
  initialNetworkType: networkTypeMap.get(networkType),
});

// href is needed for updation, but not creation
export const formatPayload = (
  {consumers, providers, ingress_services, network_type, href},
  {includeHref, includeUserGroups, allIPsHref, allServicesHref} = {},
) => {
  let payloadContainsUserGroups = false;

  const payload = {
    consumers: consumers.reduce((accum, consumer) => {
      if (consumer.categoryKey === 'security_principals') {
        payloadContainsUserGroups = true;

        // user groups maps to consuming_security_principals property
        return accum;
      }

      if (consumer.categoryKey === 'all_groups') {
        accum.push({actors: 'ams'});
      } else {
        const {label, href} = consumer;

        accum.push({label: {href: label?.href ?? href}});
      }

      return accum;
    }, []),
    providers: providers.map(({href, ip_list: ipObj}) => ({
      ip_list: {href: ipObj?.href ?? href ?? allIPsHref},
    })),
    ingress_services: ingress_services.map(({href}) => ({href: href ?? allServicesHref})),
    network_type: networkTypeMap.get(network_type[0].value),
    ...(includeHref ? {href} : {}),
  };

  if (includeUserGroups) {
    payload.consuming_security_principals = !payloadContainsUserGroups
      ? []
      : consumers.reduce((accum, {categoryKey, href}) => {
          if (categoryKey !== 'security_principals') {
            return accum;
          }

          accum.push({href});

          return accum;
        }, []);
  }

  return payload;
};

export const getDiffData = ({action, rule, prevPversionRules = []}) => {
  const oldRule =
    rule.update_type === 'update' &&
    prevPversionRules.find(({href}) => hrefUtils.getId(href) === hrefUtils.getId(rule.href));

  return {
    ...rule,
    action,
    rule,
    ...(oldRule && {oldRule}),
  };
};

const updateChangeSubset = (subset, href) => {
  subset ??= [];

  if (subset.some(({href: key}) => key === href)) {
    return subset;
  }

  subset.push({href});

  return subset;
};

export const getDraftObjects = (allowRules = [], denyRules = [], outboundAllowRulesetHref) => {
  const objectsToProvision = {};
  const objectsToRevert = {};

  [...allowRules, ...denyRules].forEach(rule => {
    // Populate outbound rules in change subsets, allow rules are added as rule_sets and deny rules as enforcement_boundaries
    if (rule.update_type) {
      if (rule.rule_set) {
        // It is an allow rule, since allow rules includes rule_set obj prop
        // It is a modified allow rule, add rule_sets changeSet if it is not included already
        if (!objectsToProvision.rule_sets) {
          objectsToProvision.rule_sets = [{href: outboundAllowRulesetHref}];
          objectsToRevert.rule_sets = [{href: outboundAllowRulesetHref}];
        }
      } else {
        objectsToProvision.enforcement_boundaries = updateChangeSubset(
          objectsToProvision.enforcement_boundaries,
          rule.href,
        );
        objectsToRevert.enforcement_boundaries = updateChangeSubset(objectsToRevert.enforcement_boundaries, rule.href);
      }
    }

    // Populate dependencies only for provision sets, dependencies cannot be reverted as a part of Outbound rules revert
    // Populate Service dependencies in change subset
    rule.ingress_services
      ?.filter(({update_type}) => update_type === 'update')
      .forEach(({href}) => {
        objectsToProvision.services = updateChangeSubset(objectsToProvision.services, href);
      });
    // Populate IP Lists dependencies in change subset
    rule.providers
      ?.flatMap(provider => provider.ip_list ?? [])
      .filter(({update_type}) => update_type === 'update')
      .forEach(({href}) => {
        objectsToProvision.ip_lists = updateChangeSubset(objectsToProvision.ip_lists, href);
      });
  });

  return {objectsToProvision, objectsToRevert};
};
