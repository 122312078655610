/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Component} from 'react';
import {Checkbox, Icon} from 'components';
import {formatDate} from './GridUtils';
import styles from './Grid.css';
import stylesUtils from 'utils.css';

export default class GridCellBody extends Component {
  constructor(props) {
    super(props);

    const {
      props: {
        row,
        cell: {id},
      },
    } = this;

    const cell = row.cells.get(id);

    if (cell.column?.templates?.length) {
      this.setState = this.setState.bind(this);
      this.state = {};
    }

    const refs =
      typeof props.cell.refs === 'function'
        ? props.cell.refs({
            value: cell.value,
            row,
            column: cell.column,
          })
        : props.cell.refs;

    // If object of refs functions is passed, create object with wrapper functions and object to store elements of refs result
    if (refs) {
      this.contentRefs = {};
      this.contentElements = {};

      for (const refName in refs) {
        if (refs.hasOwnProperty(refName)) {
          this.contentRefs[refName] = element => {
            // The second parameter:
            // ```
            // {
            //  elements: this.contentElements[refName],
            // }
            // ```
            // allow access to the accumulated value, allowing usage similar to `Array.reduce`
            this.contentElements[refName] = refs[refName](element, {
              elements: this.contentElements[refName],
            });
          };
        }
      }
    }

    this.saveRef = this.saveRef.bind(this);
  }

  saveRef(element) {
    this.props.saveCellRef(element, this);
  }

  render() {
    const {
      props,
      props: {row, error, warning, info, loading, theme, extraProps = {}, column},
    } = this;
    const {id, tid} = props.cell;

    if (id === 'checkboxes') {
      return (
        <Checkbox
          key={id}
          checked={props.selected}
          disabled={row.disableCheckbox || !row.selectable}
          hidden={!row.selectable}
          onChange={props.onSelect}
          theme={theme}
          themePrefix="checkboxCell-"
          data-tid={tid}
          inputProps={{'data-tid': 'elem-input'}}
          tooltip={row.checkboxTooltip}
          tooltipProps={row.tooltipProps}
        />
      );
    }

    if (id === 'dragbar') {
      return (
        <div className={styles.grab} ref={this.saveRef}>
          <Icon name="grab-handle" theme={theme} themePrefix="grab-" />
        </div>
      );
    }

    const cell = row.cells.get(id);
    const isSpanZero = column.span && Number(column.span[0]) === 0 && Number(column.span[1]) === 0;
    const span = extraProps.span ?? row.span;
    let value = !isSpanZero && (span?.[cell.key] === true || !column.span || column.size !== '0px') ? cell.value : null;

    if (cell.column.isDate && value !== null) {
      value = formatDate(value, typeof cell.column.isDate === 'string' ? cell.column.isDate : undefined);
    }

    const subColumnsContent = cell.column.templates?.map(subColumnId => {
      const subColumn = props.grid.columns.get(`${cell.column.id}-${subColumnId}`);

      return (
        <GridCellBody
          parentState={this.state}
          setParentState={this.setState}
          key={subColumn.id || subColumn}
          {...this.props}
          cell={subColumn}
        />
      );
    });

    if (typeof cell.column.format === 'function') {
      value = cell.column.format.call(props.component, {
        state: this.state,
        setState: this.setState,
        parentState: props.parentState,
        setParentState: props.setParentState,
        value,
        row,
        column: cell.column,
        refs: this.contentRefs,
        content: subColumnsContent,
        breakpoint: props.breakpoint.meta,
        selected: props.selected,
        component: props.component,
        error,
        warning,
        info,
        loading,
      });
    } else if (cell.column.format !== undefined) {
      value = cell.column.format;
    } else if (subColumnsContent) {
      value = subColumnsContent;
    }

    // Skip cell if it doesn't contain value unless styling is desired on cell, to make positioning fluid
    // That was one of the reason why we couldn't use pure css grid
    // https://github.com/w3c/csswg-drafts/issues/1183
    if ((value === undefined || value === null || value === false || cell.column?.hidden) && !theme[id]) {
      return null;
    }

    let isLinky;

    if (typeof props.cell.linky === 'function') {
      isLinky = props.cell.linky.call(props.component, {
        value: cell.value,
        row,
        column: cell.column,
        refs: this.contentRefs,
        breakpoint: props.breakpoint.meta,
        selected: props.selected,
        component: props.component,
      });
    } else {
      isLinky = props.cell.linky;
    }

    let ref = null;

    // If cell contains click/mouseOver handlers, save ref to it to pass its element to that handlers
    if (
      props.cell.onMouseOver === false ||
      typeof props.cell.onMouseOver === 'function' ||
      props.cell.onClick === false ||
      typeof props.cell.onClick === 'function'
    ) {
      ref = this.saveRef;
    }

    const className = cx(theme.cell, theme[id], props.column.extraCellClasses?.[id], {
      [theme.linky]: isLinky,
      [theme.cellPadding]: !cell.column.noPadding,
      [stylesUtils.gapXSmall]: subColumnsContent,
      [stylesUtils.gapHorizontalWrap]: subColumnsContent && cell.column.horizontal,
    });

    return (
      <div key={id} data-tid={tid} className={className} ref={ref}>
        {value}
      </div>
    );
  }
}
