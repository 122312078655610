/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Pill} from 'components';
import {formatSelectedLabel} from 'containers/Selector/Presets';
import {sortOptions} from 'containers/Selector/SelectorUtils';
import intl from 'intl';
import _ from 'lodash';

export const getMembers = (pversionObj, prevPversionObj, policyVersion) => {
  if (!pversionObj || !policyVersion) {
    return null;
  }

  let members = [];

  if (pversionObj.sub_groups && pversionObj.labels) {
    members = _.union([...pversionObj.sub_groups], [...pversionObj.labels]);
  }

  if (prevPversionObj) {
    let prevPversionMembers = [];

    if (prevPversionObj && prevPversionObj.sub_groups && prevPversionObj.labels) {
      prevPversionMembers = _.union([...prevPversionObj.sub_groups], [...prevPversionObj.labels]);
    }

    const pversionMembersHrefs = _.map(members, mem => mem.href);
    const prevPversionMembersHrefs = _.map(prevPversionMembers, mem => mem.href);

    // Adding 'create' update_type to new members and 'delete' update type to deleted members
    // New Members added to the label group are present in draftHrefs but not in the activeHrefs.
    const newHref = {};

    pversionMembersHrefs.forEach(href => {
      if (
        (!prevPversionMembersHrefs.includes(href) && !href.includes('label_groups')) ||
        (href.includes('label_groups') && !prevPversionMembersHrefs.includes(href.replace('draft', 'active')))
      ) {
        newHref[href] = href;
      }
    });

    members = members.map(member => ({...member, update_type: newHref[member.href] ? 'create' : null}));

    // Members deleted from the label group are present in activeHrefs but not in draftHrefs.
    const deletedHref = {};

    prevPversionMembersHrefs.forEach(href => {
      if (
        (!pversionMembersHrefs.includes(href) && !href.includes('label_groups')) ||
        (href.includes('label_groups') && !pversionMembersHrefs.includes(href.replace('active', 'draft')))
      ) {
        deletedHref[href] = href;
      }
    });

    prevPversionMembers.forEach(activeMember => {
      if (deletedHref[activeMember.href]) {
        activeMember.update_type = policyVersion === 'draft' ? 'delete' : null;
        members.push(activeMember);
      }
    });

    if (policyVersion === 'active') {
      members = members.filter(member => member.update_type !== 'create');
    }
  }

  return members;
};

export const staticsFunction = elements => {
  const statics = _.groupBy(elements, element => {
    if (element.href.indexOf('label_group') > 0) {
      return 'label_groups';
    }

    return 'labels';
  });

  statics.label_groups ||= [];

  statics.labels ||= [];

  return statics;
};

export const handleSagaError = err => {
  const token = _.get(err, 'data[0].token');
  const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(err, 'data[0].message', err.message);

  return message;
};

export const labelGroupFilterCategories = list => {
  return [
    {
      id: 'labelsAndLabelGroups',
      name: intl('Rulesets.Rules.LabelAndLabelGroups'),
      resources: {
        labelsAndLabelGroups: {
          statics: ({query}) => {
            const options = list.map(item => ({
              href: item.href,
              value: item.value || item.name,
              key: item.type,
            }));

            return sortOptions(options, query);
          },
          optionProps: {
            format: ({option}) => (
              <Pill.Label type={option.key} group={option.href.includes('label_groups')} noContextualMenu>
                {option.value}
              </Pill.Label>
            ),
            allowMultipleSelection: true,
          },
          selectedProps: {
            formatValue: formatSelectedLabel,
            valueJoiner: 'or',
            joinerIsPill: false,
          },
        },
      },
    },
  ];
};
