/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {createSelector} from 'reselect';
import {Link, Pill, ExpandableLabelList, StatusIcon, Tooltip, Icon} from 'components';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {reverseProviderConsumer} from 'containers/App/AppState';
import {getProtectionSchemaIcon} from './ServersLabelingUtils';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {getRuleOptionsPills} from 'containers/Ruleset/Item/RulesetItemUtils';
import {venStatuses} from 'containers/Ven/VenUtils';
import styles from './ServerLabeling.css';
import antmanStyles from 'antman/containers/styles.css';
import stylesUtils from 'utils.css';

export const serverLabelingListGridSettings = createSelector([getLabelsColumn], labelsColumn => ({
  id: 'serverLabelingList',
  columns: {
    itemNumber: {
      header: intl('Common.NumberSign'),
      value: ({row}) => row.data.itemNumber,
    },
    hostname: {
      header: intl('Common.Hostname'),
      value: ({row}) => row.data.hostname,
    },
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.data.status.status,
      format: ({value}) => venStatuses()[value],
      sortable: false,
      tid: 'comp-grid-column-state',
    },
    protectionSchema: {
      header: intl('Antman.Servers.Labeling.ProtectionSchema'),
      format: ({row: {data}, component: {handleProtectionSchemaModalToggle}}) => {
        const {priorityServerRole, selectedProtectionSchema, href, recommendedPolicyName} = data;

        const uiValue = recommendedPolicyName ?? selectedProtectionSchema;
        let suffix = '';
        let whyText = '';

        if (selectedProtectionSchema === priorityServerRole) {
          suffix = intl('Antman.Servers.Labeling.Recommended');
          whyText = intl('Antman.Servers.Labeling.Why');
        } else if (priorityServerRole && selectedProtectionSchema === 'None') {
          suffix = intl('Antman.Servers.Labeling.Manual');
        }

        return (
          <div className={cx(stylesUtils.gapMedium, stylesUtils.gapHorizontal, stylesUtils.centerFlexAlign)}>
            <div className={styles.gridSchemaIcon}>
              <Icon name={getProtectionSchemaIcon(selectedProtectionSchema)} />
            </div>
            <div>
              <span>{`${uiValue} ${suffix}`.trim()}</span>
              <div className={cx(stylesUtils.gapHorizontalWrap, stylesUtils.gapMedium)}>
                <Link
                  theme={{link: antmanStyles.link}}
                  onClick={_.partial(handleProtectionSchemaModalToggle, {
                    href,
                    priorityServerRole,
                    selectedProtectionSchema,
                    recommendedPolicyName,
                  })}
                >
                  {intl('Common.Change')}
                </Link>
                {whyText ? (
                  <Tooltip content={intl('Antman.Servers.Labeling.WhyTooltip', {protection: uiValue})} instant>
                    <span className={antmanStyles.link}>{whyText}</span>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
        );
      },
    },
    labels: {
      ...labelsColumn,
      header: intl('Common.Labels'),
      sortable: false,
      format: ({
        row: {
          data: {labelsMap},
        },
      }) => {
        if (!labelsMap.size) {
          return;
        }

        return (
          <ExpandableLabelList values={Array.from(labelsMap.values())}>
            {label => (
              <div key={label.value}>
                <Pill.Label {...label} type={label.key}>
                  {label.value}
                </Pill.Label>
              </div>
            )}
          </ExpandableLabelList>
        );
      },
    },
    policies: {
      header: intl('Common.Policies'),
      value: ({row}) => intl('Antman.Servers.RulesThatApplyDescription', {count: row.data.serverPolicy?.count}),
      format: ({
        value,
        row: {
          data: {serverPolicy, selectedProtectionSchema},
        },
        component: {handleShowRulePreviewClick},
      }) => {
        return serverPolicy?.count > 0 && selectedProtectionSchema !== 'None' ? (
          <Link
            onClick={_.partial(handleShowRulePreviewClick, serverPolicy.rules)}
            theme={{link: antmanStyles.link}}
            tid="rules-summary"
          >
            {value}
          </Link>
        ) : null;
      },
      sortable: false,
    },
  },
  templates: [
    {
      template() {
        return [
          {columns: ['itemNumber'], size: 'max-content'},
          {columns: ['status'], size: 'max-content'},
          {columns: ['hostname'], size: 'minmax(var(--100px), 1fr)'},
          {columns: ['protectionSchema'], size: '3fr'},
          {columns: ['labels'], size: 'minmax(max-content, 1fr)'},
          {columns: ['policies'], size: '2fr'},
        ];
      },
    },
  ],
}));

export const rulePreviewGridSettings = createSelector(reverseProviderConsumer, reverseProviderConsumer => ({
  id: 'rulePreviewGrid',
  columns: {
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.enabled,
      format: ({value: enabled}) => (
        <StatusIcon
          position="before"
          status={enabled ? 'inuse' : 'disabled-status'}
          label={<span className={stylesUtils.bold}>{enabled ? intl('Common.Enabled') : intl('Common.Disabled')}</span>}
        />
      ),
      sortable: false,
      tid: 'comp-grid-column-state',
    },
    providers: {
      header: intl('Common.Destinations'),
      value: ({row}) => row,
      format: ({value}) => {
        return (
          <div className={styles.pillGap}>
            <Pill.Endpoint value={value} type="providers" />
          </div>
        );
      },
      sortable: false,
    },
    ingress_services: {
      header: intl('Rulesets.Rules.DestinationServices'),
      value: ({row}) => row,
      format: ({value: {ingress_services, sec_connect}}) => {
        const pills = ingress_services.map((service, index) => (
          <Pill.ServiceDiff insensitive value={service} key={index} showPorts="all" />
        ));

        if (sec_connect) {
          pills.push(
            <Pill.Diff
              key="sec_connect"
              value={[
                {
                  key: intl('Common.SecureConnect'),
                  pill: <Pill icon="secure-connect">{intl('Common.SecureConnect')}</Pill>,
                },
              ]}
            />,
          );
        }

        return <div className={styles.pillGap}>{pills}</div>;
      },
      sortable: false,
    },
    arrow: {
      value: null,
    },
    consumers: {
      header: intl('Common.Sources'),
      value: ({row}) => row,
      format: ({value}) => {
        return (
          <div className={styles.pillGap}>
            <Pill.Endpoint value={value} type="consumers" />
          </div>
        );
      },
      sortable: false,
    },
    ruleOptions: {
      header: intl('Rulesets.Rules.RuleOptions'),
      value: ({row}) => row,
      format: ({value}) => <Pill.Diff noDiff value={getRuleOptionsPills(value)} />,
      sortable: false,
    },
  },
  templates: [
    {
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = [{columns: ['consumers'], size: '1fr'}];
        const providers = [
          {columns: ['providers'], size: '1fr'},
          {columns: ['ingress_services'], size: '1fr'},
        ];

        return [
          {columns: ['status'], size: 'calc(11 * var(--10px))'},
          ...(reverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(reverseProviderConsumer ? providers : consumers),
          {columns: ['ruleOptions'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 760,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = [{columns: ['consumers'], size: 'minmax(150px, 1fr)'}];
        const providers = [{columns: ['providers', 'ingress_services'], size: 'minmax(150px, 1fr)'}];

        return [
          {columns: ['status'], size: 'calc(11 * var(--10px))'},
          ...(reverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(reverseProviderConsumer ? providers : consumers),
          {columns: ['ruleOptions'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 540,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = ['consumers'];
        const providers = ['providers', 'ingress_services'];

        return [
          {
            columns: [
              'status',
              ...(reverseProviderConsumer ? consumers : providers),
              'arrow',
              ...(reverseProviderConsumer ? providers : consumers),
              'ruleOptions',
            ],
            size: 'auto',
          },
        ];
      },
    },
  ],
}));
