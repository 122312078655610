/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useState, useCallback, createElement, useContext, useEffect, useRef, useMemo} from 'react';
import * as PropTypes from 'prop-types';
import {Link, Spinner} from 'components';
import {shallowEqualLooseByProps} from 'utils/general';
import {getColSpanData} from 'components/Grid/GridUtils';
import Area from './GridAreaBody';
import {AppContext} from 'containers/App/AppUtils';
import styles from 'components/Grid/Grid.css';
import stylesManager from 'components/Grid/Manager/GridManager.css';
import {preventEvent, scrollToElement} from 'utils/dom';
import {KEY_DOWN, KEY_ESCAPE, KEY_RETURN, KEY_SPACE, KEY_UP} from 'keycode-js';
import {useDrag, useDrop} from 'react-dnd';

GridRow.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  grid: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  extraProps: PropTypes.object,
  selected: PropTypes.bool,
  dontHighlightSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  colSpanData: PropTypes.object,
};

export default function GridRow(props) {
  const {
    grid,
    row,
    selected,
    dontHighlightSelected,
    theme,
    component,
    onClick,
    onReorder,
    extraProps: {
      error = row.error ?? false,
      warning = row.warning ?? false,
      info = row.info ?? false,
      loading = row.loading ?? false,
      ...extraProps
    } = {},
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    onSelect,
    index,
    id,
  } = props;
  let areasProps;
  const context = useContext(AppContext);
  const cellsToCheckFocus = useRef([]);
  const focuser = useRef(null);
  const loader = useRef(null);
  const [focused, setFocus] = useState(false);
  const [prevState, setPrevState] = useState({span: props.row.span, breakpoint: props.breakpoint});

  const [spanState, setSpan] = useState(extraProps?.span ?? row.span);
  const [breakpointState, setBreakPoint] = useState(props.breakpoint);

  const prevPropSpanRef = useRef(extraProps?.span ?? row.span);
  const prevPropBreakpointRef = useRef(props.breakpoint);

  const spanOrBreakpointChanged =
    prevPropBreakpointRef.current !== (extraProps?.span ?? row.span) || prevPropSpanRef.current !== props.breakpoint;

  const breakpoint = spanOrBreakpointChanged ? props.breakpoint : breakpointState;
  const span = spanOrBreakpointChanged ? extraProps?.span ?? row.span : spanState;

  const {flattenedIndices, spanColumnIndices} = useMemo(() => getColSpanData(span, breakpoint), [breakpoint, span]);

  useEffect(() => {
    if (spanOrBreakpointChanged) {
      prevPropSpanRef.current = span;
      setSpan(span);

      prevPropBreakpointRef.current = span;
      setBreakPoint(breakpoint);
    }
  }, [spanOrBreakpointChanged, breakpoint, span]);

  const rowRef = useRef(null);
  const dragbarRef = useRef(null);
  const [{handlerId}, drop] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragbarRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragbarRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onReorder(dragIndex, hoverIndex, grid.rows);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: 'row',
    item: () => {
      return {id, index};
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  drag(drop(dragbarRef));

  const saveRowRef = useCallback(
    element => {
      if (element) {
        rowRef.current = props.row.link ? element.element : element;
      } else {
        rowRef.current = element;
      }
    },
    [props],
  );

  const saveCellRef = useCallback((element, instance) => {
    if (instance.props.cell.id === 'dragbar') {
      dragbarRef.current = element;
    }

    if (element) {
      cellsToCheckFocus.current.push({element, instance});
    } else {
      cellsToCheckFocus.current = cellsToCheckFocus.current.filter(cell => cell.instance !== instance);
    }
  }, []);

  const saveFocuserRef = useCallback(focuserelement => {
    focuser.current = focuserelement;
  }, []);

  const updateLoader = useCallback(() => {
    if (loader.current) {
      // Loader inherits height of the focuser, but we still need to take width from the table (row is dispaly:contents so its width is 0)
      loader.current.style.width = loader.current.closest(`.${styles.table}`).getBoundingClientRect().width + 'px';
    }
  }, []);

  const saveLoaderRef = useCallback(
    loaderElement => {
      loader.current = loaderElement;

      // Until we use subgrid (only Firefox supports it as of now) we need to compute width of the loader based on the table width
      if (loaderElement) {
        updateLoader();

        window.addEventListener('resize', updateLoader);

        // Until we have the 'inert' html attribute, we need to at least remove focus from within of the row.
        // (Existing inert polyfill is very espensive https://github.com/WICG/inert)
        if (document.activeElement && rowRef.current?.contains(document.activeElement)) {
          document.activeElement.blur();
        }
      } else {
        window.removeEventListener('resize', updateLoader);
      }
    },
    [updateLoader],
  );

  const focusElement = useCallback((element, scroll = false) => {
    element.focus({preventScroll: true});

    if (scroll) {
      // Correctly compute scroll offset by taking sticky GridManager and GridHead into account
      const offsetElements = [
        // Get the GridManager element is exists
        rowRef.current?.parentElement.parentElement.querySelector(`.${stylesManager.manager}`),
        // Need to get first elements in the head, since the header is `display: contents` until it uses the subgrid
        rowRef.current?.parentElement.querySelector(`.${styles.rowHead.split(' ')[0]}`)?.querySelector(':first-child'),
      ].filter(Boolean);

      scrollToElement({element, offsetElements});
    }
  }, []);

  const handleFocus = useCallback(
    evt => {
      // Highlight row on focuser focus
      setFocus(true);
      onFocus?.(evt);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    evt => {
      // Unhighlight row on focuser blur
      setFocus(false);
      onBlur?.(evt, this);
    },
    [onBlur],
  );

  const handleSelect = useCallback(
    (evt, checking, pressedKeys) => {
      if (onSelect) {
        onSelect(evt, row, pressedKeys);
      }
    },
    [onSelect, row],
  );

  const focusNextClickableRow = useCallback(
    upwards => {
      const clickableRowClass = styles.rowBodyClickable;
      const currentRow = rowRef.current;
      let nextRow = currentRow;

      if (upwards) {
        do {
          nextRow = nextRow.previousElementSibling;
        } while (nextRow && !nextRow.classList.contains(clickableRowClass));

        // If current row is the first one, nextRow will be null, then move focus to the last row
        nextRow ??= [...currentRow.parentElement.querySelectorAll(`.${clickableRowClass}`)].lastItem;
      } else {
        do {
          nextRow = nextRow.nextElementSibling;
        } while (nextRow && !nextRow.classList.contains(clickableRowClass));

        // If current row is the last one, nextRow will be null, then move focus to the first row
        nextRow ??= currentRow.parentElement.querySelector(`.${clickableRowClass}`);
      }

      // Exit if could not find the other row
      if (!nextRow || nextRow === currentRow) {
        return;
      }

      const focuser = nextRow.querySelector(`.${styles.focuser}`);

      if (focuser) {
        focusElement(focuser, true);
      }
    },
    [focusElement],
  );

  const handleKeyDown = useCallback(
    evt => {
      const {keyCode} = evt;

      if (keyCode === KEY_ESCAPE && document.activeElement === focuser.current) {
        // If row is focused, blur it and it will remove focus state
        preventEvent(evt);
        focuser.current.blur();
      } else if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
        // Focus previous row on up arrow or next one on down arrow
        preventEvent(evt);
        focusNextClickableRow(keyCode === KEY_UP);
      } else if (keyCode === KEY_SPACE || keyCode === KEY_RETURN) {
        // Invoke row click on Space/Enter
        preventEvent(evt);
        onClick?.(evt, row);
      }
    },
    [onClick, focusNextClickableRow, row],
  );

  const handleClick = useCallback(
    evt => {
      // prevent row click when user is selecting text
      const didUserSelectText = !evt.shiftKey && Boolean(window.getSelection().toString());

      // Prevent row click action if cell or its content not focusable or not clickable (if onMouseOver/onClick handlers contain/return false)
      const preventRowClick =
        didUserSelectText ||
        !rowRef.current?.contains(evt.target) ||
        cellsToCheckFocus.current.some(({instance, element}) => {
          if (element.contains(evt.target)) {
            const {
              props: {cell, row},
              contentElements,
            } = instance;
            const params = {evt, row, elements: contentElements, store: context.store};

            // Prevent click on row if onMouseOver or onClick returns false
            // onClick still can return true to override false from onMouseOver if row click should happen
            let prevent;

            if (cell.onClick === true) {
              prevent = false;
            } else if (cell.onClick === false) {
              prevent = true;
            } else if (typeof cell.onClick === 'function') {
              const onClickResult = cell.onClick(params);

              if (onClickResult === true) {
                prevent = false;
              } else if (onClickResult === false) {
                prevent = true;
              }
            }

            if (prevent === undefined) {
              prevent =
                cell.onMouseOver === false ||
                (typeof cell.onMouseOver === 'function' && cell.onMouseOver(params) === false);
            }

            return prevent;
          }

          return false;
        });

      if (preventRowClick) {
        evt.stopPropagation();
      } else if (onClick) {
        onClick(evt, row);
      }
    },
    [onClick, context.store, row],
  );

  const handleMouseOver = useCallback(
    evt => {
      // Prevent row focus if cell or its content not focusable (if onMouseOver handler contains/returns false)
      const preventRowFocus =
        !rowRef.current?.contains(evt.target) ||
        cellsToCheckFocus.current.some(({instance, element}) => {
          if (element.contains(evt.target)) {
            const {
              props: {cell, row},
              contentElements,
            } = instance;

            return (
              cell.onMouseOver === false ||
              (typeof cell.onMouseOver === 'function' &&
                cell.onMouseOver({evt, row, elements: contentElements}) === false)
            );
          }

          return false;
        });

      if (
        !preventRowFocus &&
        document.activeElement !== focuser.current &&
        (!document.activeElement ||
          document.activeElement === document.body ||
          document.activeElement.classList.contains(styles.focuser))
      ) {
        // If user hovers this row while nothing else is focused, or the other row is focused, focus this row instead
        focusElement(focuser.current);
      } else {
        // Otherwise, just toggle the visible focus highlight
        setFocus(!preventRowFocus);
      }

      onMouseOver?.(evt, row);
    },
    [onMouseOver, row, focusElement],
  );

  useEffect(() => updateLoader(), [updateLoader]);

  const handleMouseLeave = useCallback(
    evt => {
      setFocus(false);

      if (document.activeElement === focuser.current) {
        focuser.current.blur();
      }

      onMouseLeave?.(evt, row);
    },
    [onMouseLeave, row],
  );

  const newSpan = extraProps?.span ?? row.span;

  if (newSpan !== prevState.span || props.breakpoint !== prevState.breakpoint) {
    setSpan(newSpan);
    setBreakPoint(props.breakpoint);
    setPrevState({span: extraProps?.span ?? row.span, breakpoint: props.breakpoint});
  }

  // Each row can contain `clickable` prop to control if it can be clicked. Otherwise, onClick prop on Grid will be checked.
  const clickable = row.clickable ?? Boolean(onClick || row.link);
  const className = cx(theme.rowBody, {
    [theme.focused]: focused,
    [theme.rowBodyInsensitive]: loading,
    [theme.rowBodyClickable]: clickable,
    [theme.rowBodySelected]: selected && !dontHighlightSelected,
  });
  const style = {};

  let elementType;
  const elementProps = {
    className,
    'style': {...style, opacity},
    'ref': saveRowRef,
    'data-tid': 'comp-grid-row',
    'data-handler-id': handlerId,
  };

  if (clickable) {
    elementProps.onClick = handleClick;
    elementProps.onMouseOver = handleMouseOver;
    elementProps.onMouseLeave = handleMouseLeave;
  }

  if (Object.keys(extraProps).length) {
    Object.assign(elementProps, extraProps, {className: cx(className, extraProps.className)});
  }

  if (row.link) {
    elementType = Link;
    Object.assign(elementProps, {theme: {link: className}}, typeof row.link === 'string' ? {to: row.link} : row.link);
  } else {
    elementType = 'div';
  }

  const newAreasProps = {
    grid,
    row,
    theme,
    selected,
    breakpoint,
    component,
    error,
    warning,
    info,
    loading,
    extraProps,
  };

  let children;

  if (!areasProps || !shallowEqualLooseByProps(areasProps, newAreasProps, Object.keys(newAreasProps))) {
    children = [
      // First column contains invisible empty div to receive focus, which is reachable only if row is clickable
      <div
        key="focuser"
        className={theme.focuser}
        ref={saveFocuserRef}
        {...(clickable && {
          tabIndex: '0',
          onFocus: handleFocus,
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
        })}
      >
        {loading && ( // In case loading is happening, put absolute positioned div that will overlay the row
          <div key="loader" className={theme.loader} ref={saveLoaderRef}>
            <Spinner theme={theme} themePrefix="loader-" />
          </div>
        )}
      </div>,
    ];

    for (const [index, breakpointColumn] of breakpoint.columns.entries()) {
      const spanColumn = spanColumnIndices.find(spanColumn =>
        breakpointColumn.cells?.some(cell => cell.id === spanColumn.id),
      );

      if (!flattenedIndices.has(index) || !span) {
        children.push(
          <Area
            {...newAreasProps}
            spanColumn={spanColumn}
            column={breakpointColumn}
            onSelect={handleSelect}
            saveCellRef={saveCellRef}
          />,
        );
      }
    }

    areasProps = newAreasProps;
  }

  return createElement(elementType, elementProps, ...children);
}
