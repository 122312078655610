/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, select, put} from 'redux-saga/effects';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {gridSettings} from 'containers/Ven/List/VenListConfig';
import {getUrlScopeValue, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import * as venUpgradeState from './VenUpgradeState';
import {getFilterMap} from 'containers/Ven/List/VenListState';

function* venUpgradeSaga(options = {}) {
  const {
    settings = {},
    filterMap,
    route: {params},
    onSaga,
    // onRedirect,
  } = options;
  const filter = yield select(getUrlFilterParam, {settings, filterMap});
  const effects = {};

  if (onSaga) {
    effects.listLength = call(onSaga, {settings, filterParams: filter, params});
  }

  // Now wait for onSaga and optionally user grid settings
  yield all(effects);
}

export function* fetchVens({filter, force = false} = {}) {
  const query = {
    representation: 'ven_upgrades', // we only need href. this is the compact representation.
    max_results: 25_000, // Ven Upgrade is by design not limited to 500 items.
  };

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      // filter value is the first element of the array since most of the filters in workloads list page are single select
      const value = values[0];

      switch (name) {
        case 'name':
        case 'hostname':
          const queryValue = typeof value === 'object' ? value.value : value;

          query[queryValue.includes('*') ? `advanced_search_${name}` : name] = queryValue;
          break;
        case 'last_heartbeat_at':
        case 'version':
          if (value.from) {
            query[`${name}[gte]`] = value.from;
          }

          if (value.to) {
            query[`${name}[lte]`] = value.to;
          }

          break;
        case 'condition':
          switch (value === 'authentication_recovery') {
            case true:
              query.disconnected_before = new Date(value.value).toJSON();

              break;
            default:
              query[name] = typeof value === 'string' ? value : value.id;
          }

          break;
        case 'disconnected_before':
          query.disconnected_before = new Date(value.value).toJSON();

          break;
        case 'container_clusters':
          query.container_clusters = JSON.stringify([value.href]);

          break;
        case 'labelsAndLabelGroups':
          query.xxxlabels = [values.map(({href}) => href)];

          break;
        default:
          query[name] = typeof value === 'string' ? value : value.id;
      }
    }
  }

  return yield call(apiSaga, 'vens.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      list = list.map(item => ({href: item.href}));

      if (force || list !== (yield select(venUpgradeState.getVensIntended))) {
        yield put({type: 'VENS_GET_TARGETS', data: {list, count}});
      }

      return {list, count};
    },
  });
}

// fetch list of intended vens in case of All or Filtered. Retrieve possibly > 500
export function* fetchTargetedVens(route, refetch = false, options = {}) {
  const {params} = route;
  const {
    getGridSettings = gridSettings, // prop, from list context, or empty
    getFilters = getFilterMap,
    scopeItems,
    customScope,
    customFilter,
  } = options;

  yield call(venUpgradeSaga, {
    route,
    settings: getGridSettings,
    scopeItems,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      // const filterParams = yield select(getUrlFilterParam, {filterMap: filterItems});
      let scope;

      if (customScope) {
        scope = customScope;
      } else {
        scope = scopeParams.isEmpty ? undefined : scopeParams.valid;
        yield call(fetchValidScopeLabels, scope); // grid unneccessary
      }

      const [{list}] = yield all([
        call(fetchVens, {
          params,
          filter: filterParams.isEmpty ? customFilter : {...filterParams.valid, ...customFilter}, // filterItems,
          scope,
          force: refetch,
        }),
      ]);

      return list.length;
    },
  });
}

/* Preforms an upgrade or estimates outcomes, with dryRun=true */
export function* upgradeVens(release, venSet, dryRun) {
  // /orgs/:xorg_id/vens/upgrade, with VEN hrefs, the desired version, and "dry_run: true"
  try {
    return yield call(apiSaga, 'vens.upgrade', {
      data: {
        vens: venSet,
        release,
        dry_run: dryRun, // true to predict expected outcome, but make no change
      },
      cache: false,
      *onDone({data: {errors, expires_at}}) {
        yield put({type: 'VENS_UPGRADE', data: {errors, expires_at}});

        if (!dryRun) {
          apiCachedResponses.removeByMethodName('vens.get_collection');
          yield call(fetchPendingVens);
        }

        return {errors, expires_at};
      },
    });
  } catch (error) {
    return {errors: error.data, predictZeroUpgrades: true}; // catch 406 etc, and format as UI expects
  }
}

export function* fetchVensByCondition({condition, value = true, force = false, includeData = false}) {
  const query = {[condition]: value, max_results: includeData ? 500 : 0};
  const type = _.snakeCase(`VENS_${condition}_${value}`).toUpperCase();
  const reducerField = _.camelCase(`get_${type}`);

  yield call(apiSaga, 'vens.get_collection', {
    query,
    cache: !force,
    *onDone(result) {
      const {matched} = result.count;

      if (force || matched !== (yield select(venUpgradeState[reducerField]))) {
        yield put({type, data: matched});
      }
    },
  });
}

export function* fetchPendingVens({force = true} = {}) {
  // /orgs/:xorg_id/vens?upgrade_pending=true
  yield fetchVensByCondition({force, condition: 'upgrade_pending'});
}

export function* fetchErrorVens({force = true} = {}) {
  // /orgs/:xorg_id/vens?health=error
  yield fetchVensByCondition({force, condition: 'health', value: 'error'});
}

export function* fetchActiveVens({force = true} = {}) {
  // /orgs/:xorg_id/vens?status=active
  yield fetchVensByCondition({force, condition: 'status', value: 'active'});
}

export function* fetchWarningVens({force = true} = {}) {
  // /orgs/:xorg_id/vens?health=warning
  yield fetchVensByCondition({force, condition: 'health', value: 'warning'});
}

export function* fetchSuspendedVens({force = true} = {}) {
  // /orgs/:xorg_id/vens?status=suspended
  yield fetchVensByCondition({force, condition: 'status', value: 'suspended'});
}

// agent.upgrade_time_out
// This condition occurs when the VEN does not complete the upgrade within the expiry time (by default one hour).
// This condition will be logged the first time the VEN heartbeats after the upgrade has expired.
export function* fetchUpgradeTimeoutVens({force = true}) {
  // /orgs/:xorg_id/vens?condition=agent.upgrade_time_out
  yield fetchVensByCondition({force, condition: 'condition', value: 'agent.upgrade_time_out'});
}

// agent.missing_heartbeats_after_upgrade
// This condition occurs when the PCE records the VEN downloading the image for an upgrade, but then the VEN stops
// heatbeating. The check runs on a timed worker every 5 minutes, and looks for VENs that downloaded an image 7 or
// more minutes ago, and have not heartbeated since then.
export function* fetchHeartbeatMissingVens({force = true}) {
  yield fetchVensByCondition({force, condition: 'condition', value: 'agent.missing_heartbeats_after_upgrade'});
}
