/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import produce from 'immer';
import {PureComponent} from 'react';
import {MenuInfo, Pill, type PillProps} from 'components';
import {hrefUtils} from 'utils';
import {getFriendlyName} from './WorkloadUtils';
import type {Workload as WorkloadData} from 'illumio';
import type {IP_IP} from 'utils/ip';

export type WorkloadProps = {
  value: WorkloadData;
  contextualMenuFullIp?: IP_IP;
  isCSFrame?: boolean;
} & PillProps;

export default class Workload extends PureComponent<WorkloadProps> {
  element: HTMLElement | undefined | null = null;

  constructor(props: WorkloadProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, ...rest) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (this.props.contextualMenuFullIp && firstInfo?.attributes) {
        firstInfo.attributes.push({
          key: intl('Common.IPAddress'),
          value: this.props.contextualMenuFullIp,
        });
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, ...rest) : menu;
  };

  render() {
    const {value, contextualMenuFullIp, isCSFrame = false, ...pillProps} = this.props;
    const name = getFriendlyName(value);

    Object.assign(pillProps, {
      icon: 'workload',
      link: {to: 'workloads.item.view', params: {id: hrefUtils.getId(value.href)}},
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualMenu = this.renderContextualMenu;
      pillProps.contextualType ??= isCSFrame ? intl('Common.ServiceTag', {multiple: false}) : intl('Common.Workload');
      pillProps.contextualCopyValue ??= name;
    }

    return (
      <Pill {...pillProps} ref={this.saveRef}>
        {name}
      </Pill>
    );
  }
}
