/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {hrefUtils, portUtils, webStorageUtils} from 'utils';
import {createSelector} from 'reselect';
import {Pill} from 'components';
import Icon from 'components/Icon/Icon';
import styles from 'containers/EnforcementBoundaries/List/EnforcementBoundariesList.css';
import cx from 'classnames';
import stylesUtils from 'utils.css';
import {getCSSVariables} from 'utils/dom';
import {getGraphCSSVariables} from 'containers/IlluminationMap/Graph/Utils/MapGraphStyleUtils';

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

/** Compare both string after converting array to string, then need to multiply
 * sortFactor to get ascending or descending order
 *
 * @example
 *  sortFactor : 1 (ascending), -1 (descending)
 * */
export const enforcementBoundariesSort = ({a, b, sortFactor}) => {
  return collator.compare(a.join(''), b.join('')) * sortFactor;
};

export const servicePortObjectMap = createSelector([], () => ({
  // 'services' is the API property name in schema.json
  // 'services' API requires 'pversion' as the URI parameter
  services: {type: 'services', pversion: 'draft'},
}));

export const filterAllServicesInMatches = match => match?.name !== intl('Common.AllServices');

export const scopeFilterKeys = ['role', 'app', 'env', 'loc'];

export const scopeFilerKeysSet = new Set(scopeFilterKeys);

export const allScopeLabels = createSelector([], () => ({
  role: {value: intl('Common.AllRoles'), key: 'role', categoryKey: 'all_roles'},
  app: {value: intl('Common.AllApplications'), key: 'app', categoryKey: 'all_applications'},
  env: {value: intl('Common.AllEnvironments'), key: 'env', categoryKey: 'all_environments'},
  loc: {value: intl('Common.AllLocations'), key: 'loc', categoryKey: 'all_locations'},
}));

/** Used in Edit/Create page */
export const comboSelectObjectMap = {
  labels: {type: 'labels'},
  label_groups: {type: 'label_groups', pversion: 'draft'},
  ip_lists: {type: 'ip_lists', pversion: 'draft'},
};

/** Data from the backend needs to convert to proper mapping to show for the Seletor */
export const dataToSelector = createSelector([], () => ({
  ip_list: {
    categoryKey: 'ip_lists',
    categoryName: intl('Common.IPLists'),
  },
  label: {
    categoryKey: 'labels',
  },
  label_group: {
    categoryKey: 'label_groups',
  },
  actors: {
    ams: {
      categoryKey: 'all_workloads',
      categoryName: intl('Workloads.All'),
      href: 'allWorkloads',
    },
  },
}));

// Extract the proper scopes from Selective Enforcement
// Make the data structure for tesse react similar to legacy for selective enforcement
const getLabels = objectLabels => {
  const labels = ['providers', 'consumers'].reduce(
    (newCur, cur) => {
      objectLabels?.[cur].forEach(item => {
        if (item?.label) {
          // label
          newCur[cur].push(item);
        }

        if (item?.label_group) {
          // label groups
          newCur[cur].push(item);
        }

        if (item?.ip_list) {
          // ip list
          newCur[cur].push({ip_list: {href: item.ip_list.href, name: item.ip_list.name}});
        }

        if (item?.actors) {
          // all workloads
          newCur[cur].push({actors: {allWorkloads: true}});
        }
      });

      return newCur;
    },
    {providers: [], consumers: []},
  );

  return labels;
};

// Set session to toggle between tesse react and legacy pages
// e.g. Explorer(legacy)-> Rules(tesse react), Rules(tesse react)->Explorer(legacy)
export const setSessionSegmentationLabels = (versions, id) => {
  const {pversionObj, prevPversionObj} = versions;

  let prevPversionObjLabels;

  // Active version
  if (prevPversionObj) {
    // Note: scope will have both label and label_group
    prevPversionObjLabels = getLabels(prevPversionObj);
  }

  // Draft version
  // Note: scope will have both label and label_group
  const pversionObjLabels = getLabels(pversionObj);
  const scopeLabels = {};

  if (prevPversionObj) {
    // When active exist then there is a draft version and active version
    scopeLabels.active = {
      ...prevPversionObjLabels,
      ingress_services: prevPversionObj.ingress_services,
      href: prevPversionObj.href,
      name: prevPversionObj.name,
    };
    scopeLabels.draft = {
      ...pversionObjLabels,
      ingress_services: pversionObj.ingress_services,
      href: pversionObj.href,
      name: pversionObj.name,
    };
  } else {
    // When active doesn't exist then only pversionObj then set both active and draft are the same
    scopeLabels.active = {
      ...pversionObjLabels,
      ingress_services: pversionObj.ingress_services,
      href: pversionObj.href,
      name: pversionObj.name,
    };
    scopeLabels.draft = {
      ...pversionObjLabels,
      ingress_services: pversionObj.ingress_services,
      href: pversionObj.href,
      name: pversionObj.name,
    };
  }

  // scopeLabels are the labels/label groups specific selective rule instance user has
  webStorageUtils.setSessionItem('selectiveInstanceLabels', {scopeLabels, id});
};

export const allCssVariables = getCSSVariables();

export const colorsMap = getGraphCSSVariables(allCssVariables, 'map');

export const enforcementModeView = createSelector([], () => ({
  full: {
    desc: intl('Workloads.FullEnforcementInbound'),
    name: intl('Workloads.Full'),
    color: colorsMap['--map-combo-mode-full'],
  },
  selective: {
    desc: intl('Workloads.EnforcementBoundariesSelectedInbound'),
    name: intl('Workloads.Selective'),
    color: colorsMap['--map-combo-mode-selective'],
  },
  visibility_only: {
    desc: intl('Workloads.VisibilityOnlyReports'),
    name: intl('Common.VisibilityOnly'),
    color: colorsMap['--map-dashboard-enforcement-mode-visibility'],
  },
  idle: {
    desc: intl('Common.IdleControl'),
    name: intl('Common.Idle'),
    color: colorsMap['--map-dashboard-enforcement-mode-idle'],
  },
}));

export const enforcementModeViewEdge = {
  enforced: {
    name: intl('Common.Enforced'),
    desc: intl('Workloads.EnforceBoundariesEdgeDes'),
  },
  full: {
    name: intl('Workloads.Full'),
    desc: intl('Workloads.EnforceBoundariesEdgeDes'),
  },
  visibility_only: {
    desc: intl('Workloads.VisibilityOnlyReportsEdge'),
    name: intl('Common.VisibilityOnly'),
  },
  idle: {
    name: intl('Common.Idle'),
    desc: intl('Common.IdleControlEdge'),
  },
};

export const visibilityLevelView = createSelector([], () => ({
  flow_off: {
    desc: intl('Map.FlowVisibility.NoneDetail'),
    name: intl('Common.Off'),
  },
  flow_drops: {
    desc: intl('Map.FlowVisibility.BlockTraffic'),
    name: intl('Common.Blocked'),
  },
  flow_summary: {
    desc: intl('Map.FlowVisibility.BlockAllowed'),
    name: intl('Map.FlowVisibility.BlockPlusAllowed'),
  },
  flow_full_detail: {
    desc: intl('Map.FlowVisibility.BlockAllowed'),
    name: intl('Map.FlowVisibility.BlockPlusAllowed'),
  },
  enhanced_data_collection: {
    desc: intl('Map.FlowVisibility.EnhancedDataCollectionDesc'),
    name: intl('Map.FlowVisibility.EnhancedDataCollection'),
  },
  idle: {
    name: intl('Common.Limited'),
  },
}));

export const visibilityLevelViewEdge = {
  flow_off: {
    desc: intl('Map.FlowVisibility.NoneDetailEdge'),
    name: intl('Common.Off'),
  },
  flow_drops: {
    desc: intl('Map.FlowVisibility.BlockTrafficEdge'),
    name: intl('Common.Blocked'),
  },
  flow_summary: {
    desc: intl('Map.FlowVisibility.BlockAllowedEdge'),
    name: intl('Map.FlowVisibility.BlockPlusAllowed'),
  },
  flow_summary_enforce: {
    desc: intl('Map.FlowVisibility.BlockAllowedEdgeEnforce'),
    name: intl('Map.FlowVisibility.BlockPlusAllowed'),
  },
  idle: {
    desc: intl('Map.FlowVisibility.LimitedEdge'),
    name: intl('Common.Limited'),
  },
};

export const enforcementVisibility = createSelector([], () => ({
  full: {
    flow_off: true,
    flow_drops: true,
    flow_summary: true,
    enhanced_data_collection: true,
  },
  selective: {
    flow_summary: true,
    enhanced_data_collection: true,
  },
  visibility_only: {
    flow_summary: true,
    enhanced_data_collection: true,
  },
  idle: {
    idle: true,
  },
}));

/**
 * Extract all the name and value from specific consumer and providers
 * @param items
 * @returns {*}
 */
export const extractEnforcementProvidersConsumers = items => {
  return items.map(item => {
    const info = Object.values(item)[0];

    return (info.name ?? info.value ?? info).trim();
  });
};

/**
 * Extract all the name or value from specific ingress services
 * @param items
 * @returns {*}
 */
export const extractEnforcementIngressServices = items =>
  items.map(item => (item.name || item.value || portUtils.stringifyPortObjectReadonly(item)).trim());

/**
 * Enforcement Boundaries data structure to set the clickable or non-clickable Pills
 *
 * @param string type is the specific property to use e.g. providerLabels
 * @param string the string name of the column header
 */
export const enforcementBoundariesPills = ({type, header, sortable = true, initialCount}) => {
  return {
    header,
    onMouseOver: ({evt, elements}) => {
      /**
       * elements is initialized in GridCellBody from the 'refs' declaration in this code block.
       *
       * @example
       *  GridCellBody
       *
       * if (refs) {
       * this.contentRefs = {};
       * this.contentElements = {};
       *
       * for (const refName in refs) {
       * if (refs.hasOwnProperty(refName)) {
       *   this.contentRefs[refName] = element => {
       *     this.contentElements[refName] = refs[refName](element);
       *   }}}};
       */

      if (elements[type]) {
        for (const element of elements[type].values()) {
          /** Check to determine if evt.target when onMouseOver is a descendant of 'element'.
           * */
          if (element.contains(evt.target)) {
            /** By returning the false, GridRowBody will prevent the onClick callback when the callback is passed
             * in the Grid component. It is possible that the row has an onClick handler which navigates to the row's
             * specific detail/view page. Also only highlights the current element and not the entire row.
             *
             * @example
             *  Note: return false will avoid this onClick
             *  <Grid onClick={this.someCallback}/>
             */

            return false;
          }
        }
      }

      /** By returning true, will call Grid's component onClick prop.
       *
       * @example
       *  onClick will be called
       * <Grid onClick={this.someCallback}/>
       */
      return true;
    },
    refs: {
      [type]: instance => {
        /** instance is reference to Pill.EndPoint. element is a property of Pill.EndPoint
         *
         * @example
         * Pill.EndPoint
         * - Save every Pill.Label, etc... with property this.element
         * saveRef(key, element) {
         * // Since we have an array of pills, save ref in a Map and remove it when unmounted
         * // One of the usage example is in grid row hover:
         * // saved ref is used to check if pointer is currently above the element (not above whole cell that can be wider),
         * // this enable click/mouseOver handlers to be passed to the element
         * if (element?.element) {
         * // used element.element for the link
         *   this.element.set(key, element.element);
         *  } else {
         *     this.element.delete(key);
         *  }}
         *
         * */
        return instance?.element;
      },
    },
    sortFunction: ({rowA, rowB, sortFactor}) => {
      const aCollection = extractEnforcementProvidersConsumers(rowA.data[type]);
      const bCollection = extractEnforcementProvidersConsumers(rowB.data[type]);

      return enforcementBoundariesSort({a: aCollection, b: bCollection, sortFactor});
    },
    value: 'policyObjects',
    format: ({value, refs}) => {
      /** ref={refs[type]} - refs[type] is a callback method to keep track of all the Pills used in Pill.EndPoint */
      return (
        <Pill.Endpoint
          ref={refs[type]}
          type={type}
          value={value[type].value}
          oldValue={value[type].oldValue}
          expandableProps={{initialCount}}
        />
      );
    },
    sortable,
  };
};

/**
 * Set the proper ref callback to reference the <Pill> component for services
 *
 * @param Array a collection services
 * @param string version
 * @returns object an object reference to callback
 */
export const saveRef = ({services, version = 'draft'}) => {
  /** Set a ref callback with unique name */
  return services?.reduce((result, value) => {
    const ref = value.href ? `${value.href}.${version}` : portUtils.stringifyPortObjectReadonly(value);

    result[ref] = label => {
      return label ? label.element : null;
    };

    return result;
  }, {});
};

/**
 * Set the proper Pill component for the specific services
 *
 * @param Array services is a collection services
 * @params object refs with object reference to callback
 * @param string version
 * @returns object an object reference to callback
 */
export const getServicePills = ({services, refs, version = 'draft'}) => {
  const labelElements = services?.reduce(
    (service, value) => {
      const pillProps = {value};

      if (value.href) {
        const proposed = value.href.includes('proposed');

        if (proposed) {
          pillProps.status = 'added';
          pillProps.insensitive = true;
        } else {
          pillProps.link = {to: 'services.item', params: {pversion: 'draft', id: hrefUtils.getId(value.href)}};
        }

        if (refs) {
          pillProps.ref = refs[`${value.href}.${version}`];
        }

        pillProps.key = value.href;
        service.services.push({
          key: proposed ? value.href : `${hrefUtils.getId(value.href)}.${version}`,
          pill: <Pill.Service {...pillProps} />,
        });
      } else {
        const ports = portUtils.stringifyPortObjectReadonly(value);

        if (refs) {
          pillProps.ref = refs[ports];
        }

        /** Note: Don't need a ref on Protocols since these are un-clickable */
        pillProps.key = ports;
        service.ports.push({
          key: ports,
          pill: <Pill.Service {...pillProps} />,
        });
      }

      return service;
    },
    {services: [], ports: [], toPorts: []},
  );

  return labelElements;
};

/**
 * Get the enforcement boundary providing service pill
 * */
export const enforcementBoundariesServicesPill = (options = {}) => {
  const {sortable = true, initialCount} = options;

  return {
    header: intl('EnforcementBoundaries.ProvidingServices'),
    onMouseOver: ({evt, elements}) => {
      return Object.values(elements).every(element => !element?.contains(evt.target));
    },
    refs: ({value: {ingress_services}}) => {
      const value = saveRef({services: ingress_services.value});
      const oldValue = saveRef({services: ingress_services.oldValue, version: 'active'});

      return {...value, ...oldValue};
    },
    sortFunction: ({rowA, rowB, sortFactor}) => {
      const aCollection = extractEnforcementIngressServices(rowA.data.ingress_services);
      const bCollection = extractEnforcementIngressServices(rowB.data.ingress_services);

      return enforcementBoundariesSort({a: aCollection, b: bCollection, sortFactor});
    },
    value: 'policyObjects',
    format: ({value: {ingress_services, noDiff}, refs}) => {
      const version = ingress_services.oldValue ? 'active' : 'draft';

      const labelElementsValue = getServicePills({
        services: ingress_services.value,
        refs,
        version,
      });

      const labelElementsOldValue = getServicePills({
        services: ingress_services.oldValue,
        refs,
        version,
      });

      return (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>
          {
            <Pill.Diff
              value={Object.values(labelElementsValue)?.flat()}
              oldValue={labelElementsOldValue ? Object.values(labelElementsOldValue)?.flat() : []}
              noDiff={noDiff}
              // setting 8 for consistency across pages with labels/pills in grids
              expandableProps={{initialCount}}
            />
          }
        </div>
      );
    },
    sortable,
  };
};

export const arrowDirectionClassname = arrowDirection => {
  const svg = cx(styles.svg, {
    [styles.svgUp]: arrowDirection === 'up',
    [styles.svgDown]: arrowDirection === 'down',
    [styles.svgLeft]: arrowDirection === 'left',
  });

  return svg;
};

/**
 * Default consumer, providers, icon, providing service grid columns
 */
export const enforcementBoundariesGridColumns = (options = {}) => {
  const {noIconHeader, sortable = true, initialCount = 8} = options;

  return {
    providingServices: enforcementBoundariesServicesPill({sortable, initialCount}),
    arrow: {
      header(header) {
        /**
         * Declare header here to override default arrow.header in GridUtils
         */
        if (noIconHeader) {
          return;
        }

        const arrow = header.breakpoint.data?.arrow ?? 'right';

        const svg = cx(styles.svg, {
          [styles.svgArrow]: ['up', 'down'].includes(arrow),
        });

        return <Icon theme={{svg}} name={`arrow-${arrow}`} />;
      },
      format: format => {
        /**
         * Declare format here to override default arrow.format in GridUtils
         */
        const arrowDirection = format.breakpoint.data?.arrow ?? 'right';

        const svg = arrowDirectionClassname(arrowDirection);

        return <Icon theme={{svg}} name="enf-boundary" />;
      },
    },
    consumers: {
      ...enforcementBoundariesPills({type: 'consumers', sortable, header: intl('Common.Consumers'), initialCount}),
    },
    providers: {
      ...enforcementBoundariesPills({type: 'providers', sortable, header: intl('Common.Providers'), initialCount}),
    },
  };
};
