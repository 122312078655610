/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import {useCallback, useRef, useState, useLayoutEffect, useMemo, useContext} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import {Button} from 'components';
import ValuePanel from './ValuePanel/ValuePanel';
import {AppContext} from 'containers/App/AppUtils';
import {INPUT_ID, SEARCHBAR_ID, SEARCHBAR_CONTAINER_ID, COLUMN_WIDTH} from './SelectorUtils';

export default function SearchBar(props) {
  const {
    store: {prefetcher},
  } = useContext(AppContext);
  const {
    theme,
    saveRef,
    alwaysShowPlaceholder,
    inputProps: {onBlur} = {},
    placeholder,
    error,
    focusLockGroupName,
    noActiveIndicator,
    errors = {},
    suggestion,
    allResources,
    active,
    disabled,
    insensitive,
    hasFocusLockWithContainerResource,
    hideClearAll,
    query,
    activeCategory,
    registerHandlers,
    searchBarMaxHeight,
    onSelectedValueClick,
    onSetHighlighted,
    onMouseLeave,
    onValueRemove,
    onKeyDown,
    onInputChange,
    onClearValues,
    onToggle,
    onSearchBarClick,
    setCategoryPanelOnRight,
    title: titleProp,
    hideOptions,
  } = props;
  const autoFocus = !prefetcher.scrollRestored && props.autoFocus;

  const values = useDeepCompareMemo(props.values);
  const searchBarContainerRef = useRef(null);
  const inputRef = useRef();
  const prevValues = useRef(null);
  const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(null);
  const saveRefCallbackInput = useCallback(
    element => {
      inputRef.current = element;
      saveRef(INPUT_ID, element);
    },
    [saveRef],
  );
  const saveRefSearchBar = useCallback(
    element => {
      saveRef(SEARCHBAR_ID, element);
    },
    [saveRef],
  );

  const saveRefSearchBarContainer = useCallback(
    element => {
      searchBarContainerRef.current = element;
      saveRef(SEARCHBAR_CONTAINER_ID, element);
    },
    [saveRef],
  );

  const handleSearchBarClick = useCallback(
    evt => {
      if (!active && document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }

      onSearchBarClick(evt);
    },
    [active, onSearchBarClick],
  );

  const handleChange = useCallback(evt => onInputChange(evt, evt.target.value), [onInputChange]);
  const handleBlur = useCallback(
    evt => {
      if (active) {
        return;
      }

      onMouseLeave(evt); // reset highlighted and suggestion
      onBlur?.(evt);
    },
    [active, onMouseLeave, onBlur],
  );

  useLayoutEffect(() => {
    if (prevValues.current !== values) {
      const searchBarLeft = searchBarContainerRef.current?.getBoundingClientRect().left;
      const inputLeft = inputRef.current?.getBoundingClientRect().left;
      const offset = inputLeft - searchBarLeft;

      setActiveIndicatorLeft(offset);

      setCategoryPanelOnRight(inputLeft < COLUMN_WIDTH); // not enough space for category column
    }

    prevValues.current = values;
  }, [values, setCategoryPanelOnRight]);

  const isInsensitive = insensitive || disabled;

  const hasError = error || Object.values(errors).some(resourceError => !_.isEmpty(resourceError));

  const searchBarClasses = cx(theme.searchBar, {
    [theme.focused]: !hasError && active && !hasFocusLockWithContainerResource, //Focus can move to container elements in focusLock
    [theme.disabled]: disabled,
    [theme.error]: hasError,
  });

  const title = useMemo(
    () => (typeof titleProp === 'function' ? titleProp({active, activeCategory, values}) : titleProp),
    [titleProp, active, activeCategory, values],
  );

  // SearhBar either shows an activeIndicator Or a placeholder
  const showActiveIndicator = active && !noActiveIndicator && !activeCategory.noActiveIndicator && !title;
  const showPlaceholder = values.size === 0 || alwaysShowPlaceholder;
  const inputPlaceholder = showPlaceholder ? (active ? activeCategory.placeholder : placeholder) : null;

  const inputProps = {
    // Default attributes
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellCheck: 'false',
    autoFocus,
    ...props.inputProps,
    onBlur: handleBlur,
    ...(isInsensitive && {tabIndex: -1}),
    ...(query || inputPlaceholder || active ? {} : {size: 1}), // needed to prevent empty input row when Selector is inactive
  };

  const legendClasses = cx(theme.legend, {
    [theme.title]: Boolean(title),
    [theme.show]: showActiveIndicator || Boolean(title),
  });

  return (
    <div className={cx(theme.searchBarContainer, {[theme.clickable]: !isInsensitive})} ref={saveRefSearchBarContainer}>
      <fieldset className={searchBarClasses}>
        <legend
          className={legendClasses}
          {...(showActiveIndicator && activeIndicatorLeft ? {style: {marginLeft: activeIndicatorLeft}} : {})}
        >
          <span>{title || activeCategory.name}</span>
        </legend>
      </fieldset>
      <div ref={saveRefSearchBar} className={theme.items} onClick={isInsensitive ? undefined : handleSearchBarClick}>
        {values.size > 0 && (
          <ValuePanel
            theme={theme}
            saveRef={saveRef}
            insensitive={insensitive}
            disabled={disabled}
            values={hideOptions ? [] : values}
            errors={errors}
            allResources={allResources}
            onRemove={onValueRemove}
            registerHandlers={registerHandlers}
            searchBarMaxHeight={searchBarMaxHeight}
            onSelectedValueClick={onSelectedValueClick}
            onSetHighlighted={onSetHighlighted}
            onMouseLeave={onMouseLeave}
          />
        )}
        <FocusLock
          group={focusLockGroupName}
          disabled={!hasFocusLockWithContainerResource}
          autoFocus={false}
          className={cx(theme.inputPanel, {[theme.show]: active || Boolean(inputPlaceholder)})}
        >
          {query && suggestion && !query.includes(suggestion) ? (
            <input tabIndex="-1" readOnly className={theme.suggestion} value={`${query}${suggestion}`} />
          ) : null}
          <input
            disabled={disabled}
            readOnly={insensitive}
            data-tid="comp-selector-input"
            type="text"
            ref={saveRefCallbackInput}
            className={theme.input}
            value={query}
            placeholder={inputPlaceholder}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            {...inputProps}
          />
        </FocusLock>
      </div>
      {!hideClearAll && values.size > 0 && (
        <Button
          tid="clear"
          theme={theme}
          themePrefix="clearAll-"
          noFill
          insensitive={insensitive}
          disabled={disabled}
          icon="clear"
          size="small"
          tooltip={intl('InstantSearch.ClearAll')}
          onClick={onClearValues}
        />
      )}
      <Button
        tid="toggle"
        noFill
        color="standard"
        tabIndex="-1"
        theme={theme}
        themePrefix="toggle-"
        insensitive={insensitive}
        disabled={disabled}
        icon={active ? 'up' : 'down'}
        size="small"
        onClick={onToggle}
      />
    </div>
  );
}
