/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Icon, Form, Pill, Tooltip, StatusIcon} from 'components';
import styles from './Profile/Detail/ContainerClusterContainerWorkloadProfileDetail.css';
import {getEnforcementBoundariesVisibility} from 'containers/EnforcementBoundaries/EnforcementBoundariesVisibilityFormUtils';
import FormLabelSelectorOld from 'containers/FormComponents/FormLabelSelectorOld';
import stylesUtils from 'utils.css';

export const managementOptions = [
  {label: intl('Common.Managed'), value: 'true', subLabel: intl('Settings.Containers.ManagedDesc')},
  {label: intl('Common.Unmanaged'), value: 'false', subLabel: intl('Settings.Containers.UnmanagedDesc')},
];

export const managementView = managed => {
  if (managed) {
    // Managed
    return {
      name: intl('Common.Managed'),
      desc: intl('Settings.Containers.ManagedDesc'),
    };
  }

  // Unmanaged
  return {
    name: intl('Common.Unmanaged'),
    desc: intl('Settings.Containers.UnmanagedDesc'),
  };
};

export const inferLabelSource = (type, labels) => {
  const labelsByType = labels?.filter(label => label.key === type);

  if (labelsByType?.length) {
    if (labelsByType[0].hasOwnProperty('assignment')) {
      return 'assign'; // if there is an assigned label, mode is 'assign' from UI
    }

    if (labelsByType[0].hasOwnProperty('restriction')) {
      const restriction = labelsByType[0].restriction;

      if (Array.isArray(restriction) && restriction.length === 0) {
        return 'none'; // API representation of No labels allowed is restriction: empty array
      }
    }
  }

  return 'allow'; // new default is to allow container annotations
};

const getToolTipForAllowLabels = (typeName, allowed) => {
  const list = !allowed
    ? ''
    : `\n\n${allowed
        .slice(0, 5)
        .map(val => val.value)
        .join('\n')} ${allowed.length > 5 ? '\n...' : ''}`;
  const quantity = allowed === undefined ? intl('Protocol.Any') : allowed.length || intl('Common.No');

  return `${intl('ContainerClusters.UseContainerAnnotations')}\n${intl('ContainerClusters.LabelsAllowedNumberAndType', {
    count: quantity,
    labelTypeName: typeName || '',
  })}${list}`;
};

const allowListIcon = <Icon name="allowlist" position="before" theme={styles} themePrefix="allowlist-" />;
const hiddenSpacerIcon = <Icon name="allowlist" position="before" hidden theme={styles} themePrefix="allowlist-" />; // make flow not jump
const restrictedToNoLabels = <span className={styles.noLabelsAllowed}>{intl('ContainerClusters.NoLabelAllowed')}</span>;

// key needed because labelSet can be undefined, as in the case "Any"
export const formatAllowLabels = (labelSet, typeName, managed) => {
  if (managed === false) {
    return null;
  }

  if (!labelSet) {
    // sparse/excluded
    return (
      <Tooltip content={getToolTipForAllowLabels(typeName)} bottom>
        {allowListIcon} {intl('Protocol.Any')}
      </Tooltip>
    );
  }

  const {restriction} = labelSet;
  const length = restriction?.length;

  if (length === 0) {
    return intl('ContainerClusters.NoLabelAllowed');
  }

  const tag = length === 1 ? restriction[0].value : length > 1 ? `${length} ${intl('ContainerClusters.Allowed')}` : '';

  return (
    <Tooltip content={getToolTipForAllowLabels(typeName, restriction)} bottom>
      {allowListIcon}
      {tag}
    </Tooltip>
  );
};

export const labelOrAllowList = (labelSet, typeName, managed) => {
  if (!labelSet || labelSet?.hasOwnProperty('restriction')) {
    return formatAllowLabels(labelSet, typeName, managed);
  }

  const assigned = labelSet?.assignment;

  return assigned ? (
    <Pill.Label type={labelSet.key} href={assigned.href}>
      {assigned.value}
    </Pill.Label>
  ) : null;
};

export const getContainerClusterContainerWorkloadProfilePayload = (values, rows) => {
  const {managed} = values;
  const labels = [];

  const payload = {
    managed: managed.value === 'true',
    labels,
  };

  if (payload.managed) {
    rows.forEach(item => {
      const labelPayload = {};

      labelPayload.key = item.key;

      if (item.data.multi.length !== 0) {
        labelPayload.restriction = item.data.multi.map(item => ({href: item.href}));
      } else if (item.data.single) {
        labelPayload.assignment = {href: item.data.single.href};
      } else {
        labelPayload.restriction = [];
      }

      payload.labels.push(labelPayload);
    });

    payload.enforcement_mode = values.enforcementMode.value;

    // API will default visibility_level as 'flow_summary' when enforcement mode is 'idle'
    if (values.enforcementMode.value !== 'idle') {
      payload.visibility_level = values.visibility_level;
    }
  }

  return payload;
};

export const getLabelSourcesForm = (options, allowCreateTypes) => {
  const {values, errors, touched, setFieldValue, setFieldTouched} = options;

  if (values.managed?.value !== 'true') {
    return [];
  }

  // remove "Idle" from Container Cluster > Container Workload Profiles https://jira.illum.io/browse/EYE-89899
  const selectorOptions = {excludeOptions: ['idle']};

  return [
    ...getEnforcementBoundariesVisibility({form: options, options: selectorOptions}),
    {divider: true},
    {title: intl('Common.Labels')},
    {
      content: (
        <>
          {intl('Settings.Containers.AnnotationLabelHint')}
          <StatusIcon status="help" tooltip={intl('Settings.Containers.AnnotationLabelTooltip')} position="after" />
          <br />
          <br />
        </>
      ),
    },

    {
      key: <Form.Label name="roleSource" title={intl('Common.Role')} />,
      value: (
        <>
          <Form.RadioGroup name="roleSource" horizontal className={stylesUtils.bold}>
            <Form.Radio
              name="roleSource"
              value="allow"
              checked={values.roleSource === 'allow'}
              label={intl('ContainerClusters.UseContainerAnnotations')}
            />
            <Form.Radio
              name="roleSource"
              value="assign"
              checked={values.roleSource === 'assign'}
              label={intl('ContainerClusters.AssignLabel')}
            />
            <Form.Radio
              name="roleSource"
              value="none"
              checked={values.roleSource === 'none'}
              label={intl('Common.None')}
            />
          </Form.RadioGroup>
          <div className={styles.radioGridCell}>
            {values.roleSource === 'allow' ? allowListIcon : hiddenSpacerIcon}
            {values.roleSource === 'none' && restrictedToNoLabels}
            {values.roleSource === 'allow' && (
              <FormLabelSelectorOld
                multiple
                touched={touched}
                tid="role"
                value={values.roleAllow}
                errors={errors}
                name="roleAllow"
                type="role"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.roleSource !== 'allow'}
              />
            )}
            {values.roleSource === 'assign' && (
              <FormLabelSelectorOld
                errorWithoutTouch
                touched={touched}
                tid="role"
                value={values.role}
                errors={errors}
                name="role"
                type="role"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.roleSource !== 'assign'}
              />
            )}
          </div>
        </>
      ),
    },

    {
      key: <Form.Label name="appSource" title={intl('Common.Application')} />,
      value: (
        <>
          <Form.RadioGroup name="appSource" horizontal className={stylesUtils.bold}>
            <Form.Radio
              name="appSource"
              label={intl('ContainerClusters.UseContainerAnnotations')}
              value="allow"
              checked={values.appSource === 'allow'}
            />
            <Form.Radio
              name="appSource"
              label={intl('ContainerClusters.AssignLabel')}
              value="assign"
              checked={values.appSource === 'assign'}
            />
            <Form.Radio
              name="appSource"
              label={intl('Common.None')}
              value="none"
              checked={values.appSource === 'none'}
            />
          </Form.RadioGroup>
          <div className={styles.radioGridCell}>
            {values.appSource === 'allow' ? allowListIcon : hiddenSpacerIcon}
            {values.appSource === 'none' && restrictedToNoLabels}
            {values.appSource === 'allow' && (
              <FormLabelSelectorOld
                multiple
                touched={touched}
                tid="app"
                value={values.appAllow}
                errors={errors}
                name="appAllow"
                type="app"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.appSource !== 'allow'}
              />
            )}

            {values.appSource === 'assign' && (
              <FormLabelSelectorOld
                errorWithoutTouch
                touched={touched}
                tid="app"
                value={values.app}
                errors={errors}
                name="app"
                type="app"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.appSource !== 'assign'}
              />
            )}
          </div>
        </>
      ),
    },
    {
      key: <Form.Label name="envSource" title={intl('Common.Environment')} />,
      value: (
        <>
          <Form.RadioGroup name="envSource" horizontal className={stylesUtils.bold}>
            <Form.Radio
              name="envSource"
              label={intl('ContainerClusters.UseContainerAnnotations')}
              value="allow"
              checked={values.envSource === 'allow'}
            />
            <Form.Radio
              label={intl('ContainerClusters.AssignLabel')}
              name="envSource"
              value="assign"
              checked={values.envSource === 'assign'}
            />
            <Form.Radio
              name="envSource"
              label={intl('Common.None')}
              value="none"
              checked={values.envSource === 'none'}
            />
          </Form.RadioGroup>
          <div className={styles.radioGridCell}>
            {values.envSource === 'allow' ? allowListIcon : hiddenSpacerIcon}
            {values.envSource === 'none' && restrictedToNoLabels}
            {values.envSource === 'allow' && (
              <FormLabelSelectorOld
                multiple
                touched={touched}
                tid="env"
                value={values.envAllow}
                errors={errors}
                name="envAllow"
                type="env"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.envSource !== 'allow'}
              />
            )}
            {values.envSource === 'assign' && (
              <FormLabelSelectorOld
                errorWithoutTouch
                touched={touched}
                tid="env"
                value={values.env}
                errors={errors}
                name="env"
                type="env"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.envSource !== 'assign'}
              />
            )}
          </div>
        </>
      ),
    },
    {
      key: <Form.Label name="locSource" title={intl('Common.Location')} />,
      value: (
        <>
          <Form.RadioGroup name="locSource" horizontal className={stylesUtils.bold}>
            <Form.Radio
              name="locSource"
              label={intl('ContainerClusters.UseContainerAnnotations')}
              value="allow"
              checked={values.locSource === 'allow'}
            />
            <Form.Radio
              label={intl('ContainerClusters.AssignLabel')}
              name="locSource"
              value="assign"
              checked={values.locSource === 'assign'}
            />
            <Form.Radio
              name="locSource"
              label={intl('Common.None')}
              value="none"
              checked={values.envSource === 'none'}
            />
          </Form.RadioGroup>
          <div className={styles.radioGridCell}>
            {values.locSource === 'allow' ? allowListIcon : hiddenSpacerIcon}
            {values.locSource === 'none' && restrictedToNoLabels}
            {values.locSource === 'allow' && (
              <FormLabelSelectorOld
                multiple
                touched={touched}
                tid="loc"
                value={values.locAllow}
                errors={errors}
                name="locAllow"
                type="loc"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.locSource !== 'allow'}
              />
            )}
            {values.locSource === 'assign' && (
              <FormLabelSelectorOld
                errorWithoutTouch
                touched={touched}
                tid="loc"
                value={values.loc}
                errors={errors}
                name="loc"
                type="loc"
                allowCreateTypes={allowCreateTypes}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                disabled={values.locSource !== 'assign'}
              />
            )}
          </div>
        </>
      ),
    },
  ];
};
