/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {
  getAllUsersMap,
  isUserReadOnly,
  isUserReadOnlyAll,
  doesUserHaveGlobalObjectPermissions,
} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './LabelGroupListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isEdge} from 'containers/App/AppState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  allLabels(state = {}, action) {
    switch (action.type) {
      case 'SET_ALL_LABEL_GROUP_LABELS':
        return {...state, [action.data.href]: action.data.allLabels};
      default:
        return state;
    }
  },
};

export const getLabelGroups = state => state.labelGroup.list;
export const getLabelGroupsCount = state => state.labelGroup.count;
export const getAllLabelGroupLabels = state => state.labelGroup.allLabels;

const getLabelGroupsRows = createSelector(
  [getLabelGroups, getAllUsersMap, isUserReadOnly, doesUserHaveGlobalObjectPermissions, getDisplayNames],
  (labelGroups, usersMap, userIsReadOnly, userHasGlobalObjectPermissions, typeNames) =>
    labelGroups.map(item => ({
      key: item.href,
      // Service is in draft mode if it has pending status
      draft: Boolean(item.update_type),
      // Make Service created by system not selectable, since it cannot be removed or provisioned
      selectable: userHasGlobalObjectPermissions && (!item.created_by || item.created_by.href !== '/users/0'),
      // Service is removable only if it's active or with pending changes expect pending deletion
      removable: !userIsReadOnly && (!item.update_type || item.update_type !== 'delete'),
      // Fill each Service with user object
      data: {
        ...item,
        created_by: fillUserInfo(usersMap, item.created_by),
        updated_by: fillUserInfo(usersMap, item.updated_by),
        display_name: typeNames[item.key],
      },
    })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, isEdge, gridSettings],
  (userIsReadOnlyAll, isEdge, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll;
    columns.containerSettings.disabled = isEdge;

    return {...gridSettings, columns};
  },
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLabelGroupsRows,
    filterMap: getFilterMap,
  });

export const getLabelGroupsPage = createStructuredSelector({
  grid: getGrid,
  count: getLabelGroupsCount,
  userIsReadOnly: isUserReadOnly,
  categories,
});
