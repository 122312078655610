/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getStatusElement, sortByStatus} from '../ContainerClusterUtils';
import {createSelector} from 'reselect';

export const objectMap = {
  container_clusters: {type: 'container_clusters'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_clusters},
  },
  filterMap: {
    name: {value: intl('Common.Name'), object: objectMap.container_clusters},
  },
}));

/**
[{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector([], () => ({
  id: 'cclusterlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    state: {
      header: intl('Common.Status'),
      value: 'online',
      format: ({value, row}) =>
        getStatusElement({online: value, lastConnected: row.data.last_connected, errors: row.data.errors}),
      sortFunction: ({rowA, rowB, valueA, valueB, sortFactor}) =>
        sortByStatus(
          {online: valueA, lastConnected: rowA.data.last_connected},
          {online: valueB, lastConnected: rowB.data.last_connected},
          sortFactor,
        ),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    pce: {
      header: intl('Common.PCE'),
      value: 'pce_fqdn',
    },
    manager: {
      header: intl('Containers.PlatformVersion'),
      value: 'manager_type',
    },
    version: {
      header: intl('ContainerClusters.KubelinkVersion'),
      value: 'kubelink_version',
    },
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['state'], size: 'minmax(150px, auto)'},
      {columns: ['name'], size: 'minmax(150px, auto)'},
      {columns: ['pce'], size: 'minmax(130px, auto)'},
      {columns: ['manager'], size: 'minmax(130px, auto)'},
      {columns: ['version'], size: 'minmax(100px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, 300px)'},
    ],
    {
      maxWidth: 800,
      template() {
        return [
          {columns: ['state', 'name', 'pce'], size: 'minmax(250px, auto)'},
          {columns: ['manager', 'version'], size: 'minmax(200px, auto)'},
          {columns: ['desc'], size: 'minmax(150px, 300px)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['state', 'name', 'pce'], size: 'minmax(250px, auto)'},
          {columns: ['manager', 'version', 'desc'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template() {
        return [{columns: ['state', 'name', 'pce', 'manager', 'version', 'desc'], size: 'minmax(150px, auto)'}];
      },
    },
  ],
}));
