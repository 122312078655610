/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {createSelector, createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {reverseProviderConsumer} from 'containers/App/AppState';
import {templateGridSettings} from './TemplatesWizardConfig';

export default {
  templatesWizardState(state = {}, action) {
    switch (action.type) {
      case 'SET_TEMPLATE_RULES':
        return action.data;
      default:
        return state;
    }
  },
  ruleTemplates(state = [], action) {
    switch (action.type) {
      case 'GET_RULE_TEMPLATES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRuleTemplates = state => state.ruleTemplates;
export const getTemplatesWizardRules = state => state.templatesWizardState;
export const getAllowRules = state => state.templatesWizardState.sec_rules ?? [];
export const getDenyRules = state => state.templatesWizardState.deny_rules ?? [];

// Adds row key and row type to rule
const addKeysToRules = (rules, type) =>
  rules.map((rule, index) =>
    produce(rule, draft => {
      draft.type = type;
      draft.key = `${type}${index}`;
    }),
  );

const getGrid = (settings, rows) => {
  return state => getGridSelector(state, {settings, rows});
};

const getAllowRulesRows = createSelector(getAllowRules, rules => addKeysToRules(rules, 'allow'));
const getDenyRulesRows = createSelector(getDenyRules, rules => addKeysToRules(rules, 'deny'));

export const getTemplateGrids = createStructuredSelector({
  allowRulesGrid: getGrid(templateGridSettings, getAllowRulesRows),
  denyRulesGrid: getGrid(templateGridSettings, getDenyRulesRows),
});

// best to try and keep referential equality for Grid's `component` prop
export const getComponentObj = createStructuredSelector({
  reverseProviderConsumer,
});
