/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import cx from 'classnames';
import {createSelector} from 'reselect';
import {Pill, StatusIcon} from 'components';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {getWorkloadStatus} from 'containers/Workload/WorkloadUtils';
import stylesUtils from 'utils.css';
import antmanStyles from 'antman/containers/styles.css';

export const serverEnforcementListGridSettings = createSelector([getLabelsColumn], labelsColumn => ({
  id: 'serverEnforcementList',
  columns: {
    status: {
      header: intl('Common.Status'),
      value: ({row}) => getWorkloadStatus(row.data),
      format: ({value}) =>
        value && (
          <>
            <StatusIcon
              status={value}
              title={`${intl('Workloads.VENConnectivity')}: ${getWorkloadStatusIntl(value)}`}
            />
            &nbsp;
            {getWorkloadStatusIntl(value)}
          </>
        ),
    },
    hostname: {
      header: intl('Common.Hostname'),
      value: ({row}) => row.data.hostname,
      format: ({value}) => <span className={antmanStyles.link}>{value}</span>,
      sortable: false, // TODO: maybe allow sort on hostname
    },
    enforcementMode: {
      header: intl('Antman.Servers.Enforcement.Mode'),
      value: ({row}) => row.data.enforcementMode,
      // TODO: for now, this page only lists servers in visibility_mode
      format: ({value}) => (value === 'visibility_only' ? intl('Common.VisibilityOnly') : value),
      sortable: false,
    },
    os: {
      header: intl('Services.Mixin.Os.Title'),
      value: ({row}) => row.data.os_id,
      sortable: false,
    },
    labels: {
      ...labelsColumn,
      header: intl('Common.Labels'),
      sortable: false,
      value: ({row}) => row.data.labels,
      format: ({value}) => {
        return (
          <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontal)}>
            {value.map(label => (
              <Pill.Label {...label} type={label.key}>
                {label.value}
              </Pill.Label>
            ))}
          </div>
        );
      },
    },
  },
  templates: [
    {
      template() {
        return [
          {columns: ['status'], size: 'minmax(calc(1.2 * var(--100px)), max-content)'},
          {columns: ['hostname'], size: 'minmax(calc(2 * var(--100px)), max-content)'},
          {columns: ['enforcementMode'], size: 'minmax(calc(2 * var(--100px)), max-content)'},
          {columns: ['os'], size: 'minmax(calc(2 * var(--100px)), max-content)'},
          {columns: ['labels'], size: 'minmax(calc(2 * var(--100px)), max-content)'},
        ];
      },
    },
  ],
}));
