/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getPairingProfiles, getPairingProfilesCount, getGridSettings, getFilterMap} from './PairingProfileListState';
import gridSaga from 'components/Grid/GridSaga';
import {cachedResponses} from 'api/apiCache';
import {isPairingProfilesEnabled} from '../PairingProfileState';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';

export function* fetchPairingProfiles({filter, force = false} = {}) {
  const query = {max_results: 500, representation: 'pairing_profile_labels'};

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      if (name === 'labelsAndLabelGroups') {
        query.xxxlabels = [values.map(({href}) => href)];
      } else {
        const value = values[0];

        query[name] = typeof value === 'object' ? value.value : value;
      }
    }
  }

  return yield call(apiSaga, 'pairing_profiles.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getPairingProfiles)) || count !== (yield select(getPairingProfilesCount))) {
        yield put({type: 'PAIRING_PROFILE_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removePairingProfiles({hrefs}) {
  yield call(apiSaga, 'pairing_profiles.delete', {
    data: {profiles: hrefs.map(href => ({href}))},
    *onDone() {
      // Invalid labels list cache
      cachedResponses.removeByMethodName('labels.get_collection');
    },
    hrefs,
  });
}

export function* fetchPairingProfileList(route, refetch = false) {
  const pairingProfilesIsEnabled = yield select(isPairingProfilesEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!pairingProfilesIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchPairingProfiles, {
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          force: refetch,
        }),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list && list.length;
    },
  });
}
