/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {Component, createRef, createElement} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {TypedMessages} from 'components';
import {tidUtils} from 'utils';
import cx from 'classnames';
import styles from './ColorPicker.css';

export default class ColorPicker extends Component {
  static propTypes = {
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    hideErrorMessage: PropTypes.bool,
    tid: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    warningMessage: PropTypes.string,
    // subtext for help info
    subText: PropTypes.any,
    // themr object
    theme: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.input = createRef();

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.onChange || nextProps.readOnly) {
      return {value: nextProps.value};
    }

    return null;
  }

  handleChange(evt) {
    if (this.props.onChange) {
      this.props.onChange(evt);
    } else {
      this.setState({value: evt.target.value});
    }
  }

  render() {
    const {tid, error, errorMessage, hideErrorMessage, warningMessage, subText, theme, ...inputProps} =
      mixThemeWithProps(styles, this.props);

    const {value} = this.state;

    return createElement(
      'div',
      {className: theme.wrapper},
      <input
        type="color"
        key="input"
        // Attributes specified by parent
        {...inputProps}
        // Local state attributes
        value={value}
        ref={this.input}
        onChange={this.handleChange}
        data-tid={tidUtils.getTid('comp-field-input', tid)}
        className={cx(theme.input, {
          [theme.error]: error,
        })}
      />,
      // Render TypedMessages unconditionally, but its children conditionally to make their animation work for show/hide
      <TypedMessages key="status" gap="gapXSmall">
        {[
          !hideErrorMessage && error && errorMessage
            ? {
                content: errorMessage,
                color: 'error',
                fontSize: 'var(--12px)',
                tid: tidUtils.getTid('comp-field-error', tid),
              }
            : null,
          !error && warningMessage
            ? {
                content: warningMessage,
                color: 'warning',
                fontSize: 'var(--12px)',
                tid: tidUtils.getTid('comp-field-error', tid),
              }
            : null,
          subText ? {content: subText, color: 'gray', fontSize: 'var(--12px)'} : null,
        ]}
      </TypedMessages>,
    );
  }
}
