/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Field, type FieldProps} from 'formik';
import * as PropTypes from 'prop-types';
import {forwardRefSymbol, forwardRefFactory} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import OptionSelector from './OptionSelector';
import * as React from 'react';
import type {ForwardRefFormikWhenTypes} from 'utils/types';

type OnChangeTypes = KeyboardEvent | MouseEvent;

type FormOptionSelectorPropsTypes = ForwardRefFormikWhenTypes<{
  name: string;
  onChange: (evt: OnChangeTypes, value: string, name: string) => void;
}>;

//FormOptionSelector.Context = createContext(null);
FormOptionSelector.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  // By default form selector state and form value are automatically handled by FormOptionSelector itself (uncontrolled).
  // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,
  // Might depend on other fields
  ...whenPropTypes,
};

export function FormOptionSelector(props: FormOptionSelectorPropsTypes): JSX.Element {
  // Control field reset when dependencies are not true
  const {[forwardRefSymbol]: ref, onChange, form, field, ...selectorProps} = useWhen(props.form, props.field, props);
  const {name} = field;

  selectorProps.ref = ref;
  selectorProps.name = name;

  selectorProps.value = field.value;

  // Any property in the errors object has a validation issue thus check for property existence with hasOwnProperty
  selectorProps.errorMessage =
    form.touched[name] === true && form.errors.hasOwnProperty(name) && form.errors[name].trim();

  selectorProps.onChange = (evt: OnChangeTypes, value: string) => {
    // Only call touched when hasn't been touched
    // User can click on selector but not make selection, thus set touched
    if (!form.touched[name]) {
      form.setFieldTouched(name, true);
    }

    if (onChange && value !== undefined) {
      // Only call parent onChange when there is a value.
      return onChange(evt, value, name);
    }

    // Only update value that is not undefined
    if (value !== undefined) {
      form.setFieldValue(name, value);
    }
  };

  return <OptionSelector {...selectorProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory((props: FieldProps) => <Field {...props} component={FormOptionSelector} />, {
  hoistSource: FormOptionSelector,
});
