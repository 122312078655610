/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import {getIgnoredFilterCategoriesByFilterType} from 'containers/IlluminationMap/Filter/EditView/MapFilterEditViewUtils';
import type {Filters} from 'containers/IlluminationMap/Filter/MapFilterTypes';
import type {MenuGroup} from './ContextMenu';
import intl from 'intl';

export type RequiredField<T, K extends keyof T> = T & Required<Pick<T, K>>;
export type ApplyFiltersActionHandler = (
  id: string,
  filterType: Exclude<keyof Filters, 'time'>,
  filterCategory: string,
) => void;
export type ToggleNodeOpenStateHandler = ({id}: {id: string}) => void;

type MenuGroupsGetterParams = {
  id: string;
  filterCategory: string;
  orView: boolean;
  stickyAppGroupFields: (keyof Filters)[];
  openCombos: string[];
  applyFilters: ApplyFiltersActionHandler;
  toggleNodeOpenState: ToggleNodeOpenStateHandler;
};

export function isIgnoredFilterCategory(
  filterType: Exclude<keyof Filters, 'time'>,
  filterCategory: string,
  stickyAppGroupFields: (keyof Filters)[],
): boolean {
  let stickyAppGroup = false;

  if ((stickyAppGroupFields || []).includes(filterType)) {
    stickyAppGroup = true;
  }

  const ignoredFilters = getIgnoredFilterCategoriesByFilterType(filterType, stickyAppGroup);

  if (ignoredFilters.has(filterCategory)) {
    return true;
  }

  return false;
}

export function removeIgnoredFilterCategoryOptions(
  menuGroups: MenuGroup[],
  filterCategory: string,
  stickyAppGroupFields: (keyof Filters)[],
): MenuGroup[] {
  return menuGroups
    .map(menuGroup => {
      return {
        ...menuGroup,
        items: menuGroup.items.filter(
          item =>
            !isIgnoredFilterCategory(
              item.filterType as Exclude<keyof Filters, 'time'>,
              filterCategory,
              stickyAppGroupFields,
            ),
        ),
      };
    })
    .filter(menuGroup => menuGroup.items.length > 0);
}

export function getProviderAndConsumerMenuGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  const {applyFilters, filterCategory, id, orView, stickyAppGroupFields} = menuGroupsGetterParams;
  const isIPList = filterCategory === 'ip_list';

  const andViewMenuGroups: MenuGroup[] = [
    {
      id: 'provider',
      items: [
        {
          id: 'provider-is',
          icon: 'filter',
          filterType: 'providerInclude',
          text: isIPList ? `${intl('Explorer.ProviderIs')} ${intl('IPLists.Any')}` : intl('Explorer.ProviderIs'),
          handleClick: () => {
            applyFilters(id, 'providerInclude', filterCategory);
          },
        },
        {
          id: 'provider-is-not',
          icon: 'filter',
          filterType: 'providerExclude',
          text: isIPList ? `${intl('Explorer.ProviderIsNot')} ${intl('IPLists.Any')}` : intl('Explorer.ProviderIsNot'),
          handleClick: () => {
            applyFilters(id, 'providerExclude', filterCategory);
          },
        },
      ],
    },
    {
      id: 'consumer',
      items: [
        {
          id: 'consumer-is',
          icon: 'filter',
          filterType: 'consumerInclude',
          text: isIPList ? `${intl('Explorer.ConsumerIs')} ${intl('IPLists.Any')}` : intl('Explorer.ConsumerIs'),
          handleClick: () => {
            applyFilters(id, 'consumerInclude', filterCategory);
          },
        },
        {
          id: 'consumer-is-not',
          icon: 'filter',
          filterType: 'consumerExclude',
          text: isIPList ? `${intl('Explorer.ConsumerIsNot')} ${intl('IPLists.Any')}` : intl('Explorer.ConsumerIsNot'),
          handleClick: () => {
            applyFilters(id, 'consumerExclude', filterCategory);
          },
        },
      ],
    },
  ];

  const orViewMenuGroups: MenuGroup[] = [
    {
      id: 'provider',
      items: [
        {
          id: 'consumer-or-provider-is',
          icon: 'filter',
          filterType: 'consumerOrProviderInclude',
          text: isIPList
            ? `${intl('Explorer.ConsumerOrProviderIs')} ${intl('IPLists.Any')}`
            : intl('Explorer.ConsumerOrProviderIs'),
          handleClick: () => {
            applyFilters(id, 'consumerOrProviderInclude', filterCategory);
          },
        },
        {
          id: 'consumer-or-provider-is-not',
          icon: 'filter',
          filterType: 'consumerOrProviderExclude',
          text: isIPList
            ? `${intl('Explorer.ConsumerOrProviderIsNot')}  ${intl('IPLists.Any')}`
            : intl('Explorer.ConsumerOrProviderIsNot'),
          handleClick: () => {
            applyFilters(id, 'consumerOrProviderExclude', filterCategory);
          },
        },
      ],
    },
  ];

  return removeIgnoredFilterCategoryOptions(
    orView ? orViewMenuGroups : andViewMenuGroups,
    filterCategory,
    stickyAppGroupFields,
  );
}

export function getCollapsedMenuGroup(menuGroupsGetterParams: MenuGroupsGetterParams, labelText: string): MenuGroup {
  const {id, toggleNodeOpenState, openCombos} = menuGroupsGetterParams;
  const collapsed = !Object.keys(openCombos).some(openComboId => id.includes(openComboId));

  return {
    id: 'collapse',
    items: [
      {
        id: 'collapse',
        icon: collapsed ? 'collapse-role' : 'expand-role',
        text: `${collapsed ? intl('Common.Expand') : intl('Common.Collapse')} ${labelText}`,
        handleClick: () => {
          toggleNodeOpenState({id});
        },
      },
    ],
  };
}

export function getWorkloadMenuGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  return [...getProviderAndConsumerMenuGroups(menuGroupsGetterParams)];
}

export function getLabelSetMenuGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  return [
    ...(menuGroupsGetterParams.id.includes('open__nodes')
      ? [getCollapsedMenuGroup(menuGroupsGetterParams, intl('IlluminationMap.LabelSet'))]
      : []),
    ...getProviderAndConsumerMenuGroups(menuGroupsGetterParams),
  ];
}

export function getIpListMenuGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  const {id, orView, applyFilters, filterCategory, stickyAppGroupFields} = menuGroupsGetterParams;

  return removeIgnoredFilterCategoryOptions(
    [
      {
        id: 'provider',
        items: [
          {
            id: orView ? 'consumer-or-provider-is' : 'provider-is',
            icon: 'filter',
            filterType: orView ? 'consumerOrProviderInclude' : 'providerInclude',
            text: orView
              ? `${intl('Explorer.ConsumerOrProviderIs')} ${intl('IPLists.Any')}`
              : `${intl('Explorer.ProviderIs')} ${intl('IPLists.Any')}`,
            handleClick: () => {
              applyFilters(id, orView ? 'consumerOrProviderInclude' : 'providerInclude', filterCategory);
            },
          },
          {
            id: orView ? 'consumer-or-provider-is-not' : 'provider-is-not',
            icon: 'filter',
            filterType: orView ? 'consumerOrProviderExclude' : 'providerExclude',
            text: orView
              ? `${intl('Explorer.ConsumerOrProviderIsNot')} ${intl('IPLists.Any')}`
              : `${intl('Explorer.ProviderIsNot')} ${intl('IPLists.Any')}`,
            handleClick: () => {
              applyFilters(id, orView ? 'consumerOrProviderExclude' : 'providerExclude', filterCategory);
            },
          },
        ],
      },
    ],
    filterCategory,
    stickyAppGroupFields,
  );
}

function getGroupMenuGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  return [
    ...(menuGroupsGetterParams.id.includes('open__nodes')
      ? [getCollapsedMenuGroup(menuGroupsGetterParams, intl('Common.Group'))]
      : []),
    ...getProviderAndConsumerMenuGroups(menuGroupsGetterParams),
  ];
}

export function getGroups(menuGroupsGetterParams: MenuGroupsGetterParams): MenuGroup[] {
  const groups: Record<string, MenuGroup[]> = {
    ip_list: getProviderAndConsumerMenuGroups(menuGroupsGetterParams),
    labelsAndLabelGroups: getLabelSetMenuGroups(menuGroupsGetterParams),
    workload: getWorkloadMenuGroups(menuGroupsGetterParams),
    appgroups: getGroupMenuGroups(menuGroupsGetterParams),
  };

  return groups[menuGroupsGetterParams.filterCategory];
}
