/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import produce from 'immer';
import {PureComponent} from 'react';
import {hrefUtils, portUtils} from 'utils';
import {MenuDelimiter, MenuInfo, Pill, type PillProps} from 'components';
import {getServiceDefinitionString, getUpdateTypeProps} from '../PillUtils';
import type {Service as ServiceData} from 'illumio';
import stylesUtils from 'utils.css';

export interface ServiceProps extends PillProps {
  // services or ports object
  value: ServiceData;

  // policy version
  pversion?: string | number;

  // number: show number of ports or port ranges
  // 'all': show all the ports or port ranges
  showPorts?: boolean | number | 'all';
  noIcon?: boolean;

  contextualMenuShowMaxPorts?: number;
}

export default class Service extends PureComponent<ServiceProps> {
  element: HTMLElement | undefined | null = null;

  constructor(props: ServiceProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, {menuStyles, ...options}) => {
    const {value} = this.props;
    const ports = value.windows_services ?? value.service_ports ?? [];

    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (value.description && firstInfo?.attributes) {
        firstInfo.attributes.push({
          key: intl('Common.Description'),
          value: value.description,
          props: {
            style: {'--ellipsis-lines': '3'},
            className: stylesUtils.ellipsisLines,
          },
        });
      }

      if (firstInfo && ports.length) {
        // TODO: use ExpandableList here
        const {contextualMenuShowMaxPorts = 5} = this.props;
        const plusMore = ports.length - contextualMenuShowMaxPorts;

        draft.splice(
          draft.indexOf(firstInfo) + 1,
          0,
          {type: MenuDelimiter, props: {key: 'delimiter2'}},
          {
            type: MenuInfo,
            props: {
              key: 'ports',
              children: (
                <>
                  {ports.slice(0, contextualMenuShowMaxPorts).map(port => {
                    const portString =
                      port.proto === -1 ? intl('Common.AllPortsProtocols') : portUtils.stringifyPort(port);

                    return (
                      <div
                        className={menuStyles.menuInfoSidebar}
                        key={portUtils.stringifyPortObjectReadonly(value, portString)}
                      >
                        <div>{portString}</div>
                        {port.process_name && <div>{port.process_name}</div>}
                        {port.service_name && <div>{port.service_name}</div>}
                      </div>
                    );
                  })}
                  {plusMore > 0 && <div>{intl('Common.CountMore', {count: plusMore})}</div>}
                </>
              ),
            },
          },
        );
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, {menuStyles, ...options}) : menu;
  };

  render() {
    const {value, pversion = 'draft', showPorts = false, noIcon = false, updateTypeTooltip, ...pillProps} = this.props;
    const id = value.id ?? hrefUtils.getId(value.href);
    let content;

    if (id) {
      // When it's a service object that can be linked to its page
      content = value.name;

      Object.assign(pillProps, {
        icon: noIcon ? undefined : pillProps.icon ?? 'service',
        link: pillProps.onClick
          ? null
          : {
              to: 'services.item',
              params: {pversion, id},
            },
        ...getUpdateTypeProps({
          object: 'service',
          updateType: value.update_type,
          updateTypeTooltip,
          deleted: pillProps.deleted,
          pversion,
        }),
      });

      // Show ports inline
      if (showPorts) {
        const ports = getServiceDefinitionString(value.service_ports ?? value.windows_services ?? [], showPorts);

        if (ports) {
          content = (
            <>
              <strong>{content}</strong>
              <div className={stylesUtils.preWrap}>{ports}</div>
            </>
          );
        }
      }

      if (!pillProps.noContextualMenu) {
        pillProps.contextualMenu = this.renderContextualMenu;
        pillProps.contextualType ??= intl('Common.Service');
        pillProps.contextualCopyValue ??= value.name;
      }
    } else {
      // When it's a custom service object with a name,
      // or just a list of ports and protocols
      content = value.name || portUtils.stringifyPortObjectReadonly(value);

      // If the result name is All Services
      if (content === intl('Common.AllServices')) {
        // Still assign the gear icon for All Services,
        // but don't assign the default contextual menu in that case (parent can still specify if needed)
        pillProps.icon = noIcon ? undefined : pillProps.icon ?? 'service';
      } else if (!pillProps.noContextualMenu) {
        pillProps.contextualCopyValue ??= content;

        if (value.name) {
          // If it's custom name Service, show only the Copy action by default,
          // but the type is unknown and can to be passed by parent
          pillProps.contextualMenu = this.renderContextualMenu;
        } else {
          // If it's a list of ports and protocols, the show Type: Port/Protocols
          pillProps.contextualType ??= intl('Common.PortAndOrProtocol');
        }
      }

      // Show ports inline
      if (__ANTMAN__ && showPorts) {
        const ports = getServiceDefinitionString(value.service_ports ?? value.windows_services ?? [], showPorts);

        if (ports) {
          content = (
            <>
              <strong>{content}</strong>
              <div className={stylesUtils.preWrap}>{ports}</div>
            </>
          );
        }

        pillProps.icon = 'service';
      }
    }

    return (
      <Pill ref={this.saveRef} {...pillProps}>
        {content}
      </Pill>
    );
  }
}
