/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, TypedMessages, ToolBar, ToolGroup} from 'components';
import styles from './CustomDurationPicker.css';
import Input from 'components/Form/Input/Input';
import OptionSelector from 'components/Form/OptionSelector/OptionSelector';
import {convertTimeUnitToSeconds, convertSecondsToTimeUnitValue} from './DurationPickerUtils';
import {getTid} from 'utils/tid';

CustomDurationPicker.propTypes = {
  id: PropTypes.string,
  duration: PropTypes.object,
  config: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default function CustomDurationPicker(props) {
  const {config, id, duration, options, onCancel, onApply} = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [valueFieldTouched, setValueFieldTouched] = useState(false);
  const [initialDuration, setInitialDuration] = useState(props.duration);
  const [durationValue, setDurationValue] = useState(props.duration.value);
  const [durationUnit, setDurationUnit] = useState(props.duration.key);
  const isError = errorMessage !== '';
  const isDisabled =
    isError || (initialDuration.value === Number(durationValue) && initialDuration.key === durationUnit);
  const showErrorMessage = isError && (valueFieldTouched || durationValue !== '');
  const tid = getTid('errorMessage', id);
  const smallSelector = {selectorMain: styles.smallContainer};
  const selectedOption = useMemo(
    () => options.find(item => item.value === durationUnit) || options[0],
    [options, durationUnit],
  );
  const validateUserInput = useCallback(
    (value, unit) => {
      const {validation} = config;

      if (!validation) {
        return;
      }

      if (_.trim(value) === '') {
        return validation.required?.message || intl('PasswordPolicy.Errors.Required');
      }

      const num = Number(value);

      if (Number.isNaN(num)) {
        return validation.type?.message || intl('PasswordPolicy.Errors.Number');
      }

      if (!Number.isInteger(num)) {
        return validation.type?.message || intl('PasswordPolicy.Errors.Integer');
      }

      const valueInSeconds = convertTimeUnitToSeconds(num, unit);

      let validHint;

      if (validation.min && validation.max) {
        const min = convertSecondsToTimeUnitValue(validation.min.value, unit);
        const max = convertSecondsToTimeUnitValue(validation.max.value, unit);

        validHint = intl('PasswordPolicy.ValidHint.RangeXtoY', {rangeStart: min, rangeEnd: max});
      }

      if (validation.min && valueInSeconds < validation.min.value) {
        return validation.min.message || validHint;
      }

      if (validation.max && valueInSeconds > validation.max.value) {
        return validation.max.message || validHint;
      }

      return '';
    },
    [config],
  );

  useEffect(() => {
    setInitialDuration(duration);
    setDurationValue(duration.value >= 0 ? duration.value : '');
    setDurationUnit(duration.key !== 'infinity' ? duration.key : 'day');
  }, [duration]);

  useEffect(() => {
    setErrorMessage(validateUserInput(durationValue, durationUnit));
  }, [durationValue, durationUnit, validateUserInput]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleApply = useCallback(
    event => {
      if (onApply) {
        // OptionSelector expects value as a second argument
        onApply(event, {value: Number(durationValue), key: durationUnit});
      }
    },
    [onApply, durationValue, durationUnit],
  );

  const handleBlur = useCallback(() => {
    if (!valueFieldTouched) {
      setValueFieldTouched(true);
    }
  }, [valueFieldTouched]);

  const handleUnitChange = useCallback((event, value) => {
    if (value) {
      setDurationUnit(value.value);
    }
  }, []);

  const handleValueChange = useCallback(event => {
    setDurationValue(event.target.value);
  }, []);

  return (
    <div className={styles.customContainer}>
      <div className={styles.userInputContainer}>
        <Input
          tid="durationValue"
          name="durationValue"
          onBlur={handleBlur}
          error={showErrorMessage}
          value={durationValue}
          onChange={handleValueChange}
          autoFocus
        />
        <OptionSelector
          tid="durationUnit"
          options={options}
          theme={smallSelector}
          name="durationUnit"
          disableErrorMessage
          value={selectedOption}
          onChange={handleUnitChange}
        />
      </div>
      <div className={styles.customBody}>
        <TypedMessages key="status" gap="gapXSmall">
          {[showErrorMessage ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid} : null]}
        </TypedMessages>
      </div>

      <ToolBar justify="flex-end">
        <ToolGroup>
          <Button tid="cancel" noFill text={intl('Common.Cancel')} onClick={handleCancel} />
          <Button tid="apply" text={intl('Common.Apply')} disabled={isDisabled} onClick={handleApply} />
        </ToolGroup>
      </ToolBar>
    </div>
  );
}
