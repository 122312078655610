/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import {useCallback, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {KEY_A, KEY_I} from 'keycode-js';
import {Tooltip, Checkbox, StatusIcon} from 'components';
import {generalUtils, domUtils} from 'utils';
import {useAutoHideTooltip} from './SelectorUtils';
import styleUtils from 'utils.css';
import {getIsCSFrame} from 'containers/App/AppState';

export default function SelectorFooter({
  theme,
  advancedEnabled: advancedEnabledProp,
  filteringTipsContent,
  noFilteringTips,
  onAdvanceEnabled,
  dropdownTippyInstance,
}) {
  const isCSFrame = useSelector(getIsCSFrame);
  const [showTippy, setShowTippy, setSkipAutoHide] = useAutoHideTooltip();
  const [advancedEnabled, setAdvancedEnabled] = useState(advancedEnabledProp);

  const handleShowFilteringTips = useCallback(() => {
    setShowTippy(true);
    setSkipAutoHide(true);
  }, [setShowTippy, setSkipAutoHide]);
  const handleHideFilteringTips = useCallback(() => {
    setShowTippy(false);
    setSkipAutoHide(false);
  }, [setShowTippy, setSkipAutoHide]);

  const handleToggleAdvanced = useCallback(() => {
    onAdvanceEnabled?.(!advancedEnabled);
    setAdvancedEnabled(advancedEnabled => !advancedEnabled);
  }, [onAdvanceEnabled, advancedEnabled]);

  const handleKeyDown = useCallback(
    evt => {
      if (generalUtils.cmdOrCtrlPressed(evt) && (evt.keyCode === KEY_I || evt.keyCode === KEY_A)) {
        domUtils.preventEvent(evt);

        if (evt.keyCode === KEY_I) {
          setShowTippy(true);
        }

        if (evt.keyCode === KEY_A) {
          handleToggleAdvanced(evt);
        }

        return;
      }
    },
    [handleToggleAdvanced, setShowTippy],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const os = generalUtils.isMac() ? 'mac' : 'windows';

  return (
    <div className={cx(theme.footerContent, {[theme.advancedEnabled]: Boolean(onAdvanceEnabled)})}>
      {onAdvanceEnabled && !isCSFrame && (
        <Checkbox
          label={intl('ObjectSelector.AdvancedOptions')}
          checked={Boolean(advancedEnabled)}
          theme={theme}
          themePrefix="advancedOptions-"
          onChange={handleToggleAdvanced}
        />
      )}
      {!noFilteringTips && (
        <Tooltip
          noSingleton
          visible={showTippy}
          content={
            filteringTipsContent ??
            `${intl('ObjectSelector.FilteringTipsContentLabels')}\n${intl('ObjectSelector.FilteringTipsContent')}`
          }
          maxWidth="500px"
          appendTo={dropdownTippyInstance}
        >
          <span
            className={theme.filteringTips}
            onMouseEnter={handleShowFilteringTips}
            onMouseLeave={handleHideFilteringTips}
            data-tid="comp-selector-filteringtips"
          >
            <StatusIcon status="help" />
            {intl('ObjectSelector.FilteringTipsLabel', {os})}
          </span>
        </Tooltip>
      )}
      {!onAdvanceEnabled && (
        <>
          <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
            <span className={styleUtils.bold}>↑↓</span>
            <span>{intl('Common.ToNavigate')}</span>
          </span>
          <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
            <span className={`${styleUtils.bold} ${styleUtils.paddingSmallTop}`}>↵</span>
            <span className={styleUtils.paddingXSmallTop}>{intl('Common.ToSelect')}</span>
          </span>
          <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
            <span className={styleUtils.bold}>esc</span>
            <span>{intl('Common.ToClose')}</span>
          </span>
        </>
      )}
    </div>
  );
}
