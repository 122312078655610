/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {Label} from 'illumio';
import type {ManagedDetails, EndpointDataType, EndType} from '../../MapTypes';
import type {GraphManagedEndpoint, LabelType} from '../MapGraphTypes';

export const getNodeId = (labelsObject: Record<string, Label>, labelTypes: LabelType[]): string => {
  return [...labelTypes]
    .sort((a, b) => (a.key < b.key ? -1 : b.key < a.key ? 1 : 0))
    .map(type => (labelsObject ? `${type.key}:${labelsObject[type.key]?.id || 'discovered'}` : 'discovered'))
    .join(',');
};

export const getLabelIds = (
  labelsObject: Record<string, Label> = {},
  labelTypes: LabelType[],
): Record<string, string> => {
  return labelTypes.reduce((result, type) => {
    const label = labelsObject[type.key];

    if (label) {
      result[type.key] = `${type.key}:${label.id}`;
    } else {
      result[type.key] = `${type.key}:discovered`;
    }

    return result;
  }, {} as Record<string, string>);
};

export const getManagedEndpoint = (
  endpoint: ManagedDetails,
  type: EndpointDataType,
  ip: string,
  labelTypes: LabelType[],
  end: EndType,
): GraphManagedEndpoint => {
  const nodeId =
    end === 'focused'
      ? getNodeId(endpoint.labelObject, labelTypes)
      : `${getNodeId(endpoint.labelObject, labelTypes)}_${end}`;
  const appGroupId = end === 'focused' ? endpoint.appGroupId : `${endpoint.appGroupId}_${end}`;

  return {
    type: 'managedEndpoint',
    managedType: type,
    ...endpoint,
    ip,
    ips: new Set([ip]),
    data:
      endpoint.subType === 'deleted'
        ? {nodes: 'deleted'}
        : {
            ...getLabelIds(endpoint.labelObject, labelTypes),
            nodes: nodeId,
            appGroup: appGroupId,
            endType: end,
          },
  };
};
