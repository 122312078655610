/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {Button, TypedMessages} from 'components';
import Modal from '../Modal';
import styles from './ErrorHandler.css';

export default class ErrorHandlerModal extends PureComponent {
  render() {
    const {
      title = intl('NavigationAlert.Details'),
      children,
      message,
      requestId,
      errorId,
      details,
      isUIAnalyticsEnabled,
      ...modalProps
    } = this.props;

    const id = requestId ?? errorId;
    const idMessage = requestId
      ? intl('NavigationAlert.ProvideRequestId')
      : // only show error id if analytics is enabled and support can utilize the id to search analytics logs
      errorId && isUIAnalyticsEnabled
      ? intl('Error.ProvideErrorId')
      : null;
    const handleClose = modalProps.onClose;

    return (
      <Modal {...modalProps}>
        <Modal.Header title={title} />
        <Modal.Content>
          <span>{message}</span>
          <TypedMessages>
            {[
              details ? {icon: 'error', content: details} : null,
              idMessage
                ? {
                    icon: 'info',
                    content: (
                      <>
                        {idMessage}: <div className={styles.id}>{id}</div>
                      </>
                    ),
                  }
                : null,
            ]}
          </TypedMessages>
        </Modal.Content>
        <Modal.Footer>
          <Button text={intl('Common.Close')} tid="close" onClick={handleClose} />
        </Modal.Footer>
      </Modal>
    );
  }
}
