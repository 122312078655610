/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {createSelector} from 'reselect';
import {Icon, StatusIcon, Badge, Link} from 'components';
import {formatTimeLeft} from 'containers/PairingProfile/PairingProfileUtils';
import {formatDate} from 'components/Grid/GridUtils';
import {hrefUtils, ipUtils} from 'utils';

// Pre-defined policy states array used in select component
export const policyStates = createSelector([], () => [
  {label: intl('Common.Build'), value: 'building'},
  {label: intl('Common.Test'), value: 'testing'},
  {label: intl('Common.Enforced'), value: 'enforced'},
]);

const workloadHealthErrorMessage = createSelector([], () => ({
  security_policy_refresh_failure: intl('Policy.ChangeFailed'),
  tampering_error: intl('Policy.TamperingRevertFailed'),
  secure_connect_error: intl('Policy.SecureConnectionFailed'),
  required_processes_not_running: intl('Policy.RequiredProcessesNotRunning'),
  security_policy_stage_failure: intl('Policy.SecurityPolicyStageFailed'),
  suspended: intl('Policy.SuspendedMessage'),
}));

export const getPolicyStates = includeIdle => {
  if (includeIdle) {
    return [{label: intl('Common.Idle'), value: 'idle'}].concat(policyStates());
  }

  return policyStates();
};

// Returns string key for the policyState
export const getPolicyState = (mode, logTraffic) => {
  switch (mode) {
    case 'idle':
      return 'idle';
    case 'illuminated':
    case 'visibility_only':
      return !logTraffic ? 'building' : 'testing';
    case 'enforced':
    case 'full':
      return 'enforced';
    default:
    // no-default
  }
};

export const policyStatesIntl = createSelector([], () => ({
  idle: intl('Common.Idle'),
  build: intl('Common.Build'),
  test: intl('Common.Test'),
  high: intl('Common.EnforcedHighDetail'),
  low: intl('Common.EnforcedLowDetail'),
  none: intl('Common.EnforcedNoDetail'),
}));

export const enforcementIntl = createSelector([], () => ({
  full: intl('Workloads.Full'),
  selective: intl('Workloads.Selective'),
  visibility_only: intl('Common.VisibilityOnly'),
  idle: intl('Common.Idle'),
}));

export const visibilityIntl = createSelector([], () => ({
  flow_off: intl('Common.Off'),
  flow_drops: intl('Common.Blocked'),
  flow_summary: intl('Map.FlowVisibility.BlockPlusAllowed'),
  enhanced_data_collection: intl('Map.FlowVisibility.EnhancedDataCollection'),
}));

// Get content and icons based on policy state and visibility level
export const getPolicyStateContent = (policyState, visibilityLevel, logLevel, crowdstrikeEnabled) => {
  const result = {};

  switch (policyState) {
    case 'idle':
      result.title = intl('Common.Idle');
      result.content = intl('Policy.IdleDesc');
      result.key = 'idle';
      break;

    case 'building':
    case 'build':
      result.title = intl('Common.Build');
      result.content = intl('Policy.BuildDesc');
      result.key = 'build';
      // TODO: do we need to pass back custom or any visibility level message?
      break;

    case 'testing':
    case 'test':
      result.title = intl('Common.Test');
      result.content = intl('Policy.TestDesc');
      result.key = 'test';
      // TODO: do we need to pass back custom or any visibility level message?
      break;

    case 'illuminated':
    case 'visibility_only':
      if (logLevel) {
        result.title = intl('Common.Test');
        result.content = intl('Policy.TestDesc');
        result.key = 'test';
      } else {
        result.title = intl('Common.Build');
        result.content = intl('Policy.BuildDesc');
        result.key = 'build';
      }

      break;

    case 'enforced':
    case 'full':
      result.title = intl('Common.Enforced');
      result.iconName = 'enforced';

      if (crowdstrikeEnabled) {
        // Override any previously set visiblity levels for enforced in CS.
        // This is in case, low or none (no-detail) was previously selected.
        result.visibilityLevel = intl('Map.FlowVisibility.HighDetail');
        result.content = intl('Map.FlowVisibility.HighDetailDesc');
        result.key = 'high';

        break;
      }

      switch (visibilityLevel) {
        case 'flow_full_detail':
        case 'flow_summary':
          result.visibilityLevel = intl('Map.FlowVisibility.HighDetail');
          result.content = intl('Map.FlowVisibility.HighDetailDesc');
          result.key = 'high';
          break;

        case 'flow_drops':
          result.visibilityLevel = intl('Map.FlowVisibility.LowDetail');
          result.content = intl('Map.FlowVisibility.LowDetailDesc');
          result.key = 'low';
          break;

        case 'flow_off':
          result.visibilityLevel = intl('Map.FlowVisibility.NoDetail');
          result.content = intl('Map.FlowVisibility.NoDetailDesc');
          result.key = 'none';
          break;

        // no default
      }

      break;

    default:
      result.title = intl('Common.Unmanaged');
      result.iconName = 'unmanaged';
      result.iconStyle = 'Unmanaged';
      result.content = intl('Policy.UnmanagedDesc');
      result.key = 'unmanaged';
      break;
  }

  return result;
};

export const getPolicyStateObj = ({mode, log_traffic: logTraffic, visibility_level: visibilityLevel}) => {
  const policyState = getPolicyState(mode, logTraffic);

  return getPolicyStateContent(policyState, visibilityLevel);
};

export const formatPolicyState = data => getPolicyStateObj(data).title;

export const formatWorkloadStateValue = workload => {
  if (!workload.agent.status) {
    return '';
  }

  return formatPolicyState(workload);
};

export const getWorkloadStatus = workload => {
  if (!workload.agent || !workload.agent.status) {
    return 'unmanaged';
  }

  if (!workload.online) {
    return 'offline';
  }

  return 'online';
};

export const getWorkloadName = workload => {
  if (workload.name) {
    return workload.name;
  }

  if (workload.hostname) {
    return workload.hostname;
  }

  if (workload.agent && workload.agent.instance_id) {
    return workload.agent.instance_id;
  }
};

export const getBadgeProps = workload => {
  const {errors, warnings} = workload.agent.status.agent_health_errors;

  if (errors.length > 0) {
    return {type: 'error', label: intl('Common.Error')};
  }

  if (warnings.length > 0) {
    return {type: 'warning', label: intl('Common.Warning')};
  }

  return {type: 'ok', label: 'Common.Active'};
};

export const getVulnerabilityValues = workload => {
  const vulnerabilities = workload.vulnerability_summary;

  if (!vulnerabilities || !vulnerabilities.num_vulnerabilities) {
    return;
  }

  const internetExposure = vulnerabilities.vulnerable_port_wide_exposure;
  const workloadState = formatWorkloadStateValue(workload).toLocaleLowerCase();
  let vulnerabilityExposureScore = vulnerabilities.vulnerability_exposure_score;

  if (vulnerabilityExposureScore !== null) {
    vulnerabilityExposureScore /= 10;
  }

  return {
    severityScore: vulnerabilities.max_vulnerability_score / 10,
    vulnerabilityExposureScore,
    internetExposure: internetExposure.any || internetExposure.ip_list,
    exposureApplicable: Boolean(workloadState) && workloadState !== 'idle',
  };
};

export const getErrorNotification = count => {
  if (count === undefined) {
    return;
  }

  let title;

  if (count.error && count.suspended) {
    title = intl('Workloads.ErrorSuspensionTotal', {
      error: count.error,
      suspended: count.suspended,
    });
  } else if (count.error) {
    title = intl('Workloads.ErrorTotal', {count: count.error});
  } else if (count.suspended) {
    title = intl('Workloads.SuspensionTotal', {
      count: count.suspended,
    });
  }

  if (title) {
    return {type: 'error', title};
  }
};

export const getHealthErrors = workload => {
  const errors = Object.values(workload.agent.status.agent_health_errors).flat();

  // const errors = [].concat.apply([], workload.agent.status.agent_health_errors.values());
  return errors.map(error => workloadHealthErrorMessage()[error] || intl('Error.Unknown'));
};

/**
 * Retrieve the Health status. The return type is used for icon, and label is used for string display.
 */
export const getWorkloadHealthStatus = ({ven, localCluster, userIsSuperclusterMember, online}) => {
  if (!ven || !ven.status || !ven.status.agent_health) {
    return null;
  }

  const {status: {status, security_policy_sync_state: securityPolicySyncState, agent_health: venHealth} = {}} = ven;

  let type;
  let label;

  const severities = venHealth.map(({severity}) => severity);

  if (severities.length) {
    if (severities.includes('error')) {
      type = 'error';

      if (status === 'suspended') {
        label = intl('Workloads.Status.ErrorAndSuspended');
      } else {
        label = intl('Common.Error');
      }
    } else {
      type = 'warning';

      if (status === 'suspended') {
        label = intl('Workloads.Status.WarningAndSuspended');
      } else {
        label = intl('Common.Warning');
      }
    }
  } else if (status === 'suspended') {
    type = 'suspended';
    label = intl('Workloads.Status.Suspended');
  } else if (!online) {
    if (securityPolicySyncState === 'staged') {
      return {type: 'offline', label: getWorkloadStatusIntl(securityPolicySyncState)};
    }

    return null;
  } else {
    let workloadPce;
    let fqdn;
    let pceType;

    if (userIsSuperclusterMember) {
      // If Supercluster member PCE, set the workloadPce and the fqdn
      workloadPce = ven.active_pce_fqdn;

      if (localCluster) {
        fqdn = localCluster.fqdn;
        pceType = localCluster.type;
      }
    }

    if (fqdn === workloadPce || pceType === 'standalone' || pceType === 'leader') {
      type = securityPolicySyncState === 'applied' ? 'check' : securityPolicySyncState;

      if (securityPolicySyncState === 'syncing' && status === 'active') {
        label = intl('Workloads.ActiveSyncing');
      } else {
        label = getWorkloadStatusIntl(securityPolicySyncState);
      }
    } else {
      // No value must be displayed for policy sync here.
      // Reaches when a member is viewing a workload attached to a leader.
      type = 'unknown';
      label = null;
    }
  }

  return {type, label};
};

export const getWorkloadSyncElement = (healthStatus, syncLabel) => {
  if (healthStatus && StatusIcon.icons.includes(healthStatus.type)) {
    return <StatusIcon status={healthStatus.type} label={syncLabel} />;
  }

  return <span data-tid="elem-text">{syncLabel}</span>;
};

// Pre-defined policy states array used in select component
export const enforcedVisibilityLevels = () => [
  {label: intl('Map.FlowVisibility.HighDetail'), value: 'flow_summary'},
  {label: intl('Map.FlowVisibility.LowDetail'), value: 'flow_drops'},
  {label: intl('Map.FlowVisibility.NoDetail'), value: 'flow_off'},
];

// Workload Policy Locks for Workload Policy State
export const workloadPolicyLocks = ['enforcement_mode_lock', 'visibility_level_lock'];

/*
 * Groups errors by tokens.  Expects an input array of objects in the following format:
 *
 *  [
 *    ...
 *    {
 *      "href": "/orgs/1/workloads/1f54b6cb-a9a8-4d3e-b493-405e7e66fd64",
 *      "errors": [
 *        {
 *          "token": "rbac_cannot_operate_on_workload_with_broader_scope",
 *          "message": "You cannot modify Workloads with broader Scope(s) than your permitted Scope(s)"
 *        }
 *      ]
 *    },
 *    ...
 *  ]
 *
 *  It returns an array of objects in the following format:
 *   [
 *      {
 *        token: "rbac_cannot_operate_on_workload_with_broader_scope",
 *        count: 88,
 *        message: "You cannot modify Workloads with broader Scope(s) than your permitted Scope(s)"
 *        href: ["/orgs/1/workloads/1f54b6cb-a9a8-4d3e-b493-405e7e66fd64", ...]
 *      },
 *      ...
 *   ]
 */
export const groupErrorsByTokens = (error = []) => [
  ...error
    .reduce((result, {errors, href}) => {
      if (Array.isArray(errors) && errors.length > 0) {
        const {token, message} = errors[0];

        if (token) {
          const value = result.get(token);

          if (value) {
            value.count++;
            value.href.push(href);
          } else {
            result.set(token, {token, count: 1, message, href: [href]});
          }
        }
      }

      return result;
    }, new Map())
    .values(),
];

// Get the workload lock policy
export const getWorkloadPolicyLocks = bool =>
  workloadPolicyLocks.reduce((lock, cur) => {
    lock[cur] = bool;

    return lock;
  }, {});

// Get friendly name of workload based on several properties
export const friendlyName = workload => {
  if (workload.name) {
    return workload.name;
  }

  if (workload.hostname) {
    return workload.hostname;
  }

  if (workload.agent && workload.agent.instance_id) {
    return workload.agent.instance_id;
  }

  return '';
};

export const noLabelsExistList = [
  intl('Common.NoRoleLabel'),
  intl('Common.NoApplicationLabel'),
  intl('Common.NoEnvironmentLabel'),
  intl('Common.NoLocationLabel'),
];

// External Data Set Partners
// e.g. 'illumio_cs_integration' is set as a external_data_set from the API to indicate specific partner
export const externalDataSetPartners = {
  crowdstrike: 'illumio_cs_integration',
};

// List of External Data Set Partners
export const getExternalDataSetPartnersList = () => Object.values(externalDataSetPartners);

export const workloadHealthTag = (agent, options = {}, localData, online) => {
  if (!agent || !agent.status || !agent.status.agent_health) {
    return null;
  }

  // Possible 'status' values are
  //    ['active', 'suspended', 'uninstalling', 'deactivated_confirmed', 'deactivated_unconfirmed']
  // Possible 'security_policy_sync_state' values are ['syncing', 'staged', 'applied']
  const {status: {status, security_policy_sync_state: securityPolicSyncState, agent_health} = {}} = agent;
  // labelOnly            = only show the text label of the Health state
  // badgeOnlyOnNotOk       = only show the Badge if the health is not ok (i.e. suspended or error)
  // withIcon             = show the corresponding health icon
  // hideAppliedIcon      = hide the icon if the health is "Up to date" (i.e. applied)
  // showTitleOnUnknown   = Show the title tooltip if 'Unknown' Policy Sync
  // isSuperclusterMember = If the PCE is a member PCE in a Supercluster
  // isSummary            = If the function is called from the Summary page
  const {
    labelOnly,
    badgeOnlyOnNotOk,
    withIcon,
    hideAppliedIcon,
    showTitleOnUnknown,
    isSuperclusterMember,
    isSummaryPage,
  } = options;
  let type;
  let label;
  let title;
  let iconName;

  // agent_health is an array of objects with required keys 'type' and 'severity'
  // we discard 'type' in the UI
  // severity can be either 'error' or 'warning'
  const severities = agent_health.map(({severity}) => severity);

  if (severities.length) {
    if (severities.includes('error')) {
      type = 'error';
      iconName = 'error';

      if (status === 'suspended') {
        label = intl('Workloads.Status.ErrorAndSuspended');
      } else {
        label = intl('Common.Error');
      }
    } else {
      type = 'warning';
      iconName = 'warning';

      if (status === 'suspended') {
        label = intl('Workloads.Status.WarningAndSuspended');
      } else {
        label = intl('Common.Warning');
      }
    }
  } else if (status === 'suspended') {
    type = 'error';
    label = intl('Workloads.Status.Suspended');
    iconName = 'suspended';
  } else if (!online) {
    // Convert to this current format
    if (securityPolicSyncState === 'staged') {
      type = 'offline';
      label = getWorkloadStatusIntl(securityPolicSyncState);
    }

    return null;
  } else {
    let workloadPce;
    let fqdn;
    let pceType;

    if (isSuperclusterMember) {
      // If Supercluster member PCE, set the workloadPce and the fqdn
      workloadPce = agent.active_pce_fqdn;

      if (localData) {
        fqdn = _.get(localData, 'fqdn');
        pceType = _.get(localData, 'type');
      }
    }

    if (fqdn === workloadPce || pceType === 'standalone' || pceType === 'leader') {
      // All managed workloads will have security_policy_sync_state
      type = securityPolicSyncState === 'applied' ? 'check' : securityPolicSyncState;

      if (securityPolicSyncState === 'syncing' && status === 'active') {
        // If the Workload is 'active' but also in 'syncing' state
        // This should not happen normally or for a long duration
        label = intl('Workloads.ActiveSyncing');
        iconName = securityPolicSyncState;
      } else {
        label = getWorkloadStatusIntl(securityPolicSyncState);
        iconName = securityPolicSyncState;
      }
    } else {
      // No value must be displayed for policy sync here.
      // Reaches when a member is viewing a workload attached to a leader.
      type = 'unknown';
      label = null;
    }
  }

  // Used in the 'Policy Sync' column in the Workloads List page
  if (labelOnly && withIcon) {
    // If security_policy_sync_state is 'applied'
    if (hideAppliedIcon && iconName === 'applied') {
      return label;
    }

    return (
      <span className={isSummaryPage ? 'WorkloadHealthTag-Summary' : 'WorkloadHealthTag'}>
        {iconName ? <Icon size="large" name={iconName === 'suspended' ? 'suspended-2' : iconName} /> : null}
        <span className="WorkloadHealthTag-Text" title={showTitleOnUnknown ? title : ''}>
          {label}
        </span>
      </span>
    );
  }

  // Used in the list page and Workload Summary page
  if (labelOnly) {
    return label;
  }

  const badge = <Badge type={type}>{label}</Badge>;

  // The Name column in the List page only shows the badge if it is either 'warning' or 'error'
  if (badgeOnlyOnNotOk) {
    return type !== 'ok' ? badge : null;
  }

  return badge;
};

export const workloadUptime = (agent, localData, ishealthEnabled, userIsSupercluster) => {
  const workloadPce = agent.active_pce_fqdn;
  const fqdn = _.get(localData, 'fqdn');
  const pceType = _.get(localData, 'type');

  // If Health is disabled, it means that the PCE is on SaaS which is not Supercluster
  if (
    !ishealthEnabled ||
    fqdn === workloadPce ||
    pceType === 'standalone' ||
    (userIsSupercluster && agent?.status?.uptime_seconds)
  ) {
    return formatTimeLeft(agent.status.uptime_seconds, true, false);
  }

  return intl('Workloads.UnavailableCheckHost', {workloadPce});
};

export const lastHeartbeatOn = (agent, localData, ishealthEnabled, userIsSupercluster) => {
  const workloadPce = agent.active_pce_fqdn;
  const fqdn = _.get(localData, 'fqdn');
  const pceType = _.get(localData, 'type');

  // If Health is disabled, it means that the PCE is on SaaS which is not Supercluster
  if (
    !ishealthEnabled ||
    fqdn === workloadPce ||
    pceType === 'standalone' ||
    (userIsSupercluster && agent?.status?.last_heartbeat_on)
  ) {
    return formatDate(agent.status.last_heartbeat_on);
  }

  return intl('Workloads.UnavailableCheckHost', {workloadPce});
};

// Only show compatibility report tab for idle workloads with 17.1-on VENs
export const showWorkloadReport = workload => {
  const {
    agent: {config, status},
  } = workload;

  // For certain Workloads (like Hawks), the "agent_version" might be null
  return config.mode === 'idle' && status && status.agent_version && parseFloat(status.agent_version, 10) >= 17.1;
};

// map of DN object identifiers to DN attributes - EYE-76995
// https://docs.oracle.com/cd/E19957-01/816-5531-10/app_dn.htm#1018251
// see EYE-76995 for mappings Illumio products can expect
const objectIdentifierToAttribute = new Map([
  ['2.5.4.3', 'CN'],
  ['2.5.4.5', 'SERIALNUMBER'],
  ['2.5.4.6', 'C'],
  ['2.5.4.7', 'L'],
  ['2.5.4.8', 'ST'],
  ['2.5.4.9', 'STREET'],
  ['2.5.4.10', 'O'],
  ['2.5.4.11', 'OU'],
  ['2.5.4.12', 'TITLE'],
  ['1.2.840.113549.1.9.1', 'E'],
  ['0.9.2342.19200300.100.1.1', 'UID'],
  ['0.9.2342.19200300.100.1.2.25', 'DC'],
  ['0.9.2342.19200300.100.1.3', 'MAIL'],
]);

// converts DN object identifiers to DN attributes - EYE-76995
// https://docs.oracle.com/cd/E19957-01/816-5531-10/app_dn.htm#1018251
//
// input: '2.5.4.2=whiskey, 2.5.4.3=alpha, 2.5.4.5=bravo, 1.2.840.113549.1.9.1=charlie, 0.9.2342.19200300.100.1.2.25=delta'
// output: '2.5.4.2=whiskey, CN=alpha, SERIALNUMBER=bravo, E=charlie, DC=delta'
//
// when input is bad - invalid prefixes ("badPrefix") attached to object identifiers & inconsistent whitespace:
//
// input: 2.5.4.2=whiskey,2.5.4.3=alpha,badPrefix2.5.4.5=bravo,  1.2.840.113549.1.9.1=charlie, badPrefix0.9.2342.19200300.100.1.2.25=delta
// output: '2.5.4.2=whiskey,CN=alpha,badPrefix2.5.4.5=bravo,  E=charlie, badPrefix0.9.2342.19200300.100.1.2.25=delta'
//
export const getHumanFriendlySubjectName = subjectName => {
  const regex =
    /\b((2\.5\.4\.(3|[5-9]|1[0-2]))|(1\.2\.840\.113549\.1\.9\.1)|(0\.9\.2342\.19200300\.100\.1\.(1|2\.25|3)))/g;

  return subjectName.replaceAll(regex, identifier => objectIdentifierToAttribute.get(identifier));
};

export const statusIcons = {
  red: {status: 'error'},
  green: {status: 'inuse'},
  yellow: {status: 'warning'},
};

export const getInterfaceDisplay = ({workload, workloadInterface, unmanaged}) => {
  const isExternalPartner = getExternalDataSetPartnersList().includes(workload.external_data_set);
  const text = ipUtils.stringifyAddressObject(workloadInterface, true, isExternalPartner); // network.name if not CS
  let display;

  // set up ignore interface when ignore list is not empty
  if (workload.ignored_interface_names?.includes(workloadInterface.name) && !unmanaged) {
    const ignoredText = ` - ${intl('Workloads.Ignored')}`;
    let name;
    let address;

    // match anything up to ":" (p1) then match everything after ":" (p2)
    // [\w\\.]+:)(.*)  "match anything [\w\.]+ === [A-Za-z0-9_.] up to ":"
    // e.g. text = "eth0.public: 10.1.1.2/24 24.1.1" , p1 === eth0.public:, p2 === 10.1.1.2/24 24.1.1
    text.replace(/([\w.\\]+:)(.*)/g, (match, p1, p2) => {
      if (p1 && p2) {
        name = p1;
        address = p2;
      }
    });

    display = (
      <div key={`${name}-${address}`}>
        <span style={{color: 'var(--orange-700)'}}>{name}</span>
        <span style={{color: 'var(--orange-700)'}}>{address}</span>
        <span style={{color: 'var(--orange-700)'}}>{ignoredText}</span>
      </div>
    );
  } else {
    display = <div key={text}>{text}</div>;
  }

  return display;
};

export const workloadHealthError = agent => {
  const messages = [];

  if (!agent || !agent.status) {
    return messages;
  }

  const healthErrors = agent.status.agent_health;

  if (healthErrors.length) {
    healthErrors.forEach(error => {
      if (error.audit_event) {
        const id = hrefUtils.getId(error.audit_event);

        messages.push(
          <div>
            <Link to="events.detail" params={{id}}>
              {workloadHealthErrorMessage()[error.type]}
            </Link>
          </div>,
        );
      } else {
        messages.push(workloadHealthErrorMessage()[error.type]);
      }
    });
  }

  if (agent.status.status === 'suspended') {
    messages.push(workloadHealthErrorMessage().suspended);
  }

  return messages;
};
