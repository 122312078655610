/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {StatusIcon} from 'components';
import styles from './InfoCard.css';

InfoCardIcon.propTypes = {
  // Apply certain styles to the Icon, to align its svg with any other component like input, selector, etc.
  alignWith: PropTypes.string,
};

function InfoCardIcon({[forwardRefSymbol]: ref, alignWith, ...props}) {
  props.ref = ref;
  props.status = 'info';
  props.tooltip ??= intl('Common.ClickHere');

  props.theme = styles;

  if (alignWith === 'input') {
    props.themePrefix = 'helpIconInput-';
  } else if (alignWith === 'selector') {
    props.themePrefix = 'helpIconSelector-';
  } else {
    props.themePrefix = 'helpIcon-';
  }

  return <StatusIcon {...props} />;
}

export default forwardRefFactory(InfoCardIcon);
