/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, delay, select, put, takeLatest} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {
  getGridUrlParams,
  getUrlColumnsParam,
  getGridDefaultColsSelector,
  getUrlFilterParam,
  getUrlCapacityParam,
  getUrlPageParam,
  getUrlSortParam,
  getValidSortValues,
  getGridConfigSelector,
  getGridColumnIds,
} from './GridSelectors';
import * as GridUtils from './GridUtils';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import {getUserGridSettings, getUserGridOriginSettings} from 'containers/User/UserState';

/**
 * Saga to validate grid url parameters
 *
 * @param {Object} - Options
 *
 * @param {String} [options.id]     - Id of the grid
 * @param {Object} [options.route]  - Route object that represents current params string
 * @param {*} [options.onSaga]      - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                    If specified, will be called before page parameter validation,
 *                                    and expected to return number of rows to correctly validate page parameter
 * @param {*} [options.onRedirect]  - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                    If specified, will be called instead of throwing RedirectError
 *                                    when saga wants to redirect to altered url parameters
 * @param {Object} [options.prefetchSettings=false]  - By default user setting are fetched from api in parallel withn onSaga call.
 *                                                     Set to true to fetch user settings before calling onSaga
 */
export default function* gridSaga(options = {}) {
  const {
    settings: gridSettings,
    filterMap,
    route: {params},
    onSaga,
    onRedirect,
    prefetchSettings = false,
  } = options;

  const settings = yield select(getGridConfigSelector, {settings: gridSettings});
  const filter = yield select(getUrlFilterParam, {settings, filterMap});
  const gridUrlParams = yield select(getGridUrlParams, {settings, route: options.route});
  let gridValidParams = gridUrlParams.valid;
  let capacity = settings.capacity;

  if (!gridUrlParams.isEmpty) {
    // If grid url param contains expected props, validate each prop value separately
    gridValidParams = GridUtils.getValidGridParams(gridValidParams, {
      filter,
      sort: yield select(getUrlSortParam, {settings}),
      columns: yield select(getUrlColumnsParam, {settings}),
      capacity: yield select(getUrlCapacityParam, {settings}),
    });

    if (gridValidParams.hasOwnProperty('capacity')) {
      capacity = gridValidParams.capacity;
    }
  }

  const effects = {};
  const fetchedUserGridSettings = yield select(getUserGridOriginSettings, settings.uniqueId);

  if (fetchedUserGridSettings) {
    // If settings have already been fetched, validate them right away
    yield call(validateAndUpdateGridSettings, settings, fetchedUserGridSettings);
  } else {
    const fetchSettings = call(fetchGridSettings, settings);

    if (prefetchSettings) {
      // If prefetch option is specified, get settings from api before calling onSaga
      yield fetchSettings;
    } else {
      // Otherwise fetch grid settings along with onSaga (if specified) to shorten overall fetching time
      effects.gridSettings = fetchSettings;
    }
  }

  // Call onSaga hook where parent saga can fetch list data and return number of result items
  if (onSaga) {
    effects.listLength = call(onSaga, {gridParams: gridValidParams, filterParams: filter});
  }

  // Now wait for onSaga and optionally user grid settings
  const {listLength = 0} = yield all(effects);

  // Now we know how many items we have in response and can validate page parameter
  if (gridValidParams.hasOwnProperty('page')) {
    let page = yield select(getUrlPageParam, {settings});

    if (!page || page < 2 || listLength <= capacity) {
      page = undefined;
    } else {
      const offset = (page - 1) * capacity;

      if (offset > listLength - 1) {
        page = Math.ceil(listLength / capacity);
      }
    }

    gridValidParams = GridUtils.getValidGridParams(gridValidParams, {page});
  }

  const userGridSettings = yield select(getUserGridSettings, settings.uniqueId);

  // If there is no valid url grid parameters, then we can assign user settings and redirect to url with them
  if (_.isEmpty(gridValidParams) && !_.isEmpty(userGridSettings)) {
    gridValidParams = userGridSettings;
  }

  let redirectTo;

  if (_.isEmpty(gridValidParams)) {
    // If there are still no valid props in grid params, but that param is specified in url (empty or not),
    // then redirect to a url without grid param
    if (params[settings.id] !== undefined) {
      redirectTo = {params: _.omit(params, settings.id)};
    }
  } else if (gridValidParams !== gridUrlParams.passed) {
    // If grid url params were validated and something was removed, or there were user grid settings assigned to them
    // then redirect to url with those parameters
    redirectTo = {params: {...params, [settings.id]: gridValidParams}};
  }

  if (redirectTo) {
    // If you've reached here you might've have left out the grid setting id in your route.
    if (__DEV__) {
      const routeData = options?.route;

      if (!routeData?.route.path.includes(settings.id)) {
        console.error(
          `Please confirm you've added the correct grid setting ID [${settings.id}] to the route path [${routeData.route.path}] of this page.`,
        );
      }
    }

    if (onRedirect) {
      return onRedirect(redirectTo);
    }

    throw new RedirectError({...redirectTo, proceedFetching: true, thisFetchIsDone: false});
  }
}

export function* fetchGridSettings(settings) {
  const fetchedGridSettings = yield call(fetchKvpairs, {key: `grid-${settings.uniqueId}`, force: true});

  if (_.isPlainObject(fetchedGridSettings)) {
    const validUserGridSettings = yield call(validateAndUpdateGridSettings, settings, fetchedGridSettings, false);

    yield put({type: 'GRID_GET_SETTINGS', data: {key: settings.uniqueId, settings: validUserGridSettings}});

    return validUserGridSettings;
  }
}

export function* updateGridSettings({key, data, dispatch = true, merge = false, kvDelay = 2000}) {
  let settings;

  if (merge) {
    settings = {...(yield select(getUserGridSettings, key)), ...data};
  } else {
    settings = data;
  }

  if (dispatch) {
    // Immediately update settings in redux store
    yield put({type: 'GRID_GET_SETTINGS', data: {key, settings}});
  }

  if (kvDelay) {
    // But debounce kvpairs update with some delay in case user changes settings frequently from grid component
    yield delay(kvDelay);
  }

  yield call(updateKvpairs, {key: `grid-${key}`, data: settings});
}

export function* watchUpdateGridSettings() {
  yield takeLatest('GRID_UPDATE_SETTINGS', updateGridSettings);
}

export function* validateAndUpdateGridSettings(settings, userGridSettings, dispatch) {
  const defaultColumns = yield select(getGridDefaultColsSelector, {settings});
  const {seenColumnIds} = userGridSettings;

  // Validate capacity/sort/columns settings
  let validUserGridSettings = GridUtils.getValidGridParams(userGridSettings, {
    capacity: GridUtils.getValidCapacityValue(settings.capacities, userGridSettings.capacity),
    sort: GridUtils.getValidSortValue(yield select(getValidSortValues, {settings}), userGridSettings.sort),
    // If columns are empty, remove it, otherwise check, that it contains existing columns and required ones as well
    columns: _.isEmpty(userGridSettings.columns)
      ? undefined
      : GridUtils.getValidColumns(defaultColumns, userGridSettings.columns),
  });

  // Validate that columns in seenColumnIds are valid
  if (seenColumnIds !== undefined) {
    if (!Array.isArray(seenColumnIds)) {
      validUserGridSettings = _.omit(validUserGridSettings, 'seenColumnIds');
    } else {
      const validSeenColumn = GridUtils.getValidColumns(defaultColumns, seenColumnIds);

      if (validSeenColumn.passed !== validSeenColumn.valid) {
        validUserGridSettings = {
          ...validUserGridSettings,
          seenColumnIds: validSeenColumn.valid,
        };
      }
    }
  }

  // If settings in kvpairs are not valid, immediately update them in store and kvpairs
  if (validUserGridSettings !== userGridSettings) {
    yield call(updateGridSettings, {dispatch, kvDelay: 0, key: settings.uniqueId, data: validUserGridSettings});
  }

  return validUserGridSettings;
}

export function* toggleGridColumns(settings, targetColumns) {
  settings = typeof settings === 'function' ? yield select(settings) : settings;

  const columns = (yield select(getUrlColumnsParam, {settings})).columns;
  const columnIds = yield select(getGridColumnIds, {settings});

  return GridUtils.toggleColumns(columns, columnIds, targetColumns);
}

export function* prepareGridURLParams(settings, params) {
  settings = typeof settings === 'function' ? yield select(settings) : settings;

  const gridParams = yield select(getGridUrlParams, {settings});

  const newGridParams = {
    [settings.id]: {
      ...gridParams.valid,
      ...params,
    },
  };

  return newGridParams;
}
