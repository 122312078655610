/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {RouterProvider} from 'react-router5';
import {Provider} from 'react-redux';
import {SizeWatcherProvider} from 'react-size-watcher';
import {App} from 'containers';
import rootSaga from './sagas';
import configureStore from './configureStore';
import Prefetcher from './router/Prefetcher';
import {processRoutes} from './router/routes';
import configureRouter from './router/configureRouter';
import {getRoleNames, isSuperclusterMember} from 'containers/User/UserState';
import {setRoleNames, setHostIsSupercluserMember} from 'api/apiUtils';
import {
  getLabelSettingsMap,
  getLabelStyle,
  getLabelTypesIndexMap,
} from 'containers/Label/LabelSettings/LabelSettingState';
import {
  setLabelDisplayStyle,
  setLabelSettingsMap,
  setLabelTypesIndexMap,
} from 'containers/Label/LabelSettings/LabelSettingsUtils';

const render = Element => ReactDOM.render(Element, document.querySelector('#root')); // eslint-disable-line react/no-render-return-value

export const init = ({routes, containers, reducers}) => {
  const {routesList, routesMap, defaultRoute} = processRoutes([routes], null, containers);

  const initState = {};

  //Scroll positions are preserved using histoty replaceState method and are restored in prefetcher
  //Set scrollRestoration to manual to avoid any conflict with browser scrollRestoration implementation
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const router = configureRouter(routesList, routesMap, defaultRoute);
  const Store = configureStore(router);
  const store = new Store(reducers, initState);

  store.subscribe(() => {
    const state = store.getState();

    setRoleNames(getRoleNames(state));
    setLabelDisplayStyle(getLabelStyle(state));
    setLabelSettingsMap(getLabelSettingsMap(state));
    setLabelTypesIndexMap(getLabelTypesIndexMap(state));
    setHostIsSupercluserMember(isSuperclusterMember(state));
  });

  const prefetcher = new Prefetcher({store, router, routesList, routesMap});

  router.useMiddleware(() => prefetcher.routeMiddleware);
  store.prefetcher = prefetcher;
  store.runSaga(rootSaga, store);

  // listen to the "error" event and stop "ResizeObserver loop limit exceeded" from propagating
  // to subsequent event listeners
  //
  // This should prevent this error from being reported by LogRocket
  // This doesn't prevent Cypress from catching the error because it register its listener early than this
  // Why do we want to silent it? See comments in: https://jira.illum.io/browse/EYE-95674
  window.addEventListener('error', event => {
    // this check doesn't work on Safari since it's error message is "Script error." which is not unique.
    if (event.message.includes('ResizeObserver loop limit exceeded')) {
      event.stopImmediatePropagation();
      console.warn(event.message);
    }
  });

  if (!__DEV__) {
    // In production just render once on router start
    router.start(() => {
      render(
        <Provider store={store}>
          <SizeWatcherProvider>
            <RouterProvider router={router}>
              <App />
            </RouterProvider>
          </SizeWatcherProvider>
        </Provider>,
      );
    });
  } else {
    const RedBox = require('redbox-react').default;

    /*const {AppContainer} = require('react-hot-loader');*/

    // Unlike prod wrap App in AppContainer from hot-loader with RedBox
    const compose = App => (
      <StrictMode>
        <Provider store={store}>
          <SizeWatcherProvider>
            <RouterProvider router={router}>
              {/*<AppContainer errorReporter={RedBox}>*/}
              <App />
              {/*</AppContainer>*/}
            </RouterProvider>
          </SizeWatcherProvider>
        </Provider>
      </StrictMode>
    );

    router.start(() => {
      try {
        render(compose(App));
      } catch (error) {
        requestAnimationFrame(() => render(<RedBox error={error} />));
      }
    });
  }
};
