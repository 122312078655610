/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import _ from 'lodash';
import intl from 'intl';
import {current} from 'immer';
import {MenuDelimiter, MenuItem, Checkbox} from 'components';
import {ViewOptions, FilterVisibilityOptions} from './MapFilterEditViewConstants';
import {reportPolicyOptions} from 'containers/IlluminationMap/Filter/MapFilterConstants';
import {getOptionId} from 'containers/Selector/SelectorUtils';

/**
 Renders a list of <MenuItem/> for 'More' Button.
 1. Show Exclusion Filters
 2. Show Reported Policy Decision Filters
 ------------------------
 3. Consumer And Provider
 4. Consumer Or Provider
 ------------------------
 5. Results Setting
 */
export function renderFilterSettingsOptions({
  showExclusionFilters = false,
  showReportedPolicyDecisionFilter = false,
  orView = false,
  excludeWorkloadsFromIPListQuery = true,
  onSwitchEditMode,
  onShowHideExclusionFilters,
  onShowHideReportedPolicyDecisionFilter,
  onClickResultsSetting,
  onToggleExcludeWorkloadsFromIPListQuery,
  theme,
}) {
  const fieldVisibilityOptionParams = {
    showReportedPolicyDecisionFilter: {
      handler: onShowHideReportedPolicyDecisionFilter,
      value: showReportedPolicyDecisionFilter,
    },
    showExclusionFilters: {handler: onShowHideExclusionFilters, value: showExclusionFilters},
  };
  const fieldVisibilityOptions = FilterVisibilityOptions.map(option => (
    <MenuItem
      {...{
        data: option.id,
        noCloseOnClick: false,
        tid: `filterVisibilityOption-${option.id}`,
        key: `filterVisibilityOption-${option.id}`,
        theme,
        text: (
          <Checkbox
            iconOn="check"
            iconOff={null}
            label={option.title}
            tid={`filterVisibilityOption-${option.id}-checkbox`}
            key={`filterVisibilityOption-${option.id}-checkbox`}
            checked={fieldVisibilityOptionParams[option.id].value ?? false}
            labelProps={{tid: `filterVisibilityOption-${option.id}-label`}}
            onChange={_.partial(fieldVisibilityOptionParams[option.id].handler, option)}
          />
        ),
      }}
    />
  ));

  const consumerAndOrProviderOptions = ViewOptions.map(option => (
    <MenuItem
      {...{
        data: option.OrViewId,
        noCloseOnClick: false,
        notSelectable: orView === option.OrViewId,
        tid: `filterSettingsConsumerProvider-${option.OrViewId ? 'or' : 'and'}`,
        key: `filterSettingsConsumerProvider-${option.OrViewId ? 'or' : 'and'}`,
        onSelect: _.partial(onSwitchEditMode, option),
        theme,
        text: (
          <Checkbox
            iconOn="check"
            iconOff={null}
            label={option.title}
            tid={`filterSettingsConsumerProvider-checkbox-${option.OrViewId ? 'or' : 'and'}`}
            key={`filterSettingsConsumerProvider-checkbox-${option.OrViewId ? 'or' : 'and'}`}
            disabled={orView === option.OrViewId}
            notChangeable
            checked={orView === option.OrViewId}
            labelProps={{tid: `filterSettingsConsumerProvider-checkboxLabel-${option.OrViewId ? 'or' : 'and'}`}}
          />
        ),
      }}
    />
  ));

  const excludeWorkloadsFromIPListQuerySettingsOption = (
    <MenuItem
      key="excludeWorkloadsFromIPListQuerySetting"
      data="excludeWorkloadsFromIPListQuerySetting"
      tid="excludeWorkloadsFromIPListQuerySetting"
      onSelect={onToggleExcludeWorkloadsFromIPListQuery}
      theme
      text={
        <Checkbox
          iconOn="check"
          iconOff={null}
          label={intl('Explorer.ExcludeWorkloadsFromIpList')}
          tid="excludeWorkloadsFromIPListQuery"
          key="excludeWorkloadsFromIPListQuery"
          notChangeable
          checked={excludeWorkloadsFromIPListQuery}
          labelProps={{tid: 'excludeWorkloadsFromIPListQuery'}}
        />
      }
    />
  );

  const resultsSettingsOption = (
    <MenuItem
      key="filterSettingsResultsSetting"
      data="filterSettingsResultsSetting"
      tid="filterSettingsResultsSetting"
      onSelect={onClickResultsSetting}
      theme
      themePrefix="dropdownColumnsItem-"
      text={
        <Checkbox
          iconOn="settings"
          iconOff={null}
          label={intl('Explorer.ResultsSettings')}
          tid="filterSettingsResultsSetting-checkbox"
          key="filterSettingsResultsSetting-checkbox"
          notChangeable
          labelProps={{tid: 'filterSettingsResultsSetting-checkboxLabel'}}
        />
      }
    />
  );

  return [
    // Add 1. Show Exclusion Filters and 2. Show Reported Policy Decision Filter
    ...fieldVisibilityOptions,

    // Add 3. Consumer And Provider and 4. Consumer Or Provider
    <MenuDelimiter key="divider1" />,
    ...consumerAndOrProviderOptions,

    //  Add 5. Results Setting
    <MenuDelimiter key="divider2" />,
    excludeWorkloadsFromIPListQuerySettingsOption,

    <MenuDelimiter key="divider3" />,
    resultsSettingsOption,
  ];
}

export function getIgnoredFilterCategoriesByFilterType(filterType, stickyAppGroup) {
  let ignoreNonLabelCategories = [];

  if (stickyAppGroup) {
    ignoreNonLabelCategories = ['workload', 'ip_list', 'ip_or_cidr', 'fqdn'];
  }

  return {
    consumerInclude: new Set(['transmission', 'fqdn', ...ignoreNonLabelCategories]),
    consumerExclude: new Set(['appgroups', 'transmission', 'fqdn']),
    providerInclude: new Set(ignoreNonLabelCategories),
    providerExclude: new Set(['appgroups']),
    consumerOrProviderInclude: new Set(ignoreNonLabelCategories),
    consumerOrProviderExclude: new Set(['appgroups']),
    servicesInclude: new Set([]),
    servicesExclude: new Set([]),
  }[filterType];
}

export function getSelectorCategories(categories, fieldId, complementaryMap, stickyAppGroupFields, boundaryCount) {
  if (fieldId === 'policyDecisions') {
    return getReportedPolicyDecisionCategories(complementaryMap, boundaryCount);
  }

  let stickyAppGroup;

  if ((stickyAppGroupFields || []).includes(fieldId)) {
    stickyAppGroup = true;
  }

  const {categoryDefinitions, ignoredCategories, exclude} = {
    consumerInclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('consumerInclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: false,
    },
    consumerExclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('consumerExclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: true,
    },
    providerInclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('providerInclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: false,
    },
    providerExclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('providerExclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: true,
    },
    consumerOrProviderInclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('consumerOrProviderInclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: false,
    },
    consumerOrProviderExclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('consumerOrProviderExclude', stickyAppGroup),
      categoryDefinitions: categories.providerConsumer,
      exclude: true,
    },
    servicesInclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('servicesInclude', stickyAppGroup),
      categoryDefinitions: categories.services,
      exclude: false,
    },
    servicesExclude: {
      ignoredCategories: getIgnoredFilterCategoriesByFilterType('servicesExclude', stickyAppGroup),
      categoryDefinitions: categories.services,
      exclude: true,
    },
  }[fieldId];
  // Common values are always 'or'
  // Resources are 'and' with the sticky app group to prevent getting flows outside the app group
  // Different label types are 'and' in the 'include filter' and 'or' in the 'exclude filter'
  const [valueJoiner, resourceJoiner, labelTypeJoiner] = ['or', stickyAppGroup ? 'and' : 'or', exclude ? 'or' : 'and'];

  return Object.values(categoryDefinitions).reduce((result, category) => {
    if (!ignoredCategories.has(category.id)) {
      result.push(
        _.merge(_.cloneDeep(category), {
          resources: {
            [category.id]: {
              selectedProps: {valueJoiner, resourceJoiner, labelTypeJoiner},
              optionProps: {
                filterOption: option => filterOptionBasedOn({complementaryMap, option, category, exclude}),
              },
            },
          },
        }),
      );
    }

    return result;
  }, []);
}

export const getSelectorTitle =
  title =>
  ({active, values}) =>
    active || values.size ? title : undefined;

export function getReportedPolicyDecisionCategories(policyDecisionsFilter = new Map(), boundaryCount) {
  const policyDecisions = policyDecisionsFilter.get('policy_decisions') ?? [];
  const {boundaryItemSelected, nonBoundaryItemSelected} = policyDecisions.reduce(
    (result, option) => ({
      ...result,
      [option.value.endsWith('by_boundary') ? 'boundaryItemSelected' : 'nonBoundaryItemSelected']: true,
    }),
    {boundaryItemSelected: false, nonBoundaryItemSelected: false},
  );
  const {boundaryOptions, nonBoundaryOptions} = reportPolicyOptions.reduce(
    (result, option) => {
      const type = option.value.endsWith('by_boundary') ? 'boundaryOptions' : 'nonBoundaryOptions';

      return {
        ...result,
        [type]: {...result[type], [option.value]: option},
      };
    },
    {boundaryOptions: {}, nonBoundaryOptions: {}},
  );
  const optionsMap = {
    ...(!nonBoundaryItemSelected && boundaryCount ? boundaryOptions : {}),
    ...(!boundaryItemSelected || !boundaryCount ? nonBoundaryOptions : {}),
  };
  const orderedOptions = [
    'allowed',
    'potentially_blocked',
    'potentially_blocked_by_boundary',
    'blocked',
    'blocked_by_boundary',
    'unknown',
  ].reduce((result, optionKey) => {
    if (optionsMap[optionKey]) {
      result.push(optionsMap[optionKey]);
    }

    return result;
  }, []);

  return [
    {
      id: 'policyDecisions',
      name: intl('Common.ReportPolicy'),
      noActiveIndicator: true,
      insensitive: true,
      template: '',
      resources: {
        policy_decisions: {
          noHistory: true,
          optionProps: {allowMultipleSelection: true, textPath: 'label'},
          selectedProps: {hideResourceName: true},
          statics: orderedOptions,
          onSelect: (event, params) => {
            const {close, value} = params;
            const values = [...(current(params.values).get('policy_decisions') ?? []), ...(value ? [value] : [])];
            const [allBoundaryOptionsSelected, allNonBoundaryOptionsSelected] =
              getPolicyDecisionSelectionDetails(values);

            if (allBoundaryOptionsSelected || allNonBoundaryOptionsSelected) {
              close?.();
            }
          },
          onUnSelect: (event, params) => {
            const {close, value} = params;
            const values = (current(params.values).get('policy_decisions') ?? []).filter(
              item => value.value !== item.value,
            );
            const [allBoundaryOptionsSelected, allNonBoundaryOptionsSelected] =
              getPolicyDecisionSelectionDetails(values);

            if (allBoundaryOptionsSelected || allNonBoundaryOptionsSelected) {
              close?.();
            }
          },
        },
      },
    },
  ];
}

export function filterOptionBasedOn({complementaryMap, option, category, exclude}) {
  if (category.id === 'labelsAndLabelGroups' && /(all|exists)/.test(option.href)) {
    return false;
  }

  if (category.id === 'workload' && exclude && /(all_workloads)/.test(option.value)) {
    return false;
  }

  if (category.id === 'policy_services' && /(all services)/i.test(option.name)) {
    return false;
  }

  if (complementaryMap?.has?.(category.id)) {
    return !complementaryMap
      .get(category.id)
      .some(optionToFilter => getOptionId(optionToFilter) === getOptionId(option));
  }

  return true;
}

export function getPolicyDecisionSelectionDetails(values) {
  const selectedOptions = values.reduce((result, item) => ({...result, [item.value]: true}), {});
  const allBoundaryOptionsSelected =
    values.length &&
    ['blocked_by_boundary', 'potentially_blocked_by_boundary'].reduce(
      (result, key) => Boolean(result && selectedOptions[key]),
      true,
    );
  const allNonBoundaryOptionsSelected =
    values.length &&
    ['allowed', 'blocked', 'potentially_blocked', 'unknown'].reduce(
      (result, key) => Boolean(result && selectedOptions[key]),
      true,
    );

  return [allBoundaryOptionsSelected, allNonBoundaryOptionsSelected];
}

export function getStickyAppGroup(consumerOrProviderInclude, consumerInclude, providerInclude) {
  const stickyAppGroupFilters = [];

  [
    ['consumerOrProviderInclude', consumerOrProviderInclude],
    ['consumerInclude', consumerInclude],
    ['providerInclude', providerInclude],
  ].forEach(([fitlerType, filter]) => {
    const appGroups = filter.get('appgroups');

    if (appGroups && appGroups.some(appGroup => appGroup.detail?.sticky)) {
      stickyAppGroupFilters.push(fitlerType);
    }
  });

  return stickyAppGroupFilters;
}
