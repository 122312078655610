/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {eventUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getStaticMappingEvents, getEventTokenMap} from './EventsListUtils';
import {createSelector} from 'reselect';
import {formatGeneratedBy} from '../EventsUtils';

const objectMap = {
  events: {type: 'events'},
  workloads: {type: 'vens'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {},
  queryMaxDefault: {max_results: 1000},
  staticMap: {
    event_type: {value: intl('Common.Event'), object: objectMap.events},
    severity: {value: intl('Common.Severity'), object: objectMap.events},
    status: {value: intl('Common.Status'), object: objectMap.events},
    timestamp: {value: intl('Common.Timestamp'), object: objectMap.events},
    created_by: {value: intl('Common.GeneratedByUser'), object: objectMap.events},
  },
  staticValues: {
    event_type: getEventTokenMap(eventUtils.getSortedEventTypes()),
    severity: getStaticMappingEvents(eventUtils.EventSeverity()),
    status: getStaticMappingEvents(eventUtils.eventStatus()),
  },
  autocompleteMap: {
    workloads: {value: intl('Common.GeneratedByAgent'), object: objectMap.workloads},
  },
  filterMap: {
    event_type: {value: intl('Common.Event'), object: objectMap.events},
    severity: {value: intl('Common.Severity'), object: objectMap.events},
    status: {value: intl('Common.Status'), object: objectMap.events},
    timestamp: {value: intl('Common.Timestamp'), object: objectMap.events},
    created_by: {value: intl('Common.GeneratedByUser'), object: objectMap.events},
  },
  routeFilterMap: {
    'event_type': intl('Common.Event'),
    'severity': intl('Common.Severity'),
    'status': intl('Common.Status'),
    'timestamp': intl('Common.Timestamp'),
    'created_by': intl('Common.GeneratedBy'),
    'timestamp[gte]': null,
    'timestamp[lte]': null,
  },
}));

export const getFilterPresetSettings = createSelector([getSelectorSettings], selector => {
  const severities = _.keyBy(eventUtils.EventSeverity(), 'value');
  const statuses = _.keyBy(eventUtils.eventStatus(), 'value');

  return [
    {
      category: {value: 'status', label: selector.filterMap.status},
      value: statuses.failure,
    },
    {
      category: {value: 'timestamp', label: selector.filterMap.timestamp},
      value: {
        from: () => {
          const startOfToday = new Date();

          startOfToday.setHours(0);
          startOfToday.setMinutes(0);
          startOfToday.setSeconds(0);

          return startOfToday;
        },
        to: () => new Date(),
        label: intl('Common.Today'),
      },
    },
    {
      category: {value: 'severity', label: selector.filterMap.severity},
      value: severities.err,
    },
    {
      category: {value: 'severity', label: selector.filterMap.severity},
      value: severities.warning,
    },
  ];
});

/**
 [{
  header: string | Function,

  cellKey: string | Function,
  cellValue: string | Function,
  cellFormat: Function,
  sortValue: Function,
  unsortable: boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'eventlist',
  sort: '-timestamp',
  sortedNaturallyBy: '-timestamp',
  capacities: [25, 50, 100, 250, 500, 1000],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    event: {
      linky: true,
      header: intl('Common.Event'),
      value: ({row}) => row.data.event_type,
    },
    description: {
      header: intl('Common.Description'),
      value: ({row}) => eventUtils.getEventType(row.data.event_type, true),
    },
    severity: {
      header: intl('Common.Severity'),
      value: ({row}) => eventUtils.getEventSeverity(row.data.severity),
    },
    state: {
      header: intl('Common.Status'),
      value: ({row}) => eventUtils.getEventStatusLabel(row.data.status) || intl('Common.NA'),
    },
    timestamp: {
      header: intl('Common.Timestamp'),
      isDate: 'L_HH_mm_ss',
      value: 'timestamp',
    },
    generatedBy: GridUtils.clickableColumn({
      header: intl('Common.GeneratedBy'),
      value: ({row}) => row.data.hostname ?? eventUtils.getEventGeneratedBy(row.data.created_by),
      format: ({row, clickableRef}) => row.data.hostname ?? formatGeneratedBy(row.data.created_by, {ref: clickableRef}),
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to cellFormat function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to cellFormat function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to cellFormat function
    data: ?object,
  }];
   */

  templates: [
    [
      {columns: ['event'], size: 'minmax(120px, auto)'},
      {columns: ['description'], size: 'minmax(120px, auto)'},
      {columns: ['severity'], size: 'minmax(120px, auto)'},
      {columns: ['state'], size: 'minmax(80px, auto)'},
      {columns: ['timestamp'], size: 'minmax(120px, auto)'},
      {columns: ['generatedBy'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event'], size: 'minmax(100px, auto)'},
            {columns: ['description'], size: 'minmax(100px, auto)'},
            {columns: ['severity'], size: 'minmax(100px, auto)'},
            {columns: ['state'], size: 'minmax(70px, auto)'},
            {columns: ['timestamp'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event'], size: 'minmax(100px, auto)'},
          {columns: ['description'], size: 'minmax(100px, auto)'},
          {columns: ['severity'], size: 'minmax(100px, auto)'},
          {columns: ['state'], size: 'minmax(70px, auto)'},
          {columns: ['timestamp'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event'], size: 'minmax(100px, auto)'},
            {columns: ['description'], size: 'minmax(100px, auto)'},
            {columns: ['severity'], size: 'minmax(100px, auto)'},
            {columns: ['state'], size: 'minmax(70px, auto)'},
            {columns: ['timestamp'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event'], size: 'minmax(100px, auto)'},
          {columns: ['description'], size: 'minmax(100px, auto)'},
          {columns: ['severity'], size: 'minmax(100px, auto)'},
          {columns: ['state'], size: 'minmax(70px, auto)'},
          {columns: ['timestamp'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description'], size: 'minmax(120px, auto)'},
            {columns: ['severity', 'state'], size: 'minmax(100px, auto)'},
            {columns: ['timestamp', 'generatedBy'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description'], size: 'minmax(120px, auto)'},
          {columns: ['severity', 'state'], size: 'minmax(100px, auto)'},
          {columns: ['timestamp', 'generatedBy'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description'], size: 'minmax(100px, auto)'},
            {columns: ['severity', 'state'], size: 'minmax(90px, auto)'},
            {columns: ['timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description'], size: 'minmax(100px, auto)'},
          {columns: ['severity', 'state'], size: 'minmax(90px, auto)'},
          {columns: ['timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description', 'severity'], size: 'minmax(100px, auto)'},
            {columns: ['state', 'timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description', 'severity'], size: 'minmax(100px, auto)'},
          {columns: ['state', 'timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
