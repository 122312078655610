/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {combineReducers} from 'redux';
import {groupName} from './EndpointUtils';
import EndpointEnforcementListReducers from './EndpointEnforcement/List/EndpointEnforcementListState';

export default {
  endpoints: combineReducers({
    ...EndpointEnforcementListReducers,
    list(state = [], action) {
      switch (action.type) {
        case 'SET_ENDPOINTS':
          return action.data.list;
        default:
          return state;
      }
    },

    count(state = {}, action) {
      switch (action.type) {
        case 'SET_ENDPOINTS':
          return action.data.count;
        default:
          return state;
      }
    },

    endpointgroups(state = {}, action) {
      switch (action.type) {
        case 'SET_ENDPOINT_GROUP':
          return produce(state, draft => {
            const key = action.data.name === groupName ? 'group' : 'adminGroup';

            draft[key] = action.data;
          });
        case 'SET_ENDPOINT_PAIRING_PROFILES':
          return produce(state, draft => {
            const {userPairingScriptData, adminPairingScriptData} = action.data;

            draft.userPairingScriptData = userPairingScriptData;
            draft.adminPairingScriptData = adminPairingScriptData;
          });
        default:
          return state;
      }
    },

    recommendationDecisions(state = {}, action) {
      switch (action.type) {
        case 'SET_RECOMMENDATION_DECISION':
          return produce(state, draft => {
            draft[action.data.service] = action.data.decision;
          });
        default:
          return state;
      }
    },
  }),
};

export const getEndpointGroups = state => state.endpoints.endpointgroups;
export const getEndpointsCount = state => state.endpoints.count?.matched;
export const getEndpointsList = state => state.endpoints.list;
export const getRecommendationDecision = state => state.endpoints.recommendationDecisions;
