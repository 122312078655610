/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from 'intl';
import {convertLegacyQueries, getExplorerQuery} from 'containers/IlluminationMap/Filter/MapFilterApiUtils';

export const getLatestUniquelyLabelledQueries = (queries = [], max) => {
  const uniqueQueries = new Map();
  const sortedQueries = queries.slice().sort((queryA, queryB) => {
    if (!queryA?.date && !queryB?.date) {
      return 0;
    }

    if (!queryA?.date) {
      return 1;
    }

    if (!queryB?.date) {
      return -1;
    }

    return new Date(queryB.date).getTime() - new Date(queryA.date).getTime();
  });

  sortedQueries.some(query => {
    if (max !== undefined && uniqueQueries.size >= max) {
      return true;
    }

    if (!uniqueQueries.get(query.label)) {
      uniqueQueries.set(query.label, query);
    }

    return false;
  });

  return Array.from(uniqueQueries.values());
};

export function getFormattedDays(nDays) {
  if (nDays <= 1) {
    return intl('Common.HoursNumber', {count: 24});
  }

  if (nDays > 90) {
    return intl('DateTimeInput.Anytime', {count: nDays});
  }

  return intl('Common.DaysNumber', {count: nDays});
}

export const transformQueries = ({queries, appGroups, userSettings, services}) =>
  convertLegacyQueries(
    (queries || []).filter(({filters}) => filters && !filters.dateFrom && !filters.dateTo),
    appGroups,
    services,
  ).map(({filters, queryName}) => ({
    ...getExplorerQuery({filters, nameTag: ''}),
    query_name: queryName,
    max_results: userSettings.explorerMaxDownloadResults,
  }));
