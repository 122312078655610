/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useMemo} from 'react';
import * as PropTypes from 'prop-types';
import LabelSelector from 'containers/Selector/LabelSelector';
import {ADD_NEW_LABEL_ID} from 'containers/Selector/Presets';

ScopeSelector.propTypes = {
  scope: PropTypes.instanceOf(Map),
  onSelectionChange: PropTypes.func.isRequired,
};

// useMemo dependency
const emptyMap = new Map();

export default function ScopeSelector({scope = emptyMap, onSelectionChange}) {
  const initialItems = useMemo(() => (scope ? Array.from(scope.values()) : []), [scope]);

  const handleLabelChange = useCallback(
    selection => {
      const final = selection.reduce(
        (result, {key, href, value, name}) => result.set(key, {key, value, href, name}),
        new Map(),
      );

      onSelectionChange(final);
    },
    [onSelectionChange],
  );

  return (
    <LabelSelector
      tid="labels"
      value={Object.values(initialItems)}
      onChange={handleLabelChange}
      group
      labelsResourceModifier={{
        apiArgs: {params: {pversion: 'active'}},
        allowCreateOptions: (query, exactMatches) => {
          const showLabelCreate = !exactMatches.some(({href}) => href.includes('labels'));

          return showLabelCreate
            ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}]
            : [];
        },
      }}
    />
  );
}
