/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createShallowEqualSelector} from 'utils/general';
import {ipv4ToInt} from 'utils/ip';
import {
  formatFlowsAndBytes,
  formatArrow,
  getEnforcement,
  getIpAddressOrIpCount,
  getManagedEndpointPill,
  getIpListsOrAggregatedLists,
  formatPortProtocol,
  formatProcess,
  formatWindowsService,
  formatUserName,
  formatNetworkProfile,
  getFQDN,
  formatPolicyDecision,
  formatConnectionCount,
  getUserServices,
  sortCheck,
  formatHeader,
  getSummaryColumn,
} from './MapListUtils';
import {isManagedEndpoint} from '../MapTypes';
import {getPort} from 'containers/Service/ServiceUtils';
import _ from 'lodash';
import {clickableColumn} from 'components/Grid/GridUtils';
import {createCachedSelector} from 're-reselect';
import {getLabelTypeList} from 'containers/Label/LabelSettings/LabelSettingState';
import {getMapRouteParams} from 'containers/IlluminationMap/MapState';
import {getAnyIPList} from 'containers/IPList/Item/IPListItemState';
import {allSubColConfig} from 'containers/Label/LabelConfig';
import {getFQDNPill, getLabelsPill, getTransmissionPill} from './MapListPillUtils';

const templates = providerConsumerOrder => [
  providerConsumerOrder === 'consumerFirst'
    ? [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['policy'], size: 'minmax(100px, 200px)'},
        {columns: ['source'], size: 'minmax(160px, 300px)'},
        {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
        {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
        {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
        {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
        {columns: ['target'], size: 'minmax(160px, 300px)'},
        {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
        {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
        {columns: ['targetService'], size: 'minmax(160px, 280px)'},
        {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
        {columns: ['firstDetected'], size: 'minmax(110px, 110px)'},
        {columns: ['lastDetected'], size: 'minmax(110px, 110px)'},
      ]
    : [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['policy'], size: 'minmax(100px, 200px)'},
        {columns: ['target'], size: 'minmax(160px, 300px)'},
        {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
        {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
        {columns: ['targetService'], size: 'minmax(100px, 180px)'},
        {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
        {columns: ['source'], size: 'minmax(160px, 300px)'},
        {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
        {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
        {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
        {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
        {columns: ['firstDetected'], size: 'minmax(110px, 140px)'},
        {columns: ['lastDetected'], size: 'minmax(110px, 140px)'},
      ],
  {
    maxWidth: 1700,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 200px)'},
            {columns: ['source'], size: 'minmax(160px, 300px)'},
            {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
            {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
            {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['target'], size: 'minmax(160px, 300px)'},
            {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
            {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
            {columns: ['targetService'], size: 'minmax(160px, 280px)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
            {columns: ['firstDetected', 'lastDetected'], size: 'minmax(110px, 110px)'},
          ]
        : [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 150px)'},
            {columns: ['target'], size: 'minmax(160px, 300px)'},
            {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
            {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
            {columns: ['targetService'], size: 'minmax(100px, 180px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['source'], size: 'minmax(160px, 300px)'},
            {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
            {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
            {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
            {columns: ['firstDetected', 'lastDetected'], size: 'minmax(110px, 140px)'},
          ];
    },
  },
  {
    maxWidth: 1500,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(auto, 125px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 400px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, 400px)'},
            {columns: ['targetService'], size: 'minmax(125px, 180px)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
            {columns: ['firstDetected', 'lastDetected'], size: 'minmax(110px, 125px)'},
          ]
        : [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, auto)'},
            {columns: ['targetService'], size: 'minmax(125px, 2180px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, auto)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
            {columns: ['firstDetected', 'lastDetected'], size: 'minmax(110px, 125px)'},
          ];
    },
  },
  {
    maxWidth: 1152,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 400px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, 400px)'},
            {columns: ['targetService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(100px, 130px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, 400px)'},
            {columns: ['targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 400px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ];
    },
  },
  {
    maxWidth: 960,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 400px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, 400px)'},
            {columns: ['targetService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(100px, 100px)'},
            {columns: ['targetLabels', 'target'], size: 'minmax(auto, 400px)'},
            {columns: ['targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 400px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ];
    },
  },
  {
    maxWidth: 640,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 275px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetLabels', 'target', 'targetService'], size: 'minmax(auto, 200px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 125px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['targetLabels', 'target', 'targetService'], size: 'minmax(auto, 400px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 275px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 125px)',
            },
          ];
    },
  },
  {
    maxWidth: 400,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 275px)'},
            {columns: ['targetLabels', 'target', 'targetService'], size: 'minmax(auto, 200px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 120px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['sourceLabels', 'source', 'sourceService'], size: 'minmax(auto, 275px)'},
            {columns: ['targetLabels', 'target', 'targetService'], size: 'minmax(auto, 200px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 120px)',
            },
          ];
    },
  },
];

const summaryTemplates = providerConsumerOrder => [
  providerConsumerOrder === 'consumerFirst'
    ? [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['policy'], size: 'minmax(100px, 150px)'},
        {columns: ['source'], size: 'minmax(125px, 300px)'},
        {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
        {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
        {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
        {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
        {columns: ['target'], size: 'minmax(125px, 300px)'},
        {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
        {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
        {columns: ['targetService'], size: 'minmax(200px, 280px)'},
        {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
        {columns: ['firstDetected'], size: 'minmax(110px, 110px)'},
        {columns: ['lastDetected'], size: 'minmax(110px, 110px)'},
      ]
    : [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['policy'], size: 'minmax(100px, 150px)'},
        {columns: ['target'], size: 'minmax(125px, 300px)'},
        {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
        {columns: ['targetSummary'], size: 'minmax(180px, auto)'},
        {columns: ['targetService'], size: 'minmax(100px, 180px)'},
        {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
        {columns: ['source'], size: 'minmax(125px, 300px)'},
        {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
        {columns: ['sourceSummary'], size: 'minmax(180px, auto)'},
        {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
        {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
        {columns: ['firstDetected'], size: 'minmax(110px, 140px)'},
        {columns: ['lastDetected'], size: 'minmax(110px, 140px)'},
      ],
  {
    maxWidth: 1366,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(auto, 125px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
            {columns: ['firstDetected'], size: 'minmax(110px, 125px)'},
            {columns: ['lastDetected'], size: 'minmax(110px, 125px)'},
          ]
        : [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, auto)'},
            {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
            {columns: ['firstDetected'], size: 'minmax(110px, 125px)'},
            {columns: ['lastDetected'], size: 'minmax(110px, 125px)'},
          ];
    },
  },
  {
    maxWidth: 1152,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, 350px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(100px, 130px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ];
    },
  },
  {
    maxWidth: 960,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['policy'], size: 'minmax(100px, 125px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(100px, 100px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(140px, 150px)',
            },
          ];
    },
  },
  {
    maxWidth: 640,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(auto, 275px)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 125px)',
            },
          ]
        : [
            {columns: ['checkboxes', 'policy'], size: 'minmax(90px, 100px)'},
            {columns: ['targetSummary', 'targetService'], size: 'minmax(125px, auto)'},
            {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
            {columns: ['sourceSummary', 'sourceService'], size: 'minmax(auto, 275px)'},
            {
              columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(120px, 125px)',
            },
          ];
    },
  },
  {
    maxWidth: 400,
    template() {
      return providerConsumerOrder === 'consumerFirst'
        ? [
            {
              columns: ['checkboxes', 'policy', 'sourceSummary', 'sourceService'],
              size: 'minmax(100px, auto)',
            },
            {
              columns: ['targetSummary', 'targetService', 'flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(125px, auto)',
            },
          ]
        : [
            {
              columns: ['checkboxes', 'policy', 'targetSummary', 'targetService'],
              size: 'minmax(auto, 250px)',
            },
            {
              columns: ['sourceSummary', 'sourceService', 'flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
              size: 'minmax(125px, auto)',
            },
          ];
    },
  },
];

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const getGridSettings = createShallowEqualSelector(
  options => options,
  ({
    providerConsumerOrder = 'consumerFirst',
    policyVersion = 'reported',
    connection = 'aggregated',
    summary = true,
    colorDeficiency,
    sourceLabelsColumn,
    targetLabelsColumn,
    isCSFrame,
  }) => {
    return {
      id: 'maplist',
      sort: '-lastDetected',
      // API returns data already sorted by last detected https://jira.illum.io/browse/EYE-93678
      sortedNaturallyBy: '-lastDetected',
      capacities: [25, 50, 100, 250, 500],
      capacity: 50,
      maxPage: Number.MAX_SAFE_INTEGER,
      showColumns: true,
      showCapacity: true,
      showPagination: true,
      showManager: true,
      columns: {
        checkboxes: {},
        policy: {
          header: policyVersion === 'reported' ? intl('Common.ReportedPolicy') : intl('Common.DraftPolicy'),
          columns: {
            policyDecision: {
              header: intl('Explorer.Policy'),
              value: ({row}) => row.data,
              sort: ({value}) => value.policy[policyVersion]?.decision,
              format: ({value, component}) =>
                formatPolicyDecision(value, policyVersion, connection, colorDeficiency, component, isCSFrame),
            },
            direction: {
              header: intl('Explorer.Reporter'),
              disabled: connection === 'aggregated',
              value: ({row}) => row.data.direction,
              format: () => null,
              sort: ({value}) => value,
            },
            state: {
              header: intl('Common.ConnectionState'),
              optional: true,
              disabled: connection === 'aggregated',
              value: ({row}) => row.data.state,
              sort: ({value}) => value,
            },
          },
          templates: ['policyDecision', ...(connection === 'aggregated' ? [] : ['direction', 'state'])],
        },
        sourceSummary: {
          header: intl('Common.Consumer'),
          value: 'source',
          disabled: !summary,
          manager: summary,
          horizontal: true,
          ...sourceLabelsColumn,
        },
        source: {
          header: intl('Common.Consumer'),
          disabled: summary,
          manager: !summary,
          columns: {
            sourceName: {
              header: intl('Common.Name'),
              disabled: connection === 'aggregated',
              value: 'source',
              format: ({value, component}) => getManagedEndpointPill(value, component),
              sort: ({value}) => (isManagedEndpoint(value) ? value.details.name : null),
            },
            sourceIpOrCount: {
              header: intl('Common.IPAddress'),
              value: 'source',
              format: ({value, component}) => getIpAddressOrIpCount(value, 'source', connection, component),
              sort: ({value}) => (connection === 'aggregated' ? value.ips?.size : ipv4ToInt(value.ip)),
              sortFunction: sortCheck,
            },
            sourceNetworkProfile: {
              header: [intl('Edge.NetworkProfile')],
              value: ({row}) => row.data.network.name,
              format: ({value}) => value && formatNetworkProfile(value),
            },
            sourceFqdn: {
              header: intl('Common.ConsumerFqdn'),
              value: ({row}) => getFQDN(row.data.source), // TODO!!!!!!  dnsAddresses[rowA.dst_ip]
              format: ({value, component}) => getFQDNPill(value, value, component),
              sort: ({value = '\uFFFF'}) => value?.toLocaleLowerCase().split('.').reverse().join(''),
              sortFunction: sortCheck,
            },
            sourceMode: {
              header: intl('Common.Enforcement'),
              value: 'source',
              format: ({value}) => getEnforcement(value, connection),
            },
            sourceItemsList: {
              header: intl('Common.Items'),
              value: 'source',
              format: ({row, value, component}) =>
                getIpListsOrAggregatedLists(value, row.data, 'source', connection, null, component),
            },
          },
          templates: !isCSFrame
            ? [
                ...(connection === 'aggregated' ? [] : ['sourceName']),
                'sourceIpOrCount',
                'sourceNetworkProfile',
                'sourceFqdn',
                'sourceMode',
                'sourceItemsList',
              ]
            : [...(connection === 'aggregated' ? [] : ['sourceName']), 'sourceIpOrCount', 'sourceItemsList'],
        },
        sourceLabels: {
          header: intl('Explorer.ConsumerLabels'),
          horizontal: true,
          disabled: summary,
          manager: !summary,
          ...sourceLabelsColumn,
        },
        sourceService: {
          header: formatHeader(intl('Port.ConsumerProcess'), intl('Users.User')),
          horizontal: true,
          optional: summary,
          columns: {
            sourceProcess: {
              header: intl('Common.Process'),
              format: ({row, component}) => formatProcess(row.data.service.outbound, component),
            },
            sourceWindowsService: {
              header: intl('Services.WindowsService'),
              format: ({row, component}) => formatWindowsService(row.data.service.outbound, component),
            },
            sourceUser: {
              header: intl('Users.User'),
              format: ({row}) => formatUserName(row.data.service.outbound),
            },
          },
          templates: ['sourceProcess', 'sourceWindowsService', 'sourceUser'],
          ...(isCSFrame && {disabled: true}),
        },
        consumerToProviderArrow: {
          header: formatArrow(null, policyVersion, providerConsumerOrder, colorDeficiency),
          manager: false,
          sortable: false,
          style: 'consumerToProviderArrow',
          format: ({row}) => formatArrow(row.data, policyVersion, providerConsumerOrder, colorDeficiency, isCSFrame),
        },
        targetSummary: {
          header: intl('Common.Provider'),
          value: 'target',
          disabled: !summary,
          manager: summary,
          horizontal: true,
          ...targetLabelsColumn,
        },
        target: {
          header: intl('Common.Provider'),
          disabled: summary,
          manager: !summary,
          columns: {
            targetName: {
              header: intl('Common.Name'),
              disabled: connection === 'aggregated',
              value: 'target',
              format: ({value, component}) => getManagedEndpointPill(value, component),
              sort: ({value}) => (isManagedEndpoint(value) ? value.details.name : null),
            },
            targetIpOrCount: {
              header: intl('Common.IPAddress'),
              value: 'target',
              format: ({value, component}) => getIpAddressOrIpCount(value, 'target', connection, component),
              sort: ({value}) => (connection === 'aggregated' ? value.ips?.size : ipv4ToInt(value.ip)),
              sortFunction: sortCheck,
            },
            targetNetworkProfile: {
              header: intl('Edge.NetworkProfile'),
              value: ({row}) => row.data.network.name,
              format: ({value}) => value && formatNetworkProfile(value),
            },
            targetFqdn: {
              header: intl('Common.ProviderFqdn'),
              value: ({row}) => getFQDN(row.data.target),
              format: ({value, component}) => getFQDNPill(value, value, component),
              sort: ({value = '\uFFFF'}) => value?.toLocaleLowerCase().split('.').reverse().join(''),
              sortFunction: sortCheck,
            },
            targetTransmission: {
              header: intl('Common.TransmissionMode'),
              value: 'target',
              format: ({value, component}) =>
                value.transmission !== 'Unicast' ? getTransmissionPill(value, component) : null,
            },
            targetMode: {
              header: intl('Common.Enforcement'),
              value: 'target',
              sort: ({value}) => getEnforcement(value),
              format: ({value}) => getEnforcement(value),
            },
            targetItemsList: {
              header: intl('Common.Items'),
              value: 'target',
              format: ({row, value, component}) =>
                getIpListsOrAggregatedLists(value, row.data, 'target', connection, null, component),
            },
          },
          templates: !isCSFrame
            ? [
                ...(connection === 'aggregated' ? [] : ['targetName']),
                'targetIpOrCount',
                'targetNetworkProfile',
                'targetFqdn',
                'targetTransmission',
                'targetMode',
                'targetItemsList',
              ]
            : [
                ...(connection === 'aggregated' ? [] : ['targetName']),
                'targetIpOrCount',
                'targetTransmission',
                'targetMode',
                'targetItemsList',
              ],
        },
        targetLabels: {
          header: intl('Explorer.ProviderLabels'),
          disabled: summary,
          manager: !summary,
          horizontal: true,
          ...targetLabelsColumn,
        },
        targetService: {
          header: formatHeader(intl('Port.ProviderPortProcessService'), summary ? '' : intl('Users.User')),
          horizontal: true,
          columns: {
            portProtocol: {
              header: intl('Port.Protocol'),
              value: 'service',
              format: ({value, component}) => formatPortProtocol(value, component),
              sort: ({value}) => getPort(value),
            },
            targetProcess: {
              header: intl('Common.Process'),
              format: ({row, component}) => formatProcess(row.data.service.inbound, component),
            },
            targetWindowsService: {
              header: intl('Services.WindowsService'),
              format: ({row, component}) => formatWindowsService(row.data.service.inbound, component),
            },
            targetUser: {
              header: intl('Users.User'),
              format: ({row}) => formatUserName(row.data.service.inbound),
            },
            targetServices: {
              header: intl('Common.Services'),
              format: ({row, component}) => getUserServices(row.data.service?.services, summary, component),
            },
          },
          templates: !isCSFrame
            ? ['portProtocol', 'targetProcess', 'targetWindowsService', 'targetUser', 'targetServices']
            : ['portProtocol'],
        },
        flowsConnectionsAndBytes: {
          header: `${intl('Common.Flows')}/${intl('Common.Bytes')}`,
          optional: summary,
          columns: {
            connections: {
              header: intl('Common.Connections'),
              disabled: connection !== 'aggregated',
              value: ({row}) => parseInt(row.data.connectionCount, 10),
              format: ({value}) => formatConnectionCount(value),
            },
            flowsAndBytes: {
              header: intl('Common.Flows'),
              value: ({row}) => parseInt(row.data.flows, 10),
              format: ({row}) => formatFlowsAndBytes(row.data),
            },
          },
          templates: ['connections', 'flowsAndBytes'],
          ...(isCSFrame && {disabled: true}),
        },
        firstDetected: {
          optional: summary,
          isDate: 'L_HH_mm_ss',
          header: intl('Explorer.FirstDetected'),
          value: 'firstDetected',
        },
        lastDetected: {
          optional: summary,
          isDate: 'L_HH_mm_ss',
          header: intl('BlockedTraffic.List.LastDetected'),
          value: 'lastDetected',
        },
      },
      templates: summary ? summaryTemplates(providerConsumerOrder) : templates(providerConsumerOrder),
    };
  },
);

const getColumnFormatCallback = (key, direction) => {
  return ({row, clickableRef, component}) => {
    const endpoint = row.data[direction];
    const label = endpoint.details.labelObject?.[key] || null;

    if (_.isEmpty(endpoint.details.labelObject) || !endpoint.details.labelObject) {
      return null;
    }

    return isManagedEndpoint(endpoint) && label ? getLabelsPill(key, label, clickableRef, component) : null;
  };
};

const getColumnSortCallback = (key, direction) => {
  return ({row}) => {
    const endpoint = row.data[direction];
    const label = endpoint.details.labelObject?.[key] || null;

    return label?.value || label?.name;
  };
};

const clickableExplorerLabelConfig = (key, display_name, direction) => {
  return clickableColumn({
    header: display_name,
    noPadding: true,
    format: getColumnFormatCallback(key, direction),
    sort: getColumnSortCallback(key, direction),
  });
};

const getTemplates = (summary, direction) => {
  const templates = [];

  if (summary) {
    templates.push('name', 'ipList');

    if (direction === 'target') {
      templates.push('transmission');
    }
  }

  return templates;
};

export const getExplorerLabelsColumn = direction => {
  return createCachedSelector(
    [getLabelTypeList, (state, props) => Boolean(props?.hasAll), getMapRouteParams, getAnyIPList],
    (labelTypeList, hasAll, {connection = 'aggregated', display = 'table'}, anyList) => {
      return {
        header: direction === 'target' ? intl('Explorer.ProviderLabels') : intl('Explorer.ConsumerLabels'),
        horizontal: true,
        columns: labelTypeList.reduce(
          (result, {key, display_name}) => ({
            ...result,
            [key]: clickableExplorerLabelConfig(key, display_name, direction),
          }),
          {
            ...(hasAll && {all: allSubColConfig}),
            ...getSummaryColumn(
              direction,
              connection,
              display === 'map',
              Object.values([
                ...(hasAll ? ['all'] : []),
                ...labelTypeList.map(({key}) => key),
                ...getTemplates(display === 'map'),
              ]).every(column => column.value),
              anyList,
            ),
          },
        ),
        templates: [
          ...(hasAll ? ['all'] : []),
          ...labelTypeList.map(({key}) => key),
          ...getTemplates(display === 'map', direction),
        ],
      };
    },
  )((state, props) => (props?.hasAll ? 'hasAll' : 'noAll'));
};
