/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {isUserReadOnly, isUserScoped} from 'containers/User/UserState';
import {isEdge} from 'containers/App/AppState';

export default {
  offlineSettings(state = [], action) {
    switch (action.type) {
      case 'WORKLOADS_GET_SETTINGS':
        return action.data.settings;
      case 'UPDATE_WORKLOADS_SETTINGS':
        return action.settings;
      default:
        return state;
    }
  },
};

export const getSettings = state => state.offlineSettings;

export const getWorkloadSettings = createSelector(
  [getSettings, isUserReadOnly, isEdge],
  (data, isUserReadOnly, edgeEnabled) => {
    const disconnectTimeout = data.workload_disconnected_timeout_seconds.find(obj => !obj.scope.length);
    const uninstallTimeout = data.ven_uninstall_timeout_hours.find(obj => !obj.scope.length);
    const goodbyeTimeout = data.workload_goodbye_timeout_seconds.find(obj => !obj.scope.length);
    const disconnectNotificationTimeout = data.workload_disconnected_notification_seconds.find(
      obj => !obj.scope.length,
    );

    const settings = {
      disconnectTimeout: disconnectTimeout?.value,
      goodbyeTimeout: goodbyeTimeout?.value,
      disconnectNotificationTimeout: disconnectNotificationTimeout?.warning,
      uninstallTimeout: uninstallTimeout?.value,
    };

    return {...settings, isUserReadOnly, edgeEnabled};
  },
);

export const isOfflineTimersEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
