/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import apiSaga from 'api/apiSaga';
import {select, call, all} from 'redux-saga/effects';
import {Pill} from 'components';
import {getParameterizedPathLoosely, edge} from 'api/apiUtils';
import {getOrgId} from 'containers/User/UserState';
import LabelEdit from 'containers/Label/Edit/LabelEdit';
import LabelGroupEdit from 'containers/LabelGroup/Edit/LabelGroupEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {sortOptions} from './SelectorUtils';
import {getLabelsQueryParams} from './SelectorSaga';
import styleUtils from 'utils.css';
import styles from './Selector.css';
import {getDisplayNames, getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';

export const ADD_NEW_LABEL_ID = 'ADD_NEW_LABEL_ID';
export const ADD_NEW_LABEL_GROUP_ID = 'ADD_NEW_LABEL_GROUP_ID';

export const formatSelectedLabel = ({value: label, disabled, theme, onRemove, highlighted, onClick}) => (
  <Pill.Label
    noContextualMenu
    insensitive
    theme={theme}
    themePrefix={highlighted ? 'pillHighlighted-' : undefined}
    onClick={disabled ? undefined : onClick}
    onClose={disabled ? undefined : onRemove}
    type={label.key}
    group={label.href.includes('label_groups')}
  >
    {label.value ?? label.name}
  </Pill.Label>
);

const getNoLabelHref = (orgId, key) =>
  getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

export const reshuffleLabels = (evt, args) => {
  const {value, values, resource} = args;
  const objType = resource.id;

  if (values.has(objType)) {
    // Selected labels needs to be re-shuffled:
    // move all the selected labels that have the same type as the incoming selection to the end of the selection
    const [labelsInSelectedType, labelsInRemainingTypes] = _.partition(
      values.get(objType),
      ({key}) => key === value.key,
    );

    values.set(objType, [...labelsInRemainingTypes, ...labelsInSelectedType]);
  }
};

export function populateLabelTypesCategory({showInitial = false} = {}) {
  return {
    id: 'labelTypes',
    name: intl('Labels.Type'),
    resources: {
      labelTypes: {
        name: intl('Common.Type'),
        *statics({query}) {
          const setting = yield select(getLabelSetting);

          return sortOptions(
            setting.map(({display_info: {initial}, display_name, key, href}) => ({
              href,
              key,
              value: display_name,
              initial,
            })),
            query,
          );
        },
        optionProps: {
          idPath: 'key',
          format({option, formattedText}) {
            const label = (
              <Pill.Label noAffix position="before" type={option.key} noContextualMenu>
                {formattedText}
              </Pill.Label>
            );

            return showInitial ? (
              <div className={cx(styleUtils.gapXSmall, styleUtils.gapHorizontal, styleUtils.gapAlignBaseline)}>
                <div style={{width: 'var(--30px)', textAlign: 'right'}}>{option.initial}:</div>
                {label}
              </div>
            ) : (
              label
            );
          },
        },
      },
    },
  };
}

export function enableLabelTypeShortcut(
  labelResources,
  {labelResourceId = 'labelsAndLabelGroups', labelTypeInitialRegExp},
) {
  if (!labelTypeInitialRegExp) {
    throw new TypeError('Selector Labels category with label type shortcut must provide the prefix regular expression');
  }

  labelResources.labelTypes = populateLabelTypesCategory({showInitial: true}).resources.labelTypes;
  labelResources.labelTypes.hidden = ({keyword}) => keyword !== ':';

  labelResources.labelTypes.queryKeywordsRegex = labelTypeInitialRegExp;
  labelResources.labelTypes.onSelect = (evt, {values, setQuery, value}) => {
    setQuery(`${value.initial}:`);

    return values;
  };

  labelResources[labelResourceId].hidden = ({keyword}) => keyword === ':';

  return labelResources;
}

/**
 * This function always create a new object so consumer
 * can mutate the object freely using _.merge().
 * @param {{
 * labelTypesNameObj?: Record<string, string>,
 * labelTypeInitialRegExp?: RegExp,
 * hasTypeList?: boolean,
 * hasLabelGroups?: boolean,
 * resourceType?: string,
 * query?: Record<string, string>,
 * params?: Record<string, string>,
 * hasExists?: boolean,
 * hasAll?: boolean,
 * allowMultipleSelection?: boolean,
 * allowCreateOptions?: boolean | {},
 * }} param0
 * @returns
 */
export function populateLabelsCategory({
  labelTypesNameObj,
  labelTypeInitialRegExp,
  // whether show the label type shortcut
  hasTypeList = true,
  hasLabelGroups = true,
  resourceType,
  query,
  params,
  hasExists = false,
  hasAll = false,
  allowMultipleSelection = true,
  allowCreateOptions,
  tooltipProps,
  onSelect,
}) {
  // cloneDeep so mutation on the object doesn't leak into other objects
  // e.g. `query` or `params`
  const resources = _.cloneDeep({
    labelsAndLabelGroups: {
      *dataProvider(apiOptions) {
        const {exclude_labels = [], exclude_label_groups = [], ...query} = apiOptions.query ?? {};

        query.max_results = 25;

        const {pversion = 'draft', ...params} = apiOptions.params ?? {};

        const [{data: {matches: labels = []} = {}}, {data: labelGroups} = {}] = yield all([
          call(apiSaga, 'labels.autocomplete', {
            params,
            query: {...query, ...(exclude_labels.length && {exclude_labels: JSON.stringify(exclude_labels)})},
          }),
          ...(hasLabelGroups
            ? [
                call(apiSaga, 'label_groups.autocomplete', {
                  params: {...params, pversion},
                  query: {
                    ...query,
                    ...(exclude_label_groups.length && {
                      exclude_label_groups: JSON.stringify(exclude_label_groups),
                    }),
                  },
                }),
              ]
            : []),
        ]);

        let options = labels;

        if (labelGroups) {
          options = [
            ...options,
            ...(labelGroups.matches?.map(({name, ...labelGroup}) => ({...labelGroup, value: name})) ?? []),
          ];
        }

        const orgId = yield select(getOrgId);
        const labelTypesNameObj = yield select(getDisplayNames);

        let noLabels = [];

        if (!apiOptions.hideAllOrNoLabels || !hasAll) {
          noLabels = Object.entries(labelTypesNameObj).reduce(
            (result, [key, name]) => [
              ...result,
              {href: `all_${key}`, value: intl('Common.AllLabels', {name}), key},
              {href: getNoLabelHref(orgId, key), value: intl('Common.NoLabels', {name}), key},
            ],
            [],
          );
        }

        if (query.key || query.exclude_keys) {
          noLabels = noLabels.filter(
            ({key}) => (!query.key || query.key === key) && !query.exclude_keys?.includes(key),
          );
        }

        if (query.query) {
          return sortOptions([...noLabels, ...options], query.query);
        }

        return [...noLabels, ...sortOptions(options, query.query)];
      },
      includeSelectedResources: ['labelsAndLabelGroups'],
      queryKeywordsRegex: labelTypeInitialRegExp,
      apiArgs: {
        query: {
          resource_type: resourceType,
          getQuery: getLabelsQueryParams,
          ...(edge ? {key: 'role'} : {}),
          ...query,
        },
        ...(params && {params}),
      },
      optionProps: {
        isPill: true,
        format: ({option: label, fomattedOption}) => {
          if (label.isCreate) {
            return fomattedOption;
          }

          return (
            <Pill.Label noContextualMenu insensitive type={label.key} group={label.href.includes('label_groups')}>
              {label.value ?? label.name}
            </Pill.Label>
          );
        },
        filterOption: option =>
          (hasLabelGroups || !option.href?.includes('label_groups')) &&
          (hasExists || !option.href?.includes('exists')) &&
          (hasAll || !option.href?.includes('all')),
        allowMultipleSelection,
        tooltipProps: tooltipProps || {
          content: ({option: label}) => labelTypesNameObj?.[label.key],
        },
        hint: option => labelTypesNameObj?.[option.key],
      },
      selectedProps: {
        valueJoiner: 'and',
        hideResourceName: true,
        isPill: false,
        pillPropsResource: {theme: styles, themePrefix: 'joinerPill-', noContextualMenu: true},
        formatValue: ({value, onRemove, theme, onClick, disabled, highlighted}) => (
          <Pill.Label
            noContextualMenu
            position="before"
            type={value.key}
            group={value.href.includes('label_groups')}
            theme={theme}
            insensitive
            themePrefix={highlighted ? 'joinerValuePillHighlighted-' : 'joinerValuePill-'}
            onClick={disabled ? undefined : onClick}
            onClose={disabled ? undefined : onRemove}
          >
            {value.value || value.name}
          </Pill.Label>
        ),
        formatResource: ({valuesInResource, formatContent, resource: {selectedProps}, theme}) => {
          const labelsByTypeArray = Object.values(_.groupBy(valuesInResource, 'key'));

          return labelsByTypeArray.map((labelsByType, index) => {
            return (
              <div
                key={index}
                className={cx(styleUtils.gapInline, styleUtils.gapHorizontal, styleUtils.gapAlignBaseline)}
              >
                {index > 0 && selectedProps.resourceJoiner && (
                  <div className={theme.joiner}>{selectedProps.resourceJoiner}</div>
                )}
                {formatContent(labelsByType)}
              </div>
            );
          });
        },
      },
      allowCreateOptions:
        allowCreateOptions ??
        ((query, exactMatches) => {
          const showLabelGroupCreate = hasLabelGroups && !exactMatches.some(({href}) => href.includes('label_groups'));
          const showLabelCreate = !exactMatches.some(({href}) => href.includes('labels'));

          return [
            ...(showLabelCreate
              ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}]
              : []),
            ...(showLabelGroupCreate
              ? [{id: ADD_NEW_LABEL_GROUP_ID, value: `${query} (${intl('LabelGroups.New')})`, isCreate: true}]
              : []),
          ];
        }),
      onCreateEnter: ({id}) => (id === ADD_NEW_LABEL_ID ? 'labelForm' : 'labelGroupForm'),
      onSelect: onSelect || reshuffleLabels,
      conflict: (selected, incoming) => {
        if (incoming.resource.optionProps?.allowMultipleSelection) {
          return false;
        }

        return selected.resource.id === 'allLabel' || selected.value.key === incoming.value.key;
      },
    },
    labelForm: {
      type: 'container',
      enableFocusLock: true,
      selectIntoResource: 'labelsAndLabelGroups',
      container: LabelEdit,
      unsavedWarningData: {...getDiscardChangesModalProps('label')},
      hidden: true,
      queryKeywordsRegex: labelTypeInitialRegExp,
      containerProps: {
        controlled: true,
        buttonAlign: 'bottom',
        formProps: {id: 'labelForm'},
        getContainerProps: ({query, onDone, onCancel}) => ({
          label: {value: query},
          onDone,
          onCancel,
        }),
      },
    },
    labelGroupForm: {
      type: 'container',
      enableFocusLock: true,
      selectIntoResource: 'labelsAndLabelGroups',
      container: LabelGroupEdit,
      unsavedWarningData: {...getDiscardChangesModalProps('label_group')},
      hidden: true,
      queryKeywordsRegex: labelTypeInitialRegExp,
      containerProps: {
        controlled: true,
        buttonAlign: 'bottom',
        formProps: {id: 'labelGroupForm'},
        getContainerProps: ({query, onDone, onCancel}) => ({
          labelGroup: {
            detail: {draft: {name: query}},
          },
          onDone,
          onCancel,
        }),
      },
    },
  });

  return {
    id: 'labelsAndLabelGroups',
    name: edge
      ? intl('Common.Group')
      : hasLabelGroups
      ? intl('Rulesets.Rules.LabelAndLabelGroups')
      : intl('Common.Labels'),
    resources: hasTypeList ? enableLabelTypeShortcut(resources, {labelTypeInitialRegExp}) : resources,
  };
}

export const CategoryPresets = {
  labelsAndLabelGroups: populateLabelsCategory,
  service: {
    id: 'service',
    name: intl('Common.Service'),
    resources: {
      service: {
        dataProvider: 'services.autocomplete',
        apiArgs: {params: {pversion: 'draft'}},
        selectedProps: {
          hideResourceName: true,
          pillPropsValue: {icon: 'service', noContextualMenu: true},
        },
        optionProps: {
          format: ({option}) => <Pill.Service value={option} insensitive noContextualMenu />,
        },
      },
    },
  },
};
