/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import produce from 'immer';
import {connect, type ConnectedProps} from 'react-redux';
import {PureComponent} from 'react';
import Pill, {type ContextualMenuItem} from '../Pill';
import {isEdge, getIsCSFrame} from 'containers/App/AppState';
import {hrefUtils} from 'utils';
import type {PillDiffProps} from '../PillDiff';
import type {Rule} from 'illumio';
import type {WithDefaultProps, WithElement} from 'utils/react';
import type {IPListProps} from '../IPList/IPList';

const connector = connect(state => ({isEdge: isEdge(state), isCSFrame: getIsCSFrame(state)}), null, null, {
  forwardRef: true,
});

export interface EndpointProps extends Omit<PillDiffProps, 'value' | 'oldValue'> {
  // Rule type: 'provider' or 'consumer'
  type: 'providers' | 'consumers' | 'actors';

  value: Rule;
  oldValue?: Rule;

  // value policy version
  pversion?: string | number;
  // whether or not consuming_security_principals should be used to render user group entities
  showUserGroups?: boolean;

  showGroupTooltip?: boolean;
  showIPTooltip?: boolean;
  noIcon?: IPListProps['noIcon'];
  notExpandable?: boolean;
}

type EndpointPropsIn = Readonly<
  WithDefaultProps<EndpointProps, typeof Endpoint.defaultProps> & ConnectedProps<typeof connector>
>;

class Endpoint extends PureComponent<EndpointPropsIn> {
  static defaultProps = {
    pversion: 'draft',
    showUserGroups: false,
    showGroupTooltip: false,
    showIPTooltip: false,
  };

  element: Map<string, HTMLElement>;

  constructor(props: EndpointPropsIn) {
    super(props);

    this.element = new Map();

    this.saveRef = this.saveRef.bind(this);
    this.formatRuleEntities = this.formatRuleEntities.bind(this);
    this.renderUserGroupMenu = this.renderUserGroupMenu.bind(this);
  }

  private saveRef(key: string, element: WithElement | null) {
    // Since we have an array of pills, save ref in a Map and remove it when unmounted
    // One of the usage example is in grid row hover:
    // saved ref is used to check if pointer is currently above the element (not above whole cell that can be wider),
    // this enable click/mouseOver handlers to be passed to the element
    if (element?.element) {
      // used element.element for the link
      this.element.set(key, element.element);
    } else {
      this.element.delete(key);
    }
  }

  private formatRuleEntities(rule: Rule) {
    const {type, pversion, showUserGroups, showGroupTooltip, showIPTooltip, isEdge, noIcon, isCSFrame} = this.props;

    const entities =
      rule[type]?.map(entity => {
        if (entity.actors === 'ams') {
          // ams: All Managed Workloads
          return {
            key: 'ams',
            pill: (
              <Pill
                ref={_.partial(this.saveRef, 'ams')}
                icon={isEdge || noIcon ? undefined : 'all-workloads'}
                link={{to: 'workloads.list'}}
                insensitive={isEdge}
                contextualType={isEdge ? intl('Common.AllGroups') : intl('Workloads.All')}
                contextualCopyValue={isEdge ? intl('Common.AllGroups') : intl('Workloads.All')}
              >
                {isEdge ? intl('Common.AllGroups') : intl('Workloads.All')}
              </Pill>
            ),
          };
        }

        if (entity.actors === 'container_host') {
          return {
            key: 'container_host',
            pill: (
              <Pill ref={_.partial(this.saveRef, 'container_host')} icon="all-workloads">
                {intl('Common.ContainerHost')}
              </Pill>
            ),
          };
        }

        if (entity.label) {
          const {key, value, href} = entity.label;

          return {
            key: entity.exclusion ? `${hrefUtils.getId(href)}, exclusion` : hrefUtils.getId(href),
            pill: isEdge ? (
              <Pill.Group
                value={entity.label}
                key={entity.label.href ?? entity.label.id}
                queryParams={{tab: 'outboundpolicy'}}
                tooltip={showGroupTooltip && intl('Pill.Tooltip.ViewObject', {object: 'group'})}
              >
                {value}
              </Pill.Group>
            ) : (
              <Pill.Label
                ref={_.partial(this.saveRef, hrefUtils.getId(href))}
                type={key}
                href={href}
                exclusion={entity.exclusion}
              >
                {value}
              </Pill.Label>
            ),
          };
        }

        if (entity.label_group) {
          const {key, name, href} = entity.label_group;

          return {
            key: entity.exclusion ? `${hrefUtils.getId(href)}, exclusion` : hrefUtils.getId(href),
            pill: (
              <Pill.Label
                ref={_.partial(this.saveRef, hrefUtils.getId(href))}
                group
                type={key}
                href={href}
                pversion={pversion}
                exclusion={entity.exclusion}
              >
                {name}
              </Pill.Label>
            ),
          };
        }

        if (entity.ip_list) {
          /** Note: it is possible that label groups and ip_list can have the same href thus will clash if the are part of the same
           * data set. When passing value to Pill.Diff, React will send warning that two keys cannot be the same.
           * @example
           *  By calling both with : hrefUtils.getId(href), they will have the exact same key thus causing React key warning
           *  [{ip_list: {href: "/orgs/1/sec_policy/draft/ip_lists/1"}}
           *  {label: {href: "/orgs/1/labels/1"}}]
           *
           *  @example
           *   Pill.Diff will send warning message when both keys are the same thus need to make them unique
           *  ...value.map(({key, pill}) => {
           *  return cloneElement(pill, {key, tid: 'unchanged', ...pillProps});
           * }),
           */
          const key = `${hrefUtils.getId(entity.ip_list.href)}.ip_list`;

          return {
            key,
            pill: (
              <Pill.IPList
                noIcon={noIcon}
                ref={_.partial(this.saveRef, key)}
                value={entity.ip_list}
                pversion={pversion}
                tooltip={showIPTooltip && intl('Pill.Tooltip.ViewObject', {object: isEdge ? 'ipRange' : 'ipList'})}
              />
            ),
          };
        }

        if (entity.workload) {
          const key = hrefUtils.getId(entity.workload.href);

          return {
            key,
            pill: <Pill.Workload ref={_.partial(this.saveRef, key)} value={entity.workload} isCSFrame={isCSFrame} />,
          };
        }

        if (entity.virtual_service) {
          const key = hrefUtils.getId(entity.virtual_service.href);

          return {
            key,
            pill: (
              <Pill.VirtualService
                ref={_.partial(this.saveRef, key)}
                value={entity.virtual_service}
                pversion={pversion}
              />
            ),
          };
        }

        if (entity.virtual_server) {
          const key = hrefUtils.getId(entity.virtual_server.href);

          return {
            key,
            pill: (
              <Pill.VirtualServer
                ref={_.partial(this.saveRef, key)}
                value={entity.virtual_server}
                pversion={pversion}
              />
            ),
          };
        }

        return;
      }) || [];

    const resolveLabelsAsString = [..._.get(rule, `resolve_labels_as.${type}`, [])].sort().join(',');

    // Add virtual services usage entity
    if (resolveLabelsAsString.includes('virtual_services')) {
      entities.push({
        key: resolveLabelsAsString,
        pill: (
          <Pill>
            {intl(
              resolveLabelsAsString === 'virtual_services,workloads'
                ? 'Common.UsesVirtualServicesWorkloads'
                : 'Common.UsesVirtualServices',
            )}
          </Pill>
        ),
      });
    }

    if (rule.use_workload_subnets?.includes(type)) {
      entities.push({
        key: intl('Rulesets.Rules.UseWorkloadSubnets'),
        pill: <Pill>{intl('Rulesets.Rules.UseWorkloadSubnets')}</Pill>,
      });
    }

    if (showUserGroups) {
      rule.consuming_security_principals?.forEach(userGroup => {
        const {href, sid, name, deleted} = userGroup;
        const id = hrefUtils.getId(href) ?? sid;

        entities.push({
          key: id,
          pill: (
            <Pill
              ref={_.partial(this.saveRef, id)}
              disabled={deleted}
              icon="org"
              link={id ? {to: 'userGroups.item', params: {id}} : undefined}
              tooltip={deleted ? intl('Pill.Tooltip.UserGroupIsDeleted') : undefined}
              contextualType={intl('Common.UserGroup')}
              contextualCopyValue={name}
              contextualMenu={_.partial(this.renderUserGroupMenu, id)}
            >
              {name}
            </Pill>
          ),
        });
      });
    }

    return entities;
  }

  private renderUserGroupMenu(id: string, items: ContextualMenuItem[]) {
    return produce(items, menu => {
      if (id) {
        menu[0].attributes?.push({key: intl('UserGroups.SID'), value: id});
      }
    });
  }

  render() {
    const {
      value: rule,
      oldValue: oldRule,
      type,
      showUserGroups,
      showGroupTooltip,
      showIPTooltip,
      extraScope,
      pversion,
      isEdge,
      dispatch,
      noIcon,
      isCSFrame,
      ...extraProps
    } = this.props;

    // not sure why we don't have consistency here
    extraProps.hideIcon = noIcon;

    const pillDiffProps: PillDiffProps = extraProps;

    pillDiffProps.value = this.formatRuleEntities(rule);

    if (oldRule) {
      pillDiffProps.oldValue = this.formatRuleEntities(oldRule);
    } else {
      pillDiffProps.noDiff = true;
    }

    return <Pill.Diff {...pillDiffProps} />;
  }
}

// export the internal Endpoint instance type only in type context
export type {Endpoint};

const ConnectedEndpoint = connector(Endpoint);

export default ConnectedEndpoint;
