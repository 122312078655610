/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getId} from 'utils/href';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {serverEnforcementListGridSettings} from './ServerEnforcementListConfig';

export default {
  unenforcedServers(state = [], action) {
    switch (action.type) {
      case 'SET_UNENFORCED_SERVERS':
        return action.data;
      default:
        return state;
    }
  },
};

const getUnenforcedServers = state => state.servers.unenforcedServers;

const getUnenforcedServerRows = createSelector(getUnenforcedServers, servers =>
  servers.map(server => {
    const serverName = server.hostname || server.name;

    return {
      key: serverName,
      data: {
        hostname: serverName,
        enforcementMode: server.enforcement_mode,
        id: getId(server.href),
        ...server,
      },
    };
  }),
);

const getServerListGrid = state =>
  getGridSelector(state, {
    settings: serverEnforcementListGridSettings,
    rows: getUnenforcedServerRows,
  });

export const getServerEnforcementPage = createSelector([getServerListGrid], grid => ({
  grid,
}));
