/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {createSelector, createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {serverLabelingListGridSettings, rulePreviewGridSettings} from './ServerLabelingConfig';
import {getServerRows} from '../ServersState';
import {getRuleTemplates} from 'antman/containers/TemplatesWizard/TemplatesWizardState';
import {getHelpUrl, getHelpVersion} from 'containers/App/AppState';

export default {
  customServerPolicies(state = {}, action) {
    switch (action.type) {
      case 'SET_SERVER_POLICY':
        const {href, selectedProtectionSchema} = action.data;

        if (href) {
          return {...state, [href]: selectedProtectionSchema};
        }

        return action.data;
      default:
        return state;
    }
  },

  rulePreview(state = {}, action) {
    switch (action.type) {
      case 'GET_SERVER_RULES_PREVIEW':
        return action.data;
      default:
        return state;
    }
  },

  serverRoleLabelRecommendations(state = [], action) {
    switch (action.type) {
      case 'SET_SERVER_LABEL_RECOMMENDATIONS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getCustomServerPolicies = state => state.customServerPolicies;
const getServerRoleLabelRecommendations = state => state.serverRoleLabelRecommendations;
const getOverrideDenyRules = state => state.rulePreview.override_deny_rules ?? [];
const getAllowRules = state => state.rulePreview.sec_rules ?? [];
const getDenyRules = state => state.rulePreview.deny_rules ?? [];

const getServerRoleLabelRecommendationsMap = createSelector(getServerRoleLabelRecommendations, labelRecommendations =>
  labelRecommendations.reduce(
    (map, {server_role, label_recommendations, protection_schema}) =>
      map.set(server_role, {
        protection_schema,
        label_recommendations,
      }),
    new Map(),
  ),
);

export const getServerRowsWithPolicySelections = createSelector(
  [getServerRows, getCustomServerPolicies, getRuleTemplates, getServerRoleLabelRecommendationsMap],
  (serverRows, customPolicies, ruleTemplates, serverRoleLabelRecommendationsMap) => {
    return serverRows.map(serverRow => {
      return produce(serverRow, draft => {
        const {priorityServerRole, href} = serverRow.data;

        // only recommend a template if the template exists; default to 'None' even if server role is set
        const templateExists = Boolean(serverRoleLabelRecommendationsMap.get(priorityServerRole));
        const serverRole = templateExists ? priorityServerRole : undefined;
        const selectedProtectionSchema = customPolicies[href] ?? serverRole ?? 'None';
        const protectionSchemaDetails = serverRoleLabelRecommendationsMap.get(selectedProtectionSchema);
        const serverRules =
          ruleTemplates.find(({external_data_reference}) => external_data_reference === selectedProtectionSchema) ?? {};

        // anything after hyphen is unique identifier
        draft.data.recommendedPolicyName =
          protectionSchemaDetails?.protection_schema ?? serverRules.name?.split(' - ')[0] ?? priorityServerRole;
        draft.data.priorityServerRole = serverRole;
        draft.data.selectedProtectionSchema = selectedProtectionSchema;
        draft.data.serverPolicy = {
          rules: serverRules,
          count:
            serverRules?.deny_rules && serverRules?.sec_rules
              ? serverRules.deny_rules.length + serverRules.sec_rules.length
              : 0,
        };
        draft.data.labelsMap =
          serverRoleLabelRecommendationsMap
            .get(selectedProtectionSchema)
            ?.label_recommendations?.reduce((map, label) => {
              const labelWithHref = label.href ? label : {...label, href: ''};

              return map.set(label.key, labelWithHref);
            }, new Map()) || new Map();
      });
    });
  },
);

export const getServerLabelingListGrid = state =>
  getGridSelector(state, {
    settings: serverLabelingListGridSettings,
    rows: getServerRowsWithPolicySelections,
  });

const getGrid = (settings, rows) => {
  return state => getGridSelector(state, {settings, rows});
};

// Adds row key and row type to rule
const addKeysToRules = (rules, type) =>
  rules?.map(
    (rule, index) =>
      produce(rule, draft => {
        draft.type = type;
        draft.key = `${type}${index}`;
      }) ?? [],
  );

const getOverrideDenyRulesRows = createSelector(getOverrideDenyRules, rules => addKeysToRules(rules, 'overrideDeny'));
const getAllowRulesRows = createSelector(getAllowRules, rules => addKeysToRules(rules, 'allow'));
const getDenyRulesRows = createSelector(getDenyRules, rules => addKeysToRules(rules, 'deny'));

const getRulePreviewGrids = createStructuredSelector({
  overrideDenyRulesGrid: getGrid(rulePreviewGridSettings, getOverrideDenyRulesRows),
  allowRulesGrid: getGrid(rulePreviewGridSettings, getAllowRulesRows),
  denyRulesGrid: getGrid(rulePreviewGridSettings, getDenyRulesRows),
});

export const getServerLabelingListPage = createSelector(
  [
    getServerLabelingListGrid,
    getServerRowsWithPolicySelections,
    getRulePreviewGrids,
    getHelpUrl,
    getHelpVersion,
    getServerRoleLabelRecommendations,
    getServerRoleLabelRecommendationsMap,
  ],
  (
    grid,
    serversList,
    {overrideDenyRulesGrid, allowRulesGrid, denyRulesGrid},
    helpUrl,
    helpVersion,
    labelRecommendations,
    labelRecommendationsMap,
  ) => ({
    grid,
    serversList,
    overrideDenyRulesGrid,
    allowRulesGrid,
    denyRulesGrid,
    labelRecommendations,
    labelRecommendationsMap,
    helpLinks: {
      labelHref: `${helpUrl}/${helpVersion}/Guides/security-policy/security-policy-objects/labels-and-label-groups.htm`,
      policyHref: `${helpUrl}/${helpVersion}/Guides/security-policy/overview/illumio-policy-model.htm`,
    },
  }),
);
