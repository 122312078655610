/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

/**
 * These are shortcuts to the label type setting.
 * Since settings are global and change rarely, we can put it there in closure,
 * and allow other modules use the subsequent utils to pull the settings without subscribing to Store.
 */

let labelTypesIndexMap = new Map();
let labelSettingsMap = new Map();
let labelDisplayStyle = 'icon';

export const getLabelTypesIndexMap = () => labelTypesIndexMap;
export const getLabelSettingsMap = () => labelSettingsMap;
export const getLabelDisplayStyle = () => labelDisplayStyle;

export const setLabelTypesIndexMap = (types = labelTypesIndexMap) => {
  if (types !== labelTypesIndexMap) {
    labelTypesIndexMap = types;
  }
};
export const setLabelSettingsMap = (settings = labelSettingsMap) => {
  if (settings !== labelSettingsMap) {
    labelSettingsMap = settings;
  }
};
export const setLabelDisplayStyle = (style = labelDisplayStyle) => {
  if (style !== labelDisplayStyle) {
    labelDisplayStyle = style;

    console.log(labelDisplayStyle);
  }
};

export const getLabelSettingsByType = type => {
  return labelSettingsMap.get(type);
};

export const getLabelTypeName = (type, plural = false) => {
  const labelTypeSettings = getLabelSettingsByType(type);

  return (
    (labelTypeSettings &&
      ((plural && labelTypeSettings.display_info?.display_name_plural) ||
        labelTypeSettings.display_name ||
        labelTypeSettings.key)) ||
    ''
  );
};

export const getLabelTypeInitial = (type, group = false) => {
  const labelTypeSettings = getLabelSettingsByType(type);
  let initial = labelTypeSettings?.display_info?.initial || getLabelTypeName(type).charAt(0);

  if (__ANTMAN__ && group) {
    initial = `${initial}G`;
  }

  return initial;
};

export const getLabelTypeDisplay = type => {
  const labelTypeSettings = getLabelSettingsByType(type);

  return labelTypeSettings?.display_info ?? {};
};

/**
 * Returns labels sorted based on the order of label types
 *
 *    labels coming from PillDiff will be 2d arrays sorted separately per type of diff to keep order:
 *     [[modified], [unchanged], [removed]]
 *
 * @param labels  an array of arrays or a flat array
 * @returns flat array of labels
 */
export const getSortedLabels = (labels, typeAccessor = 'key') => {
  if (!labels) {
    return [];
  }

  const typeAccessorArr = typeAccessor.split('.');

  const sortFunc = (a, b) =>
    labelTypesIndexMap.get(_.get(a, typeAccessorArr)) - labelTypesIndexMap.get(_.get(b, typeAccessorArr));

  if (Array.isArray(labels[0])) {
    return labels.reduce((result, labelArr) => {
      result.push(...[...labelArr].sort(sortFunc));

      return result;
    }, []);
  }

  return [...labels].sort(sortFunc);
};
