/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {all, call, put, select} from 'redux-saga/effects';
import {hrefUtils} from 'utils';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {getVenInstance} from './Item/VenItemState';
import {getVensHrefMap} from './List/VenListState';
import {RedirectError} from 'errors';

export function* fetchVenInstance({id, isAgent, force = false}) {
  if (isAgent) {
    return yield call(apiSaga, 'agents.get_instance', {
      params: {agent_id: id},
      cache: !force,
      *onDone({data: item}) {
        if (force || item !== (yield select(getVenInstance, item && item.href))) {
          yield put({type: 'AGENTS_GET_ITEM', item});
        }

        return item;
      },
    });
  }

  return yield call(apiSaga, 'vens.get_instance', {
    params: {ven_id: id},
    query: {representation: 'ven_labels_workloads'},
    cache: !force,
    *onDone({data: item}) {
      if (force || item !== (yield select(getVenInstance, item && item.href))) {
        yield put({type: 'VENS_GET_ITEM', item});
      }

      return item;
    },
  });
}

export function* fetchVenItem({params: {id}}, refetch = false) {
  try {
    yield call(fetchVenInstance, {id, force: refetch});
  } catch {
    throw new RedirectError({to: 'workloads.vens.list', proceedFetching: true, thisFetchIsDone: true});
  }
}

// Take vens from store or make parallel calls to fetch needed ven
export function* fetchSelectiveVens(vensOrHrefs = [], force = false, isAgent) {
  const vens = yield select(getVensHrefMap, isAgent);

  const resultvensMap = new Map();

  yield all(
    vensOrHrefs.reduce((result, ven) => {
      if (!ven) {
        return result;
      }

      if (typeof ven.href === 'string' && ven.href) {
        if (!force && vens[ven.href]) {
          // If ven already exists in store, just return it
          resultvensMap.set(ven.href, vens[ven.href]);
        } else {
          // Otherwise fetch this ven and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(fetchVenInstance, {id: hrefUtils.getId(ven.href), isAgent, force});

                const vens = yield select(getVensHrefMap, isAgent);

                resultvensMap.set(ven.href, vens.get(ven.href));
              } catch {
                // A Ven in the ven's table may not exist even though they exist in object history, that is fine
                resultvensMap.set(ven.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultvensMap;
}

export function* unpairVens({managedHrefs, restoreType}) {
  yield call(apiSaga, 'vens.unpair', {
    data: {
      vens: managedHrefs,
      firewall_restore: restoreType,
    },
    *onDone() {
      apiCachedResponses.clearAll();
    },
  });
}

export function* setRecoveryMode({hrefs = [], seconds}) {
  let error;

  try {
    const result = yield call(apiSaga, 'vens.authentication_recovery', {
      data: {
        vens: hrefs,
        recovery_enabled: true,
        recovery_duration_seconds: seconds,
      },
      *onDone() {
        apiCachedResponses.clearAll();
      },
    });

    if (result.data.errors) {
      error = result.data.errors;
    }
  } catch (err) {
    error = [err];
  }

  return error;
}

export function* generateMaintenanceToken({ven, expireTime}) {
  let error;
  let result;

  try {
    result = yield call(apiSaga, 'vens.generate_maintenance_token', {
      data: {
        vens: ven,
        expires_at: expireTime,
      },
      *onDone() {
        apiCachedResponses.clearAll();
      },
    });

    if (result.data.errors) {
      error = result.data.errors;
    }
  } catch (err) {
    error = [err];

    return error;
  }

  return {result, error};
}
