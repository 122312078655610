/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import Selector from './Selector';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';

const defaultSelectorProps = {
  maxColumns: 3,
  noActiveIndicator: true,
  noCombinedCategory: true,
  alwaysShowPlaceholder: true,
  placeholder: intl('Common.SelectLabelsLabelGroups'),
};

LabelSelector.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  group: PropTypes.bool,
  labelsResourceModifier: PropTypes.object,
  labelEditModifier: PropTypes.object,
  labelGroupEditModifier: PropTypes.object,

  onChange: PropTypes.func,
};

export default function LabelSelector({
  value: labels = [],
  group = false,
  onChange,
  labelsResourceModifier = {},
  labelEditModifier,
  labelGroupEditModifier,
  ...selectorProps
}) {
  const labelTypeInitialRegExp = useSelector(getTypeInitialRegExp);
  const labelTypesNameObj = useSelector(getDisplayNames);
  const resourceId = 'labelsAndLabelGroups';

  const categories = useMemo(() => {
    return [
      _.merge(
        Selector.categoryPresets.labelsAndLabelGroups({
          labelTypesNameObj,
          labelTypeInitialRegExp,
          hasLabelGroups: group,
          allowMultipleSelection: false,
          hasAll: selectorProps.hasAll ?? true,
        }),
        {
          resources: {
            labelsAndLabelGroups: labelsResourceModifier,
            labelForm: _.merge(
              {
                labelTypeInitialRegExp,
              },
              labelEditModifier,
            ),
            labelGroupForm: _.merge(
              {
                labelTypeInitialRegExp,
              },
              labelGroupEditModifier,
            ),
          },
        },
      ),
    ];
  }, [
    group,
    labelTypesNameObj,
    labelTypeInitialRegExp,
    labelsResourceModifier,
    labelEditModifier,
    labelGroupEditModifier,
    selectorProps,
  ]);

  const handleSelectionChange = useCallback(
    valuesMap => {
      const labels = valuesMap.get(resourceId) ?? [];

      onChange?.(labels);
    },
    [onChange],
  );

  Object.assign(selectorProps, {
    values: new Map(labels.length ? [[resourceId, labels]] : []),
    onSelectionChange: handleSelectionChange,
    categories,
  });

  _.defaults(selectorProps, defaultSelectorProps);

  return <Selector {...selectorProps} />;
}
