/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import intl from 'intl';
import focusScope from 'a11y-focus-scope';
import focusStore from 'a11y-focus-store';
import {Button, DateTimePicker, DateTimeCustomPickerInput, DateTimeCustomPickerInputWithPresets} from 'components';
import styles from './DateTimeCustomPicker.css';

function setFocusOn(applicationElement, element) {
  focusStore.storeFocus();

  if (applicationElement) {
    applicationElement.setAttribute('aria-hidden', 'true');
  }

  focusScope.scopeFocus(element);
}

function resetFocus(applicationElement) {
  focusScope.unscopeFocus();

  if (applicationElement) {
    applicationElement.removeAttribute('aria-hidden');
  }

  focusStore.restoreFocus();
}

// Accepts a single DateTime as Input, and provides it to Selector
export default class DateTimeSingleCustomPicker extends PureComponent {
  static propTypes = {
    inputLabel: PropTypes.string,
    onValidate: PropTypes.func, // a function to validate picked value
    format: PropTypes.string, // a date format string from intl/formats.js. used for *display* in selector. Saga must format for API.
    onClose: PropTypes.func, // this is provided by selector
    onSave: PropTypes.func, // this is provided by selector
    categoryKey: PropTypes.string, // category key for the selector category that this custom picker is for
    fromPresets: PropTypes.array, // answers that can be chosen without using the calendar picker, e.g. Now, Anytime
  };

  static defaultProps = {
    inputLabel: intl('DateTimeInput.From'),
    format: 'l_HH_mm',
    onClose: _.noop,
    onSave: _.noop,
    fromPresets: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      activeInput: 'from',
    };
    this.saveCustomPickerRef = this.saveCustomPickerRef.bind(this);
    this.setDropdown = this.setDropdown.bind(this);
    this.setPreset = this.setPreset.bind(this);
    this.setActive = this.setActive.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.removeDateTimeValue = this.removeDateTimeValue.bind(this);
    this.formatInputValue = this.formatInputValue.bind(this);
    this.calcFinalValues = this.calcFinalValues.bind(this);
  }

  componentDidMount() {
    setFocusOn(document.querySelector('#root'), this.customPicker);
  }

  componentWillUnmount() {
    resetFocus(document.querySelector('#root'));
  }

  setDropdown(type) {
    // invoked by DateTimeCustomPickerInputWithPresets
    if (type === 'from') {
      this.setState({showDropdown: true});
    } else {
      this.setState({showDropdown: false});
    }
  }

  setPreset(type, val) {
    if (type === 'from') {
      this.setState({selectedPresetFrom: val});
    }
  }

  setActive(type) {
    this.setState({showDropdown: false});
    this.setState({activeInput: type});
  }

  saveCustomPickerRef = element => {
    this.customPicker = element;
  };

  handleKeyDown(event) {
    event.stopPropagation();

    if (event.key === 'Escape') {
      const {showDropdown} = this.state;

      if (!showDropdown) {
        this.props.onClose();
      }
    }
  }

  handleCancel() {
    this.props.onClose();
  }

  handleApply(evt) {
    const finalValues = this.calcFinalValues();

    this.props.onSave(evt, finalValues);
  }

  handleClick() {
    const {showDropdown} = this.state;

    if (showDropdown) {
      this.setState({showDropdown: false});
    }
  }

  handleDateTimeChange = val => {
    const valid = this.props.onValidate?.(val) ?? true;

    if (!valid) {
      return;
    }

    const {activeInput} = this.state;

    this.setState({selectedPresetFrom: 'custom'});
    this.setState({[activeInput]: val});
  };

  formatInputValue(value) {
    return intl.date(new Date(value), this.props.format);
  }

  removeDateTimeValue(type) {
    this.setState({[type]: null, activeInput: type});
  }

  calcFinalValues() {
    const {from, selectedPresetFrom} = this.state;
    const {fromPresets, format} = this.props;
    const hasFromPresets = fromPresets?.length;
    const fromPresetObject = hasFromPresets && fromPresets.find(preset => preset.name === selectedPresetFrom);
    const fromPresetValue = selectedPresetFrom === 'now' ? new Date() : fromPresetObject?.value;
    let formattedFrom = from ? intl.date(from, format) : intl('DateTimeInput.Anytime');

    if (hasFromPresets && selectedPresetFrom && selectedPresetFrom !== 'custom') {
      formattedFrom = intl.date(fromPresetValue, format);
    }

    // Pass formatted value to item so Selector can render
    const item = {
      value: formattedFrom,
    };

    if (this.props.categoryKey) {
      item.name = this.props.categoryKey;
    }

    return item;
  }

  render() {
    const {fromPresets, inputLabel} = this.props;
    const {from, activeInput, showDropdown, selectedPresetFrom, hoveredPresetFrom} = this.state;
    const hasFromPresets = fromPresets?.length;
    const disableApply = hasFromPresets && !selectedPresetFrom && !from;
    const showDateTimePicker = activeInput === 'from' && selectedPresetFrom === 'custom';

    return (
      <div
        className={styles.customPicker}
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        ref={this.saveCustomPickerRef}
      >
        <div className={styles.inputHolder}>
          <div className={styles.inputLabel}>{inputLabel}</div>
          {hasFromPresets ? (
            <DateTimeCustomPickerInputWithPresets
              focused={activeInput === 'from'}
              showDropdown={showDropdown}
              type="from"
              value={from}
              presets={fromPresets}
              selectedPreset={selectedPresetFrom}
              hoveredPreset={hoveredPresetFrom}
              placeholder={intl('DateTimeInput.SelectDateAndTime')}
              formatValue={this.formatInputValue}
              setDropdown={this.setDropdown}
              setPreset={this.setPreset}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
              onDateTimeChange={this.handleDateTimeChange}
            />
          ) : (
            <DateTimeCustomPickerInput
              focused={activeInput === 'from'}
              type="from"
              value={from}
              placeholder={intl('DateTimeInput.SelectFromPlaceholder')}
              formatValue={this.formatInputValue}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
              onDateTimeChange={this.handleDateTimeChange}
            />
          )}
        </div>
        <DateTimePicker
          type={activeInput}
          active={showDateTimePicker}
          date={from}
          onDateChange={this.handleDateTimeChange}
        />
        <div className={styles.actions}>
          <Button noFill text={intl('Common.Cancel')} onClick={this.handleCancel} />
          <Button text={intl('Common.Apply')} disabled={disableApply} onClick={this.handleApply} />
        </div>
      </div>
    );
  }
}
