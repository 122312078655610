/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import SizeWatcher from 'react-size-watcher';
import {createRef, PureComponent} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {Button, ButtonGroup, MenuItem, MenuDelimiter, Checkbox, Icon, IconSort} from 'components';
import Paginator from './GridPaginator';
import styles from './GridManager.css';
import {areArraysEqualWhenSorted} from 'utils/general';
import Badge from 'components/Badge/Badge';
import {toggleColumns} from '../GridUtils';

export default class GridManager extends PureComponent {
  static propTypes = {
    breakpoint: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.columnsMenuRef = createRef();

    this.handleCapacityChange = this.handleCapacityChange.bind(this);
    this.handleColumnToggle = this.handleColumnToggle.bind(this);
    this.handleColumnsClose = this.handleColumnsClose.bind(this);
    this.handleColumnsReset = this.handleColumnsReset.bind(this);
    this.handleColumnsSortReset = this.handleColumnsSortReset.bind(this);
    this.handleColumnsSelectionReset = this.handleColumnsSelectionReset.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.handleColumnsMenuClose = this.handleColumnsMenuClose.bind(this);
    this.renderCapacityMenu = this.renderCapacityMenu.bind(this);
    this.renderColumnsMenu = this.renderColumnsMenu.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      grid: {
        columns,
        settings: {showManager, showSelection, showColumns = false, showCapacity = false, showPagination = false},
      },
    } = nextProps;
    const theme = composeThemeFromProps(styles, nextProps);
    const checkboxesColumn = columns.get('checkboxes');

    if (showSelection === undefined) {
      // Show selection if is not set explicitly to false and checkbox column is shown exists
      showSelection = checkboxesColumn !== undefined && !checkboxesColumn.disabled;
    }

    if (showManager === undefined) {
      // If showManager is not set explicitly and any properties are set to true, show Grid Manager
      showManager = showColumns || showCapacity || showSelection || showPagination;
    }

    let breakpoints = prevState.breakpoints;

    if (theme !== prevState) {
      breakpoints = [
        {props: {className: theme.manager}},
        {maxWidth: 768, props: {className: theme.managerSmall}},
        {maxWidth: showSelection ? 600 : 520, data: {noDropdownText: true}, props: {className: theme.managerSmall}},
        {
          maxWidth: 480,
          data: {selectionNumberOnly: true, noDropdownText: true},
          props: {className: theme.managerSmall},
        },
      ];
    }

    const managerEnabledColumns = Array.from(columns).reduce((result, [id, column]) => {
      if (column.manager === false) {
        return result;
      }

      result.add(id);

      return result;
    }, new Set());

    return {
      theme,
      breakpoints,
      showManager,
      showColumns,
      showCapacity,
      showSelection,
      showPagination,
      managerEnabledColumns,
    };
  }

  handleCapacityChange(evt, item) {
    this.props.onChange(evt, item.props.data);
  }

  handleColumnToggle(evt, item) {
    const {
      grid: {columns, columnIds},
    } = this.props;
    const columnId = item.props.data;

    const newColumns = toggleColumns(columns, columnIds, [columnId]);

    this.props.onChange(evt, {
      columns: newColumns,
      columnId,
      action: (newColumns?.length ?? columnIds.default.length) > columnIds.visible.length ? 'show' : 'hide',
    });
  }

  handleColumnSort(evt, columnId) {
    const {columns, sortObject} = this.props.grid;
    const column = columns.get(columnId);
    let sort;

    if (sortObject && sortObject.columnId === columnId) {
      sort = `${sortObject.factor === 1 ? '-' : ''}${columnId}`;
    } else if (column.defaultOrder === 'desc' || (column.defaultOrder !== 'asc' && column.isDate)) {
      sort = `-${columnId}`;
    } else {
      sort = columnId;
    }

    evt.stopPropagation();
    this.props.onChange(evt, {sort});
  }

  handleColumnsClose() {
    this.columnsMenuRef.current.menu.close();
  }

  handleColumnsSortReset(evt) {
    evt.stopPropagation();
    this.props.onChange(evt, {sort: null});
  }

  handleColumnsReset(evt) {
    evt.stopPropagation();
    this.props.onChange(evt, {columns: null, sort: null});
  }

  handleColumnsMenuClose(evt) {
    const {
      grid: {
        columnIds: {seenColumnIds, newColumnIds},
      },
    } = this.props;

    if (newColumnIds?.length > 0) {
      this.props.onChange(evt, null, [...seenColumnIds, ...newColumnIds]);
    }
  }

  handleColumnsSelectionReset(evt) {
    evt.stopPropagation();
    this.props.onChange(evt, {columns: null});
  }

  renderSortButton(column, sortedColumnId, theme) {
    const {
      grid: {columns, sortObject, columnIds},
    } = this.props;
    const visibleColumns = columnIds.visible;

    if (column.templates?.length) {
      const sortSelectionMenu = column.templates.reduce((result, subColumnId) => {
        const id = `${column.id}-${subColumnId}`;
        const subColumn = columns.get(id) ?? {};

        if (subColumn.sortable === false) {
          // Only provide sortable cells in sort selection dropdown
          return result;
        }

        const buttonProps = {
          theme,
          themePrefix: 'dropdownColumnsSortButton-',
          noFill: true,
          noStyle: true,
          color: 'standard',
          tid: `sort-${id}`,
          disabled: column.sortable === false,
          showContentOnAction: sortedColumnId !== id && !sortedColumnId?.startsWith(`${id}-`),
          text: <IconSort sorted={sortedColumnId === id ? sortObject.factor : 0} />,
        };

        if (subColumn.sortable === false) {
          buttonProps.disabled = true;
        } else {
          buttonProps.onClick = _.partial(this.handleColumnSort, _, subColumn.id);
        }

        result.push(
          <MenuItem
            key={id}
            data={id}
            noCloseOnClick
            tid={`column-sub-${id}`}
            theme={theme}
            themePrefix="dropdownColumnsItem-"
            text={
              <>
                <Button {...buttonProps} />
                {!subColumn.notSelectable ? (
                  <Checkbox
                    notChangeable
                    label={subColumn.header}
                    checked={visibleColumns.includes(id)}
                    disabled={column.hidden && !subColumn.hidden}
                    theme={theme}
                    themePrefix="dropdownColumnsCheckbox-"
                  />
                ) : (
                  subColumn.header
                )}
              </>
            }
            onSelect={this.handleColumnToggle}
          />,
        );

        return result;
      }, []);

      const props = {
        theme,
        themePrefix: 'dropdownColumnsSortButton-',
        menu: sortSelectionMenu,
        menuProps: {triggerOnHover: true, triggerOnHoverOpenDebounce: 100},
        tid: `sort-${column.id}`,
        noStyle: true,
        menuNoDropdownIcon: true,
        showContentOnAction: sortedColumnId !== column.id && !sortedColumnId?.startsWith(`${column.id}-`),
        text: <IconSort sorted={sortedColumnId === column.id ? sortObject.factor : 0} />,
        disabled: column.sortable === false,
      };

      return <Button.Menu {...props} />;
    }

    const props = {
      theme,
      themePrefix: 'dropdownColumnsSortButton-',
      noFill: true,
      color: 'standard',
      showContentOnAction: column.id !== sortedColumnId,
      text: <IconSort sorted={column.id === sortedColumnId ? sortObject.factor : 0} />,
    };

    if (column.sortable === false) {
      props.disabled = true;
    } else {
      props.onClick = _.partial(this.handleColumnSort, _, column.id);
    }

    return <Button {...props} />;
  }

  renderColumnsMenu() {
    const {grid} = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    const visibleColumns = grid.columnIds.visible;
    const defaultColumns = grid.columnIds.default;
    const requiredColumns = grid.columnIds.required;
    const newColumns = grid.columnIds.newColumnIds;
    const optionalColumns = grid.columnIds.optional;
    const oneColumnLeft = visibleColumns.filter(column => this.state.managerEnabledColumns.has(column)).length === 1;
    const showSectionsTitle = optionalColumns.length > 0;
    const sortedColumnId = grid.sortObject ? grid.sortObject.columnId : null;
    const content = [];
    const defaultNotRequiredCols = grid.columnIds.defaultNotRequired.filter(column =>
      this.state.managerEnabledColumns.has(column),
    );

    if (showSectionsTitle) {
      // Show Default title if optional columns exist
      content.push(
        <MenuItem text={intl('Common.Default')} key="default" theme={theme} themePrefix="dropdownColumnsTitle-" />,
      );
    }

    for (const id of requiredColumns) {
      const column = grid.columns.get(id);
      const props = {
        key: id,
        data: id,
        notSelectable: true,
        tid: `column-required-${id}`,
        theme,
        themePrefix: 'dropdownColumnsItem-',
        text: (
          <>
            {this.renderSortButton(column, sortedColumnId, theme)}
            <Checkbox
              checked
              disabled
              iconOn="lock"
              label={column.headerManager || column.header}
              theme={theme}
              themePrefix="dropdownColumnsCheckbox-"
            />
          </>
        ),
      };

      content.push(<MenuItem {...props} />);
    }

    for (const id of defaultNotRequiredCols) {
      const column = grid.columns.get(id);
      const checked = visibleColumns.includes(id);
      const disabled = oneColumnLeft && checked;
      const props = {
        key: id,
        data: id,
        noCloseOnClick: true,
        notSelectable: disabled,
        onSelect: this.handleColumnToggle,
        tid: `column-default-${id}`,
        theme,
        themePrefix: 'dropdownColumnsItem-',
        text: (
          <>
            {this.renderSortButton(column, sortedColumnId, theme)}
            <Checkbox
              notChangeable
              checked={checked}
              disabled={disabled}
              label={column.headerManager || column.header}
              theme={theme}
              themePrefix="dropdownColumnsCheckbox-"
            />
          </>
        ),
        ...(newColumns?.includes(id) ? {badge: 'new'} : {}),
      };

      if (!column.parentId) {
        content.push(<MenuItem {...props} />);
      }
    }

    if (optionalColumns.length) {
      content.push(
        <MenuDelimiter key="optionalDelimiter" />,
        <MenuItem text={intl('Common.Optional')} key="optional" theme={theme} themePrefix="dropdownColumnsTitle-" />,
      );

      for (const id of optionalColumns) {
        const column = grid.columns.get(id);
        const checked = visibleColumns.includes(id);
        const disabled = oneColumnLeft && checked;
        const props = {
          key: id,
          data: id,
          noCloseOnClick: true,
          notSelectable: disabled,
          onSelect: this.handleColumnToggle,
          tid: `column-optional-${id}`,
          theme,
          themePrefix: 'dropdownColumnsItem-',
          text: (
            <>
              {this.renderSortButton(column, sortedColumnId, theme)}
              <Checkbox
                notChangeable
                checked={checked}
                disabled={disabled}
                label={column.headerManager || column.header}
                theme={theme}
                themePrefix="dropdownColumnsCheckbox-"
              />
            </>
          ),
          ...(newColumns?.includes(id) ? {badge: 'new'} : {}),
        };

        content.push(<MenuItem {...props} />);
      }
    }

    const isDefaultColumns = areArraysEqualWhenSorted(defaultColumns, visibleColumns);
    const isDefaultSorting = !grid.sort || grid.sort === grid.settings.sort;
    const defaultSortingColumn = grid.settings.columns[_.trim(grid.settings.sort, '-')];
    const defaultSortingColumnName = defaultSortingColumn?.headerManager || defaultSortingColumn?.header;

    content.push(
      <MenuDelimiter key="actionsDelimeter" />,
      <MenuItem
        key="columnMenuActions"
        theme={theme}
        themePrefix="dropdownColumnsButtons-"
        text={
          <>
            <Button noFill text={intl('Common.Close')} onClick={this.handleColumnsClose} tid="close" />{' '}
            <ButtonGroup color="standard" disabled={isDefaultColumns && isDefaultSorting}>
              <Button
                text={intl('Common.Reset')}
                onClick={this.handleColumnsReset}
                tid="reset"
                title={intl('Common.ResetSelsectionAndSortingBy', {name: defaultSortingColumnName})}
              />
              <Button.Menu
                size="large"
                tid="reset-partial"
                menu={[
                  <MenuItem
                    text={intl('Common.ResetColumnSelection')}
                    tid="reset-columns"
                    disabled={isDefaultColumns}
                    onClick={this.handleColumnsSelectionReset}
                  />,
                  <MenuItem
                    text={intl('Common.ResetSortingBy', {name: defaultSortingColumnName})}
                    tid="reset-sorting"
                    disabled={isDefaultSorting}
                    onClick={this.handleColumnsSortReset}
                  />,
                ]}
              />
            </ButtonGroup>
          </>
        }
      />,
    );

    return content;
  }

  renderCapacityMenu() {
    const {grid} = this.props;

    return grid.settings.capacities.map(value => (
      <MenuItem
        text={
          <Checkbox
            insensitive
            hidden={value !== grid.capacity}
            checked={value === grid.capacity}
            iconOn="check"
            label={intl.num(value)}
          />
        }
        data={{capacity: value === grid.settings.capacity ? null : value}}
        onSelect={this.handleCapacityChange}
        tid={`capacity-${value}`}
      />
    ));
  }

  render() {
    const {
      props: {grid, count, selectedKeySet, onChange, offset},
      state: {theme, breakpoints, showManager, showColumns, showCapacity, showSelection, showPagination},
    } = this;

    const isCustomColumns = !areArraysEqualWhenSorted(grid.columnIds.default, grid.columnIds.visible);
    const isCustomSorting = grid.sort && grid.sort !== grid.settings.sort;

    if (!showManager) {
      return null;
    }

    return (
      <SizeWatcher breakpoints={breakpoints} style={{top: offset}} tid="grid-manager">
        {({data = {}}) => (
          <>
            {showSelection &&
              selectedKeySet.size > 0 &&
              intl(
                data.selectionNumberOnly ? 'Common.SelectedNumber' : 'Common.SelectedCount',
                {count: selectedKeySet.size, className: theme.selectedCount},
                {html: true, htmlProps: {'className': theme.selected, 'data-tid': 'elem-count-selection'}},
              )}
            <div className={theme.expander} />
            {showColumns && (
              <>
                {grid.columnIds.newColumnIds?.length > 0 && (
                  <Badge type="new" theme={styles} themePrefix="dropdownColumnsMenu-" />
                )}
                <Button.Menu
                  color="standard"
                  noFill
                  text={data.noDropdownText ? <Icon name="grid" /> : intl('Common.CustomColumns')}
                  icon={isCustomColumns || isCustomSorting ? 'online' : undefined}
                  tid="columns-selector"
                  menu={this.renderColumnsMenu()}
                  ref={this.columnsMenuRef}
                  onClose={this.handleColumnsMenuClose}
                  menuAlign="left"
                  theme={theme}
                  themePrefix="dropdownButton-"
                />
              </>
            )}
            {showCapacity && (
              <Button.Menu
                color="standard"
                noFill
                menu={this.renderCapacityMenu()}
                theme={theme}
                themePrefix="dropdownButton-"
                icon={grid.settings.capacity !== grid.capacity ? 'online' : undefined}
                tid="capacity-selector"
                text={
                  data.noDropdownText ? intl.num(grid.capacity) : intl('Common.PerPageCount', {count: grid.capacity})
                }
              />
            )}
            {showPagination && <Paginator grid={grid} count={count} onChange={onChange} />}
          </>
        )}
      </SizeWatcher>
    );
  }
}
