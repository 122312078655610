/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {call, delay, select, put, spawn, debounce, all} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import {getSearchResult, getOptionText, getMigratedData} from './SelectorUtils';
import {getUserSelectorHistory} from 'containers/User/UserState';
import {getHrefMapByObjType, getValidSelectorRecents, getLabelQueryAndKey} from 'containers/Selector/SelectorState';
import {getId, isProperHref} from 'utils/href';

export function* fetchMatches(dataProvider, ...args) {
  if (typeof dataProvider === 'string') {
    // if it's a api name, pass only the first argument
    const {data} = yield call(apiSaga, dataProvider, args[0]);

    return data;
  }

  return yield call(dataProvider, ...args);
}

export function* getLabelsQueryParams(queryString, keyword, values = new Map(), resource = {}) {
  const query = {};

  const selectedScope = [];

  // IncludeSelectedResources: ['labelsAndLabelGroups']
  resource.includeSelectedResources?.forEach(resourceId => {
    const selections = values
      .get(resourceId)
      ?.filter(({href}) => isProperHref(href) && !href.includes('exists') && !href.includes('all'));

    if (selections?.length) {
      selectedScope.push(
        ...selections.map(({href}) => ({[href.includes('label_groups') ? 'label_group' : 'label']: {href}})),
      );
    }
  });

  if (selectedScope.length > 0) {
    query.selected_scope = JSON.stringify(selectedScope);
  }

  // label type that has the same type as the sticky option are filtered out
  const stickyLabelTypes = values
    .get(resource.id)
    ?.filter(({sticky}) => sticky)
    .map(({key}) => key);

  if (!resource.optionProps?.allowMultipleSelection && stickyLabelTypes?.length > 0) {
    query.exclude_keys = JSON.stringify(stickyLabelTypes);
  }

  const newQuery = yield select(getLabelQueryAndKey, queryString, keyword);

  return _.mergeWith(query, newQuery, (objValue, srcValue, key) => {
    if (srcValue === null) {
      _.unset(newQuery, key);
    }
  });
}

export function* prepareApiArgs({query, keyword, values, resource}) {
  let {apiArgs} = resource;

  if (typeof apiArgs === 'function') {
    apiArgs = yield call(apiArgs, query, keyword, values, resource);
  }

  apiArgs ??= {};

  const {query: {getQuery, ...otherQuery} = {}, ...args} = apiArgs;

  let restQueryParams = {query};

  if (getQuery) {
    restQueryParams = yield call(getQuery, query, keyword, values, resource);
  }

  Object.assign(args, {query: {...otherQuery, ...restQueryParams}});

  return args;
}

export function* fetchResource(context = {}) {
  try {
    const {resource, query: queryString} = context;
    const {dataProvider, statics, searchIndex} = resource;
    const apiOptions = yield call(prepareApiArgs, context);
    const trimmedQuery = queryString.trim();

    const result = {};

    if (dataProvider) {
      if (queryString) {
        yield delay(700);
      }

      result.dataProviderOptions = yield call(fetchMatches, dataProvider, apiOptions, context);
    }

    if (searchIndex || statics) {
      result.staticsOptions =
        trimmedQuery && searchIndex
          ? getSearchResult(searchIndex, trimmedQuery)
          : typeof statics === 'function'
          ? yield call(statics, context)
          : statics;
    }

    if (trimmedQuery) {
      const {createHint, allowCreateOptions, allowPartial, name, optionProps: {textPath} = {}} = resource;

      const {staticsOptions, dataProviderOptions} = result;
      const dataProviderMatches =
        (Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions?.matches) ?? [];

      if (allowPartial && dataProviderMatches.length) {
        result.partialOption = {id: `PARTIAL_ID_${resource.id}`, value: queryString, isPartial: true};
      } else {
        const filterableOptions = [
          ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
          ...dataProviderMatches,
        ];

        const exactMatches = filterableOptions.filter(
          option => getOptionText(option, textPath).trim().toLowerCase() === queryString.trim().toLowerCase(),
        );

        const showCreateOptions = (() => {
          if (allowCreateOptions === false || !queryString.trim()) {
            return false;
          }

          if (allowCreateOptions === true || Array.isArray(allowCreateOptions)) {
            return exactMatches.length ? false : allowCreateOptions;
          }

          if (typeof allowCreateOptions === 'function') {
            return allowCreateOptions(queryString, exactMatches, filterableOptions);
          }

          return;
        })();

        if (showCreateOptions) {
          result.createOptions = Array.isArray(showCreateOptions)
            ? showCreateOptions
            : [
                {
                  id: `ADD_NEW_ID_${resource.id}`,
                  value: `${queryString} ${`(${createHint ?? `${intl('Common.New')} ${name}`})`}`,
                  isCreate: true,
                },
              ];
        }
      }
    }

    return result;
  } catch (error) {
    error.message = _.get(error, 'data[0].message', error.message);

    throw error;
  }
}

export function* watchUpdateSelectorHistory() {
  yield debounce(2000, 'SELECTOR_UPDATE_HISTORY', updateKvpairs);
}

export function* updateSelectorHistory({data, dispatch = true, merge = true} = {}) {
  let recents;
  const history = yield select(getUserSelectorHistory);

  if (merge) {
    recents = {...history, ...data};
  } else {
    recents = data;
  }

  if (!_.isEqual(recents, history)) {
    if (dispatch) {
      // Immediately update recents in redux store
      yield put({type: 'SELECTOR_GET_HISTORY', data: recents});
    }

    // dispatch to debounce selector history update
    yield put({type: 'SELECTOR_UPDATE_HISTORY', key: 'selector_recent_history', data: recents});
  }
}

const nonProvisionableObjTypes = ['workloads', 'security_principals', 'labels'];

// Take objects from store or make parallel calls to fetch needed object
export function* fetchSelectiveObjects(hrefsArr = [], force = false) {
  const hrefMapByObjType = yield select(getHrefMapByObjType);

  const resultMap = new Map();

  yield all(
    hrefsArr.reduce((result, obj) => {
      if (!obj) {
        return result;
      }

      if (isProperHref(obj.href)) {
        const objType = obj.href.split('/').at(-2);

        if (!force && hrefMapByObjType[objType]?.[obj.href]) {
          // If object already exists in store, just return it
          resultMap.set(obj.href, hrefMapByObjType[objType][obj.href]);
        } else {
          // Otherwise, fetch this object and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(apiSaga, `${objType}.get_instance`, {
                  cache: !force,
                  params: {
                    [objType === 'security_principals' ? 'sid' : `${objType.slice(0, -1)}_id`]: getId(obj.href),
                    ...(nonProvisionableObjTypes.includes(objType) ? {} : {pversion: 'draft'}),
                  },
                  *onDone({data}) {
                    yield put({type: `${objType.toUpperCase()}_GET_INSTANCE`, data});
                  },
                });

                const objs = yield select(getHrefMapByObjType);

                resultMap.set(obj.href, objs[objType]?.[obj.href]);
              } catch {
                resultMap.set(obj.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultMap;
}

export function* fetchValidRecents() {
  const {recents} = yield select(getUserSelectorHistory) ?? {};

  if (recents) {
    yield call(fetchSelectiveObjects, Object.values(recents)?.flat());

    const validRecents = yield select(getValidSelectorRecents);

    // Spawn updateSelectorHistory
    // this saga performs an equality check and, if needed it will update the store and also make api call to update kvPairs with valid entries
    yield spawn(updateSelectorHistory, {data: {recents: validRecents}});

    return validRecents;
  }
}

export function* fetchSelectorHistory({force = false} = {}) {
  const data = yield call(fetchKvpairs, {
    key: 'selector_recent_history',
    force,
  });

  // Migrate selector recents data structure
  const migratedData = getMigratedData(data);

  if (_.isEqual(migratedData, data)) {
    yield put({type: 'SELECTOR_GET_HISTORY', data});
  } else {
    // PUT migrated data in store and also make api call to update kvPairs
    yield spawn(updateSelectorHistory, {data: migratedData, merge: false});
  }
}
