/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {Simplify} from 'type-fest';
import React, {useContext, useEffect, useState} from 'react';
import cx from 'classnames';
import {mixThemeWithProps, type Theme, type ThemeProps} from '@css-modules-theme/react';
import {toError} from 'utils/general';
import styles from './Menu.css';
import {AppContext} from 'containers/App/AppUtils';

export type MenuInfoFetchState = Readonly<{isFetching?: boolean; fetchData?: unknown; fetchError?: Error | null}>;

export type MenuInfoCallbackArgs = Readonly<Simplify<MenuInfoFetchState & {theme: Theme}>>;
export type MenuInfoProps = {
  children?:
    | ((args: MenuInfoCallbackArgs) => Promise<JSX.Element[] | JSX.Element> | JSX.Element[] | JSX.Element)
    | JSX.Element
    | JSX.Element[];
  prefetch?: (signal: AbortSignal) => Promise<unknown>;
  emptyText?: string;
  loadingText?: string;
  updateParentPosture?: () => void;
} & ThemeProps;

const initialFetchState: MenuInfoFetchState = {
  isFetching: true,
  fetchData: null,
  fetchError: null,
};

export default function MenuInfo(props: Readonly<MenuInfoProps>): JSX.Element {
  const {fetcher} = useContext(AppContext);
  const [{isFetching, fetchData, fetchError}, setFetchState] = useState(initialFetchState);
  const {children, prefetch, updateParentPosture, theme} = mixThemeWithProps(styles, props);

  useEffect(() => {
    if (prefetch) {
      const controller = new AbortController();
      let complete = false;

      prefetch(controller.signal)
        .then((result: unknown) => {
          if (!controller.signal.aborted) {
            setFetchState({isFetching: false, fetchData: result, fetchError: null});
          }
        })
        .catch((error: unknown) => {
          if (!controller.signal.aborted) {
            setFetchState({isFetching: false, fetchData: null, fetchError: toError(error)});
          }
        })
        .finally(() => {
          complete = true;

          if (!controller.signal.aborted) {
            updateParentPosture?.();
          }
        });

      return () => {
        if (!controller.signal.aborted && !complete) {
          controller.abort();
        }
      };
    }
  }, [fetcher, prefetch, updateParentPosture]);

  return (
    <li className={cx(theme.menuInfo)}>
      {typeof children === 'function' ? children({isFetching, fetchData, fetchError, theme}) : children}
    </li>
  );
}
