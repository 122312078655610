/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import produce from 'immer';
import {getUserSelectorHistory} from 'containers/User/UserState';
import {isProperHref} from 'utils/href';
import {getLabelSetting, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';

const emptyState = {
  ip_lists: {},
  labels: {},
  label_groups: {},
  services: {},
  security_principals: {},
  virtual_services: {},
  virtual_servers: {},
  workloads: {},
};
const actionTypeExp = /(_GET_LIST|_REMOVE|_GET_INSTANCE|_GET_DETAIL)$/;

export default {
  hrefMapByObjType: produce((draft, action) => {
    const objType = action.type.substring(0, action.type.search(actionTypeExp)).toLowerCase();

    if (Object.keys(emptyState).includes(objType)) {
      if (action.type.includes('_REMOVE')) {
        draft[objType] = _.omit(draft[objType], action.data);
      } else if (action.type.includes('_GET_LIST')) {
        for (const obj of action.data.list) {
          draft[objType][obj.href] = obj;
        }
      } else if (action.type.includes('_GET_INSTANCE')) {
        if (action.data && !_.isEqual(action.data, draft[objType][action.data.href])) {
          draft[objType][action.data.href] = action.data;
        }
      } else if (action.type.includes('_GET_DETAIL')) {
        let obj = action.data?.draft ?? action.data?.detail?.draft;

        if (obj && objType === 'virtual_servers') {
          // Only pick name and href for virtual servers
          obj = {href: obj.href, name: obj.name};
        }

        if (obj?.href && !_.isEqual(obj.href, draft[objType][obj.href])) {
          draft[objType][obj.href] = obj;
        }
      }
    }

    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
      case 'PAIRING_PROFILE_GET_LIST':
        for (const {labels} of action.data.list) {
          for (const label of labels) {
            draft.labels[label.href] = label;
          }
        }

        return draft;

      case 'VIRTUAL_SERVICES_GET_LIST':
        action.data.list.forEach(({service, labels}) => {
          if (service?.href) {
            draft.services[service.href] = service;
          }

          labels.forEach(label => {
            draft.labels[label.href] = label;
          });
        });

        return draft;

      case 'ENFORCEMENT_BOUNDARIES_GET_LIST':
        // Populate labels, label_groups, ip_lists
        action.data.list
          .flatMap(({consumers = [], providers = []}) => [...consumers, ...providers])
          .forEach(entity => {
            const entityType = Object.keys(entity)[0];

            if (Object.keys(emptyState).includes(entityType) && entity[entityType]?.href) {
              draft[`${entityType}s`][entity[entityType].href] = entity[entityType];
            }
          });

        return draft;

      case 'RULESET_GET_LIST':
        for (const {scopes} of action.data.list) {
          for (const scope of scopes) {
            for (const obj of scope) {
              if (obj?.label?.href) {
                draft.labels[obj.label.href] = obj.label;
              } else if (obj?.label_group?.href) {
                draft.label_groups[obj.label_group.href] = obj.label_group;
              }
            }
          }
        }

        return draft;

      case 'RULESET_GET_ITEM': {
        const obj = action.data.draft;

        if (obj) {
          // Populate labels, label_groups, virtual_services, virtual_servers, ip_lists and workloads
          [...(obj.rules ?? []), ...(obj.deny_rules ?? []), ...(obj.ip_tables_rules ?? [])]
            .flatMap(({consumers = [], providers = [], actors = []}) => [...consumers, ...providers, ...actors])
            .forEach(entity => {
              const entityType = Object.keys(entity)[0];

              if (Object.keys(emptyState).includes(entityType) && entity[entityType]?.href) {
                draft[`${entityType}s`][entity[entityType].href] = entity[entityType];
              }
            });

          // Populate services
          obj.rules
            .flatMap(({ingress_services = [], egress_services = []}) => [...ingress_services, ...egress_services])
            .forEach(service => {
              if (service.href) {
                draft.services[service.href] = service;
              }
            });

          obj.rules
            .flatMap(({consuming_security_principals}) => consuming_security_principals)
            .forEach(userGroup => {
              if (userGroup.href) {
                draft.security_principals[userGroup.href] = userGroup;
              }
            });
        }

        return draft;
      }
      default:
        return draft;
    }
  }, emptyState),
};

export const getHrefMapByObjType = state => state.hrefMapByObjType;

export const getValidSelectorRecents = createSelector(
  [getUserSelectorHistory, getHrefMapByObjType],
  ({recents = {}}, hrefMapByObjType) => {
    const validRecents = {};

    Object.entries(recents).forEach(([id, arr]) => {
      if (Array.isArray(arr)) {
        validRecents[id] = [];
        arr.forEach(val => {
          if (isProperHref(val.href)) {
            // Only validate the objects with href
            const objType = val.href.split('/').at(-2);

            const validObject = hrefMapByObjType[objType]?.[val.href];

            if (validObject && !validObject.deleted && validObject.update_type !== 'delete') {
              validRecents[id].push({...val, ...validObject});
            }
          } else {
            validRecents[id].push(val);
          }
        });
      }
    });

    return validRecents;
  },
);

export const getLabelQueryAndKey = createSelector(
  [getLabelSetting, getTypeInitialRegExp, (_, queryString) => queryString, (state, query, keyword) => keyword],
  (typeListSettings, labelTypeInitialRegExp, query, keyword) => {
    const result = {query};

    if (keyword) {
      result.key = typeListSettings.find(
        ({display_info} = {}) =>
          display_info?.initial?.localeCompare(keyword.trim().slice(0, -1), undefined, {sensitivity: 'base'}) === 0,
      )?.key;
    }

    return result;
  },
);
