/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {forwardRefSymbol, withForwardRef} from 'react-forwardref-utils';
import {CounterBadge, Link, Icon} from 'components';
import {getTid} from 'utils/tid';
import styles from './TabPanel.css';

const linkTheme = {
  enabled: {
    link: styles.link,
    active: styles.active,
  },
  disabled: {
    link: cx(styles.link, styles.disabled),
  },
};

const counterTheme = {counter: styles.counter};

@withForwardRef()
export default class Tab extends PureComponent {
  static propTypes = {
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    tid: PropTypes.string,

    counter: PropTypes.number, // Show CounterBadge with this number
    counterColor: PropTypes.string, // Type of CounterBadge

    onClick: PropTypes.func, // Custom click handler, can prevent navigation by returning false
    onActivityChange: PropTypes.func, // Callback for handling change of link's activity state

    icon: PropTypes.node,
    iconStyle: PropTypes.string,
    iconProps: PropTypes.object,

    mergeParams: PropTypes.bool, // when true, new route params will be merged with existing params
  };

  static defaultProps = {
    iconStyle: 'warning',
    disabled: false,
  };

  render() {
    const {
      tid,
      text,
      icon,
      counter,
      counterColor,
      link,
      [forwardRefSymbol]: ref,
      iconStyle,
      iconProps = {},
      ...props
    } = this.props;

    props.ref = ref;
    props['data-tid'] = getTid('comp-tab', tid);
    props['data-tid-active'] = 'comp-tab-active';
    props.theme = linkTheme[props.disabled ? 'disabled' : 'enabled'];

    if (iconStyle) {
      iconProps.theme = {icon: styles[iconStyle], ...iconProps.theme};
    }

    if (!props.disabled) {
      props.activityTrack = true;

      if (typeof link === 'string' || link.to) {
        // When tabs link to different route, consider new route url params when marking it active
        props.activityIgnoreQuery = true;
      }

      Object.assign(props, typeof link === 'string' ? {to: link} : link);
    }

    return (
      <Link {...props}>
        {text}
        {typeof icon === 'string' ? <Icon name={icon} {...iconProps} /> : icon}
        {counter ? (
          <CounterBadge
            instantIncrement
            key="counter"
            disabled={props.disabled}
            count={counter}
            color={counterColor}
            theme={counterTheme}
          />
        ) : null}
      </Link>
    );
  }
}
