/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import intl from 'intl';
import {Icon, StatusIcon} from 'components';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {managementView} from '../../ContainerClusterDetailUtils';
import {getNamespaceTerm} from '../../../ContainerClusterUtils';
import {getContainerClusterDetail} from '../../ContainerClusterDetailState';
import {populateFacetCategory, populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getId} from 'utils/href';
import Pill from 'components/Pill/Pill';
import {getLabelTypeList, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {clickableColumn} from 'components/Grid/GridUtils';

export const resourceType = 'container_workload_profiles';

const clickableLabelConfig = (key, display_name, path) =>
  clickableColumn({
    header: display_name,
    noPadding: true,
    value: path,
    format: ({value: labelsMap, clickableRef}) => {
      const label = labelsMap?.get(key);

      return label
        ? label.map(item => (
            <Pill.Label href={item.href} type={key} ref={clickableRef}>
              {item.value}
            </Pill.Label>
          ))
        : null;
    },
    sort: ({value: labelsMap}) => {
      const label = labelsMap?.get(key);

      return label ? label.sort((a, b) => (a.value < b.value ? -1 : b.value < a.value ? 1 : 0)) : null;
    },
  });

export const categories = createSelector(
  [getContainerClusterDetail, getTypeInitialRegExp],
  (clusterDetail, labelTypeInitialRegExp) => {
    const params = {container_cluster_id: getId(clusterDetail.href)};

    return [
      populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType, params, noPartial: true}),
      populateFacetCategory({id: 'namespace', name: intl('Common.Namespace'), resourceType, params}),
      {
        id: 'enforcement_mode',
        name: intl('Common.Enforcement'),
        resources: {
          enforcement_mode: {
            noSubtitle: true,
            statics: [
              {id: 'full', value: intl('Workloads.Full')},
              {id: 'selective', value: intl('Workloads.Selective')},
              {id: 'visibility_only', value: intl('Common.VisibilityOnly')},
              {id: 'idle', value: intl('Common.Idle')},
            ],
          },
        },
      },
      ...populateLabelsCategories({resourceType, hasNoLabels: false, labelTypeInitialRegExp}),
    ];
  },
);

/**
[{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector(
  [getContainerClusterDetail, getLabelTypeList],
  (clusterDetail, labelTypeList) => ({
    id: 'cclusterprofilelist',
    sort: 'namespace',
    capacities: [25, 50, 100, 250, 500],
    capacity: 50,
    maxPage: Number.MAX_SAFE_INTEGER,
    showColumns: true,
    showCapacity: true,
    showPagination: true,
    columns: {
      checkboxes: {},
      linked: {
        headerManager: intl('ContainerClusters.ProfileLink'),
        value: ({row}) => (row.data.linked ? intl('Common.Linked') : intl('Common.Pending')),
        format: ({row, value}) =>
          row.data.linked ? <Icon name="pair" title={value} /> : <StatusIcon status="pending" title={value} />,
      },
      name: {
        header: intl('Common.Name'),
        value: 'name',
        linky: true,
        required: true,
      },
      namespace: {
        header: getNamespaceTerm(clusterDetail),
        value: 'namespace',
        required: true,
      },
      enforcementMode: {
        header: intl('Common.Enforcement'),
        value: ({row}) => enforcementModeView()[row.data.enforcement_mode].name,
      },
      visibilityLevel: {
        header: intl('Common.Visibility'),
        value: ({row}) =>
          row.data.enforcement_mode === 'idle'
            ? visibilityLevelView()[row.data.enforcement_mode].name
            : visibilityLevelView()[row.data.visibility_level].name,
      },
      management: {
        header: intl('Common.Management'),
        value: ({row}) => managementView(row.data.managed).name,
      },
      containerAnnotationLabels: {
        header: intl('ContainerClusters.ContainerAnnotationLabels'),
        horizontal: true,
        columns: labelTypeList.reduce(
          (result, {key, display_name}) => ({
            ...result,
            [key]: clickableLabelConfig(key, display_name, 'multiMap'),
          }),
          {},
        ),
        templates: labelTypeList.map(({key}) => key),
      },
      assignedLabels: {
        header: intl('ContainerClusters.AssignedLabels'),
        horizontal: true,
        columns: labelTypeList.reduce(
          (result, {key, display_name}) => ({
            ...result,
            [key]: clickableLabelConfig(key, display_name, 'singleMap'),
          }),
          {},
        ),
        templates: labelTypeList.map(({key}) => key),
      },
      noAllow: {
        header: intl('ContainerClusters.NoLabelAllowedHeader'),
        value: 'noLabelArray',
        format: ({value}) => {
          const labels = [];

          value.forEach(label => {
            labels.push(
              <div>
                <StatusIcon key="deny" status="deny" position="before" />
                {label}
              </div>,
            );
          });

          return labels;
        },
      },
      updatedAt: {
        isDate: 'L_HH_mm_ss',
        header: intl('Common.LastModifiedOn'),
        value: ({row}) => row.data.updated_at || row.data.created_at || null,
      },
      updatedBy: GridUtils.clickableColumn({
        header: intl('Common.LastModifiedBy'),
        value: ({row}) => row.data.updated_by.username,
        format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
      }),
    },

    /* Grid's breakpoints configuration */
    /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['linked'], size: 'max-content'},
        {columns: ['name'], size: 'minmax(120px, auto)'},
        {columns: ['namespace'], size: 'minmax(120px, auto)'},
        {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
        {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
        {columns: ['management'], size: 'minmax(120px, auto)'},
        {columns: ['containerAnnotationLabels'], size: 'minmax(80px, auto)'},
        {columns: ['assignedLabels'], size: 'minmax(100px, auto)'},
        {columns: ['noAllow'], size: 'minmax(120px, auto)'},
        {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
        {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
      ],
      {
        maxWidth: 1400,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['linked'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['namespace'], size: 'minmax(120px, auto)'},
            {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
            {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
            {columns: ['management'], size: 'minmax(120px, auto)'},
            {columns: ['containerAnnotationLabels'], size: 'minmax(80px, auto)'},
            {columns: ['assignedLabels'], size: 'minmax(100px, auto)'},
            {columns: ['noAllow'], size: 'minmax(120px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        },
      },
      {
        maxWidth: 1200,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['linked'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['namespace'], size: 'minmax(120px, auto)'},
            {columns: ['enforcementMode'], size: 'minmax(120px, auto)'},
            {columns: ['visibilityLevel'], size: 'minmax(120px, auto)'},
            {columns: ['management'], size: 'minmax(120px, auto)'},
            {columns: ['containerAnnotationLabels'], size: 'minmax(80px, auto)'},
            {columns: ['assignedLabels'], size: 'minmax(100px, auto)'},
            {columns: ['noAllow'], size: 'minmax(120px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        },
      },
      {
        maxWidth: 960,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['linked'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['namespace'], size: 'minmax(120px, auto)'},
            {columns: ['enforcementMode', 'visibilityLevel', 'management'], size: 'minmax(120px, auto)'},
            {columns: ['containerAnnotationLabels', 'assignedLabels', 'noAllow'], size: 'minmax(120px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        },
      },
      {
        maxWidth: 640,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['linked'], size: 'max-content'},
            {columns: ['name', 'containerAnnotationLabels', 'assignedLabels', 'noAllow'], size: 'minmax(120px, auto)'},
            {columns: ['enforcementMode', 'visibilityLevel', 'management'], size: 'minmax(120px, auto)'},
            {columns: ['namespace', 'updatedAt', 'updatedBy'], size: 'minmax(120px, auto)'},
          ];
        },
      },
    ],
  }),
);
