/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {EditorState, convertFromRaw, genKey, BlockMapBuilder, ContentBlock, Modifier} from 'draft-js';
import {getAllNonEmptyContentBlockText} from '../../DraftJSUtils';
import {ipUtils} from 'utils';
import {Map, fromJS} from 'immutable';
import _ from 'lodash';

/**
 * Set the proper 'ip' property for the specific row when there is an existing ip property
 *
 * Note: Used for existing row with "ip" properties.
 * @params {OrderedMap} - blockMap
 * @params {string} - blockKey is used to indicate the current row
 * @params {Map} - contentBlock
 * @params {object} - validationResult is an object that is used for the API
 */
export const getIpProperties = ({blockMap, blockKey, contentBlock, validationResult}) => {
  const ip = contentBlock.get('ip');

  const results = {...validationResult};

  const original = ip?.get('original');
  const type = ip?.get('type');

  if (original) {
    /** Set original to indicate row came from API. Used for modifier blue dot */
    results.original = original;
  }

  if (type) {
    results.type = type;
  }

  // Use fromJS() to set all nested Objects and Array to Immutable data types
  return blockMap.setIn([blockKey, 'data', 'ip'], fromJS(results));
};

/**
 * Convert a plain text IP to an object usable by IPListEditor
 * @param {string} rawIpText The plain text of ip
 * @param {boolean} isInterfaceIP Indicate this IP belongs to an interface so we disable some checks
 * @returns {object} IPObject
 */
export const convertIPTextToObject = (rawIpText, isInterfaceIP = false) => {
  const ip = {from_ip: rawIpText, exclusion: false, type: 'old'};

  const originalText = ipUtils.stringifyAddressObject(ip);
  const ipAddressText = !_.isEmpty(originalText) && originalText.text ? originalText.text : originalText;
  const transformedIP = ipUtils.parseAddressLine(ipAddressText, false, isInterfaceIP, false, [], {
    // disable interface prefix check if we know it is an interface ip
    noInterfaces: isInterfaceIP,
  });

  transformedIP.type = ip.type;

  /** Clone to prevent circular reference
   * Setting original is important for modification logic when editing
   */
  transformedIP.original ||= _.cloneDeep(transformedIP);

  return transformedIP;
};

/**
 * Convert raw JSON from the API for DraftJS by setting Entity and ContentBlock
 *
 * Refer: https://github.com/facebook/draft-js/blob/master/src/model/encoding/RawDraftContentState.js
 *
 * @param {object} values - values to convert
 * @param {[key: string]: RawDraftEntity} entityType - DraftJS Entity definition
 * @param {Array<RawDraftContentBlock>} blockType - DraftJS BlockContent defintion
 * @returns {RawDraftContentState} - DraftJS
 */
export const convertInitialRawIP = (
  values,
  entityType = {type: 'IP', mutability: 'MUTABLE'},
  blockType = 'unstyled',
) => {
  const rawEditorContent = {
    blocks: [],
    entityMap: {},
  };

  const defaultProperties = {
    // touched is sync with Formik's touched
    touched: true, // existing data is touched: true by default
    iconType: 'delete',
  };

  values.forEach((ipInfo, index) => {
    // Setting up initial ContentBlock
    const blockText = ipUtils.stringifyAddressObject(ipInfo);

    const block = {
      text: blockText,
      type: blockType,
      entityRanges: [{offset: 0, length: blockText.length, key: index}],
      data: {
        ...defaultProperties,
        // Initialize ip to nested Map and List datatype to be consistent with Modifier.mergeBlockData during data modification
        // Note: only Objects and Array nested are converted using fromJS() thus any other nested properties like
        // ip.fromIp.helper is not converted to Immutable types since ip.fromIp.help is an instance of Address[4,6]
        ip: fromJS(ipInfo),
      },
    };

    rawEditorContent.blocks.push(block);

    // Setting up initial Entities
    rawEditorContent.entityMap[index] = {
      data: {
        ...defaultProperties,
        ip: fromJS(ipInfo.original),
        ...ipInfo,
      },
      ...entityType,
    };
  });

  return rawEditorContent;
};

/**
 * Create the initial editorState from IP
 *
 * @params (Array<string>) - ip arary list
 * @returns {Record}
 */
export const createEditorState = (ip = []) =>
  (Array.isArray(ip) && ip.length && EditorState.createWithContent(convertFromRaw(convertInitialRawIP(ip)))) ||
  EditorState.createEmpty();

/**
 * Get all the content blocks the set then in one Map
 *
 * @params {Map} - blockMap
 * @returns {Map} - blocks
 */
export const getAllContentBlocks = blockMap => {
  let blocks = Map();

  blockMap.forEach(block => {
    const data = block.getData();
    const blockKey = block.getKey();
    const ip = data.get('ip');

    if (ip) {
      blocks = blocks.set(blockKey, ip);
    }
  });

  return blocks;
};

/**
 * Get a new initialize ip with Map data type
 *
 * @params {string} - text
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {Map} - newIp
 */
export const getIpMap = (text, parseAddressChecks, {validityCheckResult, disableValidation} = {}) => {
  // Create the validation result
  const result =
    validityCheckResult ||
    (disableValidation ? {text} : ipUtils.parseAddressLine(text, null, null, null, null, parseAddressChecks));
  // Create Map for ip by setting all nested result to Immutable Map or List
  const newIp = Map(Object.assign(fromJS(result), {type: 'new'}));

  return newIp;
};

/**
 * Get a new block map for ip property for new lines
 *
 * @params {string} - text
 * @params {string} - blockKey
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {OrderedMap} - blockMap
 * @returns {OrderedMap}
 */
export const getNewBlockIp = (text, blockKey, blockMap, parseAddressChecks, options) => {
  const newIp = getIpMap(text, parseAddressChecks, options);

  // Set ip property with newIp
  return blockMap.setIn([blockKey, 'data', 'ip'], newIp);
};

/** Get the new blockMap when a new up line is created. It is important to manipulate the specific block for validation
 *
 * @params {Map} - content
 * @params {OrderedMap}  - blockMap
 * @params {Record} - editorState's contentBlock
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @returns {OrderedMap} - newBlockMap
 */
export const getNewLineBlockMap = (content, blockMap, editorState, parseAddressChecks, options) => {
  const data = content.getData();
  const text = content.getText();
  const contentBlockKey = content.getKey();
  let newBlockMap = blockMap;

  if (!data.has('ip') && text) {
    // Newline - cursor at the beginning or between text
    blockMap.forEach(block => {
      const blockText = block.getText();
      const blockData = block.getData();

      if (blockData.size && blockData.get('ip')) {
        // Newline - cursor at the beginning
        const blockIp = blockData.get('ip');
        const blockIpText = blockIp.get('text') || '';

        // Look for the block that has empty text with an ip property to
        // indicate this block existed but the text was deleted.
        // Note: DraftJS will create a new up row but the existing data property will still exist thus need to change
        // specific blockMap keys
        if (!blockText && blockIp && blockIpText === text) {
          const blockKey = block.getKey();
          // Set the data block with current ip and set touched: true
          const newData = data.set('ip', blockData.get('ip')).set('touched', true);

          // Set newIp to specific blockKey
          newBlockMap = getNewBlockIp(blockText, blockKey, newBlockMap, parseAddressChecks, options);
          // Set newData to specific contentBlockKey
          newBlockMap = newBlockMap.setIn([contentBlockKey, 'data'], newData);

          // Break out of the forEach loop for Immutable Map objects
          return false;
        }
      } else {
        // New Line is created in the middle of text
        newBlockMap = getNewBlockIp(text, contentBlockKey, newBlockMap, parseAddressChecks, options);

        const contentState = editorState.getCurrentContent();
        const getBlock = contentState.getBlockBefore(contentBlockKey);

        // Check to make sure the blockBefore exist, cases where the first line doesn't have a block before will fail
        // thus need to check.
        // Note: Check is made when hitting 'Enter' in the middle of a text.
        if (getBlock) {
          const text = getBlock.getText();
          const data = getBlock.getData();
          const key = getBlock.getKey();
          const ip = data.get('ip');
          const ipText = ip.get('text');

          // When text from DraftJS doesn't match ip: {text}, then need to the ip block object. This happens when hitting 'Enter'
          // in the middle of the text.
          if (ipText !== text) {
            newBlockMap = getNewBlockIp(text, key, newBlockMap, parseAddressChecks, options);
          }
        }

        // Break out of the forEach loop for Immutable Map objects
        return false;
      }
    });
  } else {
    // New Line - cursor at the end
    newBlockMap = getNewBlockIp(text, contentBlockKey, newBlockMap, parseAddressChecks, options);
  }

  return newBlockMap;
};

/**
 * When there is a toggle between validation and no validation. Need to loop through each of the rows (block) to
 * set the proper checks or no checks.
 *
 * @params {Record} - editorState's contentBlock
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {boolean} - disableValidation
 */
export const parseValidation = ({editorState, parseAddressChecks, disableValidation}) => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  let newBlockMap = blockMap;

  blockMap.forEach(block => {
    const blockText = block.getText();
    const blockData = block.getData();

    const blockKey = block.getKey();

    if (disableValidation) {
      /** Disable validation on each row */
      const noValidationResult = ipUtils.parseAddressNoValidation({
        line: blockText,
        addressCheckToggle: parseAddressChecks,
      });

      newBlockMap = getIpProperties({
        blockMap: newBlockMap,
        blockKey,
        contentBlock: blockData,
        validationResult: noValidationResult,
      });
    } else {
      /** Proceed with validation on each row */
      const validationResult = ipUtils.parseAddressLine(
        blockText,
        undefined,
        undefined,
        undefined,
        undefined,
        parseAddressChecks,
      );

      newBlockMap = getIpProperties({blockMap: newBlockMap, blockKey, contentBlock: blockData, validationResult});
    }
  });

  return newBlockMap;
};

/** Get the IP validation
 *
 * @params {Map} - contentBlock
 * @params {OrderedMap} - blockMap
 * @params {Record} - editorState's contentBlock
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @returns {Record} - object with validations
 */
export const getIpValidation = ({
  contentBlock,
  blockMap,
  editorState,
  parseAddressChecks,
  disableValidation,
  isSingle,
  isInterface,
  allowBrn,
}) => {
  // Don't need to validate undefined or delete blocks
  if (!contentBlock) {
    return Map();
  }

  const text = contentBlock.getText();
  const data = contentBlock.getData();
  const result = !disableValidation
    ? ipUtils.parseAddressLine(text, isSingle, isInterface, allowBrn, undefined, parseAddressChecks)
    : ipUtils.parseAddressNoValidation({line: text, addressCheckToggle: parseAddressChecks});
  const blockKey = contentBlock.getKey();

  let newBlockMap = blockMap;

  // Any new content or deleted content will not have an 'ip' property
  // thus will not process any validation
  if (data.has('ip')) {
    newBlockMap = getIpProperties({blockMap, blockKey, contentBlock: data, validationResult: result});
  } else {
    // New Line Block
    newBlockMap = getNewLineBlockMap(contentBlock, blockMap, editorState, parseAddressChecks, {
      validityCheckResult: result,
    });
  }

  /** Toggle to invoke validateOrderedMapAddresses */
  if (!disableValidation) {
    newBlockMap = ipUtils.validateOrderedMapAddresses(newBlockMap);
  }

  return newBlockMap;
};

/** Get the updated Ip validation then merge the validation to existing data. Once data is updated
 * return a new editorState. Method is always called to compare all blockMap to make sure there aren't issues.
 *
 * @params {Record} - editorState
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {boolean} disabledValidatoion
 * @params {boolean} forceParse is used to indicate if blockMap needs to re-render when user toggles 'disabledValidation'
 * @returns {Record} - newEditorState | editorState
 */
export const getUpdatedContentValidationState = ({
  editorState,
  parseAddressChecks,
  disableValidation,
  forceParse,
  isInterface,
}) => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  const selection = editorState.getSelection();
  const focusKey = selection.getFocusKey();
  const contentBlock = blockMap.get(focusKey);

  let newBlockMap;

  if (forceParse) {
    /** Loop through the blocks to validate or disable validation when toggle is performed */
    newBlockMap = parseValidation({editorState, parseAddressChecks, disableValidation});

    if (!disableValidation) {
      /** Need to validate the previous information for issues like overlapping, etc... */
      newBlockMap = ipUtils.validateOrderedMapAddresses(newBlockMap);
    }
  } else {
    /** Content is undefined when block is deleted thus need to update. */
    newBlockMap = contentBlock
      ? getIpValidation({
          contentBlock,
          blockMap,
          editorState,
          parseAddressChecks,
          disableValidation,
          isInterface,
        })
      : disableValidation
      ? blockMap
      : ipUtils.validateOrderedMapAddresses(blockMap);
  }

  /** New content to merge changed blockMap to currentContent */
  const newContent = currentContent.merge({
    blockMap: newBlockMap,
    selectionAfter: selection,
  });

  // Use EditorState.set() here to preserve the undo stack properly to prevent the undoStack from constantly being
  // appended especially during 'Ctrl-z' undo
  // Note:
  // a) EditorState.push() will constantly append to undoStack() which is something we don't want here which is causing
  // the undo to not work especially with 'Ctrl-z' for undo
  // b) Don't need to call EditorState.forceSelection() because don't need to force a re-render, will let DraftJS cycle handle
  // cursor position
  const newEditorState = EditorState.set(editorState, {currentContent: newContent});

  return newEditorState;
  // Pass in most current selection to preserve the cursor position
  // NOTE: Without this code the cursor will not work properly
  // By calling this method; onFocus is not being called
  //newEditorState = EditorState.forceSelection(newEditorState, selection);

  //return newEditorState;
};

/** Get all ip values from User's input by parsing the editorState
 *
 * @params {Record} - editorState
 * @returns {Array<string>} - ip
 */
export const getAllIpValues = editorState => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  const ip = [];

  blockMap.forEach(block => {
    const text = block.getText();

    ip.push(text);
  });

  return ip;
};

/**
 * Get the current editorState value and boolean check to determine if they are equivalent
 * with previous data source
 *
 * @params {Array<string>} initialIp - initial ip string declaration
 * @param {Record} editorState - editorState object
 * @returns {object} - currentValue
 */
export const getCurrentDataCheck = (initialIp, editorState) => {
  let currentValue = getAllNonEmptyContentBlockText(editorState);

  // Use xor to determine symmetric difference - an empty difference indicates data are the same thus order doesn't matter
  // Note: using _.difference will not work here
  const differences = _.xor(initialIp, currentValue);

  if (differences.length === 0) {
    currentValue = initialIp;
  }

  return {
    currentValue,
  };
};

/**
 * Determine if there is an error or duplicate on a block either touched or untouched.
 *
 * @params {OrderedMap} - blockMap
 * @returns {object} - boolean value for error, untouchedError
 */
export const getError = blockMap => {
  let error = false;
  let untouchedError = false;

  // Note: blockMap is an OrderedMap with a forEach loop that can return false to end loop.
  // e.g. https://immutable-js.github.io/immutable-js/docs/#/OrderedMap/forEach
  blockMap.forEach(block => {
    const data = block.getData();
    const ip = data.get('ip');

    if (ip) {
      // When an error or duplicate exist then set error property
      if (ip.get('error') || ip.get('duplicate')) {
        // untouchedError is an error that exist but the block hasn't been touched and it not the original item
        if (!data.get('touched') && !ip.get('original')) {
          untouchedError = true;

          return false;
        }

        // Count error for new items that has been touched e.g. data.get('touched') or original item e.g. ip.get('original')
        if (data.get('touched') || ip.get('original')) {
          error = true;

          return false;
        }
      }
    }
  });

  return {
    // error that is on a touched block or block that is with original data
    error,
    // untouchedError is an error that is on an untouched block
    untouchedError,
  };
};

/**
 * Get the current blockMap status: (error, exclusion) counts. Error counts is part of any entry with duplicate field.
 * However, when there are multiple rows with the exact same value, don't count the first.
 *
 * @params {OrderedMap} - blockMap
 * @returns {object} - counts for error, exclusion
 */
export const getErrorStatusCount = blockMap => {
  let error = 0;
  let warning = 0;
  let exclusion = 0;

  blockMap.forEach(block => {
    const data = block.getData();
    const ip = data.get('ip');

    if (ip) {
      // Show exclusion
      if (ip.get('exclusion')) {
        exclusion++;
      }

      if (ip.get('error') || ip.get('duplicate') || ip.get('warning')) {
        // Count error for new items that has been touched e.g. data.get('touched') or original item e.g. ip.get('original')
        if (data.get('touched') || ip.get('original')) {
          // When there is a 'duplicate' without 'toolTipErrorLineToReference' then we need to count.
          // 'toolTipErrorLineToReference' was used to indicate first occurrence of duplicate, thus avoid counting the ip block with
          // 'toolTipErrorLineToReference'.
          //  [1] 10.1.1.1   // Don't count this duplicate because this block has 'toolTipErrorLineToReference'
          //  [2] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1" - Count this duplicate
          //  [3] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1" - Count this duplicate
          // Errors: 2 - since not counting the first occurrence
          if (ip.get('duplicate') && !ip.get('toolTipErrorLineToReference')) {
            // Increment errors for all duplicate except those with 'toolTipErrorLineToReference'
            error++;
          }

          if (ip.get('error')) {
            // Increment any errors
            error++;
          }

          if (ip.get('warning')) {
            /** Always show one */
            warning = 1;
          }
        }
      }
    }
  });

  const errors = {
    // set error counts that is on a touched block or block that is with original data
    error,
    // set exclusion counts that is on a touched block or block that is with original data
    exclusion,
    // set warning
    warning,
  };

  return errors;
};

// Delimiters used for separating pasted text. This list can be added for other delimiters as required
export const delimiters = [',', '\n'];

/**
 * Get a list of the pasted text base on delimiter values
 *
 * @params {string} text
 * @params {Array} delimiter
 * @returns {Array<string>} list
 */
export const getConvertedPastedTextToList = (text, delimiter = delimiters, preserveWhitespace = false) => {
  let startIndex = 0;
  const list = [];
  const textLen = text.length;
  const maxIndex = textLen - 1;

  for (let i = 0; i < textLen; i++) {
    // Check for end of string or matching delimiter
    if (delimiter.includes(text[i]) || i === maxIndex) {
      let endIndex = i;

      if (i === maxIndex) {
        // Pasting one single line without any delimiter
        endIndex = textLen;
      }

      // Slice text up to endIndex
      let textMatch = text.slice(startIndex, endIndex);

      if (!preserveWhitespace) {
        // Replace empty spaces
        textMatch = textMatch.replace(/\s+/g, '');
      }

      // Don't push to list when textMatch is empty
      // This string can cause empty value because empty delimiter value
      // e.g. 10.1.11.1,,,
      if (textMatch.trim()) {
        list.push(textMatch);
      }

      // Set new starting index
      startIndex = i + 1;
    }
  }

  return list;
};

/**
 * Create content states as a blockMap for a fragment type used in Modifier.replaceWithFragment
 *
 * @params {Array<string>} list
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {boolean} - disableValidation
 * @returns {Array<ContentBlock>} contentBlocks
 */
export const createPastedContentState = (list, parseAddressChecks, disableValidation) => {
  const contentBlocks = list.reduce((contents, item) => {
    // Note: Pasted items are touched by default
    const data = Map({touched: true, ip: getIpMap(item, parseAddressChecks, {disableValidation})});

    contents.push(
      new ContentBlock({
        key: genKey(), // create unique key
        type: 'unstyled', // default
        data, // data is used for logical parsing: error checks, duplicates, touched
        text: item,
      }),
    );

    return contents;
  }, []);

  return contentBlocks;
};

/**
 * Set the pasted text into the focus cursor or selected text
 *
 * @params {Record} - editorState
 * @params {Array<string>} - list
 * @params {Object<ip::parseAddressChecks>} - parseAddressChecks
 * @params {boolean} - disableValidation
 * @returns {Record} - newEditorState
 */
export const setPastedText = (editorState, list, parseAddressChecks, disableValidation) => {
  const currentContent = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let newEditorState = editorState;

  const newContents = createPastedContentState(list, parseAddressChecks, disableValidation);

  // Use BlockMapBuilder.createFromArray to create blockMap for Modifier.replaceWithFragment
  const fragments = BlockMapBuilder.createFromArray(newContents);

  // selectionState - is the position of the selected text or non-selected text(position of the cursor without selected text)
  const newContentState = Modifier.replaceWithFragment(currentContent, selectionState, fragments);

  newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

  return newEditorState;
};

/**
 * Check if User selected all the fields
 *
 * @param {Record} - editorState
 * @returns {boolean} - true - selection all, false - did not select all
 */
export const isSelectionAll = editorState => {
  const selectionState = editorState.getSelection();
  const selectionAnchorKey = selectionState.getAnchorKey();
  const selectionFocusKey = selectionState.getFocusKey();
  const blockMap = editorState.getCurrentContent().getBlockMap();

  let count = 0;
  let start = 0;
  let end = 0;

  blockMap.forEach((val, key) => {
    if (key === selectionAnchorKey) {
      start = count;
    }

    if (key === selectionFocusKey) {
      end = count;
    }

    count++;
  });

  return end - start === blockMap.size - 1;
};

/**
 * Get pasted text range for max allowed ip when text is selected from different rows
 *
 * @param {Record} - editorState
 * @param {number} - maxAllowedIP
 * @returns {boolean} - true - selection all, false - did not select all
 */
export const getSelectedRangeMaxAllowedPastedText = ({editorState, maxAllowedIP}) => {
  const selectionState = editorState.getSelection();
  const selectionAnchorKey = selectionState.getAnchorKey();
  const selectionFocusKey = selectionState.getFocusKey();
  const selectionIsBackward = selectionState.getIsBackward();

  let count = 0;
  let start = 0;
  let end = 0;
  let blockMap = editorState.getCurrentContent().getBlockMap();

  if (selectionIsBackward) {
    /** When selecting range from bottom up need to reverse order because
     * the anchorKey starts from the end of the blockMap */
    blockMap = blockMap.reverse();
  }

  blockMap.forEach((val, key) => {
    if (key === selectionAnchorKey) {
      /** Find the selectionAnchorKey (start of the selected range) of the highlighted row */
      start = count;
    }

    if (key === selectionFocusKey) {
      /** Find the selectionFocusKey (the end of the selected range) of the highlighted row */
      end = count;
    }

    count++;
  });

  /** (end - start) is the distance between start and end. Adding +1 to include total selected rows */
  const selectedRows = end - start + 1;

  /** e.g. 8 - (5 - 2) = 5  , 5 is the total amount of additional rows that can be added */
  const max = maxAllowedIP - (blockMap.size - selectedRows);

  return max;
};

/**
 * Get new editor state and index where cursor was last focused on single line
 *
 * @param {Record} - editorState
 * @param {number} - maxAllowedIP
 * @returns {object} - newEditorState, and matched (index where the cursor was focused when pasting text)
 */
export const getSingleRowMaxAllowedPastedText = ({editorState, maxAllowedIP}) => {
  const selectionState = editorState.getSelection();
  const selectionAnchorKey = selectionState.getAnchorKey();
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  let count = 0;
  let startDelete = false;
  let newBlockMap;
  let index = 0;
  let currentEditorState = editorState;

  blockMap.forEach((val, key) => {
    if (key === selectionAnchorKey) {
      startDelete = true;
      // index where the cursor was focused when pasting text
      index = count;
    }

    if (startDelete && key !== selectionAnchorKey) {
      if (newBlockMap) {
        // When newBlockMap exist then use the new blockMap
        newBlockMap = newBlockMap.delete(key);
      } else {
        // When newBlockMap doesn't exist then use the current from the editorState
        newBlockMap = content.getBlockMap().delete(key);
      }
    }

    count++;
  });

  if (newBlockMap) {
    // New content to merge changed blockMap
    const newContent = content.merge({
      blockMap: newBlockMap,
      selectionAfter: selectionState, // current deleted block
    });

    // Get the newEditorState
    // by using EditorState.push() to save the current selectionState to the undo stack which
    // is used to undo.
    currentEditorState = EditorState.push(editorState, newContent, 'remove-range');
  }

  return {newEditorState: currentEditorState, max: maxAllowedIP - index};
};
