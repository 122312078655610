/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useCallback, useRef, useState} from 'react';
import {domUtils} from 'utils';
import {KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP} from 'keycode-js';
import styleUtils from 'utils.css';

import styles from './Selector.css';

// Escape Regex http://stackoverflow.com/questions/3115150/#answer-9310752
const ESCAPE = str => str.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');

/**
 * Returns true if element rect is in the direction of navigation from base rect
 */
const isElementInDirection = ({direction, rect, elementRect}) => {
  let isInDirection = false;

  switch (direction) {
    case KEY_UP:
      isInDirection = Math.floor(elementRect.bottom) <= Math.floor(rect.top);
      break;
    case KEY_DOWN:
      isInDirection = Math.floor(elementRect.top) >= Math.floor(rect.bottom);
      break;
    case KEY_RIGHT:
      isInDirection = Math.floor(elementRect.left) >= Math.floor(rect.right);
      break;
    case KEY_LEFT:
      isInDirection = Math.floor(elementRect.right) <= Math.floor(rect.left);
      break;
  }

  return isInDirection;
};

/**
 * Returns the closest element in a direction from a reference point
 * @param {*} [childrenPropsMap] - id and ref Map of input elements
 * @param {*} [rect] - reference point rect
 * @returns {Object}
 */
const getClosestElement = ({direction, elementsMap, rect = {}} = {}) =>
  [...Array.from(elementsMap)].reduce((result, [id, elementProps = {}]) => {
    const elementRect = elementProps.element?.getBoundingClientRect() ?? {};

    if (elementRect.top === elementRect.bottom) {
      // Hidden element
      return result;
    }

    if (isElementInDirection({direction, elementRect, rect})) {
      const distance = (elementRect.left - rect.left) ** 2 + (elementRect.top - rect.top) ** 2;

      if (!result.minDistance || distance < result.minDistance) {
        return {id, ...elementProps, minDistance: distance};
      }
    }

    return result;
  }, {});

/**
 * A custom hook to:
 * 1. store a component's child elements information in a Map, E.g. their refs, keyDown and highlighted handlers
 * 2. capture which child is in highlighted and set highlighted among its children
 */
export const useFilialPiety = () => {
  const childrenPropsMap = useRef(null); //[id, {element, setHighlightedChild, keyDown}]
  const [highlightedChild, highlightedSetter] = useState(null); // {id, element}
  const highlightedChildRef = useRef(); // Ref to store current highligtedChild, added to remove any dependency in setHighlightedChild

  childrenPropsMap.current ||= new Map();

  const saveChildRef = useCallback(
    (id, element) => childrenPropsMap.current.set(id, {element: element?.element ?? element}),
    [],
  );

  const registerChildHandlers = useCallback((id, handlers = {}) => {
    const {element} = childrenPropsMap.current.get(id) ?? {};

    childrenPropsMap.current.set(id, {element, ...handlers});

    return () => childrenPropsMap.current.delete(id); //Return a function to remove handlers (called during unmount)
  }, []);

  /*
  If we draw Selector component in a tree then each branch of this tree can be considered a pathArr,
  each node of this tree stores information on which of its child is highlighted (highlightedChild state),
  leaf nodes of this tree renders a set of options or selected values (<li> elements)
                                  input
                                /       \
      categoryPanel <--- dropdown            valuePanel
                        /        \            /   |...  \
              categoryPanel    optionPanel   R1    R2 ... Rn
             /   |...  \        /   |...  \                 \
           R1    R2 ... Rn    R1    R2 ... Rn             selected values
          /      |            /
     options  options....   option

  Examples of pathArr: If an option in resource R1 (lets assume R1 is in optionPanel) is highlighted then
  1. pathArr from input: ['dropdown', 'optionPanel', 'R1']
  2. pathArr from dropdown: ['optionPanel', 'R1']
  3. pathArr from optionPanel: ['R1']
  4. pathArr from resource R1: []
 */

  const resetHighlightedChild = useCallback((pathArr = []) => {
    highlightedChildRef.current = null;
    highlightedSetter(null);

    // we need to recursively call resetHighlightedChild of nodes along the pathArr
    return childrenPropsMap.current.get(pathArr.shift())?.resetHighlightedChild?.(pathArr);
  }, []); // NOTE: Adding a dependency to resetHighlightedChild will invoke component unmount which will delete its ref

  const setHighlightedChild = useCallback((options = {}) => {
    /*
      options: {pathArr, newHighlightedId, rect}
      There are three scenarios when setting highlighted on an option:
      1) No highlight exists - in this case the arguments are input element rect (root node) and direction of navigation
      2) highlighted option exists -
         in this case the arguments are direction of navigation, highlighted option rect and pathArr
      3) hover on an option - arguments are pathArr and optionId

      To set highlight on an option we need to know:
        a) its relative path from input element (tree branch)
        b) set of parameters to identify the option i.e. optionId Or rect & direction
      In scenario three both information are already provided to the function
    */
    if (_.isEmpty(options)) {
      return;
    }

    const {newHighlightedId, direction} = options;
    const pathArr = [...(options.pathArr ?? [])];
    const childrenMap = new Map(childrenPropsMap.current);
    const rect = highlightedChildRef.current?.element?.getBoundingClientRect() ?? options.rect;

    let closestElement = {};

    while (true) {
      if (pathArr.length > 0) {
        closestElement = {id: pathArr[0], ...childrenMap.get(pathArr[0])};
      } else {
        closestElement = newHighlightedId
          ? {id: newHighlightedId, element: childrenMap.get(newHighlightedId).element}
          : getClosestElement({direction, elementsMap: childrenMap, rect});
      }

      if (!closestElement?.element) {
        // (code tag #leave) If there is no child element in the direction Or if element is null
        // returning undefined will move highlighted search to its sibling node
        highlightedChildRef.current = null;
        highlightedSetter(null);

        return;
      }

      highlightedChildRef.current = closestElement;

      if (!closestElement.setHighlightedChild) {
        // (code tag #found) If setHighlightedChild is undefined then we have reached the leaf node
        // Scroll to the element if it is hidden and return its pathArr and rect.
        // Set this element as highlighted.
        const infoPanelElement = closestElement.element.parentElement?.parentElement?.querySelector(
          `.${styles.infoPanel}`,
        );

        // Scroll to the element if needed
        domUtils.scrollToElement({
          element: closestElement.element,
          ...(infoPanelElement && {offsetElements: [infoPanelElement]}),
        });

        highlightedSetter(closestElement);

        return [pathArr, closestElement.element.getBoundingClientRect()];
      }

      // Reset highlighted on parent, highlighted state moves to nested child
      highlightedSetter(null);

      // (code tag #enter)
      // Restore scroll position if it is a list resource
      const listResourceElement = closestElement.element.querySelector(`.${styles.listResource}`);
      const skipScrollRestore =
        pathArr.includes(closestElement.id) || // Highlight exists in this path
        direction === KEY_UP; // Highlight state enters the listResource at scroll height

      if (!skipScrollRestore && listResourceElement && listResourceElement?.scrollTop) {
        listResourceElement.scrollTop = 0;
      }

      const [highlightedElementPathArr, highlightedRectRef] =
        closestElement.setHighlightedChild({...options, pathArr: pathArr.slice(1), rect}) ?? [];

      if (!highlightedRectRef) {
        // A recursive call has returned undefined (code tag #leave) i.e. no highlighted child found in the direction
        // If pathArr exists then remove this node from path Arr and continue the closest node search.
        const index = pathArr.indexOf(closestElement.id);

        if (index !== -1) {
          pathArr.splice(index);
        }

        childrenMap.delete(closestElement.id);

        continue;
      }

      // highlighted option is found (code tag #found) then concatenate the node to pathArr and return
      return [[closestElement.id, ...(highlightedElementPathArr ?? [])], highlightedRectRef];
    }
  }, []); // NOTE: Adding a dependency to highlightedSetter will invoke component unmount which will delete its ref

  const keyDown = useCallback((evt, {pathArr = []}) => {
    if (childrenPropsMap.current.get(pathArr[0])?.keyDown) {
      // Recursively traverse the pathArr to delegate keyDown event to the child component which is managing highlighted
      return childrenPropsMap.current.get(pathArr[0]).keyDown(evt, {pathArr: pathArr.slice(1)});
    }
  }, []);

  return {
    childrenPropsMap: childrenPropsMap.current,
    saveChildRef,
    registerChildHandlers,
    highlightedChild,
    highlightedChildRef,
    setHighlightedChild,
    resetHighlightedChild,
    keyDown,
  };
};

/**
 * Bold or underline the text match in each string
 */
export const getHighlightedText = ({query, text, bold = false} = {}) => {
  if (!query.trim() || query.length === 0 || !text) {
    return text;
  }

  const regex = new RegExp(`(${ESCAPE(query)})`, 'i');
  const matches = text.split(regex).map((text, index) => {
    const highlight = text.toLowerCase() === query.toLowerCase();
    const highlightStyle = bold ? styleUtils.bold : styles.highlightTextUnderline;

    return (
      <span key={index} className={highlight ? highlightStyle : ''}>
        {text}
      </span>
    );
  });

  return <span>{matches}</span>;
};
